import React from 'react';
import { Container, Box, InputBase, Paper, Link, Stack, Typography } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import Chat from '@mui/icons-material/Chat';
import { LoadingButton } from '@mui/lab';
import SourceList from '../../components/SourceList';
import { CopyPanel } from 'src/components/CopyPanel';
import { PageTitle } from 'src/components/PageTitle';
import { AlertMessage } from 'src/components/AlertMessage';
import useSettings from 'src/hooks/useSettings';
import { Grid } from '@mui/material';
import { usePersonalDocSearchPageModel } from './usePersonalDocSearchPageModel';

export const PersonalDocSearchPage = () => {
  const { themeMode } = useSettings();
  const pm = usePersonalDocSearchPageModel();

  // ハイパーリンクを設定するURL
  const linkUrl = '/doc-search/personal/upload';

  const description = pm.translate('document-personal-search.text.description');
  const descriptionHere = pm.translate('document-personal-search.text.here');

  // 改行と「こちら」を処理
  const descriptionWithLink = description.split('\n').map((part, index) => {
    const parts = part.split(descriptionHere);
    return (
      <React.Fragment key={index}>
        {parts.map((subPart, subIndex) => (
          <span key={subIndex}>
            {subPart}
            {/* 「こちら」のリンクを追加 */}
            {subIndex < parts.length - 1 && (
              <Link href={linkUrl} target="_blank" rel="noopener noreferrer">
                {descriptionHere}
              </Link>
            )}
          </span>
        ))}
        {/* 各行の後に改行を追加（ただし、「こちら」の後は追加しない） */}
        {index < description.split('\n').length - 1 && <br />}
      </React.Fragment>
    );
  });

  return (
    <Page title={pm.translate('document-personal-search.text.pageTitle')}>
      <Container maxWidth="md">
        <Box display="flex" justifyContent="space-between">
          <PageTitle text={pm.translate('document-personal-search.text.pageTitle')} />
        </Box>
        <Grid item sx={{ marginBottom: 1 }}>
          <Typography component={'p'} fontSize={'14px'} maxWidth={'800px'}>
            {descriptionWithLink}
          </Typography>
        </Grid>{' '}
        <Stack sx={{ alignItems: 'center', paddingY: '5px' }}>
          <AlertMessage message={pm.translate('document-personal-search.text.alertMessage')} color={themeMode === 'light' ? 'black' : 'white'} />
        </Stack>
        <Paper
          component="form"
          sx={{
            marginTop: '10px',
            marginBottom: '10px',
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
          }}>
          <InputBase
            id="userMessageInput"
            sx={{
              ml: 1,
              flex: 1,
              minHeight: '38px',
            }}
            value={pm.resultState.request.inputText}
            onChange={pm.inputBaseOnChange}
            placeholder={pm.translate('common.text.questionBox')}
            multiline
            minRows={5}
            maxRows={5}
          />
        </Paper>
        <Grid container xs={12} justifyContent={'center'}>
          <Grid item xs={12} sm={6} mt={1}>
            <LoadingButton
              onClick={pm.handleSubmitButtonClick}
              startIcon={<Chat />}
              type="submit"
              variant="contained"
              loading={pm.isSubmitting}
              fullWidth
              disabled={pm.disabled}>
              {pm.translate('common.button.question')}
            </LoadingButton>
          </Grid>
        </Grid>
        <CopyPanel
          tooltipOpen={pm.isOpenCopyTip}
          tooltipClose={pm.handleCloseTip}
          tooltipTitle={pm.copiedMsg}
          btnClick={pm.handleCopyButtonClick}
          btnLabel={pm.copyBtnLabel}
          contents={pm.dsResponse}
        />
        <Box>{pm.dsResponse && <SourceList title={pm.translate('document-personal-search.text.source')} arr={pm.dsSource} />}</Box>
      </Container>
    </Page>
  );
};
