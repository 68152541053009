import { Navigate, RouteObject } from 'react-router-dom';
import { RelaseNote } from '../paths';
import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { USER_ROLES } from 'src/constants';
import { RELEASE_NOTES_V1_X } from '../paths';
import { ReleaseNotePage } from 'src/pages/release-notes-page/ReleaseNotePage';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';

const createChildren = (reloaseNotes: RelaseNote[]) => {
  const items: { path: string; element: React.ReactNode }[] = [
    {
      path: '',
      element: <Navigate to={RELEASE_NOTES_V1_X[0].path} replace />,
    },
  ];
  reloaseNotes.forEach((reloaseNote, index) => {
    items.push({
      path: RELEASE_NOTES_V1_X[index].path,
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER, USER_ROLES.GPT_USER]}>
          <ReleaseNotePage />
        </RoleBasedGuard>
      ),
    });
  });
  return items;
};

export const releaseNotesRoute: RouteObject = {
  path: 'release-notes',
  element: <AuthGuardLayout />,
  children: createChildren(RELEASE_NOTES_V1_X),
};
