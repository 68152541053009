import { Link, Typography, Stack, Grid, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { SourceType } from '../redux/slices/docSearchSlice';
import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DownloadIcon from '@mui/icons-material/Download';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import StraightenIcon from '@mui/icons-material/Straighten';
interface Props {
  title: string;
  arr: SourceType;
}
const SourceList: React.FC<Props> = React.memo((props) => {
  const { arr, title } = props;

  const theme = useTheme();
  const isLightMode = theme.palette.mode === 'light';

  const groupedArray: { name: string; source: SourceType }[] = [];
  for (const obj of arr) {
    groupedArray.push({
      name: obj.name,
      source: [obj],
    });
  }
  groupedArray.sort((a, b) => {
    const scoreA = Number(a.source[0].score); // scoreをnumber型として扱う
    const scoreB = Number(b.source[0].score);
    return scoreB - scoreA; // 降順
  });
  /**
   * タイトルの表示文字数を調整する
   * @param title title
   * @return list
   */
  const convertTitle = (title: string) => {
    const TITLE_LENGTH = 40;
    if (title.length > TITLE_LENGTH) {
      return title.slice(0, TITLE_LENGTH) + '....';
    }
    return title;
  };

  return (
    <>
      {/* TODO:サイズを少し大きく */}
      <Typography fontSize={'1.1em'} fontWeight={600}>
        {title}
      </Typography>
      <div>
        {groupedArray.map((data, index) => {
          const content = data.source.map((obj) => obj.content).join('<br><br>');
          return (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        // alignItems="baseline"
                        spacing={0}>
                        <Link
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          href={data.source[0].url}
                          target="_blank"
                          rel="noopener">
                          <Tooltip title="ファイルをダウンロード">
                            <DownloadIcon sx={{ marginX: '5px' }} />
                          </Tooltip>
                        </Link>
                        <Tooltip title={data.name}>
                          <Typography>{convertTitle(data.name)}</Typography>
                        </Tooltip>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack
                        direction="row"
                        alignItems="center"
                        // justifyContent="flex-end"
                        // alignItems="baseline"
                        spacing={1}
                        sx={{ fontSize: '1em', color: isLightMode ? 'gray' : 'white' }}>
                        <Tooltip title="コンテンツ長">
                          <StraightenIcon sx={{ fontSize: '1em' }} />
                        </Tooltip>
                        <Typography sx={{ fontSize: '1em' }}>{content.length}</Typography>
                        <Tooltip title="Score">
                          <EqualizerIcon sx={{ fontSize: '1em' }} />
                        </Tooltip>
                        <Typography sx={{ fontSize: '1em' }}>{data.source[0].score}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ color: isLightMode ? 'gray' : 'white' }}>{content}</Typography>
                </AccordionDetails>
              </Accordion>
            </>
          );
        })}
      </div>
    </>
  );
});

export default SourceList;
