import { Mic, MicOff } from '@mui/icons-material';
import { Button, Card, Dialog, Stack, Typography } from '@mui/material';

export interface RecordingDialogProps {
  isOpen: boolean;
  isRecording: boolean;
  onClick: () => void;
  onClosed: () => void;
  text: () => string;
}

/**
 * 録音用のダイアログ
 * @param props パラメータ
 * @returns レンダリングコンポーネント
 */
export const RecordingDialog = (props: RecordingDialogProps) => {
  const { isOpen, isRecording, text } = props;
  const { onClick, onClosed } = props;
  return (
    <Dialog open={isOpen} onClose={onClosed}>
      <Card sx={{ p: 4, minWidth: 200 }}>
        <Stack direction={'column'} justifyContent={'center'} spacing={1}>
          <Stack direction={'row'} justifyContent={'center'}>
            <Button onClick={onClick}>{isRecording ? <Mic sx={{ fontSize: 64 }} /> : <MicOff sx={{ fontSize: 64, color: 'gray' }} />}</Button>
          </Stack>
          <Stack direction={'row'} justifyContent={'center'}>
            <Typography sx={{ maxWidth: 100 }} variant={'body2'} children={text} />
          </Stack>
        </Stack>
      </Card>
    </Dialog>
  );
};
