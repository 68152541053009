import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { apiGateway, prefixApiGateway } from 'src/config';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { useTextToSpeech } from 'src/hooks/useTextToSpeech';
import { Country, Gender, defaultSpeed, voice } from 'src/models/voice';

export const useTextToSpeechPage = () => {
  const { tts } = useTextToSpeech();
  const snackbar = useSnackbar();

  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [text, setText] = useState('');
  const [gender, setGender] = useState('男性');
  const [lang, setLang] = useState('日本語');
  const genderList = [
    { message: '男性', value: 'male' },
    { message: '女性', value: 'female' },
  ];
  const langList = [
    { message: '日本語', value: Country.JA },
    { message: '英語', value: Country.EN },
    { message: 'フランス語', value: Country.FR },
    { message: 'スペイン語', value: Country.ES },
    { message: 'アラビア語', value: Country.AR },
    { message: '中国語', value: Country.ZH },
    { message: 'ヒンディー語', value: Country.HI },
    { message: 'インドネシア語', value: Country.ID },
    { message: 'ポルトガル語', value: Country.PT },
    { message: 'ドイツ語', value: Country.DE },
    { message: 'イタリア語', value: Country.IT },
    { message: 'タイ語', value: Country.TH },
    { message: '韓国語', value: Country.KO },
    { message: 'ロシア語', value: Country.RU },
    { message: 'スワヒリ語', value: Country.MS },
    { message: 'マレー語', value: Country.SW },
  ];

  const selectGender = (_: any, value: string | null) => {
    if (value) {
      setGender(value);
    }
  };

  const selectLang = (_: any, value: string | null) => {
    if (value) {
      setLang(value);
    }
  };

  const onTTS = async () => {
    await tts(text).catch(() => {
      snackbar.error('Failed to request.');
    });
  };

  const azureTTS = async () => {
    const langValue = langList.find((item) => item.message === lang)!.value;
    const genderValue = genderList.find((item) => item.message === gender)!.value as Gender;
    const body = {
      text: text,
      language: langValue,
      voice: voice[genderValue][langValue],
      rate: Number(defaultSpeed),
    };
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    fetch(apiGateway + prefixApiGateway + '/text-to-speech', {
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Blobから音声データを作成
        if (audio) audio.pause();
        const audioUrl = URL.createObjectURL(blob);
        const item = new Audio(audioUrl);
        setAudio(item);
      })
      .catch((error) => console.error('Error fetching audio:', error));
  };

  useEffect(() => {
    if (audio !== null) audio.play().catch((error) => console.error('Error playing audio:', error));
    return () => {
      if (audio) audio.pause();
    };
  }, [audio]);

  return {
    text: {
      value: text,
      setter: setText,
    },
    onTTS,
    genderList,
    langList,
    gender: {
      value: gender,
      setter: selectGender,
    },
    lang: {
      value: lang,
      setter: selectLang,
    },
    azureTTS,
  };
};
