import { PopoverOrigin } from '@mui/material';
import { useCallback, useState } from 'react';

export const useAnchor = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openAnchor = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [anchorEl],
  );
  const closeAnchor = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // default
  const anchorOriginBR: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };
  const transformOriginTR: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };
  // using in chat message input box 「...」
  const transformOriginBR: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };
  const anchorOriginTR: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };
  // voiceのリストのために
  const transformOriginCL: PopoverOrigin = {
    vertical: 'center',
    horizontal: 'left',
  };
  const anchorOriginCR: PopoverOrigin = {
    vertical: 'center',
    horizontal: 'right',
  };
  return {
    anchorEl,
    openAnchor,
    closeAnchor,
    open,
    id,
    anchorOriginTR,
    anchorOriginBR,
    anchorOriginCR,
    transformOriginTR,
    transformOriginBR,
    transformOriginCL,
  };
};
