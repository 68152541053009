import { useEffect, useState } from 'react';
import { useFunctionCalling } from 'src/hooks/useFunctionCalling';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';

export const useFunctionCallingPage = () => {
  const api = useFunctionCalling();
  const snackbar = useSnackbar();

  const [prompt, setPrompt] = useState<string>('');
  const [result, setResult] = useState<string>('');

  useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  const onClickedSend = async () => {
    if (prompt === '') {
      snackbar.error('プロンプトが入力されていません');
    } else {
      const result = await api.call(prompt);
      setResult(result);
    }
  };

  const onChangedPrompt = (prompt: string | undefined) => {
    setPrompt(prompt ?? '');
  };

  return {
    prompt,
    result,
    onClickedSend,
    onChangedPrompt,
  };
};
