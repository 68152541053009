import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, Typography, Tooltip, Chip } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components-minimals/@material-extend';
import { MyAvatar } from '../../components-minimals/MyAvatar';
import { MenuPopover } from '../../components-minimals/MenuPopover';
import axios from 'axios';
import { portalApi } from '../../config';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import usePageTracking from 'src/utils/useTracking';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------
type responseType = {
  id: string;
  name: string;
};
type UserInfo = {
  company: responseType;
  sbu: responseType;
  department: responseType;
  headquarter: responseType;
  group: responseType;
  name: string;
  roleNameList: responseType[];
};

/**
 *
 * @return コンポーネント
 */
export const AccountPopover = () => {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout?.();
      if (isMountedRef.current) {
        navigate('/');
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };
  const getUserInfo = async () => {
    let result;
    try {
      const session: CognitoUserSession = await Auth.currentSession();
      const token = session?.getIdToken().getJwtToken();
      result = await axios.get(portalApi, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        timeout: 60000,
      });
    } catch (e) {
      console.error(e);
    }
    setUserInfo(result?.data);
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  // GA
  usePageTracking(
    userInfo
      ? {
          // FIXME: ユーザ情報の型を整理して登録する
          companyCode: userInfo.company.id,
          divisionCode: userInfo.headquarter.id,
          sbuCode: userInfo.sbu.id,
          departmentCode: userInfo.department.id,
          groupCode: userInfo.group.id,
        }
      : { companyCode: '', departmentCode: '', sbuCode: '' },
  );

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}>
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 480, mt:2, maxHeight: '100vh', overflowY: 'scroll' }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" mt={1}>
            {t('user.name')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {userInfo?.name}
          </Typography>
          <Typography variant="subtitle2" mt={1}>
            {t('user.company')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {userInfo?.company.name}({userInfo?.company.id})
          </Typography>
          {userInfo?.headquarter ? (
            <>
              <Typography variant="subtitle2" mt={1}>
                {t('user.headquarters')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userInfo?.headquarter.name}({userInfo?.headquarter.id})
              </Typography>{' '}
            </>
          ) : (
            <></>
          )}
          {userInfo?.sbu ? (
            <>
              <Typography variant="subtitle2" mt={1}>
                {t('user.sbu')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userInfo?.sbu.name}({userInfo?.sbu.id})
              </Typography>
            </>
          ) : (
            <></>
          )}
          {userInfo?.department ? (
            <>
              <Typography variant="subtitle2" mt={1}>
                {t('user.department')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userInfo?.department.name}({userInfo?.department.id})
              </Typography>
            </>
          ) : (
            <></>
          )}
          {user?.roles ? (
            <Typography variant="subtitle2" mt={1}>
              {t('user.role')}
            </Typography>
          ) : (
            <></>
          )}
          {userInfo?.roleNameList.map((output, index) => {
            return (
              <Tooltip title={output.name} arrow key={output.id}>
                <Chip style={{ maxWidth: '180px' }} label={output.name} size="small" variant="filled" />
              </Tooltip>
            );
          })}
          {user?.roles ? (
            <Typography variant="subtitle2" mt={1}>
              {t('user.roleId')}
            </Typography>
          ) : (
            <></>
          )}
          {userInfo?.roleNameList.map((output, index) => {
            return (
              <Tooltip title={output.id} arrow key={output.id}>
                <Chip style={{ maxWidth: '180px' }} label={output.id} size="small" variant="filled" />
              </Tooltip>
            );
          })}
          <Typography variant="subtitle2" mt={1}>
            {t('user.email')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            {t('user.logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};
