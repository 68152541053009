import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useGa } from '../config';
import { PageTrackingProps } from 'src/@types/PageTrackingProps';

/**
 * トラッキング
 * @param props props
 */
const usePageTracking = (props: PageTrackingProps) => {
  const location = useLocation();

  useEffect(() => {
    if (useGa) {
      ReactGA.send({
        hitType: 'pageview',
      });
      // 会社情報があればGAに送信する
      if (props.companyCode) {
        ReactGA.gtag('set', 'user_properties', {
          company: props.companyCode,
          department: props.departmentCode,
          division: props.divisionCode,
          sbu: props.sbuCode,
          group: props.groupCode,
        });
      }
    }
  }, [location, props.companyCode]);
};

export default usePageTracking;
