import { IconButton, InputBase, Paper, Stack, Tooltip, Typography } from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import React from 'react';

interface MessageBoxProps {
  placeholder: string;
  messageInputBoxValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  // onCompositionStart: () => void;
  // onCompositionEnd: () => void;
  onSubmit: (e: any) => void;
}

export const MessageBox = React.memo(
  ({
    placeholder,
    messageInputBoxValue,
    onChange,
    // onKeyDown,
    // onCompositionStart,
    // onCompositionEnd,
    onSubmit,
  }: MessageBoxProps): React.ReactElement => {
    return (
      <>
        <Paper
          component="form"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative', // Make this a positioning context for absolute positioning
            boxShadow: '0px 3px 10px 6px rgba(0, 0, 0, 0.2)',
          }}>
          <InputBase
            id="MessageInputBox"
            sx={{
              ml: 1,
              flex: 1,
              minHeight: '38px',
            }}
            placeholder={placeholder}
            value={messageInputBoxValue} // 追加 FIXME: 名前修正必要
            onChange={onChange} // 追加  FIXME: 名前修正必要
            // onKeyDown={onKeyDown} // FIXME: 名前修正必要
            // onCompositionStart={onCompositionStart}
            // onCompositionEnd={onCompositionEnd}
            multiline
            maxRows={5}
          />
          <Stack direction="row" justifyContent="space-around" alignItems="stretch" spacing={0.5} sx={{ position: 'absolute', bottom: 0, right: 2 }}>
            <Tooltip title={`チャットページに移動します`} arrow>
              <Typography component="span">
                <IconButton
                  id="messageSubmitButton"
                  color="primary"
                  sx={{ position: 'relative', bottom: 0 }}
                  aria-label="messages"
                  onClick={onSubmit}
                  disabled={messageInputBoxValue.length === 0}>
                  <SendRoundedIcon />
                </IconButton>
              </Typography>
            </Tooltip>
          </Stack>
        </Paper>
      </>
    );
  },
);
