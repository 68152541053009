import { useEffect, useState } from 'react';
import { assistantsAPI } from 'src/apis/assistantsAPI';
import { useDialogMulti } from 'src/hooks/useDialogMulti';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { dispatch } from 'src/redux/store';

export const useAssistantV2BetaCodeInterpreterPageModel = () => {
  const prompt = useState<string>('');
  const loading = useState<boolean>(false);
  const response = useState<{ message: string; fileId: { id: string; extension: string }[]; threadId: string; inputCode: string }[]>([]);

  const snackbar = useSnackbar();
  const dialog = useDialogMulti();

  const promptEvent = async () => {
    loading[1](true);
    try {
      const threadId = Boolean(response[0].length) ? response[0][0].threadId : undefined;
      const resp = await assistantsAPI.v2CI({ prompt: prompt[0], threadId });
      // console.log(resp);
      response[1]((pre) => [resp, ...pre]);
    } catch (e: any) {
      console.log(e);
      snackbar.error('ファイル作成に失敗しました。 : ' + String(e));
      response[1]([]);
    } finally {
      loading[1](false);
    }
  };

  const downloadEvent = async (id: string, extension: string) => {
    try {
      const resp = await assistantsAPI.v2CIDl({ fileId: id });
      const blob = await fetch(`data:application/octet-stream;base64,${resp}`).then((res) => res.blob());
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `sample.${extension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e: any) {
      console.log(e);
      snackbar.error('ファイルダウンロードに失敗しました。 : ' + String(e));
    }
  };

  // SIDE EFFECT
  useEffect(() => {
    dispatch(setSideBarMenu());
  });

  return {
    prompt,
    loading,
    promptEvent,
    response,
    downloadEvent,
    dialog,
  };
};
