import { Button, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { useDocumentsUploadPageModel } from './useDocumentsUploadPageModel';
import { AlertMessage } from 'src/components/AlertMessage';
import { BoxedGrid } from 'src/components-mars/ag-grid/BoxedGrid';
import { TransparentDropZone } from 'src/components/DropFiled';
import { FileContentModal } from 'src/components/FileContentsModal';
import { PageTitle } from 'src/components/PageTitle';
import { Loading } from 'src/features/minutes/components/backdrop/Loading';
import useSettings from 'src/hooks/useSettings';
import { StyledAutocomplite } from 'src/components/StyledAutoComplite';
import { ICellRendererParams } from 'ag-grid-community';
import { btnCellRender } from 'src/components-mars/ag-grid/renderer/BtnCellRenderer';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import { LANGS_CODE } from 'src/hooks/useLocales';
import { MeatballsMenu } from 'src/components/MeatballsMenu';
import { LoadingButton } from '@mui/lab';

export const DocumentsUploadPage = () => {
  const pm = useDocumentsUploadPageModel();
  const { themeMode } = useSettings();

  return (
    <Page title={pm.locale.t('document-upload.text.pageTitle')}>
      <Loading isLoading={pm.isLoading} />
      <Loading isLoading={pm.documentMenu.isLoading} text={pm.documentMenu.message} subText={pm.documentMenu.process} />
      <Container maxWidth="md">
        <Grid container justifyContent="space-between">
          <PageTitle text={pm.locale.t('document-upload.text.pageTitle')} />
          {pm.adminRole && (
            <MeatballsMenu
              sx={{ display: 'grid', placeItems: 'center' }}
              themeMode={themeMode}
              onClick={pm.documentMenu.menuClickHanlder}
              onClose={pm.documentMenu.menuCloseHanlder}
              itemList={pm.documentMenu.itemList}
              anchorElement={pm.documentMenu.anchorEl}
            />
          )}
        </Grid>
        <Stack spacing={1}>
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item md={3}>
              <StyledAutocomplite
                label={pm.locale.t('common.text.classification')}
                size="small"
                fullWidth
                value={pm.currentOrganizationL1}
                options={pm.organizationsL1}
                display={pm.displayOrganizationL1}
                onChange={pm.onChangedOrganizationL1}
              />
            </Grid>
          </Grid>
          <StyledAutocomplite
            label={pm.currentOrganizationL1 ? pm.displayOrganizationL1(pm.currentOrganizationL1) : ''}
            size="small"
            fullWidth
            disabled={!pm.currentOrganizationL1 || pm.currentOrganizationL1.id === 'all'}
            value={pm.currentOrganizationL2}
            options={pm.organizationsL2}
            display={pm.displayOrganizationL2}
            onChange={pm.onChangedOrganizationL2}
          />
          <TransparentDropZone
            onAccepted={pm.onDropAccepted}
            onRejected={pm.onDropRejected}
            height={80}
            maxFiles={pm.maxFiles}
            disabled={pm.dropDisabled}>
            <Stack>
              <Typography variant="body2">{pm.locale.t('document-upload.drop.message.dropZoneLabel')}</Typography>
              <Typography variant="caption">{pm.locale.t('document-upload.drop.message.maxFiles', { maxFilesNum: pm.maxFiles })}</Typography>
            </Stack>
          </TransparentDropZone>
          {pm.alertShow && (
            <Stack sx={{ alignItems: 'center', paddingY: '5px' }}>
              <AlertMessage message={pm.locale.t('document-upload.text.alertMessage')} color={themeMode === 'light' ? 'black' : 'white'} />
            </Stack>
          )}
          <BoxedGrid
            gridTheme={pm.agGridTheme}
            gridHeight={'50vh'}
            gridOptions={{}}
            defaultColDef={{ flex: 2, filter: false, resizable: true, suppressMenu: true }}
            columnDefs={[
              {
                headerName: pm.locale.t('document-upload.ag-grid.column.fileName'),
                field: 'name',
              },
              {
                headerName: pm.locale.t('document-upload.ag-grid.column.operation'),
                field: 'operation',
                maxWidth: 100,
                cellRenderer: (params: ICellRendererParams) => {
                  return btnCellRender(() => pm.getDocumentFileContents(params), 'pageView');
                },
              },
            ]}
            rowData={pm.fileList}
          />
          <Grid container xs={12} alignItems="center">
            <Grid item xs={2} justifyContent={'center'}>
              <Typography variant="body1">{pm.locale.t('document-upload.text.tag')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField size="small" value={pm.tagField} onChange={pm.tagFieldOnChange} fullWidth />
            </Grid>
          </Grid>
          <Grid container justifyContent={'right'} xs={12}>
            <Grid item xs={2} mr={2}>
              <Button fullWidth color={'inherit'} variant="contained" onClick={pm.clear} sx={{ color: themeMode === 'light' ? 'black' : '#86929d' }}>
                {pm.locale.t('common.button.clear')}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <LoadingButton fullWidth variant="contained" onClick={pm.uploadDocumentFiles} disabled={pm.uploadDisabled} loading={pm.isLoading}>
                {pm.locale.t('common.button.upload')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
        {/* Dialogs */}
        <FileContentModal
          isOpen={pm.contentDialog.isOpen}
          closeAction={pm.contentDialog.onOk}
          closeBtnLabel={pm.locale.t('common.button.close')}
          contents={pm.fileContent}
        />
        <ConfirmDialog
          lang={pm.locale.i18n.language === 'ja' ? LANGS_CODE.JP : LANGS_CODE.EN}
          isOpen={pm.confrimDialog.isOpen}
          onCanceled={pm.confrimDialog.onCancel}
          cancelColor="inherit"
          cancelVariant="contained"
          onOk={pm.confrimDialog.onOk}
          okVariant="contained">
          <Grid>
            <Typography variant={'body1'}>{pm.locale.t('document-upload.dialog.contentDefault')}</Typography>
            {pm.duplicated.length > 0 && (
              <Grid>
                <Typography variant={'body1'}>{pm.locale.t('document-upload.dialog.fileList')}</Typography>
                {pm.duplicated.map((item) => (
                  <Typography variant={'body2'}>{item}</Typography>
                ))}
              </Grid>
            )}
          </Grid>
        </ConfirmDialog>
      </Container>
    </Page>
  );
};

// 部署
// snackbar
// modal
