import { Button, Grid } from '@mui/material';
import React from 'react';

interface EditCancelButtonProps {
  anchor: React.MutableRefObject<HTMLButtonElement | null>;
  flag: boolean;
  editLabel: string;
  cancelLabel: string;
  isLoading: boolean;
  editHandler: (e: any) => void;
  cancelHandler: () => void;
}
export const EditCancelButton = React.memo(
  ({ flag, editLabel, cancelLabel, isLoading, editHandler, cancelHandler, anchor }: EditCancelButtonProps) => {
    return flag ? (
      <Grid container item xs={8} direction={'row'} justifyContent={'center'} mb={2} spacing={1}>
        <Grid item xs={1} minWidth={110} ml={1} mr={1}>
          <Button variant="contained" color="primary" fullWidth disabled={isLoading} onClick={editHandler} ref={anchor}>
            {editLabel}
          </Button>
        </Grid>
        <Grid item xs={1} minWidth={110} ml={1} mr={1}>
          <Button variant="contained" color="inherit" fullWidth disabled={isLoading} onClick={cancelHandler} sx={{ backgroundColor: '#aaaaaa', color: '#fff' }}>
            {cancelLabel}
          </Button>
        </Grid>
      </Grid>
    ) : (
      <></>
    );
  },
);
