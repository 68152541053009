import { InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

interface SearchBarProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}
export const SearchBar = React.memo(({ value, onChange, placeholder }: SearchBarProps) => {
  return (
    <OutlinedInput
      fullWidth
      size="small"
      value={value}
      onChange={onChange}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      placeholder={placeholder}
    />
  );
});
