import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { INITIAL_PATH } from '../constants';

interface GuestGuardProps {
  children: React.ReactNode;
}
/**
 *
 * @param  param0 パラメータ
 * @return コンポーネント
 */
const GuestGuard: React.FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={INITIAL_PATH} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
