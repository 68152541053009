import { Card, CardContent, CardHeader, Container, Grid, Stack, Typography } from '@mui/material';
import { useMinutesMinutesPageModel } from './useMinutesMinutesPageModel';
import { StyledSelectList } from 'src/features/minutes/components/select/StyledSelectList';
import { Page } from 'src/components-minimals/Page';
import { LoadingButton } from '@mui/lab';
import DescriptionIcon from '@mui/icons-material/Description';
import { PageTitle } from 'src/components/PageTitle';
import { StyledTextarea } from 'src/features/minutes/components/textarea/StyledTextarea';
import { MarkdownView } from 'src/features/minutes/components/view/MarkdownView';

export const MinutesMinutesPage = () => {
  const vm = useMinutesMinutesPageModel();

  return (
    <Page title="Minutes Transcribe">
      <Container maxWidth={'md'}>
        <Stack spacing={3}>
          {/* Page title */}

          <PageTitle text={vm.t('minutes.text.title')} />

          {/* Page description */}
          <Typography component={'p'}>{vm.t('minutes.text.description')}</Typography>

          <Stack spacing={2}>
            <Card>
              {/* 文字起こし対象の設定用フォーム */}
              <CardHeader title={vm.t('minutes.minutes.content.title')} />
              <CardContent>
                <Grid container direction={'column'} spacing={2}>
                  {/* 結果表示 セクション */}
                  <Grid item xs={12}>
                    <StyledTextarea
                      variant="body2"
                      minRows={16}
                      value={vm.transcribedText}
                      placeholder={vm.t('transcribe.content.resultPlaceHolder')}
                      onBlur={vm.onFocusLeaveTranscribedText}
                      onChange={vm.onChangeTranscribedText}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              {/* 議事録化用の設定フォーム */}
              <CardHeader title={vm.t('minutes.minutes.title')} />
              <CardContent>
                <Grid container justifyContent={'column'} spacing={2}>
                  <Grid item xs={12}>
                    <StyledSelectList
                      label={vm.t('minutes.minutes.options.modelSelectorLabel')}
                      value={vm.currentSummarizeService.label}
                      options={vm.minutes.summarizeServiceList.map((service) => service.label)}
                      onChange={vm.onChangeSummarizeService}
                      size="small"
                      minWidth={200}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StyledSelectList
                      label={vm.t('minutes.minutes.options.inputLangSelectorLabel')}
                      value={vm.displayMinutesOutputLang(vm.currentMinutesOutputLang)}
                      options={vm.minutes.inputLanguages.map((lang) => vm.displayMinutesOutputLang(lang))}
                      onChange={vm.onchangeMinutesOutputLang}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent={'center'}>
                      <Grid item justifyContent={'center'} xs={12} sm={6} sx={{}}>
                        <LoadingButton
                          fullWidth
                          variant="contained"
                          startIcon={<DescriptionIcon />}
                          children={vm.t('minutes.minutes.content.button')}
                          onClick={vm.onClickCreateMinutes}
                          loading={vm.isGenerating}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* 結果タブ */}
                  <Grid item xs={12}>
                    <MarkdownView
                      variant="body2"
                      text={vm.minutesText}
                      onChange={vm.onChangeMinutesTabTextarea}
                      onBlur={vm.onFocusLeaveMinutesTabTextarea}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
};
