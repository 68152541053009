import { ICellRendererParams } from 'ag-grid-community';
import { ChangeEventHandler, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from 'src/constants';
import useAgGridTheme from 'src/hooks/useAgGridTheme';
import useAuth from 'src/hooks/useAuth';
import { useDialog } from 'src/hooks/useDialog';
import { useDocumentFileAccessController } from 'src/hooks/useDocumenFiletAccessController';
import { useDocumentFile } from 'src/hooks/useDocumentFile';
import { useDocumentMenu } from 'src/hooks/useDocumentMenu';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { OrganizationClassification, OrganizationSegment } from 'src/models/organization';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { dispatch } from 'src/redux/store';

export const useDocumentsUploadPageModel = () => {
  // HOOK
  // ================================================================================
  const locale = useTranslation();
  const snackbar = useSnackbar();
  const auth = useAuth();
  const documentFile = useDocumentFile();
  const contentDialog = useDialog();
  const confrimDialog = useDialog();
  const agGridTheme = useAgGridTheme();
  const documentMenu = useDocumentMenu();
  const accessController = useDocumentFileAccessController({ user: auth.user });

  // STATE
  // ================================================================================
  const [fileList, setFileList] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileContent, setFileContent] = useState<string>('');
  const [tagField, setTagField] = useState<string>('');
  const [dropDisabled, setDropDisabled] = useState<boolean>(false);
  const [duplicated, setDuplicated] = useState<string[]>([]);
  const [organizationsL1, setOrganizationsL1] = useState<OrganizationClassification[]>([]);
  const [organizationsL2, setOrganizationsL2] = useState<OrganizationSegment[]>([]);
  const [currentOrganizationL1, setCurrentOrganizationL1] = useState<OrganizationClassification | null>(null);
  const [currentOrganizationL2, setCurrentOrganizationL2] = useState<OrganizationSegment | null>(null);

  // 固定値
  const maxFiles = 2;
  const adminRole = Boolean(auth.user && auth.user.role && auth.user.role.includes(USER_ROLES.GPT_UPLOAD_ADMIN));

  // MEMO
  // ================================================================================
  /**
   * アップロード活性、非活性制御
   */
  const uploadDisabled = useMemo(() => {
    if (fileList.length <= 0) {
      return true;
    }
    if (!currentOrganizationL1) {
      return true;
    }
    return currentOrganizationL1.id !== 'all' && !currentOrganizationL2;
  }, [fileList, currentOrganizationL1, currentOrganizationL2]);

  // EVENT HANDLER ※useCallbackする
  // ================================================================================
  /**
   * ドロップゾーンで許可されたファイルをag-gridのリストに追加する関数
   * @param acceptedFiles 許可されたファイルズ
   */
  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        // Macなどからアップロードした場合、utf-8-macになるため、一覧取得時に検索結果に現れないことが発生する
        // そのため、登録時にutf-8にとなるように変換を行う必要がある
        // しかし、File型のnameはreadonlyのため、新しいFileオブジェクトを作成する
        const normalizedFiles: File[] = [];
        acceptedFiles.forEach((file) => {
          normalizedFiles.push(
            new File([file], file.name.normalize(), {
              type: file.type,
              lastModified: file.lastModified,
            }),
          );
        });

        setFileList((prevArray) => [...prevArray, ...normalizedFiles]);
      }
    },
    [fileList],
  );

  /**
   * ドロップゾーンにファイルを置くのが失敗した際の関数
   * @param files ドロップゾーンに失敗したファイルのリスト
   * @param _ ドロップのイベント
   */
  const onDropRejected = useCallback((files: FileRejection[], _: DropEvent) => {
    let message = '';
    if (files.length <= maxFiles) message = locale.t('document-upload.drop.message.unsupportedFileFormat');
    if (files.length > maxFiles) message = locale.t('document-upload.drop.message.filesOver');
    snackbar.error(message);
  }, []);

  /**
   * ドキュメントファイルの内容を取得するための関数
   * @param params ag-grid業のデータ
   */
  const getDocumentFileContents = useCallback(async (params: ICellRendererParams) => {
    try {
      setIsLoading(true);
      const file = params.data as File;
      const documentContents = await documentFile.getContentString(file);
      setFileContent(documentContents);
      contentDialog.openDialog();
    } catch (err: any) {
      // 「excel」とメッセージが返ってくるものはエクセルファイルのロード時にエラーが発生したものです。
      // 同じく Errorタイプですが、中身が違い、responseデータのdetailにあるエラーコードをベースでエラーメッセージを区別し、多言語対応を行います。
      if (err.response && err.response.data && err.response.data.detail) {
        snackbar.error(
          locale.t('document-upload.message.error.contents') + '\n' + locale.t(`document-upload.message.error.${err.response.data.detail}`),
        );
      } else if (err instanceof Error) {
        // 判明できないエラーの場合、サーバーからのエラーメッセージを表示します。
        if (err.message) snackbar.error(locale.t('document-upload.message.error.contents') + err.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  /**
   * 組織セグメントを取得するための関数
   * @param classification 組織分類
   */
  const getOrganizationL2 = useCallback(
    async (classification: OrganizationClassification) => {
      try {
        setIsLoading(true);
        const segments = await accessController.fetchSegmentsByClassification(classification);
        setOrganizationsL2(() => segments);
      } catch (err: any) {
        snackbar.error(locale.t('common.message.error.orgSegmentFetch'));
      } finally {
        setIsLoading(false);
      }
    },
    [currentOrganizationL1],
  );

  /**
   * ドキュメントファイルをアップロードするための関数
   * @param classification 組織分類
   * @param segment 組織セグメント
   */
  const uploadDocumentFiles = useCallback(async () => {
    if (checkUploadFiles()) return;
    try {
      await checkDuplicatedDocumentName();
      const result = await confrimDialog.openDialog();
      if (result === 'ok') {
        setIsLoading(true);
        if (!uploadDisabled) {
          await documentFile
            .upload({
              files: fileList,
              tag: tagField,
              classification: currentOrganizationL1?.id || '',
              headOffice: currentOrganizationL2?.code || '',
            })
            .then(() => {
              snackbar.success(locale.t('document-upload.message.success.upload'));
              clear();
            });
        }
      }
    } catch (err: any) {
      let message = '';
      let pythonHttpException = '';
      if (err instanceof Error) {
        message += err.message;
      }
      if (err.response && err.response.data && err.response.data.detail) {
        message = '';
        pythonHttpException = err.response.data.detail;
      }
      if (message) snackbar.error(locale.t('document-upload.message.error.upload') + message);
      if (pythonHttpException) snackbar.error(locale.t(`document-upload.message.error.${pythonHttpException}`));
    } finally {
      setIsLoading(false);
    }
  }, [tagField, currentOrganizationL1, currentOrganizationL2, fileList]);

  /**
   * ファイルをアップする前にバックに送るファイルの数を確認する関数です。
   * @return 最大アップロードできる数より多いファイルがgrid上に存在すると「 true 」を返します。
   */
  const checkUploadFiles = useCallback(() => {
    if (fileList.length > maxFiles) {
      snackbar.info(locale.t('document-upload.drop.message.maxFiles', { maxFilesNum: maxFiles }));
      return true;
    }
    if (fileList.filter((file) => !documentFile.ALLOWED_EXTENSIONS.includes(file.name.split('.').pop()?.toLowerCase() || '')).length > 0) {
      snackbar.error(locale.t('document-upload.message.error.extension'));
      return true;
    }
    return false;
  }, [fileList]);

  const checkDuplicatedDocumentName = useCallback(async () => {
    setIsLoading(() => true);
    const documentFiles = await documentFile.getFileNmaesByOrganization(currentOrganizationL1, currentOrganizationL2);
    setIsLoading(() => false);
    const fileNames = fileList.map((item) => item.name);
    setDuplicated(() => documentFiles.filter((doc) => fileNames.includes(doc)));
  }, [currentOrganizationL1, currentOrganizationL2, fileList]);

  /**
   * タグに何かを入力した際の関数 *onChangeに対応
   */
  const tagFieldOnChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback((event) => {
    setTagField(event.currentTarget.value);
  }, []);

  /**
   * クリアボタンのonClick対応
   */
  const clear = useCallback(() => {
    setFileList([]);
    setTagField('');
  }, []);

  /**
   * 組織分類が変更された時のイベント
   */
  const onChangedOrganizationL1Handler = useCallback(async (classification: OrganizationClassification | null) => {
    setFileList(() => []);
    setCurrentOrganizationL1(() => classification);
    setOrganizationsL2(() => []);
    setCurrentOrganizationL2(() => null);
    if (classification && classification.id !== 'all') {
      await getOrganizationL2(classification);
    }
  }, []);

  /**
   * 組織L2が変更された時のイベント
   * @param segment 設定する組織セグメント
   */
  const onChangedOrganizationL2Handler = useCallback(async (segment: OrganizationSegment | null) => {
    setCurrentOrganizationL2(() => segment);
    setFileList(() => []);
  }, []);

  // SIDE EFFECT
  // ================================================================================
  // サイドバーの表示
  useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  // ドロップゾーンの活性/非活性制御
  // 組織分類と組織セグメントが有効なパターン時のみ、ドロップゾーンを有効にする
  useEffect(() => {
    if (!currentOrganizationL1 || (currentOrganizationL1.id !== 'all' && !currentOrganizationL2)) setDropDisabled(true);
    else setDropDisabled(false);
  }, [currentOrganizationL1, currentOrganizationL2]);

  // 画面初期化時のライフラサイクルイベント
  useLayoutEffect(() => {
    const classifications = accessController.fetchClassifications();
    setOrganizationsL1(() => classifications);
  }, []);

  return {
    // hooks
    locale,
    agGridTheme,
    contentDialog,
    confrimDialog,
    documentMenu,
    // const
    maxFiles,
    adminRole,
    // states
    alertShow: fileList.length > 0,
    fileList,
    isLoading: isLoading,
    tagField,
    fileContent,
    dropDisabled,
    duplicated,
    organizationsL1,
    organizationsL2,
    currentOrganizationL1,
    currentOrganizationL2,
    // memo
    uploadDisabled,
    // event handlers
    getDocumentFileContents,
    onDropRejected,
    onDropAccepted,
    tagFieldOnChange,
    uploadDocumentFiles,
    clear,
    onChangedOrganizationL1: onChangedOrganizationL1Handler,
    onChangedOrganizationL2: onChangedOrganizationL2Handler,
    displayOrganizationL1: accessController.displayOrganizationL1,
    displayOrganizationL2: accessController.displayOrganizationL2,
  };
};
