import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';

interface PromptIconProps {
  title: string;
  fontSize?: number;
}
const defaultColorIconSx = { color: 'rgb(140, 102, 69)', backgroundColor: '#fb8500' };
const firstColorIconSx = { color: '#ffffff', backgroundColor: '#ffb703' };
const secondColorIconSx = { color: '#ffffff', backgroundColor: '#f4a261' };
const thirdColorIconSx = { color: '#ffffff', backgroundColor: '#e9c46a' };
const fourthColorIconSx = { color: '#ffffff', backgroundColor: '#2a9d8f' };
const fifthColorIconSx = { color: '#ffffff', backgroundColor: '#264653' };
const sixthColorIconSx = { color: '#ffffff', backgroundColor: '#219ebc' };

export const PromptIcon = ({ title, fontSize }: PromptIconProps) => {
  const defaultIconSx = { fontSize: fontSize ? fontSize : 45, padding: '9px', borderRadius: 0.8 };

  switch (title) {
    case 'テクニック':
    case 'technique':
      return <BuildCircleIcon sx={{ ...defaultIconSx, ...secondColorIconSx }} />;
    case 'ビジネス':
    case 'business':
      return <CorporateFareIcon sx={{ ...defaultIconSx, ...firstColorIconSx }} />;
    case '語学':
    case 'language study':
      return <GTranslateIcon sx={{ ...defaultIconSx, ...thirdColorIconSx }} />;
    case '自己啓発':
    case 'self-development':
      return <BorderColorIcon sx={{ ...defaultIconSx, ...fourthColorIconSx }} />;
    case '哲学':
    case 'philosophy':
      return <PsychologyAltIcon sx={{ ...defaultIconSx, ...fifthColorIconSx }} />;
    case 'IT':
      return <ScreenSearchDesktopIcon sx={{ ...defaultIconSx, ...sixthColorIconSx }} />;
    default:
      return <NotificationsIcon sx={{ ...defaultIconSx, ...defaultColorIconSx }} />;
  }
};
