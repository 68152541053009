import { Dialog, DialogContent, DialogContentText, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import VersionComparisonTable from './VersionComparisonTable';
import { OptionChatType } from 'src/models/chat-gpt';
type Props = {
  flag: boolean;
  closeEvent: () => void;
  title: string;
  models: OptionChatType[];
  device?: boolean;
};
export const GptDetailModal = React.memo(({ flag, closeEvent, title, models, device = false }: Props): React.ReactElement => {
  return (
    <Dialog open={flag} onClose={closeEvent} fullWidth maxWidth={'md'}>
      <DialogContent sx={{ minWidth: 300 }}>
        <Grid container xs={12} pb={'5px'} mb={2}>
          <Grid item xs={7} ml={2}>
            <Typography variant={'h5'}>{title}</Typography>
          </Grid>
          <Grid item container xs={4} justifyContent={'right'} mr={1}>
            <IconButton edge="start" color="inherit" onClick={closeEvent} aria-label="close" size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContentText>
          <VersionComparisonTable models={models} device={device} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
});
