import { Box } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';

interface DroppableProps {
  onDropAccepted: (files: File[]) => void;
  onDropRejected: (fileRejections: FileRejection[], event: DropEvent) => void;
  accept?: Accept;
  maxFiles?: number;
  children: ReactNode;
  disabled?: boolean;
}

const Droppable = (props: DroppableProps) => {
  const { onDropAccepted, onDropRejected, accept, maxFiles, children, disabled } = props;
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    maxFiles: maxFiles ?? 1,
    disabled: disabled,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export interface FileDropZoneProps {
  onAccepted: (acceptedFiles: File[]) => void;
  onRejected: (fileRejections: FileRejection[], event: DropEvent) => void;
  children: ReactNode;
  height?: number | string;
  accept?: Accept;
  maxFiles?: number;
  disabled?: boolean;
}

/**
 * 音声ファイル用のドロップゾーン
 * @param props パラメータ
 * @returns レンダリングコンポーネント
 */
export const TransparentDropZone = (props: FileDropZoneProps) => {
  const { onAccepted, onRejected, children, height, maxFiles, accept, disabled } = props;
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    if (!disabled) {
      setIsHovered(true);
    }
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Droppable onDropAccepted={onAccepted} onDropRejected={onRejected} accept={accept} maxFiles={maxFiles} disabled={disabled}>
      <Box
        sx={{
          height: height ?? '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 1,
          border: '1px dashed #666',
          bgcolor: disabled ? 'rgba(128,128,128,0.1)' : isHovered ? 'rgba(128,128,128,0.3)' : 'rgba(128,128,128,0.1)',
          borderColor: disabled ? 'transparent' : isHovered ? 'primary.main' : '#666',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        children={children}
      />
    </Droppable>
  );
};
