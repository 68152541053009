import Slider from 'react-slick';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Theme } from '@mui/material';

//
import Image from './Image';
import ChatImageModal from './ChatImageModal';
import { useCarousel } from 'src/hooks/useCarousel';
import { OriginImageList } from 'src/contexts/ChatMessageReducer';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '& .slick-slide': {
    float: theme.direction === 'rtl' ? 'right' : 'left',
    '&:focus': { outline: 'none' },
  },
}));

// ----------------------------------------------------------------------

type Props = {
  images: string[];
  isMobile: boolean;
  downloadImg: (value?: string) => void;
  originImg?: OriginImageList[];
};

const ChatImagesCarousel = ({ images, isMobile, downloadImg, originImg }: Props) => {
  const carouselHook = useCarousel({ isMobile, images, originImg, downloadImg });
  return (
    <RootStyle>
      <Box
        sx={{
          my: 1,
          mx: 'auto',
          '& .slick-current .isActive': { opacity: 1 },
          ...(images.length === 1 && { maxWidth: carouselHook.thumSize.chat * 1 + 16 }),
          ...(images.length === 2 && { maxWidth: carouselHook.thumSize.chat * 2 + 32 }),
          ...(isMobile
            ? images.length >= 3 && { maxWidth: carouselHook.thumSize.chat * 3 + 48 }
            : images.length === 3 && { maxWidth: carouselHook.thumSize.chat * 3 + 48 }),
          ...(!isMobile && images.length >= 4 && { maxWidth: carouselHook.thumSize.chat * 4 + 64 }),
        }}>
        <Slider {...carouselHook.settings} slidesToShow={carouselHook.sliderToShow.chat} arrows={false} lazyLoad="ondemand">
          {images.map((img, index) => {
            return (
              <Box key={img} sx={{ px: 0.75, position: 'relative', display: 'inline-block' }}>
                <Image
                  disabledEffect
                  alt="thumb image"
                  src={img}
                  onClick={() => carouselHook.handleOpenLightbox(img)}
                  // onContextMenu={(e) => e.preventDefault()} // 右クリック禁止
                  // onDragStart={(e) => e.preventDefault()} // drag 禁止
                  sx={{
                    width: carouselHook.thumSize.chat,
                    height: carouselHook.thumSize.chat,
                    borderRadius: 1.5,
                    cursor: 'pointer',
                    ...(carouselHook.sliderIndex === index && {
                      border: (theme: Theme) => `solid 3px ${theme.palette.primary.main}`,
                    }),
                  }}
                />
              </Box>
            );
          })}
        </Slider>
      </Box>

      <ChatImageModal
        images={carouselHook.urls}
        mainSrc={carouselHook.urls[carouselHook.selectedImage]}
        photoIndex={carouselHook.selectedImage}
        setPhotoIndex={carouselHook.setSelectedImage}
        isOpen={carouselHook.openLightbox}
        onCloseRequest={() => carouselHook.setOpenLightbox(false)}
        download={carouselHook.download}
        originImg={originImg}
      />
    </RootStyle>
  );
};

export default ChatImagesCarousel;
