/**
 * リリース対象機能かどうかを判定する
 * @returns Boolean
 */
export const isNotReleaseFunction = () => {
  // 環境がlocal,trialであればTrueを返す
  const env = process.env.REACT_APP_ENV;
  if (env === 'local' || env === 'trial') {
    return true;
  } else {
    return false;
  }
};

/**
 * ローカル or Trial環境かどうかを判定する
 * @returns Boolean
 */
export const isEnvLocalOrTrial = () => {
  // 環境がlocal,trialであればTrueを返す
  const env = process.env.REACT_APP_ENV;
  if (env === 'local' || env === 'trial') {
    return true;
  } else {
    return false;
  }
};
