type ReactEnvName = 'local' | 'development' | 'trial' | 'staging' | 'production';
type NodeEnvName = 'local' | 'development' | 'production' | 'test';
type EnvName = ReactEnvName | NodeEnvName;

interface Settings {
  projectRegion: string;
  identityPoolId: string;
  cognitoRegion: string;
  userPoolId: string;
  clientId: string;
  apiGateway: string;
  pythonGateway: string;
  portalApi: string;
  redirectSignIn: string;
  redirectSignInTeams?: string;
  redirectSignOut: string;
  cognitoDomain: string;
  aggridLicenceKey: string;
  googleAnalyticsId: string;
  useGa: boolean;
  s3URL: string;
  teamsAppId: string;
}
interface EnvSettings {
  local: Settings;
  development: Settings;
  trial: Settings;
  staging: Settings;
  test: Settings;
  production: Settings;
}

export const currentEnv: EnvName = process.env.REACT_APP_ENV || process.env.NODE_ENV;

const envSettings: EnvSettings = {
  local: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:f963aa59-9173-4a09-897a-705a7f8e3b3b',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_lfKgSdiLf',
    clientId: '40rv2omnfjqmk68tncbrnvdtib',
    apiGateway: 'http://localhost:3010',
    pythonGateway: 'http://localhost:8085',
    portalApi: 'https://datainsight-portal-server-development.toyotsu-digital.com/user/v1.1/userInfo',
    redirectSignIn: 'http://localhost:3000',
    redirectSignInTeams: 'http://localhost:3000/auth/close-popup',
    redirectSignOut: 'http://localhost:3000',
    cognitoDomain: 'datainsight-pool-dev.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: 'G-3GLRT06SMW',
    useGa: true,
    s3URL: 'https://s3.console.aws.amazon.com/s3/buckets/ttc-gpt-development?prefix=local/&region=ap-northeast-1',
    teamsAppId: '79a6ecd7-cc29-405c-a3d9-561bad642d86',
  },
  test: {
    // same as staging for fallback
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:f963aa59-9173-4a09-897a-705a7f8e3b3b',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_lfKgSdiLf',
    clientId: '40rv2omnfjqmk68tncbrnvdtib',
    apiGateway: 'http://localhost:3000',
    pythonGateway: '',
    portalApi: 'https://datainsight-portal-server-development.toyotsu-digital.com/user/v1.1/userInfo',
    redirectSignIn: 'https://flour-staging.toyotsu-digital.com/',
    redirectSignOut: 'https://flour-staging.toyotsu-digital.com/',
    cognitoDomain: 'datainsight-pool-dev.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: '',
    useGa: false,
    s3URL: 'https://s3.console.aws.amazon.com/s3/buckets/ttc-gpt-development?prefix=documents-trial/&region=ap-northeast-1', // TODO: テスト環境用追加
    teamsAppId: '',
  },
  development: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:f963aa59-9173-4a09-897a-705a7f8e3b3b',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_lfKgSdiLf',
    clientId: '40rv2omnfjqmk68tncbrnvdtib',
    apiGateway: 'https://toyotsu-gchat.datainsight-dev.toyota-tsusho.com',
    pythonGateway: 'https://toyotsu-gchat.datainsight-dev.toyota-tsusho.com',
    portalApi: 'https://portal-devevelopment-server.datainsight-dev.toyota-tsusho.com/user/v1.1/userInfo', // 本番環境のCognitoを使用しているため本番環境のポータルに接続
    redirectSignIn: 'https://toyotsu-gchat.datainsight-dev.toyota-tsusho.com',
    redirectSignInTeams: 'https://toyotsu-gchat.datainsight-dev.toyota-tsusho.com/auth/close-popup',
    redirectSignOut: 'https://toyotsu-gchat.datainsight-dev.toyota-tsusho.com',
    cognitoDomain: 'marsportal-pool-dev.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: 'G-3GLRT06SMW',
    useGa: true,
    s3URL: 'https://s3.console.aws.amazon.com/s3/buckets/doc-toyotsu-gpt-development?region=ap-northeast-1',
    teamsAppId: '79a6ecd7-cc29-405c-a3d9-561bad642d86',
  },
  trial: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:f963aa59-9173-4a09-897a-705a7f8e3b3b',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_0zpIatPef', // mickeyログインのため本番環境のCognitoに接続
    clientId: '2km215jg6nisrluuqlpgoi12tv', // mickeyログインのため本番環境のCognitoに接続
    pythonGateway: 'https://toyotsu-gpt-trial.toyotsu-digital.com',
    apiGateway: 'https://toyotsu-gpt-trial.toyotsu-digital.com',
    portalApi: 'https://portal-server.datainsight.toyota-tsusho.com/user/v1.1/userInfo', // 本番環境のCognitoを使用しているため本番環境のポータルに接続
    redirectSignIn: 'https://toyotsu-gpt-trial.toyotsu-digital.com',
    redirectSignInTeams: 'https://toyotsu-gpt-trial.toyotsu-digital.com/auth/close-popup',
    redirectSignOut: 'https://toyotsu-gpt-trial.toyotsu-digital.com',
    cognitoDomain: 'datainsight-pool.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: 'G-3GLRT06SMW',
    useGa: true,
    s3URL: 'https://s3.console.aws.amazon.com/s3/buckets/ttc-gpt-development?prefix=trial/&region=ap-northeast-1',
    teamsAppId: '79a6ecd7-cc29-405c-a3d9-561bad642d86',
  },
  staging: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: '',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_APA8bvSar',
    clientId: '7tjikcsc9ks50p92fnhq90mlnj',
    apiGateway: 'https://toyotsu-gchat-stg.datainsight-qas.toyota-tsusho.com',
    pythonGateway: 'https://toyotsu-gchat-stg.datainsight-qas.toyota-tsusho.com',
    portalApi: 'https://portal-stg-server.datainsight-qas.toyota-tsusho.com/user/v1.1/userInfo',
    redirectSignIn: 'https://toyotsu-gchat-stg.datainsight-qas.toyota-tsusho.com',
    redirectSignInTeams: 'https://toyotsu-gchat-stg.datainsight-qas.toyota-tsusho.com/auth/close-popup',
    redirectSignOut: 'https://toyotsu-gchat-stg.datainsight-qas.toyota-tsusho.com',
    cognitoDomain: 'datainsight-pool-stg.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: 'G-3GLRT06SMW',
    useGa: true,
    s3URL: 'https://s3.console.aws.amazon.com/s3/buckets/doc-toyotsu-gpt-staging?region=ap-northeast-1',
    teamsAppId: 'a424b5b2-afad-4d40-be11-b6a5861891f4',
  },
  production: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:f963aa59-9173-4a09-897a-705a7f8e3b3b',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_0zpIatPef',
    clientId: '618qpoa7kke7l8fke06e620b6c',
    pythonGateway: 'https://toyotsu-gchat.datainsight.toyota-tsusho.com',
    apiGateway: 'https://toyotsu-gchat.datainsight.toyota-tsusho.com',
    portalApi: 'https://portal-server.datainsight.toyota-tsusho.com/user/v1.1/userInfo',
    redirectSignIn: 'https://toyotsu-gchat.datainsight.toyota-tsusho.com',
    redirectSignInTeams: 'https://toyotsu-gchat.datainsight.toyota-tsusho.com/auth/close-popup',
    redirectSignOut: 'https://toyotsu-gchat.datainsight.toyota-tsusho.com',
    cognitoDomain: 'datainsight-pool.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: 'G-C2J07Z28Q4',
    useGa: true,
    s3URL: 'https://s3.console.aws.amazon.com/s3/buckets/doc-toyotsu-gpt?region=ap-northeast-1',
    teamsAppId: 'a424b5b2-afad-4d40-be11-b6a5861891f4',
  },
};
// console.log(envSettings[currentEnv]);

export const amplifyConfig = {
  aws_project_region: envSettings[currentEnv].projectRegion,
  aws_cognito_identity_pool_id: envSettings[currentEnv].identityPoolId,
  aws_cognito_region: envSettings[currentEnv].cognitoRegion,
  aws_user_pools_id: envSettings[currentEnv].userPoolId,
  aws_user_pools_web_client_id: envSettings[currentEnv].clientId,
  redirect_sign_in: envSettings[currentEnv].redirectSignIn,
  redirect_sign_in_teams: envSettings[currentEnv].redirectSignInTeams,
  redirect_sign_out: envSettings[currentEnv].redirectSignOut,
  cognitoDomain: envSettings[currentEnv].cognitoDomain,
};

export const cognitoConfig = {
  userPoolId: envSettings[currentEnv].userPoolId,
  clientId: envSettings[currentEnv].clientId,
};

export const apiGateway = envSettings[currentEnv].apiGateway;
export const prefixApiGateway = '/backend';
export const prefixPythonGateway = '/backend/python';
export const pythonGateway = envSettings[currentEnv].pythonGateway;
export const portalApi = envSettings[currentEnv].portalApi;
export const aggridLicenceKey = envSettings[currentEnv].aggridLicenceKey;
export const googleAnalyticsId = envSettings[currentEnv].googleAnalyticsId;
export const useGa = envSettings[currentEnv].useGa;
export const s3URL = envSettings[currentEnv].s3URL;

export const teamsAppConfig = {
  id: envSettings[currentEnv].teamsAppId,
};

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 50,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 40,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};
