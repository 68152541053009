import { OptionsObject, useSnackbar as useNotistack } from 'notistack';

/**
 * Snackbarを利用するためのhooks
 * @returns 公開プロパティ
 */
export const useSnackbar = () => {
  const { enqueueSnackbar } = useNotistack();

  /**
   * 通知用
   * @param message 表示文字
   * @param options オプション
   */
  const info = (message: string, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      variant: 'info',
      autoHideDuration: 5000, // 5sec
      style: { whiteSpace: 'pre-line' },
      ...options,
    });
  };

  /**
   * ポジティブメッセージ用
   * @param message 表示文字
   * @param options オプション
   */
  const success = (message: string, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: 5000, // 5sec
      style: { whiteSpace: 'pre-line' },
      ...options,
    });
  };

  /**
   * ネガティブメッセージ用
   * @param message 表示文字
   * @param options オプション
   */
  const error = (message: string, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: null, // 自動クローズしない
      style: { whiteSpace: 'pre-line' },
      ...options,
    });
  };

  /**
   * ワーニングメッセージ用
   * @param message 表示文字
   * @param options オプション
   */
  const warning = (message: string, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      variant: 'warning',
      autoHideDuration: 5000, // 5sec
      style: { whiteSpace: 'pre-line' },
      ...options,
    });
  };

  return {
    info,
    success,
    error,
    warning,
  };
};
