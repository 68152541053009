/**
 * 規約の種類
 */
export enum TermsType {
  PrivacyPolicy = 'PrivacyPolicy',
  GDPR = 'GDPR',
  CCPA = 'CCPA',
}

/**
 * 規約に関するデータの規定モデル
 * APIから取得した規約データ型
 */
export interface TermsOfServiceBase {
  id: string;
  type: TermsType;
  content: string;
  createdAt: string;
}

/**
 * 規約に関するデータモデル
 * App内部で利用する規約データ型
 */
export interface TermsOfService {
  id: string;
  type: TermsType;
  content: string;
  createdAt: Date;
}
