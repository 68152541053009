import { Navigate } from 'react-router-dom';
import AuthGuardLayout from '../../components-mars/layouts/AuthGuardLayout';

import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { USER_ROLES } from 'src/constants';
import { SnowflakePage } from 'src/features/db-search/components/page/SnowflakePage';
import { VisionTest } from 'src/pages/vision-test-page/VisionTestPage';
import { AssistantsApi } from 'src/pages/assistants-api-page/AssistantsApiPage';
import { Translate } from 'src/pages/translate-page/TranslatePage';
import { TextToSpeechPage } from 'src/pages/text-to-speech-page/TextToSpeechPage';
import { FunctionCallingPage } from 'src/pages/function-calling-page/FunctionCallingPage';
import DallE3Page from 'src/pages/dall-e3-page/DallE3Page';
import { PATH_OTHERS } from '../paths';
import { AssistantV2BetaCodeInterpreterPage } from 'src/pages/assistant-v2-beta-ci-page/AssistantV2BetaCodeInterpreterPage';

export const othersRoute = {
  path: 'others',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={PATH_OTHERS.snowflake} replace />,
    },
    {
      path: 'snowflake',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER]}>
          <SnowflakePage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'gpt-4-vision',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER]}>
          <VisionTest />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'assistants-api',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER]}>
          <AssistantsApi />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'translate',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER]}>
          <Translate />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'text-to-speech',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER]}>
          <TextToSpeechPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'function-calling',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER]}>
          <FunctionCallingPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'dall-e3',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER]}>
          <DallE3Page />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'v2-beta-ci',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER]}>
          <AssistantV2BetaCodeInterpreterPage />
        </RoleBasedGuard>
      ),
    },
  ],
};
