import { Grid, Dialog, Button, DialogContent } from '@mui/material';
import React from 'react';
import { PageDescription } from './PageDescription';
import { DialogActions } from '@mui/material';

interface FileContentModalProps {
  isOpen: boolean;
  closeAction: () => void;
  closeBtnLabel: string;
  contents: string | undefined;
}

export const FileContentModal = React.memo(({ isOpen, closeAction, closeBtnLabel, contents }: FileContentModalProps) => {
  return isOpen ? (
    <Dialog
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      disableScrollLock={true}
      open={isOpen}
      maxWidth={'md'}>
      <DialogContent>
        <PageDescription text={contents} sx={{ padding: 2, whiteSpace: 'pre-line' }} />
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent={'right'} alignItems="center">
          <Button variant="contained" onClick={closeAction}>
            {closeBtnLabel}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
});
