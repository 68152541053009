import { Box, Button, Paper, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';

interface CopyPanelProps {
  tooltipOpen: boolean;
  tooltipClose: () => void;
  tooltipTitle: string;
  btnClick: () => void;
  btnLabel: string;
  contents: string;
}
export const CopyPanel = React.memo(({ tooltipOpen, tooltipClose, tooltipTitle, btnLabel, btnClick, contents }: CopyPanelProps) => {
  return (
    <Paper sx={{ mt: 2, mb: 1 }}>
      <Box role="presentation" flexDirection="row" justifyContent="flex-end" display="flex" sx={{ paddingRight: '7px', paddingTop: '10px' }}>
        <Tooltip arrow open={tooltipOpen} onClose={tooltipClose} disableHoverListener placement="top" title={tooltipTitle}>
          <Typography component="span">
            <Button size="small" color="inherit" variant="text" onClick={btnClick} startIcon={<ContentCopyIcon />}>
              {btnLabel}
            </Button>
          </Typography>
        </Tooltip>
      </Box>
      <Box sx={{ paddingRight: 2, paddingLeft: 2, paddingBottom: 2, minHeight: 300, whiteSpace: 'pre-wrap' }}>{contents}</Box>
    </Paper>
  );
});
