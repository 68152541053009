import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: String[];
  children: ReactNode | string;
  admin?: Boolean;
};
/**
 *
 * @return ロール
 */
const useCurrentRole = () => {
  // Logic here to get current user role
  const { user } = useAuth();
  if (user) {
    const role: String[] = user['role'];
    return role;
  }
  return [''];
};
/**
 *
 * @return ロール
 */
const useCurrentRoleType = () => {
  const { user } = useAuth();
  if (user) {
    const roleType: String = user['authority'];
    return roleType;
  }
  return 'NONE';
};
/**
 *
 * @param  arr パラメータ
 * @param  admin 管理者
 * @return  {boolean} 結果
 */
export const roleCheck = (arr: String[] = [], admin?: Boolean) => {
  if (admin && useCurrentRoleType() === 'ADMIN') return true;

  const currentRole = useCurrentRole();
  return [...currentRole, ...arr].filter((item) => currentRole.includes(item) && arr.includes(item)).length > 0;
};

/**
 *
 * @param  param0 パラメータ
 * @return コンポーネント
 */
export const RoleBasedGuard = ({ accessibleRoles, children, admin }: RoleBasedGuardProp) => {
  if (!roleCheck(accessibleRoles, admin)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
};
