import { LANGS_CODE } from './useLocales';

export const useDateFormatter = () => {
  /**
   * 日本時間に変換する (yyyy/MM/dd)
   * @param date 変換する日時
   * @returns 日本時間に変換した日時(yyyy/MM/dd)
   */
  const toJstDateString = (date: Date): string => {
    const JST_OFFSET = 9 * 60;
    const jstDate = new Date(date.getTime() + JST_OFFSET * 60000);
    const year = jstDate.getUTCFullYear();
    const month = ('0' + (jstDate.getUTCMonth() + 1)).slice(-2);
    const day = ('0' + jstDate.getUTCDate()).slice(-2);
    return `${year}/${month}/${day}`;
  };

  /**
   * 日本時間に変換する (yyyy/MM/dd HH:mm:ss)
   * @param date 変換する日時
   * @returns 日本時間に変換した日時(yyyy/MM/dd HH:mm:ss)
   */
  const toJstDatetimeString = (date: Date): string => {
    const JST_OFFSET = 9 * 60;
    const jstDate = new Date(date.getTime() + JST_OFFSET * 60000);
    const year = jstDate.getUTCFullYear();
    const month = ('0' + (jstDate.getUTCMonth() + 1)).slice(-2);
    const day = ('0' + jstDate.getUTCDate()).slice(-2);
    const hours = ('0' + jstDate.getUTCHours()).slice(-2);
    const minutes = ('0' + jstDate.getUTCMinutes()).slice(-2);
    const seconds = ('0' + jstDate.getUTCSeconds()).slice(-2);
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  };

  /**
   * ロケーションに合わせた日付フォーマットに変換する
   * @param lang ロケーション
   * @param date 変換する日時
   * @returns ロケーションに合わせた日付フォーマットに変換した日時
   */
  const toLocalesDateString = (lang: LANGS_CODE, date: Date): string => {
    // 現在は日本語の時のみ表示を切り替える
    const isJapanese = lang === LANGS_CODE.JP;
    if (isJapanese) {
      return toJstDateString(date);
    } else {
      return date.toLocaleDateString();
    }
  };

  return {
    toJstDateString,
    toJstDatetimeString,
    toLocalesDateString,
  };
};
