import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box, Theme } from '@mui/material';
import React from 'react';
import { OptionChatType } from 'src/models/chat-gpt';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

type Props = {
  options: OptionChatType[];
  onChange: (event: any, value: any) => void;
  value: OptionChatType;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
}));

export const LayoutToggleButtons = React.memo(({ options, value, onChange }: Props): React.ReactElement => {
  const toggleButtonStyle = { marginLeft: { xs: '1px', sm: '1em' }, marginRight: { xs: '1px', sm: '1em' }, overflow: 'hiden', textTransform: 'none' };
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <ToggleButtonGroup value={value.label} exclusive aria-label="text alignment" style={{ marginTop: '5px', height: '30px', whiteSpace: 'nowrap' }}>
      {options.map((option) => (
        <ToggleButton value={option.label} aria-label="left aligned" onClick={(e) => onChange(e, option)} className={classes.root}>
          <Box sx={toggleButtonStyle} style={{ overflow: 'hidden', fontSize: '11px' }}>
            {option.label}
          </Box>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
});
