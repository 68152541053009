import { useEffect, useRef } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';

/**
 * 録音機能用のHooks
 * @returns 公開している関数/状態
 */
export default function useRecorder() {
  const recorder = useRef<typeof MicRecorder>(null);

  /**
   * 初期化
   */
  useEffect(() => {
    recorder.current = new MicRecorder({ bitRate: 64 });
  }, []);

  /**
   * 録音を開始するための関数
   */
  const start = async () => {
    await recorder.current.start();
  };

  /**
   * 録音を停止するための関数\
   * 録音ファイルが生成できた場合はFile型を返す\
   * 録音ファイルの生成に失敗した場合はnullを返す
   * @returns 録音データのファイル(.mp3)
   */
  const stop = async () => {
    if (recorder.current) {
      const [buffer, blob] = await recorder.current.stop().getMp3();
      const file = new File(buffer, 'audio.mp3', { type: blob.type, lastModified: Date.now() });
      return file;
    } else {
      return null;
    }
  };

  return {
    start,
    stop,
  };
}
