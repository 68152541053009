// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography, Chip } from '@mui/material';
// components
import { Page } from 'src/components-minimals/Page';
import { LoginForm } from '../../components-minimals/authentication/login';
import { UpdatePasswordForm } from '../../components-minimals/authentication/update-password';
import { useState } from 'react';
import { currentEnv } from '../../config';
import 'src/css/font.css';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
/**
 *
 * @return コンポーネント
 */
export const Login = () => {
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  return (
    <RootStyle title="Login | Toyotsu GChat">
      <Container maxWidth="sm">
        <ContentStyle>
          {currentEnv !== 'production' ? (
            <>
              <Stack direction="row" alignItems="center" sx={{ mb: 12 }}>
                {' '}
                <Chip
                  style={{
                    flexGrow: 1,
                    // marginRight: '30px',
                    // paddingLeft: '60px',
                    // paddingRight: '60px',
                    // background: '#F848B5',
                    // color: '#F848B5',
                    width: '50%',
                    fontWeight: 'bold',
                  }}
                  label={'この環境は ' + currentEnv + ' です。'}
                  color="primary"
                  variant="outlined"
                />
                {/* <Box style={{ height: '100px' }}></Box> */}
              </Stack>
            </>
          ) : (
            <></>
          )}
          {/* <Paper sx={{ padding: '25px' }}> */}
          {!user ? (
            <>
              <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
                <Box sx={{ flexGrow: 1 }} alignItems="center">
                  <Stack sx={{ alignItems: 'center' }}>
                    <AutoFixHighIcon fontSize={'inherit'} style={{ fontSize: '5rem' }} />
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                      <Typography
                        className="marsFont"
                        sx={{
                          // paddingLeft: '16px',
                          // paddingTop: '-20px',
                          paddingBottom: '0px',
                          fontSize: { xs: '30px', sm: '40px', md: '40px' },
                          fontWeight: '800',
                          fontStyle: 'normal',
                          textAlign: 'center',

                          // ...(!isDesktop && { marginRight: '30px' }),
                        }}>
                        Toyotsu GChat
                      </Typography>
                    </Box>

                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </Stack>
                </Box>
              </Stack>
              <LoginForm onGetUser={(value) => setUser(value)} />
            </>
          ) : (
            <>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    {t('login.text.update')}
                  </Typography>
                </Box>
              </Stack>
              <UpdatePasswordForm onUser={user} />
            </>
          )}
          <Box style={{ height: '200px' }}></Box>
          {/* </Paper> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};
export default Login;
