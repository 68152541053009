import useAuth from 'src/hooks/useAuth';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocales } from 'src/hooks/useLocales';
import { OrganizationClassification, OrganizationSegment } from 'src/models/organization';
import { useNavigate } from 'react-router-dom';
import { PATH_DOC_SEARCH } from 'src/routes/paths';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { DocumentFile } from 'src/models/document-file';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { useDocumentFile } from 'src/hooks/useDocumentFile';
import useAgGridTheme from 'src/hooks/useAgGridTheme';
import { useDialog } from 'src/hooks/useDialog';
import { useDocumentFileAccessController } from 'src/hooks/useDocumenFiletAccessController';

export const useDocumentCollectionPageModel = () => {
  // Dependency Hooks
  const auth = useAuth();
  const locale = useLocales();
  const snackbar = useSnackbar();
  const documentFile = useDocumentFile();
  const navigate = useNavigate();
  const agGridTheme = useAgGridTheme();
  const deleteConfirmDialog = useDialog();
  const accessController = useDocumentFileAccessController({ user: auth.user });

  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentFiles, setDocumentFiles] = useState<DocumentFile[]>([]);
  const [selectedDocumentFile, setSelectedDocumentFile] = useState<DocumentFile | null>(null);
  const [organizationsL1, setOrganizationsL1] = useState<OrganizationClassification[]>([]);
  const [organizationsL2, setOrganizationsL2] = useState<OrganizationSegment[]>([]);
  const [currentOrganizationL1, setCurrentOrganizationL1] = useState<OrganizationClassification | null>(null);
  const [currentOrganizationL2, setCurrentOrganizationL2] = useState<OrganizationSegment | null>(null);
  const [withTags, setWithTags] = useState<boolean>(false);
  const [listLoading, setListLoading] = useState<boolean>(false);
  /**
   * 画面初期化時のライフラサイクルイベント
   */
  useLayoutEffect(() => {
    const classifications = accessController.fetchClassifications();
    setOrganizationsL1(() => classifications);
  }, []);

  /**
   * 組織分類が変更された時のイベント
   * @param classification 設定する組織分類
   */
  const onChangedOrganizationL1 = async (classification: OrganizationClassification | null) => {
    setDocumentFiles(() => []);
    setCurrentOrganizationL1(() => classification);
    setCurrentOrganizationL2(() => null);

    // 組織分類が"グループ全体"以外の時は、組織セグメントの取得を行う
    if (classification && classification.id !== 'all') {
      const segments = await fetchOrganizationalSegments(classification);
      setOrganizationsL2(() => segments);
    }

    // 組織分類が"グループ全体"の場合は検索を行う
    if (classification && classification.id === 'all') {
      const files = await fetchDocumentFiles(classification, null);
      setDocumentFiles(() => files);
    }
  };

  /**
   * 組織セグメントが変更された時のイベント
   * @param segment 設定する組織セグメント
   */
  const onChangedOrganizationL2 = async (segment: OrganizationSegment | null) => {
    setDocumentFiles(() => []);
    setCurrentOrganizationL2(() => segment);

    // ドキュメントファイルの取得を行う
    if (currentOrganizationL1 && segment) {
      const files = await fetchDocumentFiles(currentOrganizationL1, segment);
      setDocumentFiles(() => files);
    }
  };

  /**
   * ドキュメントファイルを削除するためのイベント
   * @param file 削除するドキュメントファイル
   */
  const onClickedDeleteButton = async (file: DocumentFile) => {
    // 組織分類が取得できていない場合は処理を行わない
    if (!currentOrganizationL1) {
      return;
    }

    // ダイアログのボタンが押されると処理が返ってくる作りになっている
    // okボタンが押された場合は、対象のファイルを削除する
    // 削除に成功した場合はリストの更新を行う
    setSelectedDocumentFile(file);
    const result = await deleteConfirmDialog.openDialog();
    if (result === 'ok') {
      await deleteDocumentFile(file, currentOrganizationL1)
        .then(async () => {
          const files = await fetchDocumentFiles(currentOrganizationL1, currentOrganizationL2);
          setDocumentFiles(() => files);
        })
        .finally(() => {
          setSelectedDocumentFile(null);
        });
    }
  };

  /**
   * ドキュメントファイルをダウンロードするためのイベント
   * @param file ダウンロードするドキュメントファイル
   */
  const onClickedDownloadButton = async (file: DocumentFile) => {
    await downloadDocumentFile(file);
  };

  /**
   * 登録画面へ遷移するためのボタンが押下された時のイベント
   */
  const onClickedGotoDocumentRegistrationPageButton = () => {
    navigate(PATH_DOC_SEARCH.upload, { replace: false });
  };

  /**
   * 組織セグメントの取得をするための関数
   * @param classification 組織分類
   */
  const fetchOrganizationalSegments = async (classification: OrganizationClassification) => {
    try {
      setIsLoading(() => true);
      return await accessController.fetchSegmentsByClassification(classification);
    } catch (err) {
      snackbar.error(locale.translate('common.message.error.orgSegmentFetch'));
      return [];
    } finally {
      setIsLoading(() => false);
    }
  };

  /**
   * ドキュメントファイルを取得するための関数
   * @param classification 組織分類
   * @param segment 組織セグメント
   */
  const fetchDocumentFiles = async (classification: OrganizationClassification, segment: OrganizationSegment | null) => {
    try {
      setListLoading(() => true);
      return await documentFile.getByOrganization(classification, segment, withTags);
    } catch (err: any) {
      let message = '';
      if (err instanceof Error) message += err.message;
      snackbar.error(locale.translate('document-collection.message.error.get') + message);
      return [];
    } finally {
      setListLoading(() => false);
    }
  };

  /**
   * ドキュメントファイルを削除するための関数
   * @param file 削除するドキュメントファイル
   * @param classification 組織分類
   */
  const deleteDocumentFile = async (file: DocumentFile, classification: OrganizationClassification) => {
    try {
      setIsLoading(() => true);
      await documentFile.remove(file, classification);
      snackbar.success(locale.translate('document-collection.message.success.delete'));
    } catch (err: any) {
      let message = '';
      if (err instanceof Error) message += err.message;
      snackbar.error(locale.translate('document-collection.message.error.delete') + message);
    } finally {
      setIsLoading(() => false);
    }
  };

  /**
   * ドキュメントファイルをダウンロードするための関数
   * @param file ダウンロードするドキュメントファイル
   */
  const downloadDocumentFile = async (file: DocumentFile) => {
    try {
      setIsLoading(() => true);
      await documentFile.download(file);
    } catch (err: any) {
      let message = '';
      if (err instanceof Error) message += err.message;
      snackbar.error(locale.translate('document-collection.message.error.download') + message);
    } finally {
      setIsLoading(() => false);
    }
  };

  /**
   * タグデータを表示するための関数
   * @param tags タグのデータ
   * @return タグリスト
   */
  const displayTagsValue = (tags: string[] | undefined) => {
    if (tags === undefined) {
      return undefined;
    } else {
      return tags.join(', ');
    }
  };

  /**
   * チェックボックス（検索条件：タグを含むか）onChange
   */
  const checkboxHandler = useCallback(() => {
    setWithTags(() => !withTags);
  }, [withTags]);

  // SIDE EFFECT
  // ================================================================================
  // サイドバーの表示
  useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
      if (currentOrganizationL1 && (currentOrganizationL1.id === 'all' || Boolean(currentOrganizationL2))) {
        const files = await fetchDocumentFiles(currentOrganizationL1, currentOrganizationL2);
        setDocumentFiles(() => files);
      }
    };
    fetchFiles();
  }, [withTags]);

  return {
    // hooks
    locale,
    agGridTheme,
    deleteConfirmDialog,
    // states
    isLoading,
    documentFiles,
    selectedDocumentFile,
    organizationsL1,
    organizationsL2,
    currentOrganizationL1,
    currentOrganizationL2,
    withTags,
    listLoading,
    // event handlers
    onChangedOrganizationL1,
    onChangedOrganizationL2,
    onClickedDeleteButton,
    onClickedDownloadButton,
    onClickedGotoDocumentRegistrationPageButton,
    checkboxHandler,
    // display functions
    displayTagsValue,
    displayOrganizationL1: accessController.displayOrganizationL1,
    displayOrganizationL2: accessController.displayOrganizationL2,
  };
};
