// hooks
import useAuth from '../hooks/useAuth';
// utils
import { createAvatar } from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------
/**
 *
 * @param  param0 パラメータ
 * @return コンポーネント
 */
export const MyAvatar = ({ ...other }: AvatarProps) => {
  const { user } = useAuth();

  return (
    <Avatar src={user?.photoURL} alt={user?.displayName} color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color} {...other}>
      {createAvatar(user?.avatarName).name}
    </Avatar>
  );
};
