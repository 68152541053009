import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';
import { apiGateway, prefixApiGateway } from 'src/config';
import { ChatLog } from 'src/@types/chat';
import { ChatMessage } from 'src/models/chat-gpt';
import { Auth } from 'aws-amplify';

export const mainChatAPI = {
  getChatLogs(takeCount: number, skipCount: number): Promise<AxiosResponse<ChatLog[]>> {
    const params = `?filter={"take": ${takeCount}, "skip": ${skipCount}}`;
    return axios.get(apiGateway + prefixApiGateway + '/chat/chatLogs' + params);
  },
  getChatMessages(selectId: string): Promise<AxiosResponse<ChatMessage>> {
    return axios.get(apiGateway + prefixApiGateway + '/chat/message/' + selectId);
  },
  getChatTokenLength(selectId: string): Promise<AxiosResponse<number>> {
    return axios.get(apiGateway + prefixApiGateway + '/chat/token/' + selectId);
  },
  deleteChat(id: string): Promise<AxiosResponse<void>> {
    return axios.delete(apiGateway + prefixApiGateway + '/chat/' + id);
  },
  downloadImg(id: string) {
    return axios.post(apiGateway + prefixApiGateway + '/chat/image/download', { id: id }, { responseType: 'blob' });
  },
  async getImage(api: string) {
    /**
     *
     * @param blob blob
     * @returns base64
     */
    function blobToBase64(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (reader.result && typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            reject(new Error('Failed to convert blob to base64: result is not a string'));
          }
        };

        reader.onerror = () => {
          reject(new Error('Failed to read blob'));
        };

        reader.readAsDataURL(blob);
      });
    }
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const response = await fetch(api, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      return await blobToBase64(blob);
    } else {
      console.error('Failed to load image:', api);
      return '';
    }
  },
  // getOriginBase64(id: string) {
  //   return axios.post(apiGateway + prefixApiGateway + '/chat/image/origin', { id: id });
  // },
};
