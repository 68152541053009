import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, Paper, Stack, SxProps, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { TermsOfService } from 'src/models/term';

export interface TermsOfServiceDialogProps {
  open: boolean;
  terms: TermsOfService[];
  info: (tos: TermsOfService) => string;
  dialogTitle: string;
  termsTitle: (tos: TermsOfService) => string;
  onAgree?: any;
  onDisagree?: any;
  agreeButtonLabel: string;
  disagreeButtonLabel: string;
}

export const TermsOfServiceDialog = (props: TermsOfServiceDialogProps) => {
  const { open, terms, info, dialogTitle, termsTitle, onAgree, onDisagree, agreeButtonLabel, disagreeButtonLabel } = props;

  const sx: SxProps = {
    borderTop: 0.25,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
    '&.MuiPaper-root': {
      borderRadius: 0,
      boxShadow: 'none',
    },
    '&:last-child': {
      borderBottom: 0.25,
    },
  };

  return (
    <Dialog open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Paper title="TermsOfService" sx={{ height: '75vh', flexDirection: 'column', display: 'flex' }}>
        {/* Header block */}
        <Stack direction={'column'}>
          <Stack direction={'row'} justifyContent={'center'}>
            <Box sx={{ p: 3 }}>
              <Typography variant={'h5'}>{dialogTitle}</Typography>
            </Box>
          </Stack>
        </Stack>

        {/* Content block */}
        <Stack direction={'column'}>
          {terms.map((tos) => (
            <Accordion key={tos.id} disableGutters sx={sx}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Stack direction={'column'}>
                  <Box>
                    <Typography variant={'caption'} color="grey">
                      {info(tos)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant={'h6'}>{termsTitle(tos)}</Typography>
                  </Box>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant={'body2'}>
                  <Box component={'div'} dangerouslySetInnerHTML={{ __html: tos.content }} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>

        {/* Footer block */}
        <Stack direction="column" justifyContent={'center'} marginTop={'auto'} sx={{ pt: 2, pb: 2 }}>
          <Stack direction={'row'} justifyContent={'space-evenly'}>
            <Button variant="contained" color="error" onClick={onDisagree}>
              {disagreeButtonLabel}
            </Button>
            <Button variant="contained" color="primary" onClick={onAgree}>
              {agreeButtonLabel}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Dialog>
  );
};
