import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import { IconWithMessage } from './IconWithMesssage';
import { PromptIcon } from './PromtIcon';

const useStyles = makeStyles({
  rectangle: {
    '&:hover': {
      background: 'rgba(128,128,128,0.25)',
    },
    borderRadius: '10px',
  },
});
interface PromptCardProps {
  onClick: () => void;
  title: string;
  // description: string;
  usedHistory: number;
  usage: string;
  tags: string[];
}
export const PromptCard = React.memo(({ onClick, title, usage, usedHistory, tags }: PromptCardProps) => {
  const classes = useStyles();
  return (
    <Grid item md={6} xs={12}>
      <Paper elevation={0}>
        <Box onClick={onClick} className={classes.rectangle} sx={{ maxWidth: '927px' }}>
          <Grid container sx={{ minHeight: '88px', maxHeight: '88px' }}>
            <Grid item xs={2}>
              <Grid container justifyContent="center" style={{ paddingTop: '12px', height: '100%', marginBottom: '8px' }}>
                <Grid item>
                  <PromptIcon title={tags[0]} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} height={'100%'} marginTop={1.5} marginBottom={1.5} container direction={'column'}>
              <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-start" spacing={0}>
                    <Typography variant="subtitle2">{title}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" spacing={1} sx={{ fontSize: '1em', color: 'gray' }}>
                    <IconWithMessage icon={<BarChartRoundedIcon color="disabled" fontSize="small" />} message={`${usedHistory}`} tooltip={usage} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
});
