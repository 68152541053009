import { apiGateway, prefixApiGateway } from 'src/config';
import axios from 'src/utils/axios';
import { OrganizationResponse } from 'src/models/organization';

export const portalAPI = {
  getPortalDatas(props: { classification: string }): Promise<OrganizationResponse[]> {
    const { classification } = props;
    return axios.get(apiGateway + prefixApiGateway + '/portal/organization?classification=' + classification).then((res) => res.data);
  },
};
