import { app, authentication } from '@microsoft/teams-js';
import { Stack, Container, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ClosePopup = () => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const code: string = params.get('code') as string;
  const { authId, method } = JSON.parse(params.get('state') as string);
  let url = '';

  if (method === 'noweb') {
    url = `msteams://teams.microsoft.com/l/auth-callback?authId=${authId}&result=${code}`;
    window.location.href = url;
  } else {
    app.initialize().then(async () => {
      authentication.notifySuccess(code);
    });
  }

  const callbackTeams = () => {
    window.location.href = url;
  };

  useEffect(() => {
    const elem: any = document.getElementById('messageDiv');
    elem.innerHTML = '';
    return () => {};
  }, []);

  return (
    <div>
      <div id="messageDiv"></div>
      {method === 'noweb' ? (
        <Container maxWidth="sm">
          <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 3 }}>
            <Typography variant="body1" mb={2}>{`${t('login.text.closePopupLabel1')}`}</Typography>
            <Typography variant="body2" mb={2}>{`${t('login.text.closePopupLabel2')}`}</Typography>
            <Button fullWidth color="secondary" size="large" variant="contained" onClick={callbackTeams}>
              {`${t('login.text.closePopupButton')}`}
            </Button>
          </Stack>
        </Container>
      ) : null}
    </div>
  );
};
