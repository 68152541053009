import DeleteIcon from '@mui/icons-material/Delete';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import RecyclingIcon from '@mui/icons-material/Recycling';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import WcIcon from '@mui/icons-material/Wc';
import SpeedIcon from '@mui/icons-material/Speed';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import DownloadIcon from '@mui/icons-material/Download';
import PageviewIcon from '@mui/icons-material/Pageview';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import React from 'react';

export type IconType =
  | 'delete'
  | 'volume'
  | 'help'
  | 'open'
  | 'recycle'
  | 'voice'
  | 'gender'
  | 'speed'
  | 'lang'
  | 'download'
  | 'pageView'
  | 'bolt'
  | 'copy'
  | 'edit'
  | 'dot'
  | 'web';

interface IconComponentProps {
  icon?: IconType;
  fontSize?: 'small' | 'inherit' | 'medium' | 'large';
  sx?: React.CSSProperties;
  color?: 'inherit' | 'disabled' | 'action' | 'success' | 'error' | 'primary' | 'secondary' | 'info' | 'warning';
}
export const IconComponent = React.memo(({ icon, fontSize = 'medium', sx, color }: IconComponentProps) => {
  const iconComponents: Record<IconType, React.ReactElement> = {
    delete: <DeleteIcon fontSize={fontSize} sx={sx} color={color} />,
    volume: <VolumeUpIcon fontSize={fontSize} sx={sx} color={color} />,
    help: <HelpOutlineIcon fontSize={fontSize} sx={sx} color={color} />,
    open: <FolderOpenIcon fontSize={fontSize} sx={sx} color={color} />,
    recycle: <RecyclingIcon fontSize={fontSize} sx={sx} color={color} />,
    voice: <GraphicEqIcon fontSize={fontSize} sx={sx} color={color} />,
    gender: <WcIcon fontSize={fontSize} sx={sx} color={color} />,
    speed: <SpeedIcon fontSize={fontSize} sx={sx} color={color} />,
    lang: <GTranslateIcon fontSize={fontSize} sx={sx} color={color} />,
    download: <DownloadIcon fontSize={fontSize} sx={sx} color={color} />,
    pageView: <PageviewIcon fontSize={fontSize} sx={sx} color={color} />,
    bolt: <BoltRoundedIcon fontSize={fontSize} sx={sx} color={color} />,
    copy: <ContentCopyIcon fontSize={fontSize} sx={sx} color={color} />,
    edit: <EditNoteIcon fontSize={fontSize} sx={sx} color={color} />,
    dot: <MoreHorizRoundedIcon fontSize={fontSize} sx={sx} color={color} />,
    web: <TravelExploreIcon fontSize={fontSize} sx={sx} color={color} />,
  };

  return icon ? iconComponents[icon] : <></>;
});
