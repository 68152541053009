/* eslint-disable no-throw-literal */
import { apiGateway, prefixApiGateway, pythonGateway, prefixPythonGateway } from 'src/config';
import { Auth } from 'aws-amplify';
import { ChatGpt } from 'src/models/chat-gpt';

export const gptAPI = () => {
  /**
   * GPTへの質問を行うための関数 (1問1答 ストリーミング用)
   * @param model 使用するGPTのバージョン
   * @param prompt GPTへ送るプロンプト
   * @param onResponse GPTからの返答を受け取るコールバック関数
   */
  async function* stream(model: ChatGpt, prompt: string): AsyncGenerator<string> {
    const url = apiGateway + prefixApiGateway + '/gpt/stream';
    const method = 'POST';
    const body = JSON.stringify({ model: model.serverSideVersionString, prompt });
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await Auth.currentSession()).getIdToken().getJwtToken(),
    };

    // APIへのリクエスト
    const res = await fetch(url, { method, body, headers });
    if (!res.ok) {
      throw { code: res.status, detail: res.statusText };
    }

    // ストリームリーダーの取得
    const reader = res.body?.getReader();
    if (!reader) {
      throw { code: 0, detail: 'Failed to get reader.' };
    }

    // ストリームの受信処理
    // APIからのレスポンスは改行コードで区切られているため、改行コードで分割して処理を行う
    // 1度に読み込みで複数のレスポンスを受け取っている可能性があるため、分割後の配列をループで処理する必要がある
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const text = new TextDecoder().decode(value);
      const texts = text.split('\n');
      if (texts.length >= 2) {
        texts.pop();
        const jsons = texts.filter((text) => !text === false).map((text) => JSON.parse(text));
        for (const json of jsons) {
          if (json.code === 200) {
            yield json.result;
          } else {
            throw json;
          }
        }
      }
    }
  }

  /**
   * テキストを音声に変換するための関数
   * @param text 変換するテキスト
   * @param timeout タイムアウト時間(単位:ms)
   * @returns 変換された音声データ
   */
  async function tts(text: string, timeout: number): Promise<Blob> {
    // タイマーの設定
    const controller = new AbortController();
    const signal = controller.signal;
    setTimeout(() => controller.abort(), timeout);

    // リクエスト
    const response = await fetch(pythonGateway + prefixPythonGateway + '/ai/tts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      body: JSON.stringify({ text: text }),
      signal,
    });

    if (!response.ok) {
      throw Error('Failed to request.');
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw Error('Failed to get reader.');
    }

    const chunks: Uint8Array[] = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks.push(value!);
    }

    return new Blob(chunks, { type: 'audio/wav' });
  }

  return {
    stream,
    tts,
  };
};

/**
 * gptAPIで発生するエラーコード
 *
 * /server/src/gpt/gpt.exception.tsファイルと一致させること
 */
export enum GptApiErrorCode {
  UNDEFINED_ERROR = 130000,
  MODEL_ERROR,
  TOKEN_SIZE_LIMIT_EXCEEDED,
  CONTENT_FILTERING_ERROR,
}
