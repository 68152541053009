import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { app } from '@microsoft/teams-js';
//
import enLocales from './en.json';
import jaLocales from './ja.json';

const getLang = async () => {
  let teamsLocale = '';
  if (window !== window.parent) {
    await app.initialize();
    teamsLocale = (await app.getContext()).app.locale;
  }
  if (teamsLocale) return teamsLocale.slice(0, 2);
  else if (localStorage.getItem('i18nextLng')) return localStorage.getItem('i18nextLng') as 'ja' | 'en';
  else if (navigator.languages[0] === 'ja') return 'ja';
  else return 'en';
};
// ----------------------------------------------------------------------
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      ja: { translations: jaLocales },
    },
    lng: await getLang(),
    fallbackLng: 'ja',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
