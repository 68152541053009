// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, ListItemText, IconButton, Typography } from '@mui/material';
// type
import { NavSectionProps } from '../type';
import React, { memo } from 'react';
import { ListItemStyle, ListItemIconStyle } from './style';
import { DotIcon } from './NavItem';

import { useChatMessageContext, useDispatchChatMessageContext } from 'src/contexts/ChatMessageContext';
import { mainChatAPI } from 'src/apis/mainChatAPI';
import { ChatLog } from 'src/@types/chat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import snackbar from 'src/components-mars/Snackbar';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import i18n from 'src/locales/i18n';
import { useState, useEffect } from 'react';
import { startOfWeek, startOfMonth, subMonths } from 'date-fns';
import { GptModel, chatModels } from 'src/models/chat-gpt';
import { CHAT_TAKE_COUNT } from 'src/constants';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

/**
 * @return  コンポーネント
 */
export const NewChatIcon = () => {
  return (
    <ListItemIconStyle>
      <FontAwesomeIcon icon={faStar} size="lg" />
    </ListItemIconStyle>
  );
};

// ----------------------------------------------------------------------
type chatChangeProps = { id: string; version: number; model: GptModel; isWebSearch?: boolean };
/**
 *
 * @param  param0 パラメータ
 * @return コンポーネント
 */
export const NavSectionVertical = ({ navConfig, isCollapse = false, ...other }: NavSectionProps) => {
  const {
    chatLog,
    handleChatLog,
    selectId,
    handleSelectId,
    handleIsNewChat,
    abortCntl,
    handleSkipCount,
    handleUseChatType,
    handleChatHistoryWebSearch,
    handleIsDelete,
  } = useChatMessageContext();
  const { onSnackbarClose } = snackbar();
  const dispatch = useDispatchChatMessageContext();
  const { t } = useTranslation();

  const [termChat, setList] = useState<ChatLog[][] | null>();

  const category = [
    t('chat.sidemenu.group.today'),
    t('chat.sidemenu.group.thisWeek'),
    t('chat.sidemenu.group.thisMonth'),
    t('chat.sidemenu.group.lastMonth'),
    t('chat.sidemenu.group.priorToLastMonth'),
  ];
  // const [targetIndex, settargetIndex] = useState<number[]>([]);

  const date = new Date();
  const strNowdate = JSON.stringify(date);

  // 週初め
  const startDayofWeek = startOfWeek(new Date());
  // 月初め
  const startDayofMonth = startOfMonth(new Date());
  // 先月
  const DayofLastMonth = subMonths(new Date(), 1);
  const strDayofLastMonth = JSON.stringify(DayofLastMonth);
  // 先月初め
  const startDayofLastMonth = startOfMonth(DayofLastMonth);

  // const handleClick = (index: any) => {
  //   targetIndex.includes(index)
  //     ? settargetIndex((prevState) => prevState.filter((value) => value !== index))
  //     : settargetIndex((prevState) => [...prevState, index]);
  // };

  // 今日 今週 今月 先月 先月以前でグルーピング
  useEffect(() => {
    if (chatLog) {
      // 今日の日付に一致するチャット
      const matchToday = chatLog.filter((chatLog) => {
        const strChatdate = JSON.stringify(chatLog.updatedAt);
        return strChatdate.substring(1, 11) === strNowdate.substring(1, 11);
      });

      // 今週の日付に一致するチャット(今日以外)
      const matchThisWeek = chatLog.filter((chatLog) => {
        const strChatdate = JSON.stringify(chatLog.updatedAt);
        const Chatdate = new Date(chatLog.updatedAt);
        return strChatdate.substring(1, 11) !== strNowdate.substring(1, 11) && Chatdate >= startDayofWeek;
      });

      // 今月の日付に一致するチャット(今週以外)
      const matchThisMonth = chatLog.filter((chatLog) => {
        const Chatdate = new Date(chatLog.updatedAt);
        return Chatdate < startDayofWeek && Chatdate >= startDayofMonth;
      });

      // 先月の日付に一致するチャット
      const matchLastMonth = chatLog.filter((chatLog) => {
        const strChatdate = JSON.stringify(chatLog.updatedAt);
        return strChatdate.substring(1, 8) === strDayofLastMonth.substring(1, 8);
      });

      // 先月より前のチャット
      const matchBeforeLastMonth = chatLog.filter((chatLog) => {
        const Chatdate = new Date(chatLog.updatedAt);
        return Chatdate < startDayofLastMonth;
      });

      const result = [matchToday, matchThisWeek, matchThisMonth, matchLastMonth, matchBeforeLastMonth];
      setList(result);
    }
  }, [chatLog]);

  /**
   * チャット履歴から選択されたメッセージを表示する
   * @param props チャットの情報
   */
  const handleChatChange = async (props: chatChangeProps) => {
    const { id, version, model, isWebSearch } = props;
    const foundModel = chatModels.find((item) => item.versionNum === version && item.serverSideVersionString === model);
    if (id) {
      handleIsNewChat(false);
      handleChatHistoryWebSearch(isWebSearch ? true : false);
    } else {
      handleIsNewChat(true);
    }
    handleUseChatType(foundModel || chatModels[0]);
    if (selectId !== id) {
      dispatch({
        type: 'message/delete',
        payload: { role: 'user', content: '' },
      });
      // 切り替えが行われた場合は中断する
      if (abortCntl) {
        abortCntl.abort();
      }
      handleSelectId(id);
    }
  };

  const selectList = (id: string) => {
    if (selectId === id) {
      return true;
    }
    return false;
  };

  const handleDelete = async (id: string) => {
    try {
      mainChatAPI
        .deleteChat(id)
        .then(() => {
          dispatch({
            type: 'message/delete',
            payload: { role: 'user', content: '' },
          });
          handleSkipCount(0);
          handleIsDelete(false);
          mainChatAPI
            .getChatLogs(CHAT_TAKE_COUNT, 0)
            .then((res) => {
              handleChatLog(res.data);
              handleSkipCount(0 + CHAT_TAKE_COUNT);
              handleIsDelete(true);
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => {
              handleSelectId('');
            });
        })
        .catch((e) => {
          console.log(e);
          onSnackbarClose({
            color: 'error',
            isPersist: true,
            msg: `${t('common.message.error.sendGPTMessage')} ${e}`,
          });
        });
    } catch (e) {
      console.log(e);
      onSnackbarClose({
        color: 'error',
        isPersist: true,
        msg: `${t('common.message.error.sendGPTMessage')} ${e}`,
      });
    }
  };

  const newChattitleStyle = {
    border: '1px solid',
  } as const;

  const multilineStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2 /* number of lines to show */,
    WebkitBoxOrient: 'vertical',
  };

  return (
    <Box {...other}>
      <List sx={{ px: 2 }}>
        <ListItemStyle
          sx={newChattitleStyle}
          activeRoot={selectList('')}
          onClick={() => handleChatChange({ id: '', version: chatModels[0].versionNum, model: chatModels[0].serverSideVersionString })}>
          <NewChatIcon />
          <ListItemText disableTypography primary={'new Chat'} />
        </ListItemStyle>
        <Typography align="left" sx={{ marginTop: 1, marginBottom: 1, marginLeft: 3, fontWeight: 'bold', fontSize: '90%' }}>
          {i18n.t('chat.sidemenu.history')}
        </Typography>
        {termChat &&
          termChat.map((chatlog: ChatLog[], index: number) => (
            <React.Fragment key={index}>
              {chatlog.length !== 0 && (
                <Typography align="left" sx={{ marginTop: 1, marginBottom: 1, marginLeft: 3, fontWeight: 'bold', fontSize: '80%' }}>
                  {category[index]}
                </Typography>
              )}
              <>
                {chatlog.length !== 0 && (
                  <>
                    {chatlog.map((chat: ChatLog) => (
                      <ListItemStyle
                        key={chat.id}
                        activeRoot={selectList(chat.id)}
                        onClick={() =>
                          handleChatChange({ id: chat.id, version: chat.version, model: chat.modelName, isWebSearch: chat.isWebSearch })
                        }>
                        <DotIcon active={selectList(chat.id)} />
                        <ListItemText disableTypography>
                          <Grid container alignItems="center" justifyContent="flex-start">
                            <Grid item xs={selectList(chat.id) ? 10 : 12} textAlign="left" sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography variant="body2" sx={multilineStyle} className="chatText">
                                {chat.title}
                              </Typography>
                            </Grid>
                            {selectList(chat.id) && (
                              <Grid item xs={2}>
                                <IconButton onClick={() => handleDelete(chat.id)}>
                                  <DeleteIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </ListItemText>
                      </ListItemStyle>
                    ))}
                  </>
                )}
              </>
            </React.Fragment>
          ))}
      </List>
    </Box>
  );
};

export default memo(NavSectionVertical);
