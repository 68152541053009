import { ICellRendererParams } from 'ag-grid-community';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from 'src/constants';
import useAgGridTheme from 'src/hooks/useAgGridTheme';
import useAuth from 'src/hooks/useAuth';
import { useDialog } from 'src/hooks/useDialog';
import { useDocumentFile } from 'src/hooks/useDocumentFile';
import { useDocumentMenu } from 'src/hooks/useDocumentMenu';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';

export const usePersonalDocumentsUploadPageModel = () => {
  // HOOK
  // ================================================================================
  const locale = useTranslation();
  const snackbar = useSnackbar();
  const auth = useAuth();
  const documentFile = useDocumentFile();
  const contentDialog = useDialog();
  const confrimDialog = useDialog();
  const agGridTheme = useAgGridTheme();
  const documentMenu = useDocumentMenu();

  // STATE
  // ================================================================================
  const [fileList, setFileList] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [fileContent, setFileContent] = useState<string>('');
  const [duplicated, setDuplicated] = useState<string[]>([]);

  // 固定値
  const maxFiles = 2;
  const adminRole = Boolean(auth.user && auth.user.role && auth.user.role.includes(USER_ROLES.GPT_UPLOAD_ADMIN));
  const FOLDER_LIMIT = 1024 * 1024 * 1024;
  const maxFileNameLength = 100;
  const maxFileSize = 50 * 1024 * 1024;
  // MEMO
  // ================================================================================
  /**
   * アップロード活性、非活性制御
   */
  const uploadDisabled = useMemo(() => {
    if (fileList.length <= 0) {
      return true;
    }
  }, [fileList]);

  // EVENT HANDLER ※useCallbackする
  // ================================================================================
  /**
   * ドロップゾーンで許可されたファイルをag-gridのリストに追加する関数
   * @param acceptedFiles 許可されたファイルズ
   */
  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        // Macなどからアップロードした場合、utf-8-macになるため、一覧取得時に検索結果に現れないことが発生する
        // そのため、登録時にutf-8にとなるように変換を行う必要がある
        // しかし、File型のnameはreadonlyのため、新しいFileオブジェクトを作成する
        const normalizedFiles: File[] = [];
        acceptedFiles.forEach((file) => {
          normalizedFiles.push(
            new File([file], file.name.normalize(), {
              type: file.type,
              lastModified: file.lastModified,
            }),
          );
        });

        setFileList((prevArray) => [...prevArray, ...normalizedFiles]);
      }
    },
    [fileList],
  );

  /**
   * ドロップゾーンにファイルを置くのが失敗した際の関数
   * @param files ドロップゾーンに失敗したファイルのリスト
   * @param _ ドロップのイベント
   */
  const onDropRejected = useCallback((files: FileRejection[], _: DropEvent) => {
    let message = '';
    if (files.length <= maxFiles) message = locale.t('document-personal-upload.drop.message.unsupportedFileFormat');
    if (files.length > maxFiles) message = locale.t('document-personal-upload.drop.message.filesOver');
    snackbar.error(message);
  }, []);

  /**
   * ドキュメントファイルの内容を取得するための関数
   * @param params ag-grid業のデータ
   */
  const getDocumentFileContents = useCallback(async (params: ICellRendererParams) => {
    try {
      setIsLoading(true);
      const file = params.data as File;
      const documentContents = await documentFile.getContentString(file);
      setFileContent(documentContents);
      contentDialog.openDialog();
    } catch (err: any) {
      // 「excel」とメッセージが返ってくるものはエクセルファイルのロード時にエラーが発生したものです。
      // 同じく Errorタイプですが、中身が違い、responseデータのdetailにあるエラーコードをベースでエラーメッセージを区別し、多言語対応を行います。
      if (err.response && err.response.data && err.response.data.detail) {
        snackbar.error(
          locale.t('document-personal-upload.message.error.contents') +
            '\n' +
            locale.t(`document-personal-upload.message.error.${err.response.data.detail}`),
        );
      } else if (err instanceof Error) {
        // 判明できないエラーの場合、サーバーからのエラーメッセージを表示します。
        if (err.message) snackbar.error(locale.t('document-personal-upload.message.error.contents') + err.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  /**
   * ドキュメントファイルをアップロードするための関数
   * @param classification 組織分類
   * @param segment 組織セグメント
   */
  const uploadDocumentFiles = useCallback(async () => {
    if (checkUploadFiles()) return;
    try {
      await checkDuplicatedDocumentName();
      const folderSize = await checkFolderSize();
      const uploadFileTotal = fileList.reduce((acc, file) => acc + file.size, 0);
      if (folderSize + uploadFileTotal > FOLDER_LIMIT) {
        snackbar.info(locale.t('document-personal-upload.message.info.sizeMaximum'));
        return;
      }
      const result = await confrimDialog.openDialog();
      if (result === 'ok') {
        setIsUploadLoading(true);
        if (!uploadDisabled) {
          await documentFile
            .uploadPersonal({
              files: fileList,
            })
            .then(() => {
              snackbar.success(locale.t('document-personal-upload.message.success.upload'));
              clear();
            });
        }
      }
    } catch (err: any) {
      let message = '';
      if (err instanceof Error) {
        message += err.message;
      }
      if (message) {
        if (documentMenu.UPLOAD_ERR_MSG_LIST.includes(message))
          snackbar.error(locale.t(`document-personal-upload.message.error.${message}`, { maxFileSize: maxFileSize / (1024 * 1024) }));
        else snackbar.error(locale.t('document-personal-upload.message.error.upload') + message);
      }
    } finally {
      setIsUploadLoading(false);
    }
  }, [fileList]);

  /**
   * ファイルをアップする前にバックに送るファイルの数を確認する関数です。
   * @return 最大アップロードできる数より多いファイルがgrid上に存在すると「 true 」を返します。
   */
  const checkUploadFiles = useCallback(() => {
    if (fileList.length > maxFiles) {
      snackbar.info(locale.t('document-personal-upload.drop.message.maxFiles', { maxFilesNum: maxFiles }));
      return true;
    }
    if (fileList.filter((file) => file.name.length > maxFileNameLength).length > 0) {
      snackbar.error(locale.t('document-personal-upload.message.error.filenameLength', { maxFileNameLength: maxFileNameLength }));
      return true;
    }
    if (fileList.filter((file) => file.size > maxFileSize).length > 0) {
      snackbar.error(locale.t('document-personal-upload.message.error.sizeOver', { maxFileSize: maxFileSize / (1024 * 1024) }));
      return true;
    }
    if (fileList.filter((file) => !documentFile.ALLOWED_EXTENSIONS.includes(file.name.split('.').pop()?.toLowerCase() || '')).length > 0) {
      snackbar.error(locale.t('document-personal-upload.message.error.extension'));
      return true;
    }
    return false;
  }, [fileList]);

  const checkDuplicatedDocumentName = useCallback(async () => {
    setIsLoading(() => true);
    try {
      const documentFiles = await documentFile.getFileNamesByUserID();
      const fileNames = fileList.map((item) => item.name);
      setDuplicated(() => documentFiles.filter((doc) => fileNames.includes(doc)));
    } catch (err: any) {
      console.log(err);
      setDuplicated([]);
    } finally {
      setIsLoading(() => false);
    }
  }, [fileList]);

  const checkFolderSize = useCallback(async () => {
    setIsLoading(() => true);
    return await documentFile.getS3FolderSize().finally(() => setIsLoading(() => false));
  }, [fileList]);

  /**
   * クリアボタンのonClick対応
   */
  const clear = useCallback(() => {
    setFileList([]);
  }, []);

  // SIDE EFFECT
  // ================================================================================
  // サイドバーの表示
  useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  return {
    // hooks
    locale,
    agGridTheme,
    contentDialog,
    confrimDialog,
    documentMenu,
    // const
    maxFiles,
    adminRole,
    // states
    alertShow: fileList.length > 0,
    fileList,
    isLoading,
    isUploadLoading,
    fileContent,
    duplicated,
    // memo
    uploadDisabled,
    // event handlers
    getDocumentFileContents,
    onDropRejected,
    onDropAccepted,
    uploadDocumentFiles,
    clear,
  };
};
