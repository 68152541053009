import { Container, Grid } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { useReleaseNotePageModel } from './useReleaseNotePageModel';

export const ReleaseNotePage = () => {
  const pm = useReleaseNotePageModel();
  return (
    <Page title="ReleaseNotes">
      <Container maxWidth={"md"}>
        <Grid className="bg-gray-200 p-3 text-sm w-full prose prose-sm">
          <Grid dangerouslySetInnerHTML={{ __html: pm.htmlText }} className="w-3/4" />
        </Grid>
      </Container>
    </Page>
  );
};
