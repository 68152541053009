import React from 'react';
import { useDallE3PageModel } from './useDallE3PageModel';
import { Container, InputBase, Paper, Grid, Box, Tooltip, Typography, Button } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { PageTitle } from 'src/components/PageTitle';
import { PageDescription } from 'src/components/PageDescription';
import { LoadingButton } from '@mui/lab';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

const DallE3Page = (): React.ReactElement => {
  const pm = useDallE3PageModel();
  return (
    <Page title="Dall・E3 Sample">
      <Container maxWidth={'md'}>
        <Grid display="flex">
          <PageTitle text={'Dall・E3サンプル'} />
        </Grid>
        <PageDescription
          text={
            'DALL-E 3(ダリ・スリー)は2023年9月21日にOpenAI社が公開したばかりの最新の画像生成AIで、テキストプロンプトからその内容を反映した画像を生成するDALLシリーズの最新版です。'
          }
        />
        <PageDescription
          text={'このページはDALL-E3を使用するサンプルページです。 画像の内容を書いて生成ボタンを押してくさださい。'}
          sx={{ marginBottom: 1 }}
        />
        <Paper
          component="form"
          sx={{
            marginBottom: '10px',
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
          }}>
          <InputBase
            id="userMessageInput"
            sx={{
              ml: 1,
              flex: 1,
              minHeight: '38px',
            }}
            value={pm.text.value}
            onChange={(e) => pm.text.setter(e.target.value)}
            placeholder={'ここに質問を入力してください。'}
            multiline
            minRows={5}
            maxRows={5}
          />
        </Paper>
        <Grid item>
          <LoadingButton
            onClick={pm.onClickedSend}
            type="submit"
            variant="contained"
            fullWidth
            loading={pm.loading}
            disabled={false}
            children={'送信'}
          />
        </Grid>
        <Paper sx={{ mt: 2, mb: 1 }}>
          <Box role="presentation" flexDirection="row" justifyContent="flex-end" display="flex" sx={{ paddingRight: '7px', paddingTop: '10px' }}>
            <Tooltip
              arrow
              open={pm.tooltipOpen}
              onClose={pm.handleCloseTip}
              disableHoverListener
              placement="top"
              title={'イメージをダウンロードします。'}>
              <Typography component="span">
                <Button size="small" color="inherit" variant="text" onClick={pm.handleDownloadClick} startIcon={<SimCardDownloadIcon />}>
                  ダウンロード
                </Button>
              </Typography>
            </Tooltip>
          </Box>
          <Box sx={{ paddingRight: 2, paddingLeft: 2, paddingBottom: 2, minHeight: 300, whiteSpace: 'pre-wrap' }}>
            <img src={pm.imgBase64} style={{ width: '100%' }}></img>
          </Box>
        </Paper>
      </Container>
    </Page>
  );
};

export default DallE3Page;
