import { experimentalAPI } from 'src/apis/experimentalAPI';

export const useDallE3 = () => {
  const api = experimentalAPI;

  const call = async (prompt: string): Promise<string> => {
    return await api.dallE3({ prompt });
  };

  return {
    call,
  };
};
