//
// 文字起こし用の言語型
// server/src/transcribe/transcribe.types.tsにある
// TranscribeLanguageと一致するcodeを持つ必要がある
//

// Interface
export interface TranscribeLanguage {
  code: string;
  fullname: string;
}

// Auto
export const langAuto: TranscribeLanguage = {
  code: '',
  fullname: 'Auto',
};

// Japan
export const langJapan: TranscribeLanguage = {
  code: 'jp',
  fullname: 'Japanese',
};

// English (US)
export const langEnglish: TranscribeLanguage = {
  code: 'en',
  fullname: 'English (US)',
};
