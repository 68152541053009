import React, { useCallback } from 'react';
import { mainChatAPI } from 'src/apis/mainChatAPI';
import { apiGateway, prefixApiGateway } from 'src/config';
import { imageOriginAddress, imageStart } from 'src/constants';
import { OriginImageList } from 'src/contexts/ChatMessageReducer';

type Props = {
  isMobile: boolean;
  images: string[];
  originImg?: OriginImageList[];
  downloadImg?: (value?: string) => void;
};

export const useCarousel = ({ isMobile, images, originImg, downloadImg }: Props) => {
  const [sliderIndex, setSliderIndex] = React.useState(0);
  const [openLightbox, setOpenLightbox] = React.useState(false);

  const [selectedImage, setSelectedImage] = React.useState<number>(0);
  const [urls, setUrls] = React.useState<string[]>(images);

  const handleOpenLightbox = React.useCallback(
    (url: string) => {
      const selectedImage = images.indexOf(url);
      if (selectedImage === sliderIndex) {
        setOpenLightbox(true);
        setSelectedImage(selectedImage);
      }
    },
    [images, sliderIndex],
  );

  const getDownlaodSrc = (src: string, originImg?: OriginImageList[]) => {
    if (originImg !== undefined && originImg.length !== 0) {
      const findItem = originImg.find((item) => item.in === src);
      if (findItem !== undefined) return findItem.origin;
    } else if (src.startsWith(apiGateway + prefixApiGateway)) {
      return src.replace(imageStart, imageOriginAddress);
    }
    return src;
  };

  React.useEffect(() => {
    const fetchImages = async () => {
      const imagePromises = images.map((img) => {
        const api = getDownlaodSrc(img, originImg);
        if (img.includes(apiGateway)) return mainChatAPI.getImage(api);
        else return api;
      });
      const imageUrls = await Promise.all(imagePromises);
      setUrls(imageUrls);
    };
    if (openLightbox) fetchImages();
  }, [openLightbox]);

  const msgBox = isMobile ? 3 : 5;
  const chatBox = isMobile ? 3 : 4;
  const THUMB_SIZE_MSG = 80;
  const THUMB_SIZE_CHAT = isMobile ? 80 : 124;

  const settings = {
    dots: true,
    infinite: images.length > 1,
    speed: 500,
    // slidesToShow: length < show ? length : show,
    slidesToScroll: 1,
    initialSlide: 0,
    focusOnSelect: true,
    beforeChange: (_: number, next: number) => setSliderIndex(next),
  };

  const download = useCallback(() => {
    if (downloadImg) downloadImg(getDownlaodSrc(images[selectedImage], originImg));
  }, [urls, selectedImage]);

  return {
    sliderIndex,
    urls,
    show: {
      msg: msgBox,
      chat: chatBox,
    },
    settings,
    sliderToShow: {
      msg: images.length < msgBox ? images.length : msgBox,
      chat: images.length < chatBox ? images.length : chatBox,
    },
    thumSize: {
      msg: THUMB_SIZE_MSG,
      chat: THUMB_SIZE_CHAT,
    },
    handleOpenLightbox,
    selectedImage,
    openLightbox,
    setSelectedImage,
    setOpenLightbox,
    download,
  };
};
