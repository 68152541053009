import styled from '@emotion/styled';

import { CodeComponent } from 'react-markdown/lib/ast-to-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DoneIcon from '@mui/icons-material/Done';
import { Grid } from '@mui/material';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import { setCopiedText } from 'src/redux/slices/clipboardSlice';
/**
 * コードブロックのシンタックスハイライトなどを行う
 * @param options a
 * @returns a
 */
export const CodeBlock: CodeComponent = (options) => {
  const { inline, className, children, node } = options;

  console.log(options);
  if (inline) {
    return <code className={className}>{children}</code>;
  }
  const match = /language-(\w+)/.exec(className || '');
  let lang = match?.[1];

  if (!lang) {
    // 言語が確定しない場合は、tsxとして扱う
    lang = 'tsx';
  }
  if (!lang && node.tagName !== 'code') {
    return <code className={className}>{children}</code>;
  }

  const copiedText = useSelector((state: RootState) => state.clipState).copiedText;

  const onClickHandler = async () => {
    try {
      await navigator.clipboard.writeText(String(children[0]));
      dispatch(setCopiedText(String(children[0])));
    } catch (error) {
      console.log(error);
    }
  };

  // FIXME: ソースコード出力する時にハイライトがうまく効いていないのでなんとかしたい・・・
  return (
    <CodeBlockWrapper>
      <Grid sx={{ borderRadius: '6px 6px 0 0', background: '#343541' }} container alignItems="center" justifyContent={'space-between'}>
        <Grid sx={{ color: 'white' }} item xs={2} m={1}>
          {lang}
        </Grid>
        <Grid container item xs={3} sx={{ cursor: 'pointer' }} onClick={onClickHandler} alignItems={'center'} m={1} justifyContent={'right'}>
          {copiedText === children[0] ? (
            <DoneIcon sx={{ color: 'white' }} fontSize="small" />
          ) : (
            <ContentPasteIcon sx={{ color: 'white' }} fontSize="small" />
          )}
          <Grid sx={{ color: 'white', marginLeft: 0.5 }} ml={2} fontSize="small">
            {copiedText === children[0] ? 'Copied!' : 'Copy Code'}
          </Grid>
        </Grid>
      </Grid>
      <SyntaxHighlighter style={vscDarkPlus} language={lang}>
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    </CodeBlockWrapper>
  );
};

const CodeBlockWrapper = styled.div`
  > pre {
    border-radius: 0 0 6px 6px;
    margin-top: 0 !important;
  }
`;
