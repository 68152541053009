import { Box, Container, InputBase, Paper, Grid, Typography, Button } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { useAssistantsApiPageModel } from './useAssistantsApiPageModel';
import { LoadingButton } from '@mui/lab';
import Chat from '@mui/icons-material/Chat';
import { PageTitle } from 'src/components/PageTitle';
import { PageDescription } from 'src/components/PageDescription';
import { FileInputs } from 'src/components-mars/fileInput/FileInputs';
import { MeatballsMenu } from 'src/components/MeatballsMenu';
import useSettings from 'src/hooks/useSettings';

export const AssistantsApi = (): React.ReactElement => {
  const pm = useAssistantsApiPageModel();
  const { themeMode } = useSettings();

  return (
    <Page title="Assistant API">
      <Container maxWidth={'md'}>
        <Box display="flex" justifyContent="space-between">
          <PageTitle text={'Assistants API サンプル'} />
          <MeatballsMenu
            sx={{ display: 'grid', placeItems: 'center' }}
            themeMode={themeMode}
            onClick={(event) => {
              pm.setAnchorEl(event.currentTarget);
            }}
            onClose={() => {
              pm.setAnchorEl(null);
            }}
            itemList={[{ message: 'スレッド削除', onClick: pm.clearThreadHandler, icon: 'open', sx: { paddingLeft: '5px' } }]}
            anchorElement={pm.anchorEl}
          />
        </Box>
        <PageDescription
          text={'GChatのAssistants APIを使うサンプルページです。添付するファイルの内容に関連した質問を投げてください。'}
          sx={{ marginBottom: 1 }}
        />
        <Paper
          component="form"
          sx={{
            marginBottom: '10px',
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
          }}>
          <InputBase
            id="userMessageInput"
            sx={{
              ml: 1,
              flex: 1,
              minHeight: '38px',
            }}
            value={pm.request}
            onChange={pm.requestOnChange}
            placeholder={'ここに質問を入力してください。'}
            multiline
            minRows={5}
            maxRows={5}
          />
        </Paper>
        <Paper
          component="form"
          sx={{
            marginY: '15px',
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative', // Make this a positioning context for absolute positioning
            boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
          }}>
          <FileInputs setField={pm.fileChange} files={pm.files} />
        </Paper>
        <Grid container xs={12} direction="row" justifyContent="flex-end" spacing={1}>
          <Grid item xs={4}>
            <LoadingButton
              onClick={pm.AssistantsApiTestHandlerV2}
              startIcon={<Chat />}
              type="submit"
              variant="contained"
              fullWidth
              loading={pm.isLoading}
              disabled={pm.disabled}>
              {'質問 Assistant v2'}
            </LoadingButton>
          </Grid>
          <Grid item xs={4}>
            <LoadingButton
              onClick={pm.AssistantsApiTestHandler}
              startIcon={<Chat />}
              type="submit"
              variant="contained"
              fullWidth
              loading={pm.isLoading}
              disabled={pm.disabled}>
              {'質問(CodeInterpreter)'}
            </LoadingButton>
          </Grid>
        </Grid>
        {Boolean(pm.latestRes) && (
          <Box sx={{ mt: 1, padding: 2, height: 'auto', whiteSpace: 'pre-wrap', borderRadius: '8px' }}>
            <Typography component="span">{pm.latestRes}</Typography>
          </Box>
        )}
        {pm.responses.map((response, index) => {
          if (Boolean(pm.latestRes) && index === 0) return;
          return (
            <Box sx={{ mt: 1, padding: 2, height: 'auto', whiteSpace: 'pre-wrap', borderRadius: '8px' }}>
              <Typography component="span">{response.message}</Typography>
              <br />
              {response.downloadUrls.map((url, index) => (
                <Button onClick={() => pm.fileDownloadHandler(url)}>{`Download File. (${response!.fileIds[index]})`}</Button>
              ))}
            </Box>
          );
        })}
      </Container>
    </Page>
  );
};
