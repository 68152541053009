import { FormControl, InputBasePropsSizeOverrides, InputLabel, MenuItem, Select as MuiSelect, SelectChangeEvent } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

export interface StyledSelectListProps<T extends string | number> {
  label: string;
  value: T | undefined;
  options: T[];
  size?: OverridableStringUnion<'small' | 'medium', InputBasePropsSizeOverrides>;
  disabled?: boolean;
  onChange: (e: SelectChangeEvent<T>) => void;
  formatter?: (value: T) => string;
  minWidth?: number | string;
}

export const StyledSelectList = <T extends string | number>(props: StyledSelectListProps<T>) => {
  const { label, value, options, size, disabled, minWidth } = props;
  const { onChange, formatter } = props;
  return (
    <FormControl>
      <InputLabel shrink>{label}</InputLabel>
      <MuiSelect label={label} value={value} disabled={disabled} size={size} onChange={onChange} notched sx={{ minWidth: minWidth || 150 }}>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {formatter ? formatter(option) : option}
            {/* 10分 */}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
