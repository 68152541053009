import { FFmpegExceptionCode, TranscribeApiErrorCode } from 'src/apis/transcribeAPI';
import { TFunction } from 'react-i18next';
import { GptApiErrorCode } from 'src/apis/gptAPI';
import { chatEncoding } from 'src/models/chat-gpt';

/**
 * APIエラーの文言を管理するためのHooks
 * @param t i18nのtranslation関数
 * @returns プロパティ
 */
export const useApiError = (t: TFunction<'translation', undefined>) => {
  /**
   * エラーメッセージの取得
   * @param error エラーオブジェクト
   * @param encoding chat encoding
   * @returns エラー文字列
   */
  const getErrorMessage = (error: any, encoding?: chatEncoding): string => {
    // エラーオブジェクトのエラーコードを取得
    // 各種エラーオブジェクトによってプロパティが違うため、全てのプロパティを確認し、優先度の高いエラーコードのメッセージを返す
    let code: number | string | null = null;
    if (!code) code = error.response?.data?.code;
    if (!code) code = error.statusCode;
    if (!code) code = error.code;
    if (!code && error.error) code = error.error.status;
    console.log(`ErrorCode: ${code}`);

    // APIエラー系
    // prettier-ignore
    switch(code) {
      case GptApiErrorCode.MODEL_ERROR: return t('common.message.error.modelError');
      case GptApiErrorCode.TOKEN_SIZE_LIMIT_EXCEEDED: return encoding ?  t('minutes.message.error.tokenSizeLimitExceeded', { additional: t(`minutes.message.error.additional.${encoding}`)}) :  t('minutes.message.error.tokenSizeLimitExceeded')
      case GptApiErrorCode.CONTENT_FILTERING_ERROR: return t('common.message.error.contentFilter');
      case TranscribeApiErrorCode.APIKEY_NOTFOUND_ERROR: return t('minutes.message.error.transcriptionFailed');
      case TranscribeApiErrorCode.UNDEFINED_ERROR: return t('minutes.message.error.transcriptionFailed');
      case TranscribeApiErrorCode.UNSUPPORTED_TRANSCRIBE_SERVICE: return t('minutes.message.error.unsupportedTranscribeService');
      case TranscribeApiErrorCode.CONTENT_TOO_LARGE_ERROR: return t('minutes.message.error.fileSizeLimitExceeded');
      case TranscribeApiErrorCode.TOO_MANY_REQUEST_ERROR: return t('minutes.message.error.exceededConcurrentRequests');
      case TranscribeApiErrorCode.ATTEMPTS_EXCEEDED_ERROR: return t('minutes.message.error.exceededNumberOfAttempts');
      case TranscribeApiErrorCode.UNSUPPORTED_INPUT_LAUNGUAGE_ERROR: return t('minutes.message.error.unsupportedInputLanguage');
      case FFmpegExceptionCode.EXECUTE_FAILD: return t('minutes.message.error.excuteFail')
      case FFmpegExceptionCode.INVALID_AUDIO_LENGTH: return t('minutes.message.error.invalidAduioLength')
      case FFmpegExceptionCode.INVALID_ARGUMENT: return t('minutes.message.error.invalidArgument')
    }

    // 共通エラー系
    // prettier-ignore
    switch(code) {
      case 408: return t('common.message.error.timeout') 
      // 413: Content Too Lage
      case 413: return t('common.message.error.contentTooLarge')
    }

    // 未定義のエラー時の処理
    return `${t('common.message.error.undefinedError')} : ${error.message ? error.message : error.error ? error.error.message : undefined}`;
  };

  return {
    getErrorMessage,
  };
};
