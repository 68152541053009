import { createSlice } from '@reduxjs/toolkit';

export interface MinutesPageState {
  transcribedText: string;
  minutesText: string;
}

export const initialState: MinutesPageState = {
  transcribedText: '',
  minutesText: '',
};

const slice = createSlice({
  name: 'MinutesPageState',
  initialState,
  reducers: {
    setTranscribedText(state: MinutesPageState, action): void {
      state.transcribedText = action.payload;
    },
    setMinutesText(state: MinutesPageState, action): void {
      state.minutesText = action.payload;
    },
    addMinutesText(state: MinutesPageState, action): void {
      state.minutesText += action.payload;
    },
    clearMinutesText(state: MinutesPageState): void {
      state.minutesText = '';
    },
  },
});

export default slice;
export const { reducer, actions } = slice;
