import { useCallback, useEffect, useState } from 'react';
import { s3URL } from 'src/config';
import { CustomMenuItemProps } from 'src/components/CustomMenuItem';
import { useTranslation } from 'react-i18next';
import { documentAPI } from 'src/apis/docmentAPI';
import { useSnackbar } from './useSnackbar';

export type updateProcess = 's3' | 'cognitive' | 'delete' | 'task' | 'check' | 'end';

export const useDocumentMenu = () => {
  const UPLOAD_ERR_MSG_LIST = ['sizeOver', '429', '255', 'docLoad', 'excel', 'encode', 'sizeMaximum', 'countOver'];
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(() => t('common.menu.process.default'));
  const [process, setProcess] = useState<string>(() => '');
  const snackbar = useSnackbar();

  const updateProcessMessage = useCallback((props: { msg?: updateProcess; prs?: number }) => {
    const { msg, prs } = props;
    if (msg) setMessage(() => t(`common.menu.process.${msg}`));
    if (msg === 'end') setProcess(() => '');
    if (prs) setProcess(() => prs + '%');
  }, []);

  const handleUpdateIndexClick = useCallback(async (): Promise<void> => {
    try {
      await documentAPI.updateIndex(updateProcessMessage);
      snackbar.success(t('document-upload.message.success.indexing'));
    } catch (err: any) {
      let errCode = 'default';
      let message = 'undefined';
      if (err instanceof Error) {
        if (err.message.includes('FileError')) {
          errCode = 'indexing';
          message = `「${err.message.replace('FileError/', '')}」`; // file name
        } else message = err.message;
      }
      snackbar.error(t(`document-upload.message.error.${errCode}`, { err: message }));
    }
  }, []);
  const handleDocumentLinkClick = useCallback(() => {
    window.open(s3URL, '_blank', 'noopener');
  }, []);

  const menuClickHanlder = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const menuCloseHanlder = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const menuListAction1st = useCallback(() => {
    handleDocumentLinkClick();
    setAnchorEl(null);
  }, []);
  const menuListAction2nd = useCallback(async () => {
    setAnchorEl(null);
    setIsLoading(true);
    await handleUpdateIndexClick();
    setIsLoading(false);
  }, []);
  const itemList: CustomMenuItemProps[] = [
    { message: t('common.menu.documentLink'), onClick: menuListAction1st, icon: 'open', sx: { paddingLeft: '5px' } },
    {
      message: t('common.menu.updateIndex'),
      onClick: menuListAction2nd,
      icon: 'recycle',
      sx: { paddingLeft: '5px' },
    },
  ];

  useEffect(() => {
    if (!isLoading) {
      setMessage(() => t('common.menu.process.default'));
      setProcess(() => '');
    }
  }, [isLoading]);
  return {
    menuClickHanlder,
    menuCloseHanlder,
    itemList,
    anchorEl,
    isLoading,
    message,
    process,
    UPLOAD_ERR_MSG_LIST,
  };
};
