import React from 'react';
import { Button, Container, InputBase, Paper, Typography, Box, Grid } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { Auth } from 'aws-amplify';
import { pythonGateway, prefixPythonGateway } from 'src/config';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Chat from '@mui/icons-material/Chat';
import { Tooltip } from '@mui/material';
import snackbar from 'src/components-mars/Snackbar';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { clearResponse, clearSql, addResponse, addSql } from '../../../../redux/slices/dataSearchSlice';
import { StyledAutocomplite } from 'src/components/StyledAutoComplite';

type Ext1Type = {
  request: string;
};
type dbSizeType = {
  id: 'default' | 'big';
  displayJp: '基本' | '大きい';
  displayEn: 'Default' | 'Big';
};
const dbDefault: dbSizeType = { id: 'default', displayJp: '基本', displayEn: 'Default' };
const dbBig: dbSizeType = { id: 'big', displayJp: '大きい', displayEn: 'Big' };
// このコードはサンプルなので、コンポーネント分割なども適当にする。
// State管理もState/Propsの単純な形式で行う。実コードとなる時は	Reduxを使う。
export const SnowflakePage = () => {
  const [state, setState] = React.useState<Ext1Type>({ request: '' });
  const dataResponse = useSelector((state: RootState) => state.dataSearchState.response);
  const dataSql = useSelector((state: RootState) => state.dataSearchState.sql);
  const { onSnackbarClose } = snackbar();
  const { t, i18n } = useTranslation();

  // 送信状態を管理
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [dbSize, setDbSize] = React.useState<dbSizeType>(dbDefault);
  const dbList = [dbDefault, dbBig];

  const display = (value: dbSizeType) => {
    return i18n.language === 'ja' ? value.displayJp : value.displayEn;
  };

  const onChange = (value: dbSizeType | null) => {
    setDbSize(() => value || dbDefault);
  };

  /**
   * 初期データの取得
   */
  React.useEffect(() => {
    dispatch(setSideBarMenu());
    dispatch(clearResponse());
    dispatch(clearSql());
  }, []);

  const handleSubmitButtonClick = async () => {
    setIsSubmitting(true);
    dispatch(clearResponse()); // メッセージ表示エリアをクリア
    dispatch(clearSql());

    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    let reader: ReadableStreamDefaultReader<Uint8Array> | undefined;
    try {
      const completion = await fetch(pythonGateway + prefixPythonGateway + `/data/snowflake?text=${state.request}&type=${dbSize.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        method: 'GET',
      });

      reader = completion.body?.getReader();
      if (!reader) {
        setIsSubmitting(false);
        throw new Error(completion.statusText);
      }

      const decoder = new TextDecoder('utf-8');
      // この read で再起的にメッセージを待機して取得します
      const read = async (prevContent?: string | undefined): Promise<any> => {
        if (prevContent === undefined) prevContent = '';

        const { done, value } = await reader!.read();
        if (done) {
          // 再帰処理の終了
          return reader!.releaseLock();
        }

        // レスポンスは{data:""}{data:""}の形式で返却される
        const _content = decoder.decode(value, { stream: true });

        // {data:""}{data:""}を分割して、dataの部分だけを取得し画面に表示する
        const parsedContent = _content.split('}{');
        let currentContent = '';
        parsedContent.map((content, index) => {
          // contentの1文字目が「{」でなければ「{」を追加する
          if (content[0] !== '{') {
            content = '{' + content;
          }
          // contentの最後の文字が「}」でなければ「}」を追加する
          if (content[content.length - 1] !== '}') {
            content = content + '}';
          }
          const parsedContent = JSON.parse(content);
          if (parsedContent.error !== undefined) throw new Error(parsedContent.error);
          if (parsedContent.sql !== undefined) {
            dispatch(addSql(parsedContent.sql));
            return;
          }
          const data = parsedContent.data;
          currentContent += data;
          dispatch(addResponse(data));
        });
        return read(prevContent + currentContent);
      };
      // 読み出し処理を読み出し
      await read();
    } catch (e: any) {
      console.log(e);
      if (!(e instanceof DOMException && e.name === 'AbortError')) {
        onSnackbarClose({
          color: 'error',
          msg: `${t('common.message.error.sendGPTMessage')} ${e}`,
          isPersist: true,
        });
      }
      setIsSubmitting(false);
    }
    // ReadableStream を最後は解放する
    if (reader) reader.releaseLock();
    setIsSubmitting(false);
  };

  // クリップボードにコピー
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.log(error || i18n.t('common.message.error.copyFailurelog'));
    }
  };

  const [openTip, setOpenTip] = React.useState<boolean>(false);

  const handleCloseTip = (): void => {
    setOpenTip(false);
  };

  const handleClickButton = (): void => {
    setOpenTip(true);
    copyToClipboard(dataResponse);
    // 3秒後に閉じる
    setTimeout(() => {
      setOpenTip(false);
    }, 3000);
  };

  return (
    <Page title="DocSearch">
      <Container maxWidth={false}>
        <h2>DBデータ検索デモ(Snowflake)</h2>
        <Typography>
          これは、SnowflakeをベースにChatGPTに回答してもらうDemoです。
          <br />
          UIについてはDemo版のため、簡易的な作りにしてあります。（最終版はもう少し検討が必要）
        </Typography>
        {/* <a target="_blank" href="xx">リンクを押したらDivが開く</a> */}
        <Paper
          component="form"
          sx={{
            marginY: '15px',
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative', // Make this a positioning context for absolute positioning
            boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
          }}>
          <InputBase
            id="userMessageInput"
            sx={{
              ml: 1,
              flex: 1,
              minHeight: '38px',
            }}
            value={state.request}
            onChange={(e) => setState({ ...state, request: e.target.value })}
            placeholder={i18n.t('common.text.questionBox')}
            multiline
            minRows={5}
            maxRows={5}
          />
        </Paper>
        <Grid container item xs={12} justifyContent={'right'} spacing={1}>
          <Grid item xs={2}>
            <StyledAutocomplite label={'DBサイズ'} size="small" fullWidth value={dbSize} options={dbList} display={display} onChange={onChange} />
          </Grid>
          <Grid item xs={1}>
            <LoadingButton onClick={handleSubmitButtonClick} startIcon={<Chat />} type="submit" variant="contained" loading={isSubmitting}>
              {i18n.t('common.button.question')}
            </LoadingButton>
          </Grid>
        </Grid>
        <Paper sx={{ mt: 2, mb: 1 }}>
          <Box role="presentation" sx={{ position: 'absolute', right: 50 }}>
            <Tooltip arrow open={openTip} onClose={handleCloseTip} disableHoverListener placement="top" title={i18n.t('common.text.Copied')}>
              <Button size="medium" color="inherit" variant="text" onClick={() => handleClickButton()} startIcon={<ContentCopyIcon />}>
                {i18n.t('common.button.copy')}
              </Button>
            </Tooltip>
          </Box>
          <Box sx={{ paddingTop: 5, paddingRight: 2, paddingLeft: 2, paddingBottom: 2, minHeight: 300, whiteSpace: 'pre-wrap' }}>
            {dataResponse}
            {dataSql ? (
              <>
                <br />
                {dataSql}
              </>
            ) : (
              ''
            )}
          </Box>
        </Paper>
        <Grid container item xs="auto">
          <Box role="presentation" sx={{ position: 'fixed', right: 50 }}></Box>
        </Grid>
      </Container>
    </Page>
  );
};
