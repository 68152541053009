import { Grid, LinearProgress, Stack, Tooltip, Box, Button, Typography, PopoverOrigin } from '@mui/material';
import { cssStyles } from 'src/utils/cssStyles';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'src/@types/settings';
import { CustomMenuItemProps } from './CustomMenuItem';
import { MeatballsMenu } from './MeatballsMenu';
import { MessageBox } from './MessageBox';
import { RecordingStatus } from 'src/contexts/type/ChatMessageContextType';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

interface InputMessageFormProps {
  isChat: boolean;
  position: 'fixed' | 'static';
  maxTokenLength: number;
  progress: number;
  tokenLength: number;
  messageToken: number;
  isLoading: boolean;
  inputElement: React.RefObject<HTMLInputElement>;
  recordingStatus: RecordingStatus;
  themeMode: ThemeMode;
  anchorElement: null | HTMLElement;
  itemList: CustomMenuItemProps[];
  newButtonLabel: string;
  placeholder: string;
  messageInputBoxValue: string;
  menuOnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  menuOnClose: () => void;
  newBtnOnClick: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  // onCompositionStart: () => void;
  // onCompositionEnd: () => void;
  onSubmit: (e: any) => void;
  onRecordingStartButtonClick: (e: any) => void;
  onRecordingStopButtonClick: (e: any) => void;
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
  isWebSearch: boolean;
  chatModel: string;
  plugin?: CustomMenuItemProps;
  chatMain?: boolean;
  handlePaste: React.ClipboardEventHandler<HTMLDivElement>;
  images: string[];
  imgClear: (item: string) => void;
  inputFile: React.ChangeEventHandler;
  newBtnTooltip?: string;
  isMobile: boolean;
  handleSwitchWebSearch: (value: boolean) => void;
}

export const InputMessage = React.memo(
  ({
    isChat,
    position,
    maxTokenLength,
    progress,
    tokenLength,
    messageToken,
    themeMode,
    anchorElement,
    itemList,
    newButtonLabel,
    placeholder,
    messageInputBoxValue,
    isLoading,
    inputElement,
    recordingStatus,
    menuOnClick,
    menuOnClose,
    newBtnOnClick,
    onChange,
    // onKeyDown,
    // onCompositionStart,
    // onCompositionEnd,
    onSubmit,
    onRecordingStartButtonClick,
    onRecordingStopButtonClick,
    anchorOrigin,
    transformOrigin,
    isWebSearch,
    chatModel,
    plugin,
    chatMain,
    handlePaste,
    images,
    imgClear,
    inputFile,
    newBtnTooltip = '',
    isMobile,
    handleSwitchWebSearch,
  }: InputMessageFormProps): React.ReactElement => {
    const theme = useTheme();
    return (
      <Stack
        sx={{
          // 上のメニューと同じスタイルを適用
          ...cssStyles(theme).bgBlur({ blur: 0.5, opacity: 0.8 }),
          alignItems: 'center',
          width: '100%',
          position: position,
          bottom: position === 'fixed' ? 20 : 0,
          paddingBottom: 1,
          transition: 'bottom 0.8s ease',
        }}>
        <Box sx={{ width: '100%', maxWidth: '800px', textAlign: 'center', paddingX: '10px', display: 'flex', flexDirection: 'column' }}>
          <Stack
            sx={{ width: '100%' }}
            alignItems={'center'}
            direction={'row'}
            justifyContent={messageInputBoxValue.length === 0 ? 'space-between' : 'right'}>
            {messageInputBoxValue.length === 0 && (
              <Grid container height={'34px'} alignItems={'center'}>
                {isWebSearch && <TravelExploreIcon sx={{ padding: '5px', width: '34px', height: '34px' }} color={'disabled'} />}
                <Typography color={theme.palette.text.disabled} variant="subtitle2" pl={1}>
                  {chatModel}
                </Typography>
              </Grid>
            )}
            <MeatballsMenu
              themeMode={themeMode}
              onClick={menuOnClick}
              onClose={menuOnClose}
              itemList={itemList}
              anchorElement={anchorElement}
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
              sx={{ paddingBottom: '2px' }}
              plugin={plugin}
              chatMain={chatMain}
            />
          </Stack>
          <MessageBox
            placeholder={placeholder}
            minRows={position === 'fixed' ? 1 : 4}
            isLoading={isLoading}
            messageInputBoxValue={messageInputBoxValue}
            onChange={onChange}
            // onKeyDown={onKeyDown}
            // onCompositionStart={onCompositionStart}
            // onCompositionEnd={onCompositionEnd}
            onSubmit={onSubmit}
            onRecordingStartButtonClick={onRecordingStartButtonClick}
            onRecordingStopButtonClick={onRecordingStopButtonClick}
            inputElement={inputElement}
            recordingStatus={recordingStatus}
            handlePaste={handlePaste}
            images={images}
            imgClear={imgClear}
            inputFile={inputFile}
            isMobile={isMobile}
            isWebSearch={isWebSearch}
            handleSwitchWebSearch={handleSwitchWebSearch}
            plugin={plugin}
          />
          <Tooltip title={`現在のトークンの使用量: ${tokenLength + messageToken} (最大 ${maxTokenLength}トークン)`} arrow>
            <LinearProgress id="TokenUsageSize" sx={{ padding: '2px', marginX: '5px' }} variant="determinate" value={progress} />
          </Tooltip>
          {isChat && (
            <Grid container alignItems="center" justifyContent="center" direction="column">
              <Grid item xs={12}>
                <Stack sx={{ minWidth: '150px', width: '100%', marginLeft: 'auto', paddingTop: '10px' }}>
                  <Tooltip arrow placement="top" title={newBtnTooltip}>
                    <Button onClick={newBtnOnClick} type="submit" variant="contained" size="small">
                      <Typography variant="button">{newButtonLabel}</Typography>
                    </Button>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>
      </Stack>
    );
  },
);
