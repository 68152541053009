import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { useTranslation } from 'react-i18next';
import { termsofserviceAPI } from 'src/apis/termsofserviceAPI';
import { marked } from 'marked';

export const useTermsPageModel = () => {
  // HOOKS
  // ======================================================================
  const location = useLocation();
  const locale = useTranslation();

  // STATES
  // ======================================================================
  const [path, setPath] = useState('');
  const [contents, setContents] = useState('');
  const [createdAt, setCreatedAt] = useState('');

  // EVENT
  // ======================================================================
  const getContents = useCallback(async (path: string) => {
    const value = await termsofserviceAPI.getContents(path);
    setContents(value.content);
    setCreatedAt(value.createdAt);
  }, []);

  // SIDE EFFECT
  // ======================================================================
  useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  useEffect(() => {
    const pathSplited = location.pathname.split('/');
    const pathValue = pathSplited[pathSplited.length - 1];
    setPath(pathValue);
    getContents(pathValue);
  }, [location.pathname, locale.i18n.language]);

  const htmlText = marked.parse(contents);

  return {
    // hooks
    locale,
    // states
    path,
    htmlText,
    createdAt,
  };
};
