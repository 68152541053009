/**
 *
 * @param  root ルート
 * @param  sublink サブリンク
 * @return パス
 */
const path = (root: string, sublink: string) => {
  return `${root}${sublink}`;
};

const ROOTS_AUTH = '/auth';
const ROOTS_OVERVIEW = '/overview';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CHAT = '/chat';
const ROOTS_DOC_SEARCH = '/doc-search';
const ROOTS_PROMPT = '/prompt';
const ROOTS_TERMS = '/terms';
const ROOTS_HOME = '/home';
const ROOTS_OTHERS = '/others';
const ROOTS_MINUTES = '/minutes';

export const ROOTS_RELEASE_NOTES = '/release-notes';
export interface RelaseNote {
  ver?: string;
  path?: string;
  info?: string;
}

// ----------------------------------------------------------------------
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  account: path(ROOTS_DASHBOARD, '/account'),
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  components: '/components',
};

// ChatGPTページ
export const PATH_CHAT = {
  root: ROOTS_CHAT,
  main: path(ROOTS_CHAT, `/main`),
  newPage: path(ROOTS_CHAT, `/main/newpage`),
};

export const PATH_OVERVIEW = {
  root: ROOTS_OVERVIEW,
  links: path(ROOTS_OVERVIEW, '/links'),
};

export const RELEASE_NOTES_V1_X = [
  { path: ROOTS_RELEASE_NOTES + '/v2.3.1', info: 'リリースノート v2.3.1', infoEn: 'Release Note v2.3.1' },
  { path: ROOTS_RELEASE_NOTES + '/v2.3.0', info: 'リリースノート v2.3.0', infoEn: 'Release Note v2.3.0' },
  { path: ROOTS_RELEASE_NOTES + '/v2.2.3', info: 'リリースノート v2.2.3', infoEn: 'Release Note v2.2.3' },
  { path: ROOTS_RELEASE_NOTES + '/v2.2.2', info: 'リリースノート v2.2.2', infoEn: 'Release Note v2.2.2' },
  { path: ROOTS_RELEASE_NOTES + '/v2.2.1', info: 'リリースノート v2.2.1', infoEn: 'Release Note v2.2.1' },
  { path: ROOTS_RELEASE_NOTES + '/v2.2.0', info: 'リリースノート V2.2.0', infoEn: 'Release Note V2.2.0' },
  { path: ROOTS_RELEASE_NOTES + '/v2.1.0', info: 'リリースノート V2.1.0', infoEn: 'Release Note V2.1.0' },
  { path: ROOTS_RELEASE_NOTES + '/v2.0.6', info: 'リリースノート V2.0.6', infoEn: 'Release Note V2.0.6' },
  { path: ROOTS_RELEASE_NOTES + '/v2.0.5', info: 'リリースノート V2.0.5', infoEn: 'Release Note V2.0.5' },
  { path: ROOTS_RELEASE_NOTES + '/v2.0.4', info: 'リリースノート V2.0.4', infoEn: 'Release Note V2.0.4' },
  { path: ROOTS_RELEASE_NOTES + '/v2.0.3', info: 'リリースノート V2.0.3', infoEn: 'Release Note V2.0.3' },
  { path: ROOTS_RELEASE_NOTES + '/v2.0.2', info: 'リリースノート V2.0.2', infoEn: 'Release Note V2.0.2' },
  { path: ROOTS_RELEASE_NOTES + '/v2.0.1', info: 'リリースノート V2.0.1', infoEn: 'Release Note V2.0.1' },
  { path: ROOTS_RELEASE_NOTES + '/v2.0.0', info: 'リリースノート V2.0.0', infoEn: 'Release Note V2.0.0' },
];

export const PATH_DOC_SEARCH = {
  root: ROOTS_DOC_SEARCH,
  search: path(ROOTS_DOC_SEARCH, '/search'),
  local: path(ROOTS_DOC_SEARCH, '/local'),
  pinecone: path(ROOTS_DOC_SEARCH, '/pinecone'),
  collection: path(ROOTS_DOC_SEARCH, '/collection'),
  upload: path(ROOTS_DOC_SEARCH, '/upload'),
  // 個人
  personalSearch: path(ROOTS_DOC_SEARCH, '/personal/search'),
  personalCollection: path(ROOTS_DOC_SEARCH, '/personal/collection'),
  personalUpload: path(ROOTS_DOC_SEARCH, '/personal/upload'),
};

export const PATH_PROMPT = {
  root: ROOTS_PROMPT,
  page: path(ROOTS_PROMPT, '/page'),
};

export const PATH_TERMS = {
  root: ROOTS_TERMS,
  privacy: path(ROOTS_TERMS, '/privacy-policy'),
  gdpr: path(ROOTS_TERMS, '/gdpr'),
  ccpa: path(ROOTS_TERMS, '/ccpa'),
};

export const PATH_HOME = {
  root: ROOTS_HOME,
};

export const PATH_OTHERS = {
  root: ROOTS_OTHERS,
  snowflake: path(ROOTS_OTHERS, '/snowflake'),
  tts: path(ROOTS_OTHERS, '/text-to-speech'),
  vision: path(ROOTS_OTHERS, '/gpt-4-vision'),
  assistants: path(ROOTS_OTHERS, '/assistants-api'),
  translate: path(ROOTS_OTHERS, '/translate'),
  functionCalling: path(ROOTS_OTHERS, '/function-calling'),
  dallE3: path(ROOTS_OTHERS, '/dall-e3'),
  v2BetaCI: path(ROOTS_OTHERS, '/v2-beta-ci'),
};

export const PATH_MINUTES = {
  root: ROOTS_MINUTES,
  transcribing: path(ROOTS_MINUTES, '/transcribing'),
  minutes: path(ROOTS_MINUTES, '/minutes'),
};
