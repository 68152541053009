import { Box, Grid, useTheme } from '@mui/material';
import React from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

interface SpeakerIconProps {
  flag: boolean;
}
export const SpeakerIcon = React.memo(({ flag }: SpeakerIconProps) => {
  const theme = useTheme();
  return flag ? (
    <Grid item xs={12} sm={1}>
      {' '}
      <Box>
        <AccountCircleIcon
          sx={{
            borderRadius: '3px',
            fontSize: '30px',
            padding: '3px',
          }}></AccountCircleIcon>
      </Box>
    </Grid>
  ) : (
    <Grid item xs={12} sm={1}>
      {' '}
      <Box>
        <AutoFixHighIcon
          sx={{
            borderRadius: '3px',
            fontSize: '30px',
            padding: '3px',
            background: theme.palette.primary.dark,
            color: 'white',
          }}></AutoFixHighIcon>
      </Box>
    </Grid>
  );
});
