import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';

import React from 'react';
import { CodeBlock } from './CodeBlock';
import { TextField } from '@mui/material';
import { LinkBlock } from './LinkBlock';
// import { markdownImgLinkStart } from 'src/constants';
// import { ImageBlock } from './ImageBlock';
import { ChatCompletionContentPartImage } from 'openai/resources';
// import { OriginImageList } from 'src/contexts/ChatMessageReducer';

type Props = {
  children: { message: string; role: string };
  isEditing: boolean;
  onChange: (e: any) => void;
  defaultMsg: string | ChatCompletionContentPartImage;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  // downloadImg: (value?: string) => void;
  // originImg?: OriginImageList[];
};

export const MarkdownRenderer = ({ children, isEditing, onChange, defaultMsg, onKeyDown }: Props) => {
  // ユーザの入力値はコードブロックを利用しない
  // if (children.role === 'user' && !isEditing && Boolean(children.message) && !children.message.startsWith(markdownImgLinkStart)) {
  if (children.role === 'user' && !isEditing && Boolean(children.message)) {
    // マークダウンで始まるとパース
    return (
      <StyledMarkdownWrapper>
        <p>{defaultMsg}</p>
      </StyledMarkdownWrapper>
    );
  } else if (children.role === 'user' && isEditing) {
    return <TextField multiline fullWidth value={children.message} onChange={onChange} onKeyDown={onKeyDown} />;
  } else {
    return (
      <StyledMarkdownWrapper>
        <ReactMarkdown
          components={{
            a: LinkBlock,
            code: CodeBlock,
            // img: ({ src }) => <ImageBlock src={src} download={downloadImg} originImg={originImg} />, // イメージのサイズ調整のため必要
          }}
          linkTarget={'_blank'}>
          {children.message}
        </ReactMarkdown>
      </StyledMarkdownWrapper>
    );
  }
};

export const StyledMarkdownWrapper = styled('div')`
  > p:first-of-type {
    margin-top: 0;
  }
  > p:last-of-type {
    margin-bottom: 0;
  }
  p {
    line-height: 28px;
    white-space: pre-wrap;
    word-break: break-all;
  }
`;
