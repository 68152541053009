import { IconButton, Stack } from '@mui/material';
import { Grid } from '@mui/material';
import { IconComponent, IconType } from 'src/components/IconComponents';

export const btnCellRender = (clickHandler: (e: any) => void, icon: IconType) => {
  return (
    <Grid container xs={12} justifyContent={'center'}>
      <IconButton
        onClick={clickHandler}
        sx={{
          width: '40px',
          heigth: '40px',
        }}>
        <IconComponent icon={icon} />
      </IconButton>
    </Grid>
  );
};

export const dowbleBtnCellRender = (props: {
  firstHandler: (e: any) => void;
  secondHandler: (e: any) => void;
  firstIcon: IconType;
  secondIcon: IconType;
}) => {
  const { firstHandler, firstIcon, secondHandler, secondIcon } = props;
  return (
    <Stack direction={'row'} justifyContent={'center'}>
      <IconButton
        onClick={firstHandler}
        sx={{
          width: '40px',
          heigth: '40px',
        }}>
        <IconComponent icon={firstIcon} fontSize="small" />
      </IconButton>
      <IconButton
        onClick={secondHandler}
        sx={{
          width: '40px',
          heigth: '40px',
        }}>
        <IconComponent icon={secondIcon} fontSize="small" />
      </IconButton>
    </Stack>
  );
};
