import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export enum LANGS_CODE {
  JP = 'JP',
  EN = 'EN',
}

const LANGS = [
  {
    value: 'ja',
    label: 'Japanese',
    icon: '/static/icons/ic_flag_ja.svg',
    code: LANGS_CODE.JP,
  },
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg',
    code: LANGS_CODE.EN,
  },
];

/**
 *
 * @return 言語
 */
export const useLocales = () => {
  const { i18n, t: translate } = useTranslation();
  const currentLang = LANGS.find((_lang) => _lang.value === i18n.language) || LANGS[0];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
};
