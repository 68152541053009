import { pythonGateway, prefixPythonGateway } from 'src/config';
import axios from 'src/utils/axios';

export const visionAPI = {
  /**
   * Visionのサンプル
   * @param props プロパティオブション
   * @returns 答え
   */
  call: async (props: { file: FileList; request: string; interpret: string }): Promise<string> => {
    const formData = new FormData();
    formData.append(`file`, props.file[0]);
    formData.append('request', props.request);
    formData.append('interpret', props.interpret);
    return await axios.post(pythonGateway + prefixPythonGateway + '/vision/test', formData).then((res) => res.data);
  },
};
