import { Button, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { usePersonalDocumentsUploadPageModel } from './usePersonalDocumentsUploadPageModel';
import { AlertMessage } from 'src/components/AlertMessage';
import { BoxedGrid } from 'src/components-mars/ag-grid/BoxedGrid';
import { TransparentDropZone } from 'src/components/DropFiled';
import { FileContentModal } from 'src/components/FileContentsModal';
import { PageTitle } from 'src/components/PageTitle';
import { Loading } from 'src/features/minutes/components/backdrop/Loading';
import useSettings from 'src/hooks/useSettings';
import { ICellRendererParams } from 'ag-grid-community';
import { btnCellRender } from 'src/components-mars/ag-grid/renderer/BtnCellRenderer';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import { LANGS_CODE } from 'src/hooks/useLocales';
import { LoadingButton } from '@mui/lab';

export const PersonalDocumentsUploadPage = () => {
  const pm = usePersonalDocumentsUploadPageModel();
  const { themeMode } = useSettings();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page title={pm.locale.t('document-personal-upload.text.pageTitle')}>
      <Loading isLoading={pm.isLoading} />
      <Loading
        isLoading={pm.isUploadLoading}
        text={pm.locale.t('document-personal-upload.text.loadingMessage')}
        subText={pm.locale.t('document-personal-upload.text.loadingSubMessage')}
      />
      <Container maxWidth="md">
        <Grid container justifyContent="space-between">
          <PageTitle text={pm.locale.t('document-personal-upload.text.pageTitle')} />
        </Grid>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {pm.locale.t('document-personal-upload.text.pageExplanation')}
        </Typography>
        <Stack spacing={1}>
          <TransparentDropZone onAccepted={pm.onDropAccepted} onRejected={pm.onDropRejected} height={80} maxFiles={pm.maxFiles} disabled={false}>
            <Stack>
              <Typography variant="body2">{pm.locale.t('document-personal-upload.drop.message.dropZoneLabel')}</Typography>
              <Typography variant="caption">{pm.locale.t('document-personal-upload.drop.message.maxFiles', { maxFilesNum: pm.maxFiles })}</Typography>
            </Stack>
          </TransparentDropZone>
          {pm.alertShow && (
            <Stack sx={{ alignItems: 'center', paddingY: '5px' }}>
              <AlertMessage message={pm.locale.t('document-personal-upload.text.alertMessage')} color={themeMode === 'light' ? 'black' : 'white'} />
            </Stack>
          )}
          <BoxedGrid
            gridTheme={pm.agGridTheme}
            gridHeight={'50vh'}
            gridOptions={{}}
            defaultColDef={{ flex: 2, filter: false, resizable: true, suppressMenu: true }}
            columnDefs={[
              {
                headerName: pm.locale.t('document-personal-upload.ag-grid.column.fileName'),
                field: 'name',
              },
              {
                headerName: pm.locale.t('document-personal-upload.ag-grid.column.operation'),
                field: 'operation',
                maxWidth: 100,
                cellRenderer: (params: ICellRendererParams) => {
                  return btnCellRender(() => pm.getDocumentFileContents(params), 'pageView');
                },
              },
            ]}
            rowData={pm.fileList}
          />
          <Grid container justifyContent={'right'} xs={12}>
            <Grid item xs={isMobile ? 5 : 2} mr={2}>
              <Button fullWidth color={'inherit'} variant="contained" onClick={pm.clear} sx={{ color: themeMode === 'light' ? 'black' : '#86929d' }}>
                {pm.locale.t('common.button.clear')}
              </Button>
            </Grid>
            <Grid item xs={isMobile ? 5 : 2}>
              <LoadingButton fullWidth variant="contained" onClick={pm.uploadDocumentFiles} disabled={pm.uploadDisabled} loading={pm.isLoading}>
                {pm.locale.t('common.button.upload')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
        {/* Dialogs */}
        <FileContentModal
          isOpen={pm.contentDialog.isOpen}
          closeAction={pm.contentDialog.onOk}
          closeBtnLabel={pm.locale.t('common.button.close')}
          contents={pm.fileContent}
        />
        <ConfirmDialog
          lang={pm.locale.i18n.language === 'ja' ? LANGS_CODE.JP : LANGS_CODE.EN}
          isOpen={pm.confrimDialog.isOpen}
          onCanceled={pm.confrimDialog.onCancel}
          cancelColor="inherit"
          cancelVariant="contained"
          onOk={pm.confrimDialog.onOk}
          okVariant="contained">
          <Grid>
            <Typography variant={'body1'}>{pm.locale.t('document-personal-upload.dialog.contentDefault')}</Typography>
            {pm.duplicated.length > 0 && (
              <Grid>
                <Typography variant={'body1'}>{pm.locale.t('document-personal-upload.dialog.fileList')}</Typography>
                {pm.duplicated.map((item) => (
                  <Typography variant={'body2'}>{item}</Typography>
                ))}
              </Grid>
            )}
          </Grid>
        </ConfirmDialog>
      </Container>
    </Page>
  );
};

// 部署
// snackbar
// modal
