//
// AIに関する定義をまとめるためのファイル
//

/**
 * AIの種類を表す型
 */
export enum AiModel {
  CHAT_GPT = 'ChatGPT',
}

/**
 * AIの共通プロパティを表す型
 *
 * MEMO: 後発の型のため、既存の型に合わせたプロパティになっている
 * TODO: versionNumは混乱するため、将来的に消したい
 */
export interface Ai {
  readonly model: AiModel;
  readonly version: string;
  readonly versionNum: number;
}

export interface Vision {
  readonly id: 'auto' | 'low' | 'high';
  readonly displayEng: string;
  readonly displayJpn: string;
}
