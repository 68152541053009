import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
export type SourceType = { name: string; url: string; content: string }[] | [];
export interface DataSearchData {
  response: string;
  sql: string;
}

export const initialState: DataSearchData = {
  response: '',
  sql: '',
};

const dataSearchSlice = createSlice({
  name: 'DataSearchSlice',
  initialState,
  reducers: {
    /**
     * ドキュメント検索の結果を追加する
     * @param state state
     * @param actions action
     */
    addResponse(state: DataSearchData, actions): void {
      state.response = state.response + actions.payload;
    },
    /**
     * ドキュメント検索の結果をクリアする
     * @param state state
     */
    clearResponse(state: DataSearchData): void {
      state.response = '';
    },

    /**
     * 取得元sqlをクリアする
     * @param state state
     */
    clearSql(state: DataSearchData): void {
      state.sql = '';
    },

    /**
     * 取得元sqlを登録する
     * @param state state
     * @param actions action
     */
    addSql(state: DataSearchData, actions): void {
      state.sql = actions.payload;
    },
  },
});

/**
 * レスポンスをクリアする
 * @return void
 */
export const clearResponse =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(dataSearchSlice.actions.clearResponse());
  };

/**
 * レスポンスを追加する
 * @param response 追加するレスポンス
 * @return void
 */
export const addResponse =
  (response: string): AppThunk =>
  (dispatch): void => {
    dispatch(dataSearchSlice.actions.addResponse(response));
  };
/**
 * sqlを追加する
 * @param sql 追加するレスポンス
 * @return void
 */
export const addSql =
  (sql: string): AppThunk =>
  (dispatch): void => {
    dispatch(dataSearchSlice.actions.addSql(sql));
  };

/**
 * sqlをクリアする
 * @return void
 */
export const clearSql =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(dataSearchSlice.actions.clearSql());
  };

export default dataSearchSlice.reducer;
export const { reducer, actions } = dataSearchSlice;
