import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import { OptionChatType } from 'src/models/chat-gpt';
import { useTranslation } from 'react-i18next';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
type Props = {
  models: OptionChatType[];
  device: boolean; // mobile true
};
const VersionComparisonTable = React.memo(({ models, device }: Props): React.ReactElement => {
  const locale = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="product table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: '100px' }}>{locale.t('chat.table.version')}</TableCell>
            <TableCell sx={{ minWidth: '60px' }}>{locale.t('chat.table.precision')}</TableCell>
            <TableCell sx={{ minWidth: '60px' }}>{locale.t('chat.table.speed')}</TableCell>
            <TableCell sx={{ minWidth: '120px' }}>{locale.t('chat.table.size')}</TableCell>
            <TableCell sx={{ minWidth: '150px' }}>{locale.t('chat.table.study')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {models.map((product, index) => (
            <TableRow key={index}>
              <TableCell>{product.label}</TableCell>
              <TableCell>
                {product.versionNum === 4 ? (
                  <Grid container direction={'row'}>
                    <FiberManualRecordIcon fontSize="small" color="primary" />
                    <FiberManualRecordIcon fontSize="small" color="primary" />
                    <FiberManualRecordIcon fontSize="small" color="primary" />
                  </Grid>
                ) : (
                  <Grid container direction={'row'}>
                    <FiberManualRecordIcon fontSize="small" color="primary" />
                    <FiberManualRecordIcon fontSize="small" color="primary" />
                    <FiberManualRecordIcon fontSize="small" color="disabled" />
                  </Grid>
                )}
              </TableCell>
              <TableCell>
                {product.versionNum === 4 ? (
                  <Grid container direction={'row'}>
                    <FiberManualRecordIcon fontSize="small" color="primary" />
                    <FiberManualRecordIcon fontSize="small" color="primary" />
                    <FiberManualRecordIcon fontSize="small" color="disabled" />
                  </Grid>
                ) : (
                  <Grid container direction={'row'}>
                    <FiberManualRecordIcon fontSize="small" color="primary" />
                    <FiberManualRecordIcon fontSize="small" color="primary" />
                    <FiberManualRecordIcon fontSize="small" color="primary" />
                  </Grid>
                )}
              </TableCell>
              <TableCell>
                {product.token}
                {locale.t('chat.table.unit')}
              </TableCell>
              <TableCell>{product.versionNum === 4 ? locale.t('chat.table.study4') : locale.t('chat.table.study4')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default VersionComparisonTable;
