import { ReactNode } from 'react';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';

export interface DroppableProps {
  onDropAccepted: (files: File[]) => void;
  onDropRejected: (fileRejections: FileRejection[], event: DropEvent) => void;
  accept?: Accept;
  maxFiles?: number;
  children: ReactNode;
}

/**
 * ドロップゾーン
 * @param props パラメータ
 * @returns レンダリングコンポーネント
 */
export const Droppable = (props: DroppableProps) => {
  const { onDropAccepted, onDropRejected, accept, maxFiles, children } = props;
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    maxFiles: maxFiles ?? 1,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};
