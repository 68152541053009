import Mustache from 'mustache';
import { AuthUser } from 'src/@types/authentication';
import { minutesAPI } from 'src/apis/minutesAPI';
import { OptionChatType, chatGptV4oMini, chatGptV4o } from 'src/models/chat-gpt';
import { TranscribeLanguage } from 'src/models/transcribe-language';
import { TranscribeService, TranscribeServiceSet, azureWhisper } from 'src/models/transcribe-service';
import * as Language from 'src/models/language';

/**
 * 議事録機能を使うためのHooks
 *
 * ユーザーのロールによって利用可能なパラメータが変わる
 *
 * @param user ユーザー情報
 * @returns 公開プロパティ
 */
export const useMinutes = (user?: AuthUser) => {
  /**
   * 使用可能な文字起こし用のリスト
   */
  // prettier-ignore
  const transcribeServiceList: Readonly<TranscribeServiceSet>[] = [azureWhisper];

  /**
   * 使用可能な議事録要約用のGPTモデルのリスト
   */
  // prettier-ignore
  const summarizeServiceList: Readonly<OptionChatType>[] = [
    chatGptV4oMini,
    chatGptV4o,
  ];

  /**
   * 使用可能な音声ファイル分割時間のリスト (単位: 秒)
   *
   * Admin時には分割数が一般ユーザーより多くなる
   */
  const audioSplitLengthList: Readonly<number>[] = [1200];

  /**
   * 議事録作成機能の入力言語リスト
   */
  const inputLanguages: Language.Language[] = [
    Language.japanese,
    Language.english,
    Language.french,
    Language.spanish,
    Language.arabic,
    Language.chinese,
    Language.hindi,
    Language.indonesian,
    Language.portuguese,
    Language.german,
    Language.italian,
    Language.thai,
    Language.korean,
    Language.russian,
    Language.malay,
    Language.swahili,
    Language.czech,
    Language.polish,
    Language.turkish,
  ];

  /**
   * 議事録の文字起こし機能
   * @param file 文字起こしするオーディオファイル
   * @param service 文字起こしをするためのサービス
   * @param splitLength 音声分割時間
   * @param inputLang 入力音声の言語
   * @param onNotify 進捗通知用コールバック
   * @returns 文字起こしされた文字列
   */
  const transcribe = async (
    file: File,
    service: TranscribeService,
    splitLength: number,
    inputLang: TranscribeLanguage | '',
    onNotify: (msg: string) => void,
  ) => {
    console.log(`
    Service        => ${service.name}
    SplitLength    => ${splitLength}
    InputLang      => ${inputLang ? inputLang.code : ''}
    `);
    return await minutesAPI().transcribe(file, service.name, splitLength, inputLang ? inputLang.code : '', onNotify);
  };

  /**
   * 議事録作成用のプロンプトを取得するための関数
   * @param content 会話の文書
   * @param lang 言語
   */
  const getGenerateMinutesPrompt = async (content: string, lang: Language.Language) => {
    const template = minutePrompt();
    return Mustache.render(template, { content, formatLang: lang.displayJP });
  };

  return {
    transcribeServiceList,
    audioSplitLengthList,
    summarizeServiceList,
    inputLanguages,
    transcribe,
    getGenerateMinutesPrompt,
  };
};

/**
 * 議事録を作成してもらうためのプロンプト。プロンプトの指示は改行するとうまく動かなくなることがわかっているので改行しない。
 * @returns Mustache
 */
const minutesPromptMustache = () => `
以下に会議の文字起こし情報があります。
以下のフォーマットを{{formatLang}}に翻訳してください。
翻訳したフォーマットを参考にして要約を作成してください。
議題は必ず10個以上出してください。
重要なポイントは必ずそれぞれ4個以上出してください。
途中で終了せず、必ず全ての内容を出力してください。


\`\`\`フォーマット

## 議題（必要な数）: [議題に関するサマリーをここに]
### 重要なポイント:
（必要な数）1. : [ポイントをここに]

### 結論:
- [議題に対する結論や次のアクションをここに]
\`\`\`


\`\`\`フォーマット例
## 議題1 : プロジェクトXの進捗
### 重要なポイント:
1. 期限は来月末
2. 予算オーバーのリスクが高まっている

### 結論:
- 進捗状況を毎週報告し、必要な対策を速やかに講じる

\`\`\`



{{ content }}

`;

const minutePrompt = () => `
議事メモをもとに議事録を作成してください。
提供したフォーマットは日本語ですが、指定した出力言語に翻訳してから出力してください。

# 議事メモ
{{ content }}

# フォーマット

## 議題（必要な数）: [議題に関するサマリーをここに]
### 重要なポイント:
（必要な数）1. : [ポイントをここに]

### 結論:
- [議題に対する結論や次のアクションをここに]
\`\`\`


\`\`\`フォーマット例
## 議題1 : プロジェクトXの進捗
### 重要なポイント:
1. 期限は来月末
2. 予算オーバーのリスクが高まっている

### 結論:
- 進捗状況を毎週報告し、必要な対策を速やかに講じる


# 出力言語
{{formatLang}}`;

/**
 * 議事録の作成モード
 */
export enum GenerateMinutesMode {
  MINUTES = 'Minutes',
  HIGHLIGHTS = 'Highlights',
}
