import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  FormControl,
  FormControlPropsSizeOverrides,
  TextField,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import React from 'react';

interface CustomSelectionProps {
  label: string;
  value: string;
  list: string[];
  onChange:
    | ((
        event: React.SyntheticEvent<Element, Event>,
        value: string | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string> | undefined,
      ) => void)
    | undefined;
  size?: OverridableStringUnion<'small' | 'medium', FormControlPropsSizeOverrides> | undefined;
  fullWidth?: boolean;
  disabled?: boolean;
}

export const CustomSelection = React.memo(({ label, size, list, onChange, fullWidth, disabled = false, value }: CustomSelectionProps) => {
  return (
    <FormControl fullWidth={fullWidth} size={size}>
      <Autocomplete
        size={size}
        options={list}
        disabled={disabled}
        value={value || null}
        isOptionEqualToValue={(option, value) => option === value}
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={onChange}
      />
    </FormControl>
  );
});
