import { Grid, SxProps, TextField, Typography } from '@mui/material';
import React from 'react';

interface PromptSampleProps {
  text: string;
  sx?: SxProps;
  replace: string[];
  replaceStates: [string, React.Dispatch<React.SetStateAction<string>>][];
}

export const PromptSample = React.memo(({ text, sx, replace, replaceStates }: PromptSampleProps) => {
  let leftText: string = text;
  const textComponents: string[] = [];

  for (const item of replace) {
    const splitText = leftText.split(item);
    textComponents.push(splitText[0]);
    leftText = splitText[1];
  }

  const countOccurrence = (text: string, searchString: string) => {
    const matches = text.match(new RegExp(searchString, 'g'));
    return matches ? matches.length : 0;
  };

  return (
    <Grid item sx={sx}>
      <Typography component={'p'} fontSize={'14px'} maxWidth={'800px'}>
        {textComponents.map((value, index) => {
          return (
            <Grid>
              {value}
              <TextField
                value={replaceStates[index][0]} // useStateの0番はvalueを持ちます。
                onChange={(e) => replaceStates[index][1](e.target.value)} // useStateの1番はset関数を持ちます。
                fullWidth
                size="small"
                multiline
                minRows={countOccurrence(replace[index], '\n')}
              />
            </Grid>
          );
        })}
        {leftText}
      </Typography>
    </Grid>
  );
});
