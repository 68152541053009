import { LoadingButton } from '@mui/lab';
import { Button, Container, Grid, IconButton, InputBase, Paper, Stack } from '@mui/material';
import React from 'react';
import { Page } from 'src/components-minimals/Page';
import { PageDescription } from 'src/components/PageDescription';
import { PageTitle } from 'src/components/PageTitle';
import { useAssistantV2BetaCodeInterpreterPageModel } from './useAssistantBetaCIPageModel';
import ReactMarkdown from 'react-markdown';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { CodeBlockDialog } from 'src/components/CodeBlockDialog';
export const AssistantV2BetaCodeInterpreterPage = (): React.ReactElement => {
  const pm = useAssistantV2BetaCodeInterpreterPageModel();
  return (
    <Page title="Assistant API V2 Beta Test">
      <Container maxWidth={'md'}>
        <PageTitle text={'CodeInterpreter(beta)'} />
        <PageDescription
          text={'メッセージに対応するファイルを作成するページです。\n Assistant API V2 betaのCodeInterpreterのサンプルになります。'}
          sx={{ marginBottom: 3 }}
        />
        <Paper
          component="form"
          sx={{
            marginY: '15px',
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative', // Make this a positioning context for absolute positioning
            boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
          }}>
          <InputBase
            id="CodeinterpreterTest"
            sx={{
              ml: 1,
              flex: 1,
              minHeight: '38px',
            }}
            value={pm.prompt[0]}
            onChange={(e) => {
              pm.prompt[1](e.target.value);
            }}
            placeholder={'どのようなファイルを作成するか書いてください。'}
            multiline
            minRows={5}
            maxRows={5}
          />
        </Paper>
        <Grid container xs={12} direction="row" justifyContent="flex-end">
          <Grid item xs={2}>
            <LoadingButton
              onClick={pm.promptEvent}
              type="submit"
              variant="contained"
              fullWidth
              loading={pm.loading[0]}
              disabled={pm.prompt[0].length === 0}>
              {'要請'}
            </LoadingButton>
          </Grid>
        </Grid>
        {pm.response[0].map((response, index) => (
          <>
            <Paper sx={{ mt: 2, mb: 1 }}>
              <Grid sx={{ padding: 2, minHeight: 100, whiteSpace: 'pre-wrap' }}>
                <ReactMarkdown
                  components={{
                    a: () => <></>,
                  }}>
                  {response.message}
                </ReactMarkdown>
              </Grid>
            </Paper>
            <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
              <Grid xs={10} spacing={1}>
                {response.fileId.map((item) => (
                  <Button variant={'contained'} onClick={() => pm.downloadEvent(item.id, item.extension)}>
                    {'ファイルDL'}
                  </Button>
                ))}
              </Grid>
              <Grid xs={2}>
                {response.inputCode && (
                  <IconButton onClick={() => pm.dialog.openDialog(index)}>
                    <CropFreeIcon />
                  </IconButton>
                )}
              </Grid>
            </Stack>
            <CodeBlockDialog
              isOpen={pm.dialog.isOpen}
              code={'```\n' + response.inputCode + '\n```'}
              onOk={pm.dialog.onOk}
              okColor={'primary'}
              okVariant={'contained'}
              indexing={index}
            />
          </>
        ))}
      </Container>
    </Page>
  );
};
