import { ChatCompletionMessageParam } from 'openai/resources';
import { Auth } from 'aws-amplify';
import { apiGateway, prefixApiGateway } from 'src/config';
import { OptionChatType } from 'src/models/chat-gpt';
import { OriginImageList } from 'src/contexts/ChatMessageReducer';
// import axios from '../../../utils/axios';
interface submitMessageToOpenApiInSyncProps {
  id: string;
  messages: Array<ChatCompletionMessageParam>;
  chatType: OptionChatType;
  setAbortCntl: (data: AbortController | undefined) => void;
  callback: (message: string, count: number) => Promise<void>;
  language: string;
  purpose: 'chat' | 'edit';
  isWebSearch?: boolean;
  index?: number; // 編集時に必要なものなので、別のものが入ったら編集ができなくなります。
  ttsFlag?: boolean;
  tts?: (message: string) => void;
  resizedImage?: OriginImageList[];
}
/**
 * Completionを同期モードで呼び出す。
 * OpenAIのAPIはSyncに対応していないためfetchを使って対応
 * @param id id
 * @param messages messages
 * @param chatType chatType
 * @param setAbortCntl setAbortCntl
 * @param callback callback
 * @param language language
 * @param purpose purpose
 * @param isWebSearch isWebSearch
 * @param index index
 * @return return
 */
export async function submitMessageToOpenApiInSync({
  id,
  messages,
  chatType,
  setAbortCntl,
  callback,
  language,
  purpose,
  isWebSearch,
  index,
  ttsFlag = false,
  tts = (message: string) => {},
  resizedImage,
}: submitMessageToOpenApiInSyncProps) {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const newAbortCntl = new AbortController();
  const { signal } = newAbortCntl;
  // 切り替え時に問い合わせを止めるためにセットする
  setAbortCntl(newAbortCntl);

  const apiUrl = purpose === 'chat' ? '/chat/message-azure' : '/chat/message/edit';
  const body =
    purpose === 'chat'
      ? JSON.stringify({
          id: id,
          messages,
          version: chatType.versionNum,
          language: language,
          model: chatType.serverSideVersionString,
          isWebSearch: isWebSearch,
          resizedImage: resizedImage,
        })
      : JSON.stringify({
          id: id,
          messages,
          version: chatType.versionNum,
          language: language,
          model: chatType.serverSideVersionString,
          index: index,
          isWebSearch: isWebSearch,
        });
  // 直接 POST する。そうすることで、即 completion が帰ってきます。
  const completion = await fetch(apiGateway + prefixApiGateway + apiUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    signal,
    method: 'POST',
    body: body,
  });
  // ReadableStream として使用する
  const reader = completion.body?.getReader();

  if (!reader) {
    throw new Error(completion.statusText);
  }

  if (completion.status === 400) {
    const err = await parseError(reader);
    throw new Error(err);
  }

  if (completion.status !== 201) {
    throw new Error(completion.statusText);
  }

  const decoder = new TextDecoder('utf-8');
  let count = 0;
  let message = '';
  try {
    // この read で再起的にメッセージを待機して取得します
    const read = async (prevContent?: string | undefined): Promise<any> => {
      if (prevContent === undefined) prevContent = '';
      // console.log("受信MSG：", prevContent);
      const { done, value } = await reader.read();

      if (done) {
        // 再帰処理の終了
        return reader.releaseLock();
      }

      // あとはこの jsons を好きに使用すれば良いだけ。
      let content: string = '';
      const _content = decoder.decode(value, { stream: true });
      if (_content) {
        content = content + _content;
      }

      // これまでの文字列と新たに受け取った文字列を結合してmessageを更新
      const newMessage = prevContent.concat(content);
      if (ttsFlag) message += content;
      callback(newMessage, count);
      count++;

      return read(prevContent + content);
    };
    // 読み出し処理を読み出し
    await read();
    console.log(message);
    if (ttsFlag) tts(message);
  } catch (e: any) {
    console.log();
    if (e.name === 'AbortError') {
      reader.releaseLock();
      return '';
    } else {
      console.error(e);
    }
  }
  // ReadableStream を最後は解放する
  reader.releaseLock();

  return completion.headers.get('chat-id');
}

const parseError = async (reader: ReadableStreamDefaultReader<Uint8Array>): Promise<string> => {
  const decoder = new TextDecoder('utf-8');

  try {
    const { value } = await reader.read();
    const data = decoder.decode(value, { stream: true });
    const json = JSON.parse(data);
    return json.message;
  } catch (e) {
    return '';
  }
};
