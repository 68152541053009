import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { pythonGateway, prefixPythonGateway } from 'src/config';
import { clearResponse, clearSource, addResponse, addSource } from 'src/redux/slices/docSearchSlice';
import { dispatch } from 'src/redux/store';
interface UseDocSearchProps {
  text: string;
  errorMsgHandler: (e: any) => void;
}

export const usePersonalDocSearch = ({ text, errorMsgHandler }: UseDocSearchProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleSubmitButtonClick = useCallback(async () => {
    setIsSubmitting(true);
    dispatch(clearResponse()); // メッセージ表示エリアをクリア
    dispatch(clearSource());

    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    let reader: ReadableStreamDefaultReader<Uint8Array> | undefined;
    try {
      const completion = await fetch(pythonGateway + prefixPythonGateway + '/ai/search/personalDocuments', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        method: 'POST',
        body: JSON.stringify({ prompt: text }),
      });

      reader = completion.body?.getReader();
      if (!reader) {
        throw new Error(completion.statusText);
      }

      const parseJson = (jsonString: string) => {
        let content = jsonString;
        // contentの1文字目が「{」でなければ「{」を追加する
        if (content[0] !== '{') {
          content = '{' + content;
        }
        // contentの最後の文字が「}」でなければ「}」を追加する
        if (content[content.length - 1] !== '}') {
          content = content + '}';
        }
        const result = JSON.parse(content);
        if (result.error !== undefined) {
          throw new Error(result.error);
        }
        return result;
      };

      const decoder = new TextDecoder('utf-8');
      // この read で再起的にメッセージを待機して取得します
      const read = async (prevContent?: string | undefined): Promise<any> => {
        if (prevContent === undefined) prevContent = '';

        const { done, value } = await reader!.read();
        if (done) {
          const parsedContent = parseJson(prevContent);
          if (parsedContent.source !== undefined) {
            dispatch(addSource(parsedContent.source));
          }

          // 再帰処理の終了
          return;
        }

        const _content = prevContent + decoder.decode(value, { stream: true });

        // レスポンスは{data:""}...{data:""}{source:""}の形式で返却される
        // {data:""}{data:""}を分割して、dataの部分だけを取得し画面に表示する
        const contents = _content.split('}{');
        for (let i = 0; i < contents.length - 1; i++) {
          const parsedContent = parseJson(contents[i]);
          dispatch(addResponse(parsedContent.data));
        }
        return read(contents[contents.length - 1]);
      };
      // 読み出し処理を読み出し
      await read();
    } catch (e: any) {
      console.log(e);
      errorMsgHandler(e);
    }
    // ReadableStream を最後は解放する
    if (reader) reader.releaseLock();
    setIsSubmitting(false);
  }, [text]);

  useEffect(() => {
    if (text && disabled) setDisabled(false);
    else if (!text) setDisabled(true);
  }, [text]);
  return {
    isSubmitting,
    disabled,
    handleSubmitButtonClick,
  };
};
