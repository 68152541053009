import { Navigate } from 'react-router-dom';
import { USER_ROLES } from 'src/constants';
import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { MinutesTranscribingPage } from 'src/pages/minutes-transcribing-page/MinutesTranscrbingPage';
import { MinutesMinutesPage } from 'src/pages/minutes-minutes-page/MinutesMinutesPage';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';
import { PATH_MINUTES } from '../paths';

export const MinutesRoute = {
  path: 'minutes',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={PATH_MINUTES.transcribing} replace />,
    },
    {
      path: 'transcribing',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER, USER_ROLES.GPT_USER]}>
          <MinutesTranscribingPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'minutes',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER, USER_ROLES.GPT_USER]}>
          <MinutesMinutesPage />
        </RoleBasedGuard>
      ),
    },
  ],
};
