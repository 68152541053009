import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLocales } from 'src/hooks/useLocales';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { ReleaseNote } from 'src/utils/releaseNote/releaseNote';
import { marked } from 'marked';

export const useReleaseNotePageModel = () => {
  const { pathname } = useLocation();
  const [markdownText, setmarkdownText] = useState('');
  const { currentLang } = useLocales();

  useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  //  mdファイルからマークダウンのテキストを読み込む
  useEffect(() => {
    const pathSplited = pathname.split('/');
    const path = pathSplited[pathSplited.length - 1];
    ReleaseNote.forEach((releaseNote) => {
      if (`${path}_${currentLang.value}` === releaseNote.version) {
        setmarkdownText(releaseNote.content);
      }
    });
  }, [pathname, currentLang]);

  //  改行して表示する
  marked.setOptions({
    gfm: true,
    breaks: true,
  });

  const htmlText = marked.parse(markdownText);

  return {
    htmlText,
  };
};
