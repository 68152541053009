// 言語モデル
// コードはISO-639-1に準拠
export interface Language {
  code: string;
  displayJP: string;
  displayEN: string;
}

export const none: Language = {
  code: '',
  displayJP: '',
  displayEN: '',
};

export const japanese: Language = {
  code: 'ja',
  displayJP: '日本語',
  displayEN: 'Japanese',
};

export const english: Language = {
  code: 'en',
  displayJP: '英語',
  displayEN: 'English',
};

export const chinese: Language = {
  code: 'zh',
  displayJP: '中国語',
  displayEN: 'Chinese',
};

export const spanish: Language = {
  code: 'es',
  displayJP: 'スペイン語',
  displayEN: 'Spanish',
};

export const arabic: Language = {
  code: 'ar',
  displayJP: 'アラビア語',
  displayEN: 'Arabic',
};

export const french: Language = {
  code: 'fr',
  displayJP: 'フランス語',
  displayEN: 'French',
};

export const hindi: Language = {
  code: 'hi',
  displayJP: 'ヒンディー語',
  displayEN: 'Hindi',
};

export const indonesian: Language = {
  code: 'id',
  displayJP: 'インドネシア語',
  displayEN: 'Indonesian',
};

export const portuguese: Language = {
  code: 'pt',
  displayJP: 'ポルトガル語',
  displayEN: 'Portuguese',
};

export const german: Language = {
  code: 'de',
  displayJP: 'ドイツ語',
  displayEN: 'German',
};

export const italian: Language = {
  code: 'it',
  displayJP: 'イタリア語',
  displayEN: 'Italian',
};

export const thai: Language = {
  code: 'th',
  displayJP: 'タイ語',
  displayEN: 'Thai',
};

export const korean: Language = {
  code: 'ko',
  displayJP: '韓国語',
  displayEN: 'Korean',
};

export const russian: Language = {
  code: 'ru',
  displayJP: 'ロシア語',
  displayEN: 'Russian',
};

export const malay: Language = {
  code: 'ms',
  displayJP: 'マレー語',
  displayEN: 'Malay',
};

export const swahili: Language = {
  code: 'sw',
  displayJP: 'スワヒリ語',
  displayEN: 'Swahili',
};

export const czech: Language = {
  code: 'cz',
  displayJP: 'チェコ語',
  displayEN: 'Czech',
};

export const polish: Language = {
  code: 'pl',
  displayJP: 'ポーランド語',
  displayEN: 'Polish',
};

export const turkish: Language = {
  code: 'tr',
  displayJP: 'トルコ語',
  displayEN: 'Turkish',
};
