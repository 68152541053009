import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { assistantsAPI } from 'src/apis/assistantsAPI';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { AssistantResponse } from 'src/apis/assistantsAPI';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { Auth } from 'aws-amplify';

export const useAssistantsApiPageModel = () => {
  // STATE
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [request, setRequest] = useState<string>('');
  const [responses, setResponses] = useState<AssistantResponse[]>([]);
  const [files, setFile] = useState<FileList[]>([]);
  const [threadId, setThreadId] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [latestRes, setLatestRes] = useState<string>('');

  // HOOKS
  const snackbar = useSnackbar();

  // EVENT HANDLER
  /**
   * ユーザーのメッセージ入力欄のonChangeイベント
   */
  const requestOnChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      setRequest(event.target.value);
    },
    [request],
  );

  /**
   * ファイル追加
   */
  const fileChange = useCallback(async (_: string, value: any) => {
    setFile(value);
  }, []);

  /**
   * メッセージとファイルのデータを送信するボタンクリックのイベント
   */
  const AssistantsApiTestHandler = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await assistantsAPI.call({ file: files?.[0], request, threadId });
      setResponses((prevResponses) => [resp, ...prevResponses]);
      setThreadId(resp.threadId);
    } catch (e) {
      console.log(e);
      snackbar.error(String(e));
    } finally {
      setIsLoading(false);
    }
  }, [files, request, threadId]);

  /**
   * メッセージとファイルのデータを送信するボタンクリックのイベント
   */
  const AssistantsApiTestHandlerV2 = useCallback(async () => {
    try {
      setLatestRes('');
      setIsLoading(true);
      // const resp = await assistantsAPI.v2FS({ file: files?.[0], request, threadId });
      for await (const msg of assistantsAPI.v2FileSearchStream({ file: files?.[0], request, threadId })) {
        setLatestRes((value) => value + msg);
      }
      // setResponses((prevResponses) => [resp, ...prevResponses]);
      // setThreadId(resp.threadId);
    } catch (e) {
      console.log(e);
      snackbar.error(String(e));
    } finally {
      setIsLoading(false);
      setResponses((prevResponses) => [{ message: latestRes, threadId: '', downloadUrls: [], fileIds: [] }, ...prevResponses]);
    }
  }, [files, request, threadId, latestRes]);

  const fileDownloadHandler = useCallback(async (url) => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'translated.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const clearThreadHandler = useCallback(async () => {
    try {
      console.log(threadId);
      setIsLoading(true);
      await assistantsAPI.clearThread({ threadId });
    } catch (e) {
      console.log(e);
      snackbar.error(String(e));
    } finally {
      setIsLoading(false);
    }
  }, [threadId]);

  // SIDE EFFECT
  useEffect(() => {
    dispatch(setSideBarMenu());
  });

  useEffect(() => {
    setDisabled(!request);
  }, [request]);
  return {
    // state
    isLoading,
    disabled,
    request,
    responses,
    files,
    anchorEl,
    latestRes,
    // handler
    requestOnChange,
    fileChange,
    AssistantsApiTestHandler,
    fileDownloadHandler,
    setAnchorEl,
    clearThreadHandler,
    AssistantsApiTestHandlerV2,
  };
};
