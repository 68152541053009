import { ChatCompletionMessageParam } from 'openai/resources';
import { useReducer } from 'react';
export type OriginImageList = { in: string; origin: string };

export type ChatCompletionRequestMessageWithId = ChatCompletionMessageParam & {
  // 新たなタイプに合わせて interfaceではなく タイプで変更
  id?: string;
  isWebSearch?: boolean;
  originImg?: OriginImageList[];
};

type Action = {
  type: 'message/add' | 'message/update' | 'message/delete' | 'message/init' | 'message/addReset' | 'message/edit';
  payload: ChatCompletionRequestMessageWithId | ChatCompletionRequestMessageWithId[];
  index?: number;
};

const chatMessageReducer = (prevMessages: ChatCompletionRequestMessageWithId[], { type, payload: message, index }: Action) => {
  switch (type) {
    // Todo登録
    case 'message/add':
      const messageAdd = message as ChatCompletionRequestMessageWithId;
      if (messageAdd.content !== '') {
        return [...prevMessages, messageAdd];
      } else {
        return [...prevMessages];
      }
    case 'message/update':
      const messageUpdate = message as ChatCompletionRequestMessageWithId;
      // Syncの時に利用。最後のmesssageに追記する
      if (!prevMessages || prevMessages.length === 0) return [messageUpdate];
      return [...prevMessages.slice(0, prevMessages.length - 1), messageUpdate];
    case 'message/delete':
      return [];
    case 'message/addReset':
      // イメージを含むメッセージの場合2個削除する必要がある。
      return [...prevMessages.slice(0, prevMessages.length - 2)];
    case 'message/init':
      const messageInit = message as ChatCompletionRequestMessageWithId[];
      return messageInit;
    case 'message/edit':
      const editMessage = message as ChatCompletionRequestMessageWithId;
      if (!prevMessages || prevMessages.length === 0) return [editMessage];
      return [...prevMessages.slice(0, index), editMessage];
    default:
      return prevMessages;
  }
};

const useChatMessageReducer = () => {
  const [chatMessages, chatMessageDispatch] = useReducer(chatMessageReducer, []);
  return { chatMessages: chatMessages, chatMessageDispatch: chatMessageDispatch };
};

// createContextの初期値用オブジェクト
const defaultChatReducer: ReturnType<typeof useChatMessageReducer> = {
  chatMessages: [],
  chatMessageDispatch: () => {},
};

export { defaultChatReducer };
export default useChatMessageReducer;
