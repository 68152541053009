import { Box, Container, InputBase, Paper, Grid, Typography, Button, TextField, Autocomplete } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { useTranslatePageModel } from './useTranslatePageModel';
import { LoadingButton } from '@mui/lab';
import Chat from '@mui/icons-material/Chat';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { PageTitle } from 'src/components/PageTitle';
import { PageDescription } from 'src/components/PageDescription';
import { FileInputs } from 'src/components-mars/fileInput/FileInputs';
import { MeatballsMenu } from 'src/components/MeatballsMenu';
import useSettings from 'src/hooks/useSettings';

export const Translate = (): React.ReactElement => {
  const pm = useTranslatePageModel();
  const { themeMode } = useSettings();
  const languages = ['日本語', '英語', 'フランス語', '中国語', 'ドイツ語'];

  return (
    <Page title="GPT-4-Vision GChat">
      <Container maxWidth={'md'}>
        <Box display="flex" justifyContent="space-between">
          <PageTitle text={'ファイル翻訳'} />
          <MeatballsMenu
            sx={{ display: 'grid', placeItems: 'center' }}
            themeMode={themeMode}
            onClick={(event) => {
              pm.setAnchorEl(event.currentTarget);
            }}
            onClose={() => {
              pm.setAnchorEl(null);
            }}
            itemList={[{ message: 'スレッド削除', onClick: pm.clearThreadHandler, icon: 'open', sx: { paddingLeft: '5px' } }]}
            anchorElement={pm.anchorEl}
          />
        </Box>
        <PageDescription text={'ファイルのテキストを翻訳して出力します。言語を選択してファイルをアップロードして下さい。'} sx={{ marginBottom: 3 }} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={4}>
            <Autocomplete
              options={languages}
              value={pm.beforeLang}
              onChange={(event, newValue) => {
                pm.setBeforeLang(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="翻訳前" variant="outlined" size="small" />}
            />
          </Grid>
          <Grid item xs={1}>
            <Button onClick={pm.handleSwitchLang}>
              <SwapHorizIcon />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={languages}
              value={pm.afterLang}
              onChange={(event, newValue) => {
                pm.setAfterLang(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="翻訳後" variant="outlined" size="small" />}
            />
          </Grid>
        </Grid>
        <Paper
          component="form"
          sx={{
            marginY: '15px',
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative', // Make this a positioning context for absolute positioning
            boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
          }}>
          <FileInputs setField={pm.fileChange} files={pm.files} />
        </Paper>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {pm.responses.map((response) => (
            <Box
              sx={{
                mt: 2,
                padding: 2,
                height: 'auto',
                whiteSpace: 'pre-wrap',
                backgroundColor: response.type === 'assistants' ? '#f8f8f8' : '#cccccc',
                borderRadius: '8px',
                color: response.type === 'assistants' ? '#000000' : '#000000',
                width: 'fit-content',
                maxWidth: '70%',
                alignSelf: response.type === 'assistants' ? 'flex-start' : 'flex-end',
              }}>
              <Typography component="span">{response.message}</Typography>
              <br />
              {/* {response.message.match(/assistant-\w{24}/) && (
                <Button onClick={() => pm.fileDownloadHandler(response.message.match(/assistant-\w{24}/))}>{`ここからダウンロード`}</Button>
              )} */}
              {response.downloadUrls.map((url, index) => (
                <Button onClick={() => pm.fileDownloadHandler(url)}>{`Download File. (${response!.fileIds[index]})`}</Button>
              ))}
            </Box>
          ))}
        </Box>
        {pm.responses.length > 0 && (
          <Paper
            component="form"
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
            }}>
            <InputBase
              id="userMessageInput"
              sx={{
                ml: 1,
                flex: 1,
              }}
              value={pm.request}
              onChange={pm.requestOnChange}
              placeholder={'ここに質問を入力してください。'}
              multiline
              minRows={1}
              maxRows={3}
            />
          </Paper>
        )}
        <Grid container xs={12} direction="row" justifyContent="flex-end">
          <Grid item xs={2}>
            <LoadingButton
              onClick={pm.AssistantsApiTestHandler}
              startIcon={<Chat />}
              type="submit"
              variant="contained"
              fullWidth
              loading={pm.isLoading}
              disabled={pm.disabled}>
              {'質問'}
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
