import { Stack, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { amplifyConfig } from 'src/config';

export const StartPopup = () => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const authId: string = params.get('authId') || '';
  const method: string = params.get('oauthRedirectMethod') || '';
  const state: string = encodeURI(JSON.stringify({ authId, method }));
  const redirectUrl = encodeURI(params.get('hostRedirectUrl') as string);

  const queryObj = {
    state,
    client_id: amplifyConfig.aws_user_pools_web_client_id,
    response_type: 'code',
    identity_provider: 'mickey',
    scope: 'openid',
  };

  const query = Object.entries(queryObj)
    .map(([k, v]) => `&${k}=${v}`)
    .join('');

  window.location.href = `https://${amplifyConfig.cognitoDomain}/oauth2/authorize?redirect_uri=${redirectUrl}${query}`;

  return (
    <Container maxWidth="sm">
      <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 3 }}>
        <Typography variant="body1">{`${t('login.text.redirectLabel')}`}</Typography>
      </Stack>
    </Container>
  );
};
