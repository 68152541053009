import { Grid, IconButton } from '@mui/material';
import React from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';

interface EditIconButtonProps {
  flag: boolean;
  onClick: () => void;
}
export const EditIconButton = React.memo(({ flag, onClick }: EditIconButtonProps) => {
  return flag ? (
    <Grid item xs={12} sm={1}>
      <IconButton
        id="editButton"
        color="default"
        size="small"
        sx={{
          position: 'relative',
          bottom: 0,
        }}
        aria-label="messages"
        onClick={onClick}>
        <EditNoteIcon />
      </IconButton>
    </Grid>
  ) : (
    <Grid item xs={12} sm={1}></Grid>
  );
});
