import React from 'react';
import { OptionChatType, chatModels } from 'src/models/chat-gpt';
interface useSelectionProps {
  useChatType: OptionChatType;
  handleUseChatType: (data: OptionChatType) => void;
}
const useSelection = ({ useChatType, handleUseChatType }: useSelectionProps) => {
  const options: OptionChatType[] = chatModels;

  const [value, setValue] = React.useState<OptionChatType>(() => {
    const filteredOptions = options.filter((item: OptionChatType) => item.serverSideVersionString === useChatType.serverSideVersionString);
    return filteredOptions[0];
  });

  const onChangeOption = React.useCallback((_: any, value: OptionChatType) => {
    if (value) {
      handleUseChatType(value);
      setValue(value);
    }
  }, []);

  React.useEffect(() => {
    setValue(() => {
      const filteredOptions = options.filter((item: OptionChatType) => item.serverSideVersionString === useChatType.serverSideVersionString);
      return filteredOptions[0];
    });
  }, [useChatType]);

  return {
    options,
    value,
    onChangeOption,
  };
};
export default useSelection;
