import { transcribeAPI } from 'src/apis/transcribeAPI';
import { TranscribeLanguage } from 'src/models/transcribe-language';
import { TranscribeService } from 'src/models/transcribe-service';

export const useTranscribe = () => {
  /**
   * 文字起こしをするための機能 (非ストリーム)
   * @param file 音声ファイル
   * @param service 文字起こしサービス
   * @param splitLength 音声ファイルの分割時間(秒)
   * @param inputLang 入力音声の言語
   * @returns 文字起こしされた文字列
   */
  const transcribe = async (file: File, service: TranscribeService, splitLength: number, inputLang: TranscribeLanguage | null) => {
    return await transcribeAPI().transcribe(file, service.name, splitLength, inputLang?.code ?? '');
  };

  return {
    transcribe,
  };
};
