import { apiGateway, prefixApiGateway } from 'src/config';
import axios from 'src/utils/axios';
import { Auth } from 'aws-amplify';

// FIXME: 仮定義
// サンプルの実装が終わったら正式なモデルを定義すること
export interface AssistantResponse {
  message: string;
  fileIds: string[];
  threadId: string;
  downloadUrls: string[];
  type?: string;
}

export const assistantsAPI = {
  /**
   * CodeInterpreterのサンプル
   * @param props プロパティオブション
   * @returns 答え
   */
  call: async (props: { file: FileList | null; request: string; threadId: string }): Promise<AssistantResponse> => {
    const path = apiGateway + prefixApiGateway + '/codeinterpreter';
    const formData = new FormData();
    if (props.file?.[0]) {
      formData.append(`file`, props.file[0]);
    }
    formData.append('request', props.request);
    formData.append('threadId', props.threadId);
    const data = await axios.post(path, formData).then((res) => res.data);
    return {
      message: data.message,
      fileIds: data.file_ids,
      threadId: data.threadId,
      downloadUrls: data.file_ids.map((id: string) => `${path}/${id}`),
    };
  },
  fileDownLoad: async (props: { url: string }) => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const resp = await fetch(props.url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  },
  clearThread: async (props: { threadId: string }) => {
    const path = apiGateway + prefixApiGateway + '/codeinterpreter/clearThread';
    await axios.post(path, { threadId: props.threadId });
  },
  /**
   * CodeInterpreterのサンプル
   * @param props プロパティオブション
   * @returns 答え
   */
  v2FS: async (props: { file: FileList | null; request: string; threadId: string }): Promise<AssistantResponse> => {
    const path = apiGateway + prefixApiGateway + '/codeinterpreter/v2-beta-fs';
    const formData = new FormData();
    if (props.file?.[0]) {
      formData.append(`file`, props.file[0]);
    }
    formData.append('request', props.request);
    formData.append('threadId', props.threadId);
    const data = await axios.post(path, formData).then((res) => res.data);
    return {
      message: data,
      fileIds: [],
      threadId: '',
      downloadUrls: [],
    };
  },

  /**
   * CodeInterpreterのサンプル
   * @param props プロパティオブション
   * @returns 答え
   */
  v2FileSearchStream: async function* (props: { file: FileList | null; request: string; threadId: string }): AsyncGenerator<string> {
    const path = apiGateway + prefixApiGateway + '/codeinterpreter/v2-beta-fs/stream';
    const method = 'POST';
    const headers = {
      Authorization: 'Bearer ' + (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const form = new FormData();
    if (props.file?.[0]) {
      form.append(`file`, props.file[0] as File);
    }
    form.append('prompt', props.request);
    form.append('threadId', props.threadId);

    // APIへのリクエスト
    console.log('????');
    const res = await fetch(path, { method, body: form, headers });
    if (!res.ok) {
      throw new CustomError(res.status, res.statusText);
    }
    console.log('????!!!');
    // ストリームリーダーの取得
    const reader = res.body?.getReader();
    if (!reader) {
      throw new CustomError(0, 'Failed to get reader.');
    }

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const text = new TextDecoder().decode(value);
      const texts = text.split('\n');
      if (texts.length >= 2) {
        texts.pop();
        const jsons = texts.filter((text) => !text === false).map((text) => JSON.parse(text));
        for (const json of jsons) {
          if (json.code === 200) {
            yield json.result;
          } else {
            throw json;
          }
        }
      }
    }
  },
  /**
   * CodeInterpreterのサンプル
   * @param props プロパティオブション
   * @param threadId threadId
   * @returns 答え
   */
  v2CI: async (props: {
    prompt: string;
    threadId?: string;
  }): Promise<{ message: string; fileId: { id: string; extension: string }[]; threadId: string; inputCode: string }> => {
    const path = apiGateway + prefixApiGateway + '/codeinterpreter/v2-beta-ci';
    const body = {
      prompt: props.prompt,
      threadId: props.threadId,
    };
    const data = await axios.post(path, body).then((res) => res.data);
    return {
      message: data.message,
      fileId: data.fileId,
      threadId: data.threadId,
      inputCode: data.inputCode,
    };
  },
  /**
   * CodeInterpreterのサンプル
   * @param props file id
   * @returns 答え
   */
  v2CIDl: async (props: { fileId: string }): Promise<any> => {
    const path = apiGateway + prefixApiGateway + '/codeinterpreter/v2-beta-ci/' + props.fileId;
    return await axios.get(path).then((res) => {
      // console.log(res);
      // console.log(typeof res.data);
      return res.data;
    });
  },
};

/**
 * CustomError
 */
class CustomError extends Error {
  private code: number;
  /**
   * error
   * @param code code num
   * @param detail 詳細
   */
  constructor(code: number, detail: string) {
    super(detail);
    this.code = code;
  }
}
