import { useCallback, useEffect, useRef, useState } from 'react';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector, dispatch } from 'src/redux/store';
import { startEditing, stopEditing } from '../redux/slices/chatGptSlice';
import { useTranslation } from 'react-i18next';
// import { base64ImageStart, imageStart, markdownImgLinkStart } from 'src/constants';
// import { apiGateway, prefixApiGateway } from 'src/config';
import { ChatCompletionContentPartImage } from 'openai/resources';
interface useChatContentsProps {
  id: number;
  message: string | ChatCompletionContentPartImage;
  role: string;
  selectId: string;
  handleSendUpdateMessageButton: (e: any, updatedMsg: string, index: number, isWebSearch: boolean) => Promise<void>;
  isWebSearch: boolean;
}
export const useChatContents = ({ role, message, id: index, selectId, handleSendUpdateMessageButton, isWebSearch }: useChatContentsProps) => {
  const { t } = useTranslation();
  const editBtnLabel = t('common.button.send');
  const cancelBtnLabel = t('common.button.cancel');
  const { themeMode } = useSettings();
  const backColor = role === 'user' ? '' : themeMode === 'light' ? '#f8f8f8' : '#1a212a';
  const iconFlag = role === 'user';
  const editingId = useSelector((state: RootState) => state.chatGptState).editingId;
  const editingFlag = editingId === index && typeof message === 'string';
  const [editableMsg, setEditanleMsg] = useState<string>(typeof message === 'string' ? message : '');

  // // メッセージとして入ったデータがイメージなのか確認;
  // const messageTypeCheck = typeof message !== 'string' && Boolean(message?.image_url?.url);
  // const base64Flag = messageTypeCheck && message.image_url.url.startsWith(base64ImageStart); // base64 リンクか確認
  // const imgFlag = (messageTypeCheck && message.image_url.url.startsWith(imageStart)) || base64Flag; // イメージなのか確認

  // // markdown リンク形式に修正
  // const imgText = imgFlag
  //   ? base64Flag
  //     ? `${markdownImgLinkStart}(${message.image_url.url})`
  //     : `${markdownImgLinkStart}(${apiGateway + prefixApiGateway}${message.image_url.url})`
  //   : ''; // base64だとapigateway部分がいらない

  const closeHandler = useCallback(
    (flag: boolean) => {
      if (editingId === undefined) {
        dispatch(startEditing(index));
      } else {
        if (flag) {
          dispatch(startEditing(index));
        } else {
          dispatch(stopEditing());
        }
      }
    },
    [editingId, index],
  );

  const onChange = useCallback((event: any) => {
    setEditanleMsg(event.target.value);
  }, []);

  const initHandler = useCallback(
    (flag: boolean) => {
      if (flag) {
        setEditanleMsg(editableMsg);
      } else {
        setEditanleMsg(typeof message === 'string' ? message : '');
      }
    },
    [message, editableMsg],
  );

  const cancelHandler = useCallback(() => {
    closeHandler(false);
    initHandler(editingId !== index);
  }, [editableMsg, editingId, index, message]);

  const sendHandler = useCallback(
    (e: any) => {
      handleSendUpdateMessageButton(e, editableMsg, index, isWebSearch);
      closeHandler(false);
    },
    [editableMsg, index, editingId],
  );

  const editIconHandler = useCallback(() => {
    closeHandler(editingId !== index);
    initHandler(editingId !== index);
  }, [editingId, index]);

  useEffect(() => {
    dispatch(stopEditing());
  }, [selectId]);

  useEffect(() => {
    setEditanleMsg(typeof message === 'string' ? message : '');
  }, [message]);

  const editButtonRef = useRef<null | HTMLButtonElement>(null);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      editButtonRef.current?.focus();
    }
  };

  return {
    editButtonRef,
    handleKeyPress,
    editBtnLabel,
    cancelBtnLabel,
    backColor,
    editableMsg,
    onChange,
    message,
    cancelHandler,
    sendHandler,
    iconFlag,
    editingFlag,
    editIconHandler,
    // imgFlag,
    // imgText,
  };
};
