import { useSnackbar, VariantType } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { Typography, IconButton } from '@mui/material';

type SnackBarPros = {
  color: VariantType;
  msg?: string;
  isPersist?: boolean;
};

/**
 *
 * @return コンポーネント
 */
const snackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /**
   * Snackbarを表示する
   * @param props  props
   */
  const onSnackbarClose = (props: SnackBarPros) => {
    enqueueSnackbar(
      <div>
        <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
          {props.color}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {props.msg}
        </Typography>
      </div>,
      {
        variant: props.color,
        action: (key) => (
          <IconButton size="small" color="inherit" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        ),
        persist: props.isPersist,
        autoHideDuration: 5000,
      },
    );
  };
  return {
    onSnackbarClose,
    closeSnackbar,
  };
};

export default snackbar;
