import { useState } from 'react';

export type DialogResult = 'cancel' | 'ok' | 'confirm' | 'positive' | 'negative';

export const useDialogMulti = () => {
  const [isOpen, setIsOpen] = useState<number>(999);
  const [resolve, setResolve] = useState<(result: DialogResult) => void>();

  /**
   * ダイアログを開く
   * @param index index
   * @returns ダイアログで押されたボタンの識別子
   */
  const openDialog = (index: number): Promise<string> => {
    setIsOpen(() => index);
    setResolve(() => undefined);
    return new Promise((resolve) => {
      setResolve(() => resolve);
    });
  };

  /**
   * ダイアログのOKボタンが押された時の処理
   */
  const onOk = () => {
    setIsOpen(() => 999);
    if (resolve) {
      resolve('ok');
    }
  };

  /**
   * ダイアログのキャンセルボタンが押された時の処理
   */
  const onCancel = () => {
    setIsOpen(() => 999);
    if (resolve) {
      resolve('cancel');
    }
  };

  /**
   * ダイアログの確認ボタンが押された時の処理
   */
  const onConfirm = () => {
    setIsOpen(() => 999);
    if (resolve) {
      resolve('confirm');
    }
  };

  /**
   * ダイアログの肯定的なボタンが押された時の処理
   */
  const onPositive = () => {
    setIsOpen(() => 999);
    if (resolve) {
      resolve('positive');
    }
  };

  /**
   * ダイアログの否定的なボタンが押された時の処理
   */
  const onNegative = () => {
    setIsOpen(() => 999);
    if (resolve) {
      resolve('negative');
    }
  };

  return {
    isOpen,
    openDialog,
    onOk,
    onCancel,
    onConfirm,
    onPositive,
    onNegative,
  };
};
