import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import { CodeBlock } from 'src/shared/components/CodeBlock';

export interface CodeBlockDialogProps {
  isOpen: number;
  code: string;
  onOk: VoidFunction;
  okColor?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning';
  okVariant?: 'text' | 'outlined' | 'contained';
  indexing?: number;
}

export const CodeBlockDialog = (props: CodeBlockDialogProps) => {
  const { isOpen, code, onOk, okColor = 'primary', okVariant = 'outlined', indexing = '' } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return isOpen === indexing ? (
    <Dialog open={isOpen === indexing} onClose={onOk} fullWidth>
      <DialogContent sx={{ minWidth: 240 }}>
        <DialogContentText>
          <ReactMarkdown
            components={{
              code: CodeBlock,
            }}>
            {code}
          </ReactMarkdown>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent={'right'} alignItems="center">
          <Grid item xs={isMobile ? 5 : 3}>
            <Button variant={okVariant} onClick={onOk} color={okColor} fullWidth>
              {'Close'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
};
