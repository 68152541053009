import { BoxProps, Grid, Typography } from '@mui/material';
import React from 'react';

interface PageTitleProps extends BoxProps {
  text: string;
  variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | undefined;
}

export const PageTitle = React.memo(({ text, variant = 'h4' }: PageTitleProps) => {
  return (
    <Grid item mt={2} mb={2}>
      <Typography variant={variant}>{text}</Typography>
    </Grid>
  );
});
