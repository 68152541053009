import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import { LogoOnlyLayout } from '../components-mars/layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuardLayout from '../components-mars/layouts/AuthGuardLayout';
// paths
import { INITIAL_PATH } from '../constants';

// components
import { LoadingScreen } from '../components-minimals/LoadingScreen';
import { MinutesRoute } from 'src/routes/pages-route/MinutesTranscribingPageRoute';
import { releaseNotesRoute } from './pages-route/ReleaseNotePageRoute';
import { chatRoute } from './pages-route/ChatPageRoute';
import { docSearchRoute, docSearchRouteRelease } from './pages-route/DocSearchPageRoute';
import { promptRoute } from './pages-route/PromptPageRoute';
import { StartPopup } from 'src/components-minimals/authentication/login/StartPopup';
import { ClosePopup } from 'src/components-minimals/authentication/login/ClosePopup';
import { termsRoute } from './pages-route/TermsPageRoute';
import { TermsOfServiceGuard } from 'src/guards/TermsOfServiceGuard';
import { isNotReleaseFunction } from 'src/utils/envChecker';
import { homeRoute } from './pages-route/HomePageRoute';
import { othersRoute } from './pages-route/OthersRoute';

/**
 *
 * @param  Component コンポーネント
 * @return コンポーネント
 */
const Loadable = (Component: any) => (props: any) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }>
      <Component {...props} />
    </Suspense>
  );
};
const Login = Loadable(lazy(() => import('../features/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../features/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../features/authentication/VerifyCode')));
const Page500 = Loadable(lazy(() => import('../features/errors/Page500')));
const NotFound = Loadable(lazy(() => import('../features/errors/Page404')));
const pageRoutes = isNotReleaseFunction()
  ? [homeRoute, chatRoute, docSearchRoute, othersRoute, MinutesRoute, promptRoute, releaseNotesRoute, termsRoute]
  : [homeRoute, chatRoute, docSearchRouteRelease, MinutesRoute, promptRoute, releaseNotesRoute, termsRoute]; // URLとタブのマッピング

/**
 *
 * @return ルーター
 */
export const Router = () => {
  return useRoutes([
    {
      path: '/',
      element: <AuthGuardLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to={INITIAL_PATH} replace />,
        },
      ],
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'start-popup', element: <StartPopup /> },
        { path: 'close-popup', element: <ClosePopup /> },
      ],
    },
    {
      element: <TermsOfServiceGuard />,
      children: pageRoutes,
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
};
