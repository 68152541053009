import { AutocompleteChangeDetails, Grid } from '@mui/material';
import React from 'react';
import { CustomSelection } from './CustomSelection';
import { SearchBar } from './SearchBar';
import { AutocompleteChangeReason } from '@mui/material';

interface SearchBarWithCategoryProps {
  searchText: string;
  searchOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label: string;
  categoryValue: string;
  categoryOnChange:
    | ((
        event: React.SyntheticEvent<Element, Event>,
        value: string | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string> | undefined,
      ) => void)
    | undefined;
  list: string[];
}
export const SearchBarWithCategory = React.memo(
  ({ searchText, searchOnChange, placeholder, label, categoryValue, categoryOnChange, list }: SearchBarWithCategoryProps) => {
    return (
      <Grid container spacing={2} mt={1} mb={1}>
        <Grid item md={1} />
        <Grid item md={7} xs={12}>
          <SearchBar value={searchText} onChange={searchOnChange} placeholder={placeholder} />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSelection value={categoryValue} list={list} onChange={categoryOnChange} label={label} fullWidth size={'small'} />
        </Grid>
        <Grid item md={1} />
      </Grid>
    );
  },
);
