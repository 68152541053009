import axios from 'src/utils/axios';
import { apiGateway, prefixApiGateway } from 'src/config';
import { TermsOfService, TermsOfServiceBase } from 'src/models/term';
import { marked } from 'marked';
import { Contents } from '../@types/terms';

export const termsofserviceAPI = {
  registAgree: async () => {
    return await axios.get<null>(apiGateway + prefixApiGateway + '/termsofservice/agreement/');
  },
  checkAgree: async (): Promise<TermsOfService[]> => {
    return await axios.get<TermsOfServiceBase[]>(apiGateway + prefixApiGateway + '/termsofservice/check/').then((res) =>
      res.data.map((base) => ({
        id: base.id,
        type: base.type,
        content: marked(base.content, { breaks: true }),
        createdAt: new Date(base.createdAt),
      })),
    );
  },
  getContents: async (path: string): Promise<Contents> => {
    return await axios.get(apiGateway + prefixApiGateway + '/termsofservice/contents?path=' + path).then((res) => res.data);
  },
};
