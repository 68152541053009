import React from 'react';
import { useDallE3 } from 'src/hooks/useDallE3';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';

export const useDallE3PageModel = () => {
  const snackbar = useSnackbar();
  const api = useDallE3();

  const [text, setText] = React.useState<string>('');
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const [imgBase64, setImgBase64] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const onClickedSend = async () => {
    setLoading(true);
    if (text === '') {
      snackbar.error('プロンプトが入力されていません');
    } else {
      const result = await api.call(text);
      setImgBase64(result);
    }
    setLoading(false);
  };

  const handleCloseTip = (): void => {
    setTooltipOpen(false);
  };

  const handleDownloadClick = () => {
    const blob = base64toBlob(imgBase64);
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'image.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const base64toBlob = (base64Data: string) => {
    const byteString = atob(base64Data.split(',')[1]);
    const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  React.useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  return {
    text: {
      value: text,
      setter: setText,
    },
    onClickedSend,
    tooltipOpen,
    handleCloseTip,
    imgBase64,
    handleDownloadClick,
    loading,
  };
};
