import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';

export interface LoadingProps {
  isLoading: boolean;
  text?: string;
  subText?: string;
}

/**
 * ローディング画面表示用のコンポーネント
 * @param props パラメータ
 * @returns レンダリングコンポーネント
 */
export const Loading = (props: LoadingProps) => {
  const { isLoading, text, subText } = props;

  useEffect(() => {
    document.body.style.overflow = isLoading ? 'hidden' : 'auto';
  }, [isLoading]);

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isLoading}
      onClick={() => {}}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" justifyContent={'center'}>
          <CircularProgress color="inherit" />
        </Stack>
        <Stack direction="column" justifyContent={'center'} style={{ whiteSpace: 'pre-line' }}>
          <Typography variant="h6" textAlign={'center'} children={text ? text : ''} />
          <Typography variant="subtitle2" textAlign={'center'} children={subText} />
        </Stack>
      </Stack>
    </Backdrop>
  );
};
