import { Box, Dialog, Divider, Grid, Paper } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { PageTitle } from './PageTitle';
import { PageDescription } from './PageDescription';
import { PromptDescription } from './PromptDescription';
import { PromptSample } from './PromptSample';

const useStyles = makeStyles({
  tableScrollBar: {
    overflowY: 'auto',
    margin: 'auto',
  },
});

interface PromptModalProps {
  open: boolean;
  closeModal: () => void;
  title: string;
  promptId: number;
  inputMessage: (id: number, updated: string[]) => void;
  label: string;
  modalTitlePrompt: string;
  modalTitleSample: string;
  sampleResponse: string;
  prompt: string;
  tags: string[];
  isChat?: boolean;
  replace: string[];
}
export const PromptModal = React.memo(
  ({
    open,
    closeModal,
    title,
    label,
    inputMessage,
    modalTitlePrompt,
    modalTitleSample,
    sampleResponse,
    prompt,
    tags,
    promptId,
    isChat = false,
    replace,
  }: PromptModalProps) => {
    const classes = useStyles();
    const replaceStates = replace.map((str) => React.useState<string>(str));

    return (
      <Dialog
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        maxWidth={'md'}
        disableScrollLock={true}>
        <Grid>
          <Paper className={classes.tableScrollBar}>
            <Box sx={{ m: 2 }}>
              <Grid container>
                <Grid item xs={12} container justifyContent="center" alignItems="center">
                  <PromptDescription
                    title={title}
                    tags={tags}
                    inputMessage={() =>
                      inputMessage(
                        promptId,
                        replaceStates.map((replaced) => replaced[0]),
                      )
                    }
                    label={label}
                    closeModal={closeModal}
                    isChat={isChat}
                  />
                </Grid>
                <Divider style={{ width: '100%' }} />
                <Grid item xs={12}>
                  <PageTitle text={modalTitlePrompt} variant="subtitle2" />
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={5}>
                    <PromptSample text={prompt} sx={{ padding: 2, whiteSpace: 'pre-line' }} replace={replace} replaceStates={replaceStates} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <PageTitle text={modalTitleSample} variant="subtitle2" />
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={5}>
                    <PageDescription text={sampleResponse} sx={{ padding: 2, whiteSpace: 'pre-line' }} />
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Dialog>
    );
  },
);
