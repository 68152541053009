import { documentAPI } from 'src/apis/docmentAPI';
import { DocumentFile } from 'src/models/document-file';
import { OrganizationClassification, OrganizationSegment } from 'src/models/organization';

export const useDocumentFile = () => {
  const ALLOWED_EXTENSIONS = ['txt', 'csv', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'pdf', 'md', 'msg', 'rtf', 'json', 'html', 'xml'];

  /**
   * 組織分類からドキュメントファイルを取得するための関数
   * @param classification 検索条件とする組織分類
   * @param segment 検索条件とする組織セグメント
   * @param includeTag タグを含むか
   */
  const getByOrganization = async (
    classification: OrganizationClassification | null,
    segment: OrganizationSegment | null,
    includeTag?: boolean,
  ): Promise<DocumentFile[]> => {
    // MEMO: 組織分類が「グループ全体」になっている時の処理
    if (classification !== null && segment === null) {
      return await documentAPI.getByOrganization({ classification, segment: null, includeTag });
    }
    // MEMO: 組織セグメントまで検索条件が指定されている時の処理
    else if (classification !== null && segment !== null) {
      return await documentAPI.getByOrganization({ classification, segment, includeTag });
    }
    // MEMO: 未定義の引数パターン時の処理
    else {
      throw new Error('Undefined argument pattern');
    }
  };

  /**
   * ユーザーIDからドキュメントファイルを取得するための関数
   * @param includeTag タグを含むか
   */
  const getByUserID = async (): Promise<DocumentFile[]> => {
    return await documentAPI.getByUserID();
  };

  /**
   * ドキュメントファイルの中身を文字列化したものを取得するための関数
   * @param file ドキュメントの生データ
   * @returns ドキュメントファイルの内容を文字列化したもの
   */
  const getContentString = async (file: File): Promise<string> => {
    return await documentAPI.getContentsString({ file });
  };

  /**
   * ドキュメントファイルアップロードするための関数
   * @param props properties
   * @param props.files アップロードするファイルの配列
   * @param props.tag アップロード時のタグ
   * @param props.classification 種別
   * @param props.headOffice セグマント
   * @returns ドキュメントファイルの内容を文字列化したもの
   */
  const upload = async (props: { files: File[]; tag: string; classification: string; headOffice: string }): Promise<void> => {
    await documentAPI.uploadFiles(props);
  };

  const uploadPersonal = async (props: { files: File[] }): Promise<void> => {
    // await documentAPI.uploadPersonalFiles(props);
    await documentAPI.uploadPersonalFilesStreaming(props);
  };

  /**
   * ドキュメントファイルを削除するための関数
   * @param file 削除するドキュメントファイル
   * @param classification 組織分類
   */
  const remove = async (file: DocumentFile, classification: OrganizationClassification): Promise<void> => {
    await documentAPI.deleteFile({ file, classification });
  };

  /**
   * ドキュメントファイルを削除するための関数
   * @param file 削除するドキュメントファイル
   */
  const removePersonal = async (file: DocumentFile): Promise<void> => {
    await documentAPI.deletePersonalFile({ file });
  };

  /**
   * ドキュメントファイルをダウンロードするための関数
   * @param file ダウンロードするドキュメントファイル
   */
  const download = async (file: DocumentFile): Promise<void> => {
    const url = await documentAPI.getPresignedS3Url({ file });
    const a = document.createElement('a');
    a.href = url;
    a.download = file.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  /**
   * ドキュメントファイルをダウンロードするための関数
   * @param file ダウンロードするドキュメントファイル
   */
  const downloadPersonal = async (file: DocumentFile): Promise<void> => {
    const url = await documentAPI.getPersonalPresignedS3Url({ file });
    const a = document.createElement('a');
    a.href = url;
    a.download = file.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  /**
   * 組織分類からドキュメントファイル名リストを取得
   * @param classification 検索条件とする組織分類
   * @param segment 検索条件とする組織セグメント
   */
  const getFileNmaesByOrganization = async (
    classification: OrganizationClassification | null,
    segment: OrganizationSegment | null,
  ): Promise<string[]> => {
    // MEMO: 組織分類が「グループ全体」になっている時の処理
    if (classification !== null && segment === null) {
      const docs = await documentAPI.getByOrganization({ classification, segment: null });
      return docs.map((doc) => doc.fileName);
    }
    // MEMO: 組織セグメントまで検索条件が指定されている時の処理
    else if (classification !== null && segment !== null) {
      const docs = await documentAPI.getByOrganization({ classification, segment });
      return docs.map((doc) => doc.fileName);
    }
    // MEMO: 未定義の引数パターン時の処理
    else {
      throw new Error('Undefined argument pattern');
    }
  };

  /**
   *
   * @returns filenames
   */
  const getFileNamesByUserID = async (): Promise<string[]> => {
    const docs = await documentAPI.getByUserID();
    return docs.map((doc) => doc.fileName);
  };

  const getS3FolderSize = async (): Promise<number> => {
    let folderSize = 0;
    try {
      folderSize = await documentAPI.getFolderSize();
    } catch (e) {
      console.log(e);
    }
    return folderSize;
  };

  return {
    ALLOWED_EXTENSIONS,
    getByOrganization,
    getByUserID,
    getContentString,
    remove,
    removePersonal,
    download,
    downloadPersonal,
    upload,
    uploadPersonal,
    getFileNmaesByOrganization,
    getS3FolderSize,
    getFileNamesByUserID,
  };
};
