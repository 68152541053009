import { Stack, Tooltip, Typography, TypographyPropsVariantOverrides } from '@mui/material';
import React from 'react';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';

export interface IconWithMessageProps {
  message: string;
  icon: JSX.Element;
  tooltip?: string;
  color?: string;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides> | undefined;
}
export const IconWithMessage = React.memo(({ icon, message, tooltip, variant = 'caption', color = 'gray' }: IconWithMessageProps) => {
  return (
    <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
      <Tooltip arrow placement="bottom" title={tooltip}>
        {icon}
      </Tooltip>
      <Typography variant={variant} color={color}>
        {message}
      </Typography>
    </Stack>
  );
});
