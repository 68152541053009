import { Container, Grid, Stack, Box, Typography, Button, Tooltip, IconButton, ClickAwayListener } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { InputMessage } from 'src/components/InputMessage';
import { useChatPageModel } from './useChatPageModel';
import { ChatContents } from 'src/components/ChatContents';
import React from 'react';
import { ChatStatusHeader } from 'src/components/ChatStatusHeader';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { NavLink as RouterLink } from 'react-router-dom';
import { PATH_PROMPT } from 'src/routes/paths';
import { ChatLogo } from 'src/components/ChatIcon';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { GptDetailModal } from 'src/components/GptDetailModal';
import { PromptCard } from 'src/components/PromptCard';
import { PromptModal } from 'src/components/PromptModal';
import { LayoutToggleButtons } from 'src/components/LayoutToggleButtons';
import { ChatCompletionContentPartImage } from 'openai/resources';
import { ChatContentsImage } from 'src/components/ChatContentsImage';

export const MainChat = (): React.ReactElement => {
  const pm = useChatPageModel();

  return (
    <Page title="Toyotsu GChat">
      <Container maxWidth={false} sx={{ padding: '0px' }}>
        <Stack sx={{ alignItems: 'center', width: '100%' }}>
          <Stack sx={{ alignItems: 'center', paddingTop: '5px', width: '100%' }}>
            {pm.existChatMessage ? (
              <>
                {/* // データがすでにある場合はメッセージを表示 */}
                <Grid sx={{ width: '100%', marginBottom: '85px' }}>
                  <ChatStatusHeader model={pm.chatModel} isWebSearch={pm.isWebSearch} />
                  {pm.chatMessages.map((element, index, _) => {
                    if (element.content && typeof element.content !== 'string') {
                      const images = element.content.filter((item) => item.type === 'image_url') as ChatCompletionContentPartImage[];
                      return element.content.map((content, indexContent) => {
                        if (content.type === 'text') {
                          return (
                            <>
                              <ChatContentsImage images={images} downloadImg={pm.downloadImg} originImg={element.originImg} isMobile={pm.isMobile} />
                              <ChatContents
                                key={index + '_content_' + indexContent}
                                id={index}
                                role={element.role}
                                message={content.text}
                                isLoading={pm.isLoading}
                                selectId={pm.selectId}
                                handleSendUpdateMessageButton={pm.handleSendUpdateMessageButton}
                                isWebSearch={pm.isWebSearch}
                                // downloadImg={pm.downloadImg}
                                // originImg={element.originImg}
                              />
                            </>
                          );
                        }
                      });
                    } else if (typeof element.content === 'string') {
                      return (
                        <ChatContents
                          key={index}
                          id={index}
                          role={element.role}
                          message={element.content}
                          isLoading={pm.isLoading}
                          selectId={pm.selectId}
                          handleSendUpdateMessageButton={pm.handleSendUpdateMessageButton}
                          isWebSearch={pm.isWebSearch}
                          // downloadImg={pm.downloadImg}
                          // originImg={element.originImg}
                        />
                      );
                    }
                    return <></>;
                  })}
                  <Grid ref={pm.messagesEnd} />
                </Grid>
                <InputMessage
                  position={'fixed'}
                  isChat={true}
                  maxTokenLength={pm.maxTokenLength}
                  progress={pm.progress}
                  tokenLength={pm.tokenLength}
                  messageToken={pm.messageToken}
                  themeMode={pm.themeMode}
                  anchorElement={pm.anchorEl}
                  itemList={pm.itemList}
                  newButtonLabel={pm.newBtnLabel}
                  placeholder={pm.placeholder}
                  isLoading={pm.isLoading}
                  messageInputBoxValue={pm.inputMessage}
                  inputElement={pm.inputElement}
                  recordingStatus={pm.recordingStatus}
                  newBtnOnClick={pm.newBtnOnClick}
                  onChange={pm.handleInputChange}
                  // onKeyDown={pm.onKeyDownHandler}
                  // onCompositionStart={pm.startComposition}
                  // onCompositionEnd={pm.endComposition}
                  onSubmit={pm.handleSendMessageButton}
                  menuOnClick={pm.meatballsClick}
                  menuOnClose={pm.meatballsClose}
                  onRecordingStartButtonClick={pm.onRecordingStartButtonClick}
                  onRecordingStopButtonClick={pm.onRecordingStopButtonClick}
                  anchorOrigin={pm.anchorOrigin}
                  transformOrigin={pm.transformOrigin}
                  isWebSearch={pm.isWebSearch}
                  chatModel={pm.chatModel}
                  handlePaste={pm.handlePaste}
                  images={pm.images}
                  imgClear={pm.imgClearOne}
                  inputFile={pm.inputFile}
                  newBtnTooltip={pm.t('chat.tooltip.newChat')}
                  isMobile={pm.isMobile}
                  handleSwitchWebSearch={pm.handleSwitchWebSearch}
                />
              </>
            ) : // データがない場合は以下を表示
            pm.existSelectId ? (
              <></>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack sx={{ alignItems: 'center' }}>
                    <Stack direction={'row'} spacing={2}>
                      <Grid pt={pm.isMobile ? 0 : 1}>
                        <ChatLogo size={45} themeMode={pm.themeMode} />
                      </Grid>
                      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                        <Typography
                          className="marsFont"
                          sx={{
                            paddingBottom: '0px',
                            fontSize: { xs: '30px', sm: '35px' },
                            fontWeight: '300',
                          }}>
                          Toyotsu GChat
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent={'center'}
                  sx={{ paddingTop: { xs: '10px', sm: '10px', md: '10px', lg: '20px' } }}>
                  <LayoutToggleButtons options={pm.chatVersionOptions} onChange={pm.onChangeOption} value={pm.value} />

                  <Tooltip
                    arrow
                    open={pm.gptTip.tooltip}
                    disableHoverListener
                    placement={'bottom'}
                    title={
                      <>
                        <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap' }} width={'320px'}>
                          {pm.t('chat.text.tooltip')}
                        </Typography>
                        <ClickAwayListener onClickAway={() => pm.gptTip.tooltipHandler(false)}>
                          <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap' }} width={'320px'}>
                            <Grid
                              container
                              direction={'row'}
                              onClick={() => {
                                pm.gptTip.modalHandler(true);
                                pm.gptTip.tooltipHandler(false);
                              }}>
                              {pm.t('chat.text.detailTip')}
                              <Grid sx={{ color: 'skyblue', textDecoration: 'underline', ':hover': { cursor: 'pointer' } }} component={'span'}>
                                {pm.t('chat.text.this')}
                              </Grid>
                            </Grid>
                          </Typography>
                        </ClickAwayListener>
                      </>
                    }>
                    <Typography component="span">
                      <IconButton size={'small'} sx={{ height: 40 }} onClick={() => pm.gptTip.tooltipHandler(!pm.gptTip.tooltip)} color="inherit">
                        <HelpOutlineIcon style={{ color: 'gray' }} />
                      </IconButton>
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid id="asdasd" container sx={{ position: 'static' }}>
                  <InputMessage
                    position={'static'}
                    isChat={false}
                    maxTokenLength={pm.maxTokenLength}
                    progress={pm.progress}
                    tokenLength={pm.tokenLength}
                    messageToken={pm.messageToken}
                    themeMode={pm.themeMode}
                    anchorElement={pm.anchorEl}
                    itemList={pm.itemList}
                    newButtonLabel={pm.newBtnLabel}
                    placeholder={pm.placeholder}
                    isLoading={pm.isLoading}
                    messageInputBoxValue={pm.inputMessage}
                    inputElement={pm.inputElement}
                    recordingStatus={pm.recordingStatus}
                    newBtnOnClick={pm.newBtnOnClick}
                    onChange={pm.handleInputChange}
                    // onKeyDown={pm.onKeyDownHandler}
                    // onCompositionStart={pm.startComposition}
                    // onCompositionEnd={pm.endComposition}
                    onSubmit={pm.handleSendMessageButton}
                    menuOnClick={pm.meatballsClick}
                    menuOnClose={pm.meatballsClose}
                    onRecordingStartButtonClick={pm.onRecordingStartButtonClick}
                    onRecordingStopButtonClick={pm.onRecordingStopButtonClick}
                    anchorOrigin={pm.anchorOrigin}
                    transformOrigin={pm.transformOrigin}
                    isWebSearch={pm.isWebSearch}
                    chatModel={pm.chatModel}
                    plugin={pm.plugin}
                    chatMain={true}
                    handlePaste={pm.handlePaste}
                    images={pm.images}
                    imgClear={pm.imgClearOne}
                    inputFile={pm.inputFile}
                    isMobile={pm.isMobile}
                    handleSwitchWebSearch={pm.handleSwitchWebSearch}
                  />
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justifyContent={'center'}
                  sx={{
                    alignItems: 'center',
                    width: '100%',
                    maxWidth: '800px',
                    paddingBottom: 1,
                  }}>
                  {pm.prompts.list.map((prompt, i) => (
                    <PromptCard
                      key={i}
                      onClick={() => pm.prompts.hook.handleClick(prompt)}
                      title={prompt.title}
                      usage={pm.t('prompts.text.usage')}
                      usedHistory={prompt.usedHistory}
                      tags={prompt.tags}
                    />
                  ))}
                  <Button variant="text" component={RouterLink} to={PATH_PROMPT.root} disableRipple={true}>
                    <AutoModeIcon></AutoModeIcon> {pm.promptShowMessage}
                  </Button>
                </Grid>
              </>
            )}
          </Stack>
          {pm.gptTip.modal && (
            <GptDetailModal
              title={pm.t('chat.text.modalTitle')}
              closeEvent={() => pm.gptTip.modalHandler(false)}
              flag={pm.gptTip.modal}
              models={pm.chatVersionOptions}
              device={pm.isMobile}
            />
          )}
        </Stack>
        {pm.prompts.hook.showModal && (
          <PromptModal
            label={pm.prompts.hook.buttonLabel}
            modalTitlePrompt={pm.prompts.hook.modalTitlePrompt}
            modalTitleSample={pm.prompts.hook.modalTitleSample}
            open={pm.prompts.hook.showModal}
            closeModal={pm.prompts.hook.closeModal}
            title={pm.prompts.hook.title}
            sampleResponse={pm.prompts.hook.sampleResponse}
            prompt={pm.prompts.hook.prompt}
            tags={pm.prompts.hook.tags}
            inputMessage={pm.prompts.input}
            promptId={pm.prompts.hook.promptCategory}
            isChat={true}
            replace={pm.prompts.hook.replace}
          />
        )}
      </Container>
    </Page>
  );
};
