import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect, ChangeEvent, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiError } from 'src/hooks/useApiError';
import useAuth from 'src/hooks/useAuth';
import { useChatGPT } from 'src/hooks/useChatGPT';
import { LANGS_CODE, useLocales } from 'src/hooks/useLocales';
import { useMinutes } from 'src/hooks/useMinutes';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { ChatGpt, OptionChatType } from 'src/models/chat-gpt';
import { RootState } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { actions as minutesPageActions } from '../../redux/slices/minutesPageSlice';
import { Typewriter, useTypewriter } from 'src/hooks/useTypewriter';
import * as Language from 'src/models/language';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { inputTokenLength } from 'src/utils/calculateToken';

/**
 * 議事録の文字起こしページ用のViewModel
 * @returns 公開している関数/状態
 */
export const useMinutesMinutesPageModel = () => {
  const store = useSelector((state: RootState) => state.minutesPageState);
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const auth = useAuth();
  const minutes = useMinutes(auth.user);
  const chat = useChatGPT();
  const { t } = useTranslation();
  const apiError = useApiError(t);
  const locale = useLocales();

  const minutesText = useTypewriter({});

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [currentSummarizeService, setCurrentSummarizeService] = useState<OptionChatType>(minutes.summarizeServiceList[0]);
  const [currentMinutesOutputLang, setCurrentMinutesOutputLang] = useState(minutes.inputLanguages[0]);

  /**
   * 初期データの取得
   */
  useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  /**
   * 文字起こしテキストエリアからフォーカスが外れた時の処理
   * @param e イベントオブジェクト
   */
  const onFocusLeaveTranscribedText = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(minutesPageActions.setTranscribedText(e.target.value));
  };

  /**
   * 文字起こしテキストエリアの文字が変更された時の処理
   * @param e イベントオブジェクト
   */
  const onChangeTranscribedText = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // MEMO: ここは一度に変更される文字数が多いので、store(persistent)ではなくstateで管理し、leaveした時にstoreへ格納した方がパフォーマンスが良いかもしれない
    dispatch(minutesPageActions.setTranscribedText(e.target.value));
  };

  /**
   * 要約サービスが変更された時の処理
   * @param e イベントオブジェクト
   */
  const onChangeSummarizeService = (e: SelectChangeEvent) => {
    if (e.target.value) {
      const results = minutes.summarizeServiceList.filter((item) => e.target.value === item.label);
      if (results.length === 1) {
        setCurrentSummarizeService(() => results[0]);
      }
    }
  };

  /**
   * 出力言語が変更された時の処理
   * @param e イベントオブジェクト
   */
  const onChangeMinutesOutputLang = (e: SelectChangeEvent) => {
    if (e.target.value) {
      const results = minutes.inputLanguages.filter((item) => e.target.value === displayMinutesOutputLang(item));
      if (results.length === 1) {
        setCurrentMinutesOutputLang(() => results[0]);
      }
    }
  };

  /**
   * 『議事録の作成』ボタン押下時の処理
   */
  const onClickCreateMinutes = async () => {
    try {
      setIsGenerating(() => true);
      // モデルの取得
      const model = currentSummarizeService;
      if (!model) {
        // FIXME: サポートしていないという言い回しの方が好ましいかもしれない
        snackbar.error(t('minutes.minutes.message.failedToRetrieveLlmModel'));
        return;
      }

      // 入力文字列の確認
      if (store.transcribedText === '') {
        snackbar.error(t('minutes.minutes.message.transcribedTextNotFound'));
        return;
      }

      // プロンプトの取得
      const prompt = await createPrompt(store.transcribedText, currentMinutesOutputLang);
      if (!prompt) {
        snackbar.error(t('minutes.minutes.message.failedToRetrievePrompt'));
        return;
      } else {
        const token = await inputTokenLength({ encoding: model.encoding, message: prompt });
        if (model.maxTokenLength < token) {
          snackbar.error(t('minutes.message.error.tokenSizeLimitExceeded', { additional: t(`minutes.message.error.additional.${model.encoding}`) }));
          return;
        }
      }

      // タイプライターの取得
      const typewriter = await getTypewriter();
      if (!typewriter) {
        // FIXME: 議事録作成に失敗したというメッセージの方がよいかもしれない
        snackbar.error(t('minutes.minutes.message.failedToRetrieveTypewriter'));
        return;
      }

      // メッセージ取得時アクションの取得
      const action = await getGenerateMinutesAction();
      if (!action) {
        // FIXME: 議事録作成に失敗したというメッセージの方がよいかもしれない
        snackbar.error(t('minutes.minutes.message.failedToRetrieveGeneratMinutesAction'));
        return;
      }

      // 既存出力のクリア
      await clearGeneratedText();

      // 生成処理
      // 注意：タイプライターの出力が終わるまで待機する
      await generateMinutes(model, prompt, typewriter, action).catch((error) => {
        const message = apiError.getErrorMessage(error, model.encoding);
        snackbar.error(message);
        typewriter.abort();
        return;
      });
    } finally {
      setIsGenerating(() => false);
    }
  };

  /**
   * 議事録作成機能用のプロンプトを生成するための関数
   * @param transcribedText 文字起こしされた文字列
   * @param outputLang 出力言語
   * @returns プロンプト文字列
   */
  const createPrompt = async (transcribedText: string, outputLang: Language.Language) => {
    if (transcribedText === '') {
      return null;
    }

    return await minutes.getGenerateMinutesPrompt(transcribedText, outputLang);
  };

  /**
   * タイプライターを取得するための関数
   * @returns タイプライター
   */
  const getTypewriter = async () => {
    return minutesText;
  };

  /**
   * メッセージ取得時のアクションを取得するための関数
   * @returns メッセージ取得時のアクション
   */
  const getGenerateMinutesAction = async () => {
    return minutesPageActions.addMinutesText;
  };

  /**
   * 既存の議事録作成結果をクリアするための関数
   */
  const clearGeneratedText = async () => {
    dispatch(minutesPageActions.clearMinutesText());
  };

  /**
   * 議事録を生成するための関数
   * @param model 使用するAIのモデル
   * @param prompt GPTへ送るプロンプト
   * @param typewriter タイプライター
   * @param action メッセージ取得時のアクション
   */
  const generateMinutes = async (model: ChatGpt, prompt: string, typewriter: Typewriter, action: ActionCreatorWithPayload<any, any>) => {
    for await (const msg of chat.stream(model, prompt)) {
      // GPTの返答に```が含まれるケースがある
      // ```が含まれると、Markdownエディタの枠をはみ出して表示されるため、`は除外するようにする
      typewriter.typing(msg, (char) => dispatch(action(char !== '`' ? char : '')));
    }
    await typewriter.waitForTyping();
  };

  /**
   * 議事録タブの内容を変更した時の処理
   * @param e イベントオブジェクト
   */
  const onChangeMinutesTabTextarea = async (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(minutesPageActions.setMinutesText(e.target.value));
  };

  /**
   * 議事録タブのフォーカスが外れた時の処理
   * @param e イベントオブジェクト
   */
  const onFocusLeaveMinutesTabTextarea = async (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(minutesPageActions.setMinutesText(e.target.value));
  };

  /**
   * MinutesOutputLangの表示名取得用関数
   * @param lang 言語
   * @returns 表示名
   */
  const displayMinutesOutputLang = (lang: Language.Language) => {
    const isJapanese = locale.currentLang.code === LANGS_CODE.JP;
    return isJapanese ? lang.displayJP : lang.displayEN;
  };

  useEffect(() => {
    console.log(store.transcribedText);
  }, []);
  return {
    t,
    // State
    transcribedText: store.transcribedText,
    currentSummarizeService,
    minutes,
    isGenerating,
    minutesText: store.minutesText,
    currentMinutesOutputLang,
    // Callback functions
    onFocusLeaveTranscribedText,
    onChangeTranscribedText,
    onChangeSummarizeService,
    onchangeMinutesOutputLang: onChangeMinutesOutputLang,
    onClickCreateMinutes,
    onChangeMinutesTabTextarea,
    onFocusLeaveMinutesTabTextarea,
    // Dsiplay formatter
    displayMinutesOutputLang,
  };
};
