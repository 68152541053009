import { TranscribeLanguage, langAuto, langEnglish, langJapan } from './transcribe-language';

export interface TranscribeService {
  readonly name: 'Whisper';

  /** サポート対象の言語 */
  readonly langs: TranscribeLanguage[];
}

export interface TranscribeServiceSet {
  readonly service: TranscribeService;
}

/**
 * Azure (Whisper)
 */
export const azureWhisper: TranscribeServiceSet = {
  service: {
    name: 'Whisper',
    langs: [langAuto, langJapan, langEnglish], // 言語選択不要
  },
};
