import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Drawer, Typography } from '@mui/material';
// hooks
import { useResponsive } from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// config
import { NAVBAR } from '../../../config';
// components
import { Scrollbar } from 'src/components-minimals/Scrollbar';
import { NavSectionVertical } from '../../nav-section';
//
import sidebarConfig from './NavConfig';
import 'src/css/font.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useChatMessageContext } from '../../../contexts/ChatMessageContext';
import { CHAT_TAKE_COUNT } from 'src/constants';
import { mainChatAPI } from 'src/apis/mainChatAPI';
import { PATH_CHAT } from 'src/routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

/**
 *
 * @param  param0 パラメータ
 * @return コンポーネント
 */
export const NavbarVertical = ({ isOpenSidebar, onCloseSidebar }: Props) => {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const dashboardElement = useRef<null | HTMLDivElement>(null); // スクロールする先、ポイント
  const [isReload, setIsReload] = useState<boolean>(false);
  const [isReloadEnd, setIsReloadEnd] = useState<boolean>(false);
  const [isAllLoaded, setIsAllLoaded] = useState<boolean>(false);
  const { handleChatLog, chatLog, handleSkipCount, skipCount, isDelete } = useChatMessageContext();

  const { isCollapse, collapseClick, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const setReload = (divElement: HTMLDivElement) => {
    // スクロールが1番下になった時
    if (divElement.scrollHeight - divElement.scrollTop === divElement.clientHeight) {
      setIsReload(true);
    }
  };

  useEffect(() => {
    // 初回のみサイドバーが閉じている場合にデータ取得を行う
    setIsReload(true);
  }, []);

  // チャット削除後にもスクロールイベントが走るようにfalseにリセットしておく
  useEffect(() => {
    setIsReload(false);
    setIsAllLoaded(false);
  }, [isDelete]);

  useEffect(() => {
    if (isReload) {
      setIsReloadEnd(true);
    }
  }, []);

  useEffect(() => {
    const divElement = dashboardElement.current;
    if (!divElement) return;

    // scrollされた時のイベント
    const handleScroll = () => {
      setReload(divElement);
    };

    // scrollのイベントをセット
    divElement.addEventListener('scroll', handleScroll);
    setReload(divElement);

    return () => {
      divElement.removeEventListener('scroll', handleScroll);
    };
  }, [dashboardElement.current]);

  useEffect(() => {
    // すでに全て取得済みであれば処理しない
    if (isAllLoaded) return;
    if (isReload) {
      // 取得
      setIsReloadEnd(false);
      mainChatAPI
        .getChatLogs(CHAT_TAKE_COUNT, skipCount)
        .then((res) => {
          if (res.data.length > 0) {
            setTimeout(() => {
              handleChatLog([...chatLog, ...res.data]);
              handleSkipCount(skipCount + CHAT_TAKE_COUNT);
              setIsReloadEnd(true);
              setIsReload(false);
            }, 10);
          } else {
            setIsAllLoaded(true);
            setIsReloadEnd(true);
            setIsReload(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsAllLoaded(true);
          setIsReloadEnd(true);
          setIsReload(false);
        });
    } else {
      const divElement = dashboardElement.current;
      if (!divElement) return;
      // スクロールが1番下になった時
      if (divElement.scrollHeight - divElement.scrollTop === divElement.clientHeight) {
        setIsReload(true);
      }
    }
  }, [isReload]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
      scrollableNodeProps={{ ref: dashboardElement }}>
      <NavSectionVertical navConfig={sidebarConfig()} isCollapse={isCollapse} onClick={onCloseSidebar} />
      {chatLog.length > 0 && !isReloadEnd && <FontAwesomeIcon icon={faSpinner} spin />}
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}>
      {pathname === PATH_CHAT.main && (
        <>
          {!isDesktop && (
            <Drawer open={isOpenSidebar} variant="persistent" onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
              {' '}
              {renderContent}
            </Drawer>
          )}

          {isDesktop && (
            <Drawer
              open
              variant="persistent"
              onMouseEnter={onHoverEnter}
              onMouseLeave={onHoverLeave}
              PaperProps={{
                sx: {
                  width: NAVBAR.DASHBOARD_WIDTH,
                  borderRightStyle: 'dashed',
                  bgcolor: 'background.default',
                  transition: (theme) =>
                    theme.transitions.create('width', {
                      duration: theme.transitions.duration.standard,
                    }),
                  ...(isCollapse && {
                    width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                  }),
                },
              }}>
              {/* スクロールの配置を下にずらすと履歴が見切れるため「Toyotsu GChat」のテキストをスクロール外に再配置 */}
              <Typography
                className="marsFont"
                sx={{
                  margin: "13px",
                  paddingLeft: "21px",
                  fontSize: "16px",
                  fontWeight: "bold"
                }}>
                Toyotsu GChat
              </Typography>
              {renderContent}
            </Drawer>
          )}
        </>
      )}
    </RootStyle>
  );
};
