import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_MINUTES } from 'src/routes/paths';
import { useApiError } from 'src/hooks/useApiError';
import useAuth from 'src/hooks/useAuth';
import { useMinutes } from 'src/hooks/useMinutes';
import useRecorder2 from 'src/hooks/useRecorder2';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { TranscribeLanguage } from 'src/models/transcribe-language';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { useDispatch } from 'react-redux';
import { actions as minutesPageActions } from '../../redux/slices/minutesPageSlice';

/**
 * 議事録の文字起こしページ用のViewModel
 * @returns 公開している関数/状態
 */
export const useMinutesTranscribingPageModel = () => {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const recorder = useRecorder2();
  const auth = useAuth();
  const minutes = useMinutes(auth.user);
  const { t } = useTranslation();
  const apiError = useApiError(t);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notify, setNotify] = useState<string>('');
  const [notifySubText, setNotifySubText] = useState<string>('');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isOpenRecordingDialog, setIsOpenRecordingDialog] = useState<boolean>(false);
  const currentTranscribeService = minutes.transcribeServiceList[0];
  // prettier-ignore
  const [currentTranscribeInputLang, setCurrentTranscribeInputLang] = useState<TranscribeLanguage>(currentTranscribeService.service.langs[0]);

  /**
   * 初期データの取得
   */
  useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  /**
   * 文字起こしする処理
   * @param file オーディオファイル
   */
  const onTranscribe = async (file: File) => {
    setNotify(() => t('transcribe.notify.uploading'));
    // TODO: ひとまず日本語対応のみ
    setNotifySubText(() => '(60分のファイルで約3~10分程度かかります)');
    setIsLoading(() => true);

    await minutes
      .transcribe(file, currentTranscribeService.service, 1200, currentTranscribeInputLang, /* onNotify */ setNotify)
      .then((result) => {
        console.log(result);
        dispatch(minutesPageActions.setTranscribedText(result));
        navigate(PATH_MINUTES.minutes, { replace: false });
      })
      .catch((error) => {
        console.error(error);
        const message = apiError.getErrorMessage(error);
        snackbar.error(message);
      })
      .finally(() => {
        setNotify('');
        setNotifySubText('');
        setIsLoading(() => false);
      });
  };

  /**
   * 録音ダイアログが開かれた時の処理
   * ダイアログが表示されると同時に録音が開始される
   */
  const onRecordingDialogOpen = async () => {
    setIsOpenRecordingDialog(() => true);
    await onRecordingStart();
  };

  /**
   * 録音ダイアログが閉じられた時の処理
   */
  const onRecordingDialogClose = async () => {
    if (isRecording) {
      snackbar.error(t('minutes.minutes.message.pleaseStopTheRecording'));
    } else {
      setIsOpenRecordingDialog(() => false);
    }
  };

  /**
   * 録音開始ボタンが押された時の処理
   * マイクの許可がされていないなどの場合はスナックバーの表示とダイアログが閉じられる
   */
  const onRecordingStart = async () => {
    try {
      recorder.start();
      setIsRecording(() => true);
    } catch (error) {
      console.error(error);
      snackbar.error(t('minutesPage.message.failedToStartRecording'));
      onRecordingDialogClose();
    }
  };

  /**
   * 録音停止ボタンが押された時の処理
   * 録音正常に停止した場合、ダイアログが閉じられる
   */
  const onRecordingStop = async () => {
    try {
      const file = await recorder.stop();
      setIsRecording(() => false);
      if (file !== null) {
        onTranscribe(file);
        setIsOpenRecordingDialog(() => false);
      }
    } catch (error) {
      console.error(error);
      snackbar.error(t('minutesPage.message.failedToStopRecording'));
    }
  };

  /**
   * 文字起こしの入力言語が切り替わった時の処理
   * @param e イベントオブジェクト
   */
  const onChangeCurrentTranscribeInputLang = (e: SelectChangeEvent<string>) => {
    if (e.target.value) {
      const langs = currentTranscribeService.service.langs.filter((lang) => lang.fullname === e.target.value);
      if (langs.length === 0) {
        snackbar.error(t('minutesPage.message.unsupportedLanguageHasBeenSelected'));
      } else if (langs.length === 1) {
        setCurrentTranscribeInputLang(() => langs[0]);
      }
    }
  };

  return {
    t,
    // State
    currentTranscribeService,
    currentTranscribeInputLang,
    minutes,
    notify,
    notifySubText,
    isLoading,
    isRecording,
    isOpenRecordingDialog,
    // Callback functions
    onTranscribe,
    onRecordingDialogOpen,
    onRecordingDialogClose,
    onRecordingStart,
    onRecordingStop,
    onChangeCurrentTranscribeInputLang,
  };
};
