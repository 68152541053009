import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { USER_ROLES } from 'src/constants';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';
import HomePage from 'src/pages/home-page/HomePage';

export const homeRoute = {
  path: 'home',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER, USER_ROLES.GPT_USER]}>
          <HomePage />
        </RoleBasedGuard>
      ),
    },
  ],
};
