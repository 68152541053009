import { Navigate } from 'react-router-dom';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';
import { PATH_PROMPT } from '../paths';
import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { USER_ROLES } from 'src/constants';
import { PromptPage } from 'src/pages/prompt-page/PromptPage';

export const promptRoute = {
  path: 'prompt',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={PATH_PROMPT.page} replace />,
    },
    {
      path: 'page',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER, USER_ROLES.GPT_USER]}>
          <PromptPage />
        </RoleBasedGuard>
      ),
    },
  ],
};
