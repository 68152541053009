import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { PageTitle } from 'src/components/PageTitle';
import { Loading } from 'src/features/minutes/components/backdrop/Loading';
import { usePersonalDocumentCollectionPageModel } from './usePersonalDocumentCollectionPageModel';
import { BoxedGrid } from 'src/components-mars/ag-grid/BoxedGrid';
import { ValueGetterParams } from 'ag-grid-community';
import { DocumentFile } from 'src/models/document-file';
import dateToStringFormatter from 'src/components-mars/ag-grid/valueFormatter/dateToStringFormatter';
import { dowbleBtnCellRender } from 'src/components-mars/ag-grid/renderer/BtnCellRenderer';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import { LinearProgress } from '@mui/material';
import { grey } from '@mui/material/colors';

export const PersonalDocumentCollectionPage: React.FC = () => {
  const vm = usePersonalDocumentCollectionPageModel();

  return (
    <Page title={vm.locale.translate('document-personal-collection.text.pageTitle')}>
      {/* Backdrops */}
      <Loading isLoading={vm.isLoading || vm.listLoading} />

      {/* Dialogs */}
      <ConfirmDialog
        lang={vm.locale.currentLang.code}
        isOpen={vm.deleteConfirmDialog.isOpen}
        onCanceled={vm.deleteConfirmDialog.onCancel}
        onOk={vm.deleteConfirmDialog.onOk}
        cancelColor="inherit"
        cancelVariant="contained"
        okColor="error"
        okVariant="contained">
        <Typography variant={'body1'}>
          {vm.locale.translate('common.dialog.confirm.fileDeleted', { filename: vm.selectedDocumentFile?.fileName })}
        </Typography>
      </ConfirmDialog>

      {/* Content */}
      <Container maxWidth={'md'}>
        <PageTitle text={vm.locale.translate('document-personal-collection.text.pageTitle')} />
        <Stack spacing={1}>
          <Grid container justifyContent={'space-between'}>
            <Grid item md={8}>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {vm.locale.translate('document-personal-collection.text.pageExplanation')}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Button
                variant="contained"
                children={vm.locale.translate('document-personal-collection.text.registration')}
                fullWidth
                onClick={vm.onClickedGotoDocumentRegistrationPageButton}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <BoxedGrid
                gridTheme={vm.agGridTheme}
                gridHeight={'65vh'}
                gridOptions={{ pagination: true }}
                defaultColDef={{ flex: 2, resizable: true, filter: true, sortable: true, floatingFilter: true }}
                columnDefs={[
                  {
                    headerName: vm.locale.translate('document-personal-collection.ag-grid.column.fileName'),
                    filter: 'agMultiColumnFilter',
                    field: 'fileName',
                  },
                  {
                    headerName: vm.locale.translate('document-personal-collection.ag-grid.column.size'),
                    filter: 'agMultiColumnFilter',
                    maxWidth: 163,
                    valueGetter: (data: ValueGetterParams<DocumentFile>) => {
                      return ((data.data?.size || 0) * vm.bytesToMegaBytes).toFixed(3);
                    },
                    cellClass: 'ag-right-aligned-cell',
                  },
                  {
                    headerName: vm.locale.translate('document-personal-collection.ag-grid.column.updatedAt'),
                    filter: false,
                    maxWidth: 178,
                    field: 'updatedAt',
                    valueFormatter: dateToStringFormatter,
                  },
                  {
                    headerName: '',
                    maxWidth: 100,
                    filter: false,
                    resizable: false,
                    suppressMenu: true,
                    cellRenderer: (arg: { data: DocumentFile }) => {
                      return dowbleBtnCellRender({
                        firstHandler: () => vm.onClickedDownloadButton(arg.data),
                        firstIcon: 'download',
                        secondHandler: () => vm.onClickedDeleteButton(arg.data),
                        secondIcon: 'delete',
                      });
                    },
                  },
                ]}
                rowData={vm.documentFiles}
              />
              <Grid mt={1}>
                <LinearProgress
                  id="StorageUsage"
                  sx={{ padding: '2px', marginX: '5px', height: 7, backgroundColor: vm.themeMode === 'light' ? grey[200] : grey[800] }}
                  variant="determinate"
                  value={Number((vm.usageStorage / vm.FOLDER_LIMIT) * 100)}
                />
                <Grid container xs={12} justifyContent={'space-between'}>
                  <Grid item xs={6}>
                    {vm.locale.translate('document-personal-collection.text.folderSize')}
                  </Grid>
                  <Grid item xs={6} container justifyContent={'right'}>
                    <>{`${(vm.usageStorage * vm.bytesToMegaBytes).toFixed(1)} MB / ${vm.FOLDER_LIMIT * vm.bytesToMegaBytes} MB`}</>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Page>
  );
};
