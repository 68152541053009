import { apiGateway, prefixApiGateway } from 'src/config';
import axios from 'src/utils/axios';
import { Auth } from 'aws-amplify';

// FIXME: 仮定義
// サンプルの実装が終わったら正式なモデルを定義すること
export interface TranslateResponse {
  message: string;
  fileIds: string[];
  threadId: string;
  downloadUrls: string[];
  type?: string;
}

export const translateAPI = {
  /**
   * CodeInterpreterのサンプル
   * @param props プロパティオブション
   * @returns 答え
   */
  call: async (props: { file: FileList | null; request: string; threadId: string }): Promise<TranslateResponse> => {
    const path = apiGateway + prefixApiGateway + '/translate';
    const formData = new FormData();
    if (props.file?.[0]) {
      formData.append(`file`, props.file[0]);
    }
    formData.append('request', props.request);
    formData.append('threadId', props.threadId);
    const data = await axios.post(path, formData).then((res) => res.data);
    return {
      message: data.message,
      fileIds: data.file_ids,
      threadId: data.threadId,
      downloadUrls: data.file_ids.map((id: string) => `${path}/${id}`),
    };
  },
  fileDownLoad: async (props: { url: string }) => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const resp = await fetch(props.url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  },
  clearThread: async (props: { threadId: string }) => {
    const path = apiGateway + prefixApiGateway + '/translate/clearThread';
    await axios.post(path, { threadId: props.threadId });
  },
};
