// routes
import { Router } from './routes';
// theme
import { ThemeProvider } from './theme';
// hooks
import useAuth from './hooks/useAuth';
// import { Settings } from './components/settings';
import { RtlLayout } from './components-mars/layouts/RtlLayout';
import { ScrollToTop } from './components-minimals/ScrollToTop';
import { LoadingScreen } from './components-minimals/LoadingScreen';
import { NotistackProvider } from './components-minimals/NotistackProvider';
import { ThemePrimaryColor } from './components-minimals/ThemePrimaryColor';
import { LicenseManager } from 'ag-grid-enterprise';
import { aggridLicenceKey, googleAnalyticsId, useGa } from './config';
import ReactGA from 'react-ga4';
import { app } from '@microsoft/teams-js';

import { SystemComment } from 'src/components/SystemComment';
/**
 * @return コンポーネント
 */
const App = () => {
  LicenseManager.setLicenseKey(aggridLicenceKey);

  const { isInitialized } = useAuth();
  // Google Analyticsの解析を行うか
  if (useGa) {
    ReactGA.initialize(googleAnalyticsId);
  }

  // iframeから表示される場合
  if (window !== window.parent) {
    // Initialize the Microsoft Teams SDK
    app
      .initialize()
      .then(() => {
        console.log('App.js: initializing client SDK initialized');
        app.notifyAppLoaded();
        app.notifySuccess();
      })
      .catch((error) => console.error(error));
  }

  return (
    <ThemeProvider>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            {/* <Settings /> */}
            <ScrollToTop />
            {isInitialized ? <Router /> : <LoadingScreen />}
            <SystemComment />
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeProvider>
  );
};
export default App;
