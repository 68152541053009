import { Grid } from '@mui/material';
import { MarkdownRenderer } from 'src/shared/components/MarkdownRenderer';
import React from 'react';
import { EditCancelButton } from './EditCancelButton';
import { EditIconButton } from './EditIconButton';
import { SpeakerIcon } from './SpeakerIcon';
import { useChatContents } from 'src/hooks/useChatContents';
import { ChatCompletionContentPartImage } from 'openai/resources';
// import { OriginImageList } from 'src/contexts/ChatMessageReducer';

interface ChatContentsProps {
  id: number;
  message: string | ChatCompletionContentPartImage;
  role: string;
  isLoading: boolean;
  selectId: string;
  handleSendUpdateMessageButton: (e: any, updatedMsg: string, index: number, isWebSearch: boolean) => Promise<void>;
  isWebSearch: boolean;
  // /*
  // 以前のメッセージがイメージなのかを確認するフラグ
  // イメージと一緒に投げたメッセージの場合、修正できない（Chat GPT）
  //  */
  // downloadImg: (value?: string) => void;
  // originImg?: OriginImageList[];
}

export const ChatContents = React.memo(
  ({
    id,
    message,
    role,
    isLoading,
    selectId,
    handleSendUpdateMessageButton,
    isWebSearch,
  }: // downloadImg,
  // originImg,
  ChatContentsProps): React.ReactElement => {
    const {
      editButtonRef,
      handleKeyPress,
      editBtnLabel,
      cancelBtnLabel,
      backColor,
      onChange,
      editableMsg,
      cancelHandler,
      sendHandler,
      iconFlag,
      editingFlag,
      editIconHandler,
      // イメージを表示するため
      // imgFlag,
      // imgText,
    } = useChatContents({
      role,
      message,
      id,
      selectId,
      handleSendUpdateMessageButton,
      isWebSearch,
    });
    return (
      <Grid
        key={id}
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        sx={{ paddingX: '20px', width: '100%', background: backColor, alignItems: 'center' }}>
        <Grid
          container
          sx={{ paddingTop: '20px', paddingBottom: '20px', maxWidth: '800px' }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={0}>
          <Grid item xs={2} sm={2}>
            <SpeakerIcon flag={iconFlag} />
          </Grid>
          <Grid item xs={9} sm={9}>
            <MarkdownRenderer onChange={onChange} isEditing={editingFlag} defaultMsg={message} onKeyDown={handleKeyPress}>
              {/* downloadImg={downloadImg}
               originImg={originImg}> */}
              {{ message: editableMsg, role: role }}
            </MarkdownRenderer>
          </Grid>
          {/* {!imgFlag && ( // イメージだった場合、イメージと繋ぐメッセージは編集不可 */}
          <Grid item xs={1} sm={1} container justifyContent={'center'}>
            <EditIconButton flag={iconFlag} onClick={editIconHandler} />
          </Grid>
          {/* )} */}
        </Grid>
        <EditCancelButton
          anchor={editButtonRef}
          flag={editingFlag}
          editLabel={editBtnLabel}
          editHandler={sendHandler}
          cancelLabel={cancelBtnLabel}
          cancelHandler={cancelHandler}
          isLoading={isLoading}
        />
      </Grid>
    );
  },
);
