import { encodingForModel } from 'js-tiktoken';
import { chatEncoding } from 'src/models/chat-gpt';
interface inputTokenLenthProps {
  encoding: chatEncoding;
  message: string;
}
export const inputTokenLength: (props: inputTokenLenthProps) => Promise<number> = async ({ encoding, message }: inputTokenLenthProps) => {
  return await new Promise((resolve) =>
    setTimeout(() => {
      const coding = encodingForModel(encoding);
      const token = coding.encode(message);
      resolve(token.length);
    }, 500),
  );
};
