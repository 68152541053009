import { Grid, Link } from '@mui/material';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { cssStyles } from 'src/utils/cssStyles';
import React from 'react';

export const SystemComment = React.memo(() => {
  const theme = useTheme();
  return (
    <Grid
      id="SystemComment"
      container
      sx={{
        // 上のメニューと同じスタイルを適用
        ...cssStyles(theme).bgBlur({ blur: 0.5, opacity: 0.8 }),
        alignItems: 'center',
        width: '100%',
        paddingBottom: 1,
        position: 'fixed',
        bottom: 0,
        justifyContent: 'center',
      }}>
      <Typography sx={{ paddingTop: '5px', fontSize: '10px', color: '#999999' }}>
        Toyotsu GChat is developed by{' '}
        <Link sx={{ color: 'gray' }} href="https://www.tsyscom.co.jp/">
          Toyotsu Syscom.
        </Link>
      </Typography>
    </Grid>
  );
});
