import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { translateAPI } from 'src/apis/translateAPI';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { TranslateResponse } from 'src/apis/translateAPI';
import { AssistantResponse } from 'src/apis/assistantsAPI';
import { useSnackbar } from 'src/hooks/useSnackbar';

export const useTranslatePageModel = () => {
  // STATE
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [request, setRequest] = useState<string>('');
  const [responses, setResponses] = useState<TranslateResponse[]>([]);
  const [files, setFile] = useState<FileList[]>([]);
  const [extension, setExtension] = useState<string>('');
  const [threadId, setThreadId] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [beforeLang, setBeforeLang] = useState<null | string>('日本語');
  const [afterLang, setAfterLang] = useState<null | string>('英語');

  const handleSwitchLang = () => {
    const temp = beforeLang;
    setBeforeLang(afterLang);
    setAfterLang(temp);
  };

  // HOOKS
  const snackbar = useSnackbar();

  // EVENT HANDLER
  /**
   * ユーザーのメッセージ入力欄のonChangeイベント
   */
  const requestOnChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      setRequest(event.target.value);
    },
    [request],
  );

  /**
   * ファイル追加
   */
  const fileChange = useCallback(async (_: string, value: any) => {
    setFile(value);
    if (value.length) setExtension(value[0][0].name.split('.').pop());
  }, []);

  /**
   * メッセージとファイルのデータを送信するボタンクリックのイベント
   */
  const AssistantsApiTestHandler = useCallback(async () => {
    try {
      setIsLoading(true);
      const promptTemplate = `アップロードした${extension}ファイルから${beforeLang}のテキストを抽出して下さい。
次に、外部ライブラリや外部APIを使用せず、手動でテキストを${afterLang}に翻訳して下さい。
最後に、テキストを翻訳後のテキストに差し替えた${extension}ファイルを出力して下さい。`;
      const resp = await translateAPI.call({ file: files?.[0], request: responses.length ? request : promptTemplate, threadId });
      Object.assign(resp, { type: 'assistants' });
      const nextResponses: TranslateResponse[] = [];
      if (responses.length) nextResponses.push({ message: request, type: 'user', fileIds: [], threadId: '', downloadUrls: [] });
      nextResponses.push(resp);
      setResponses((prevResponses) => [...prevResponses, ...nextResponses]);
      setThreadId(resp.threadId);
      setRequest('');
    } catch (e) {
      console.log(e);
      snackbar.error(String(e));
    } finally {
      setIsLoading(false);
    }
  }, [files, request, threadId, beforeLang]);

  const fileDownloadHandler = useCallback(
    async (url) => {
      const response = await translateAPI.fileDownLoad({ url });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `translated.${extension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [extension],
  );

  const clearThreadHandler = useCallback(async () => {
    try {
      console.log(threadId);
      setIsLoading(true);
      await translateAPI.clearThread({ threadId });
    } catch (e) {
      console.log(e);
      snackbar.error(String(e));
    } finally {
      setIsLoading(false);
    }
  }, [threadId]);

  // SIDE EFFECT
  useEffect(() => {
    dispatch(setSideBarMenu());
  });

  useEffect(() => {
    setDisabled(!request);
  }, [request]);
  useEffect(() => {
    setDisabled(!files.length);
  }, [files]);
  return {
    // state
    isLoading,
    disabled,
    request,
    responses,
    files,
    anchorEl,
    beforeLang,
    afterLang,
    // handler
    requestOnChange,
    fileChange,
    AssistantsApiTestHandler,
    fileDownloadHandler,
    setAnchorEl,
    clearThreadHandler,
    setBeforeLang,
    setAfterLang,
    handleSwitchLang,
  };
};
