import React, { useState } from 'react';
import { Button, Grid, List, ListItem, Typography, Dialog, Box, IconButton } from '@mui/material';
import { Mic } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

export interface TranscribingFromMicDialogProps {
  onClick: () => void;
}

/**
 * ファイルから文字起こしのダイアログ
 * @param props パラメータ
 * @returns レンダリングコンポーネント
 */
export const TranscribingFromMicDialog = (props: TranscribingFromMicDialogProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <Button fullWidth variant="contained" children={t('transcribe.content.micButton')} onClick={() => setIsOpen(true)} />

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Grid container direction={'column'} spacing={1}>
          <Grid item>
            <Box display="flex" justifyContent={'flex-end'}>
              <IconButton onClick={() => setIsOpen(false)} color="info">
                <CancelIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction={'column'} spacing={1} sx={{ pt: 6, pb: 10, pl: 2, pr: 2 }}>
              <Grid item>
                <Grid container justifyContent={'center'}>
                  <Grid item justifyContent={'center'} xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color={'error'}
                      startIcon={<Mic />}
                      children={t('transcribe.content.startRecording')}
                      onClick={() => {
                        setIsOpen(false);
                        props.onClick();
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent={'center'}>
                  <Grid item>
                    <List dense>
                      <ListItem dense>
                        <Typography variant="caption">・{t('transcribe.content.captions.caption1')}</Typography>
                      </ListItem>
                      <ListItem dense>
                        <Typography variant="caption">・{t('transcribe.content.captions.caption2')}</Typography>
                      </ListItem>
                      <ListItem dense>
                        <Typography variant="caption">・{t('transcribe.content.captions.caption3')}</Typography>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};
