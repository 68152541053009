import { Grid, IconButton, Input, InputBase, Paper, Stack, Tooltip, Typography } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import CircularProgress from '@mui/material/CircularProgress';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ImageIcon from '@mui/icons-material/Image';
import { RecordingStatus } from 'src/contexts/type/ChatMessageContextType';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImagesCarousel } from './ImagesCarousel';
import { ImageNum } from 'src/constants';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { CustomMenuItemProps } from './CustomMenuItem';
interface MessageBoxProps {
  placeholder: string;
  messageInputBoxValue: string;
  minRows: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  // onCompositionStart: () => void;
  // onCompositionEnd: () => void;
  onSubmit: (e: any) => void;
  onRecordingStartButtonClick: (e: any) => void;
  onRecordingStopButtonClick: (e: any) => void;
  isLoading: boolean;
  inputElement: React.RefObject<HTMLInputElement>;
  recordingStatus: RecordingStatus;
  handlePaste: React.ClipboardEventHandler<HTMLDivElement>;
  images: string[];
  imgClear: (item: string) => void;
  inputFile: React.ChangeEventHandler;
  isMobile: boolean;
  isWebSearch: boolean;
  handleSwitchWebSearch: (value: boolean) => void;
  plugin?: CustomMenuItemProps;
}

export const MessageBox = React.memo(
  ({
    placeholder,
    messageInputBoxValue,
    minRows,
    onChange,
    // onKeyDown,
    // onCompositionStart,
    // onCompositionEnd,
    onSubmit,
    onRecordingStartButtonClick,
    onRecordingStopButtonClick,
    isLoading,
    inputElement,
    recordingStatus,
    handlePaste,
    images,
    imgClear,
    inputFile,
    isMobile,
    isWebSearch,
    handleSwitchWebSearch,
    plugin,
  }: MessageBoxProps): React.ReactElement => {
    const { t } = useTranslation();

    const verticalPadding = 2;
    const [maxRows, setMaxRows] = React.useState(5);
    const elm = React.useRef<HTMLElement>(null);

    // // Web検索用の処理
    const [buttonColor, setButtonColor] = useState<'default' | 'primary'>('default');

    const pluginHandler = () => {
      setButtonColor((prevColor) => (prevColor === 'default' ? 'primary' : 'default'));
      if (plugin && plugin.onClick) {
        plugin.onClick(); // plugin の onClick 関数を呼び出す
      }
    };

    React.useLayoutEffect(() => {
      if (minRows > 1) {
        const updateSize = (): void => {
          let textFieldTop: number = 0;
          let lineHeight: number = 0;
          let textFieldPadding: number = 0;
          if (elm.current) {
            textFieldTop = elm.current?.getBoundingClientRect()?.top;
            const styles = window.getComputedStyle(elm.current);
            textFieldPadding = parseInt(styles.getPropertyValue('padding-top')) + parseInt(styles.getPropertyValue('padding-bottom'));
            lineHeight = parseInt(styles.getPropertyValue('line-height'));
          }
          if (textFieldTop && lineHeight > 0) {
            const footer: any = document.getElementById('SystemComment');
            const footerHeight = footer?.getBoundingClientRect().height ?? 0;
            const tokenUsageBarHeight = document.getElementById('TokenUsageSize')?.getBoundingClientRect().height ?? 0;
            const inputMessageBox = document.getElementById('InputMessagePaper');
            let inputMessageBoxPadding = 0;
            if (inputMessageBox) {
              inputMessageBoxPadding = parseInt(window.getComputedStyle(inputMessageBox)?.getPropertyValue('padding-bottom'));
            }
            const maxRows =
              Math.trunc(
                window.innerHeight - footerHeight - verticalPadding - inputMessageBoxPadding - tokenUsageBarHeight - textFieldPadding - textFieldTop,
              ) / lineHeight;
            if (maxRows >= minRows) {
              setMaxRows(maxRows);
            }
          }
        };

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
      }
    });

    return (
      <>
        <Paper
          id="InputMessagePaper"
          component="form"
          sx={{
            p: verticalPadding + 'px 4px',
            display: 'flex',
            flexDirection: 'column', // 수직 정렬을 위해 추가
            alignItems: 'stretch', // 수직 정렬을 위해 추가
            position: 'relative', // Make this a positioning context for absolute positioning
            boxShadow: '0px 3px 10px 6px rgba(0, 0, 0, 0.2)',
          }}>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            minHeight={'85px'}
            maxHeight={'85px'}
            sx={{ display: images.length === 0 ? 'none' : 'flex' }}
            justifyContent={'left'}
            mb={4}
            ml={3}>
            {images.length > 0 && <ImagesCarousel images={images} closeEvent={imgClear} isMobile={isMobile} />}
          </Grid>
          <InputBase
            ref={elm}
            id="MessageInputBox"
            sx={{
              ml: 1,
              flex: 1,
              minHeight: '38px',
            }}
            onPaste={handlePaste}
            placeholder={placeholder}
            value={messageInputBoxValue} // 追加 FIXME: 名前修正必要
            onChange={onChange} // 追加  FIXME: 名前修正必要
            // onKeyDown={onKeyDown} // FIXME: 名前修正必要
            // onCompositionStart={onCompositionStart}
            // onCompositionEnd={onCompositionEnd}
            multiline
            minRows={minRows}
            maxRows={maxRows}
            inputRef={inputElement}
          />
          <Stack direction="row" justifyContent="space-around" alignItems="stretch" spacing={0.5} sx={{ position: 'absolute', bottom: 0, right: 2 }}>
            {messageInputBoxValue.length === 0 && (
              <>
                {recordingStatus === 'STOPPING' ? (
                  <Tooltip title={t('chat.text.inputByVoice')} arrow>
                    <Typography component="span">
                      <IconButton
                        id="messageSubmitButton"
                        color="primary"
                        sx={{ position: 'relative', bottom: 0 }}
                        aria-label="messages"
                        onClick={onRecordingStartButtonClick}>
                        {recordingStatus === 'STOPPING' ? (
                          <MicIcon />
                        ) : recordingStatus === 'RECORDING' ? (
                          <StopCircleIcon color="error" />
                        ) : (
                          <CircularProgress size={20} />
                        )}
                      </IconButton>
                    </Typography>
                  </Tooltip>
                ) : (
                  <Tooltip title={recordingStatus === 'RECORDING' ? `録音を停止` : '文字情報生成中'} arrow>
                    <Typography component="span">
                      <IconButton
                        id="messageSubmitButton"
                        color="primary"
                        sx={{ position: 'relative', bottom: 0 }}
                        aria-label="messages"
                        onClick={onRecordingStopButtonClick}>
                        {recordingStatus === 'RECORDING' ? <StopCircleIcon color="error" /> : <CircularProgress size={20} />}
                      </IconButton>
                    </Typography>
                  </Tooltip>
                )}
              </>
            )}
            {images.length < ImageNum && (
              <Tooltip title={t(`chat.text.upload-file`)} arrow>
                <Typography component="span">
                  <label htmlFor="upload-file">
                    <Input
                      type="file"
                      style={{ display: 'none' }}
                      id="upload-file"
                      onChange={inputFile}
                      inputProps={{ multiple: true, accept: 'image/png, image/jpeg, image/webp, image/gif' }}
                    />
                    <IconButton id="fileUploadToMsgBoxButton" color="primary" sx={{ position: 'relative', bottom: 0 }} component="span">
                      <ImageIcon />
                    </IconButton>
                  </label>
                </Typography>
              </Tooltip>
            )}
            {plugin && (
              <Tooltip title={t(`chat.text.webSearch`)} arrow>
                <Typography component="span">
                  <IconButton
                    id="webSearchButton"
                    color={buttonColor}
                    sx={{ position: 'relative', bottom: 0 }}
                    component="span"
                    onClick={pluginHandler}>
                    <TravelExploreIcon />
                  </IconButton>
                </Typography>
              </Tooltip>
            )}
            <Tooltip title={t(`chat.text.messageSubmitButton`)} arrow>
              <Typography component="span">
                <IconButton
                  id="messageSubmitButton"
                  color="primary"
                  sx={{ position: 'relative', bottom: 0 }}
                  aria-label="messages"
                  onClick={onSubmit}
                  disabled={isLoading === true || messageInputBoxValue.length === 0}>
                  {isLoading ? <CircularProgress size={20} /> : <SendRoundedIcon />}
                </IconButton>
              </Typography>
            </Tooltip>
          </Stack>
        </Paper>
      </>
    );
  },
);
