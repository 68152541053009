import UseAuth from '../../../hooks/useAuth';
import i18n from '../../../locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { PATH_CHAT } from 'src/routes/paths';
// サイドバーの描画
export const chatBar = {
  subheader: i18n.t('chat.sidemenu.title'),
  items: [
    {
      title: i18n.t('chat.sidemenu.item'),
      path: PATH_CHAT.main,
      icon: <FontAwesomeIcon icon={faStar} size="lg" />,
    },
  ],
};

// import { USER_ROLES } from '../../../constants';

// ----------------------------------------------------------------------

// const sidebarConfigAdmin = [chatBar];
// const sidebarConfigPoweruser = [chatBar];
// const sidebarConfigUser = [chatBar];
const sidebarConfigDefault = [chatBar];

/**
 * ロールによってサイドバーのコンテンツを変更する
 * @return   サイドバーリスト
 */
const sidebarConfig = () => {
  const { user } = UseAuth();
  // console.log('user', user);
  if (user) {
    // const role = user.role;
    // const hasRolesArray: boolean = Array.isArray(role);
    // if (hasRolesArray && role.includes(USER_ROLES.SCAFFOLD_ADMIN)) {
    //   return sidebarConfigAdmin;
    // } else if (hasRolesArray && role.includes(USER_ROLES.SCAFFOLD_POWERUSER)) {
    //   return sidebarConfigPoweruser;
    // } else if (hasRolesArray && role.includes(USER_ROLES.SCAFFOLD_USER)) {
    //   return sidebarConfigUser;
    // } else {
    //   return sidebarConfigDefault;
    // }
    return sidebarConfigDefault;
  } else {
    return sidebarConfigDefault;
  }
};

export default sidebarConfig;
