import { Box, Container, InputBase, Paper, Grid } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { useVisionTestPageModel } from './useVisionTestPageModel';
import { CopyPanel } from 'src/components/CopyPanel';
import { LoadingButton } from '@mui/lab';
import Chat from '@mui/icons-material/Chat';
import { PageTitle } from 'src/components/PageTitle';
import { PageDescription } from 'src/components/PageDescription';
import { FileInputs } from 'src/components-mars/fileInput/FileInputs';
import { StyledAutocomplite } from 'src/components/StyledAutoComplite';

export const VisionTest = (): React.ReactElement => {
  const pm = useVisionTestPageModel();

  return (
    <Page title="GPT-4-Vision GChat">
      <Container maxWidth={'md'}>
        <Box display="flex" justifyContent="space-between">
          <PageTitle text={'GPT-4-Visionサンプル'} />
        </Box>
        <PageDescription
          text={'GChatのVisionを使うサンプルページです。visionに使うファイルとファイルの内容に関した質問を投げてください。'}
          sx={{ marginBottom: 1 }}
        />
        <Paper
          component="form"
          sx={{
            marginBottom: '10px',
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
          }}>
          <InputBase
            id="userMessageInput"
            sx={{
              ml: 1,
              flex: 1,
              minHeight: '38px',
            }}
            value={pm.request}
            onChange={pm.requestOnChange}
            placeholder={'ここに質問を入力してください。'}
            multiline
            minRows={5}
            maxRows={5}
          />
        </Paper>
        <Paper
          component="form"
          sx={{
            marginY: '15px',
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative', // Make this a positioning context for absolute positioning
            boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
          }}>
          <FileInputs setField={pm.fileChange} files={pm.files} />
        </Paper>
        <Grid container xs={12} direction="row" justifyContent="space-between">
          <Grid item xs={3}>
            <StyledAutocomplite
              label={'画像解釈'}
              size="small"
              fullWidth
              value={pm.vision.interpret}
              options={pm.vision.interpretOptions}
              display={pm.vision.displayInterpret}
              onChange={pm.vision.onChangedInterpret}
            />
          </Grid>
          <Grid item xs={2}>
            <LoadingButton
              onClick={pm.gptVisionTestHandler}
              startIcon={<Chat />}
              type="submit"
              variant="contained"
              fullWidth
              loading={pm.isLoading}
              disabled={pm.disabled}>
              {'質問'}
            </LoadingButton>
          </Grid>
        </Grid>
        <CopyPanel
          tooltipOpen={pm.copy.isOpenCopyTip}
          tooltipClose={pm.copy.handleCloseTip}
          tooltipTitle={pm.copy.copiedMsg}
          btnClick={pm.copy.handleCopyButtonClick}
          btnLabel={pm.copy.copyBtnLabel}
          contents={pm.response}
        />
      </Container>
    </Page>
  );
};
