export type Speed = '1.0' | '2.0' | '3.0';
export const defaultSpeed: Speed = '1.0';

export type Gender = 'male' | 'female';

export enum Country {
  JA = 'ja-JP',
  EN = 'en-US',
  FR = 'fr-FR',
  ES = 'es-ES',
  AR = 'ar-SY',
  ZH = 'zh-CN',
  HI = 'hi-IN',
  ID = 'id-ID',
  PT = 'pt-PT',
  DE = 'de-DE',
  IT = 'it-IT',
  TH = 'th-TH',
  KO = 'ko-KR',
  RU = 'ru-RU',
  MS = 'ms-MY',
  SW = 'sw-TZ',
  CS = 'cs-CZ',
  PL = 'pl-PL',
  TR = 'tr-TR',
}

export type Voice = {
  [key in Gender]: {
    [countryKey in Country]: string;
  };
};

// https://learn.microsoft.com/en-us/azure/ai-services/speech-service/language-support?tabs=tts
export const voice: Voice = {
  male: {
    [Country.JA]: Country.JA + '-KeitaNeural',
    [Country.EN]: Country.EN + '-GuyNeural',
    [Country.FR]: Country.FR + '-HenriNeural',
    [Country.ES]: Country.ES + '-AlvaroNeural',
    [Country.AR]: Country.AR + '-LaithNeural',
    [Country.ZH]: Country.ZH + '-YunxiNeural',
    [Country.HI]: Country.HI + '-MadhurNeural',
    [Country.ID]: Country.ID + '-ArdiNeural',
    [Country.PT]: Country.PT + '-DuarteNeural',
    [Country.DE]: Country.DE + '-ConradNeural',
    [Country.IT]: Country.IT + '-DiegoNeural',
    [Country.TH]: Country.TH + '-NiwatNeural',
    [Country.KO]: Country.KO + '-InJoonNeural',
    [Country.RU]: Country.RU + '-DmitryNeural',
    [Country.MS]: Country.MS + '-OsmanNeural',
    [Country.SW]: Country.SW + '-DaudiNeural',
    [Country.CS]: Country.CS + '-AntoninNeural',
    [Country.PL]: Country.PL + '-MarekNeural',
    [Country.TR]: Country.TR + '-AhmetNeural',
  },
  female: {
    [Country.JA]: Country.JA + '-NanamiNeural',
    [Country.EN]: Country.EN + '-JennyNeural',
    [Country.FR]: Country.FR + '-DeniseNeural',
    [Country.ES]: Country.ES + '-ElviraNeural',
    [Country.AR]: Country.AR + '-AmanyNeural',
    [Country.ZH]: Country.ZH + '-XiaoxiaoNeural',
    [Country.HI]: Country.HI + '-SwaraNeural',
    [Country.ID]: Country.ID + '-GadisNeural',
    [Country.PT]: Country.PT + '-RaquelNeural',
    [Country.DE]: Country.DE + '-KatjaNeural',
    [Country.IT]: Country.IT + '-ElsaNeural',
    [Country.TH]: Country.TH + '-PremwadeeNeural',
    [Country.KO]: Country.KO + '-SunHiNeural',
    [Country.RU]: Country.RU + '-SvetlanaNeural',
    [Country.MS]: Country.MS + '-YasminNeural',
    [Country.SW]: Country.SW + '-RehemaNeural',
    [Country.CS]: Country.CS + '-VlastaNeural',
    [Country.PL]: Country.PL + '-AgnieszkaNeural',
    [Country.TR]: Country.TR + '-EmelNeural',
  },
};
