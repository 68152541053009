import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

interface LinkBlockProps {
  children: React.ReactNode;
}

export const LinkBlock = React.memo(({ children, ...props }: LinkBlockProps) => {
  const theme = useTheme();
  const aStyles = makeStyles({
    a: {
      textDecoration: 'none',
      color: theme.palette.mode === 'dark' ? '#59ACFB' : 'blue',
      '&:visited': {
        color: theme.palette.mode === 'dark' ? '#DA80F7' : 'purple',
      },
    },
  });

  const classes = aStyles();

  return (
    <a className={classes.a} {...props}>
      {children}
    </a>
  );
});
