import { Navigate } from 'react-router-dom';
import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { ARROWED_TO_ACCESS_DOCUMENT_PERSONAL, ARROWED_TO_ACCESS_DOCUMENT_SEARCH, ARROWED_TO_ACCESS_DOCUMENT_UPLOAD } from 'src/constants';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';
import { PATH_DOC_SEARCH } from '../paths';
import { DocumentsUploadPage } from 'src/pages/document-upload-page/DocumentsUploadPage';
import { DocumentCollectionPage } from 'src/pages/document-collection-page/DocumentCollectionPage';
import { DocSearchPage } from 'src/pages/document-search-page/DocSearchPage';
import { PersonalDocSearchPage } from 'src/pages/personal-doc-search-page/PersonalDocSearchPage';
import { PersonalDocumentCollectionPage } from 'src/pages/personal-doc-collection-page/PersonalDocumentCollectionPage';
import { PersonalDocumentsUploadPage } from 'src/pages/personal-doc-upload-page/PersonalDocumentsUploadPage';

/**
 * ドキュメント検索の関連ロール
 * Topメニューの可視制御などに使用する
 */
// prettier-ignore
export const docSearchRoute = {
  path: 'doc-search',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={PATH_DOC_SEARCH.personalSearch} replace />,
    },
    {
      path: 'search', // ドキュメント検索
      element: (
        <RoleBasedGuard accessibleRoles={ARROWED_TO_ACCESS_DOCUMENT_SEARCH}>
          <DocSearchPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'collection', // ドキュメント一覧
      element: (
        <RoleBasedGuard accessibleRoles={ARROWED_TO_ACCESS_DOCUMENT_UPLOAD}>
          <DocumentCollectionPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'upload', // ドキュメント登録
      element: (
        <RoleBasedGuard accessibleRoles={ARROWED_TO_ACCESS_DOCUMENT_UPLOAD}>
          <DocumentsUploadPage />
        </RoleBasedGuard>
      ),
    },{
      path : "personal",
      children : [
        {
          path: "search",
          element: (
            <RoleBasedGuard accessibleRoles={ARROWED_TO_ACCESS_DOCUMENT_PERSONAL}>
              <PersonalDocSearchPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: "collection",
          element: (
            <RoleBasedGuard accessibleRoles={ARROWED_TO_ACCESS_DOCUMENT_PERSONAL}>
              <PersonalDocumentCollectionPage/>
            </RoleBasedGuard>
          ),
        },
        {
          path: "upload",
          element: (
            <RoleBasedGuard accessibleRoles={ARROWED_TO_ACCESS_DOCUMENT_PERSONAL}>
              <PersonalDocumentsUploadPage/>
            </RoleBasedGuard>
          ),
        }
      ]
    }
  ],
};

export const docSearchRouteRelease = {
  path: 'doc-search',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={PATH_DOC_SEARCH.personalSearch} replace />,
    },
    {
      path: 'personal',
      children: [
        {
          path: 'search',
          element: (
            <RoleBasedGuard accessibleRoles={ARROWED_TO_ACCESS_DOCUMENT_PERSONAL}>
              <PersonalDocSearchPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'collection',
          element: (
            <RoleBasedGuard accessibleRoles={ARROWED_TO_ACCESS_DOCUMENT_PERSONAL}>
              <PersonalDocumentCollectionPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'upload',
          element: (
            <RoleBasedGuard accessibleRoles={ARROWED_TO_ACCESS_DOCUMENT_PERSONAL}>
              <PersonalDocumentsUploadPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
  ],
};
