import { pythonGateway, prefixPythonGateway } from 'src/config';
import axios from 'src/utils/axios';

export const experimentalAPI = {
  functionCalling: async (props: { prompt: string }): Promise<string> => {
    const form = new FormData();
    form.append('prompt', props.prompt);
    return await axios.post<string>(pythonGateway + prefixPythonGateway + '/experiment/function-calling', form).then((resp) => resp.data);
  },
  dallE3: async (props: { prompt: string }): Promise<string> => {
    const body = {
      prompt: props.prompt,
    };
    return await axios.post<string>(pythonGateway + prefixPythonGateway + '/experiment/dall-e3', body).then((resp) => resp.data);
  },
};
