import { Stack, Box, Grid, Typography } from '@mui/material';
import { cssStyles } from 'src/utils/cssStyles';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { MessageBox } from './MessageBoxHome';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

interface InputMessageFormProps {
  placeholder: string;
  messageInputBoxValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  // onCompositionStart: () => void;
  // onCompositionEnd: () => void;
  onSubmit: (e: any) => void;
  isWebSearch: boolean;
  chatModel: string;
}

export const InputMessage = React.memo(
  ({
    placeholder,
    messageInputBoxValue,
    onChange,
    // onKeyDown,
    // onCompositionStart,
    // onCompositionEnd,
    onSubmit,
    isWebSearch,
    chatModel,
  }: InputMessageFormProps): React.ReactElement => {
    const theme = useTheme();
    return (
      <Stack
        sx={{
          // 上のメニューと同じスタイルを適用
          ...cssStyles(theme).bgBlur({ blur: 0.5, opacity: 0.8 }),
          alignItems: 'center',
          width: '100%',
          paddingBottom: 1,
          position: 'absolute',
          bottom: 0,
        }}>
        <Box sx={{ width: '100%', maxWidth: '800px', textAlign: 'center', paddingX: '10px', display: 'flex', flexDirection: 'column' }}>
          {messageInputBoxValue.length === 0 && (
            <Grid container height={'34px'} alignItems={'center'}>
              {isWebSearch && <TravelExploreIcon sx={{ padding: '5px', width: '34px', height: '34px' }} color={'disabled'} />}
              <Typography color={theme.palette.text.disabled} variant="subtitle2" pl={1}>
                {chatModel}
              </Typography>
            </Grid>
          )}
          <MessageBox
            placeholder={placeholder}
            messageInputBoxValue={messageInputBoxValue}
            onChange={onChange}
            // onKeyDown={onKeyDown}
            // onCompositionStart={onCompositionStart}
            // onCompositionEnd={onCompositionEnd}
            onSubmit={onSubmit}
          />
        </Box>
      </Stack>
    );
  },
);
