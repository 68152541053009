import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'src/redux/store';

export interface ChatGptState {
  isLoading: boolean;
  editingId: number | undefined;
}

export const initialState: ChatGptState = {
  isLoading: false,
  editingId: undefined,
};

const slice = createSlice({
  name: 'ChatGptStat',
  initialState,
  reducers: {
    startLoading(state: ChatGptState): void {
      state.isLoading = true;
    },
    stopLoading(state: ChatGptState): void {
      state.isLoading = false;
    },
    startEditing(state: ChatGptState, action: PayloadAction<number>): void {
      state.editingId = action.payload;
    },
    stopEditing(state: ChatGptState): void {
      state.editingId = undefined;
    },
  },
});

/**
 *
 * @return void
 */
export const startLoading =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.startLoading());
  };

/**
 *
 * @return void
 */
export const stopLoading =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.stopLoading());
  };

/**
 *
 * @param value 値
 * @return void
 */
export const startEditing =
  (value: number): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.startEditing(value));
  };

/**
 *
 * @return void
 */
export const stopEditing =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.stopEditing());
  };

export default slice;
export const { reducer, actions } = slice;
