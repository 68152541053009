import { useRef, useState } from 'react';
// material
import { Box, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

// components
import { MIconButton } from 'src/components-minimals/@material-extend';
import { MenuPopover } from 'src/components-minimals/MenuPopover';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useLocales } from 'src/hooks/useLocales';

// ----------------------------------------------------------------------
/**
 *
 * @return コンポーネント
 */
export const SupportPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const locale = useLocales();

  const manualHandler = () => {
    // window.open('manual link', '_blank');
    window.open('https://toyotsu.service-now.com/sp?id=search&spa=1&q=gchat&disableAllSuggestions=false&disableSpellCheck=false', '_blank'); // 開発環境
    setOpen(false);
  };

  const helpDeskHandler = () => {
    // window.open('helpdesk link', '_blank');
    const contactLink = `https://toyotsu.service-now.com/sp?id=sc_cat_item&sys_id=e9872a0a1bf19010c08433b5cc4bcbbc`;
    window.open(contactLink, '_blank'); // 開発環境
    setOpen(false);
  };
  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          fontSize: 30,
        }}>
        <QuestionMarkIcon fontSize="large" />
      </MIconButton>
      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          <MenuItem key={'manual'} onClick={manualHandler} sx={{ py: 1, px: 2.5 }}>
            <ListItemIcon children={<MenuBookIcon />} sx={{ fontWeight: 'bold' }} />
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{locale.translate('support.manual')}</ListItemText>
          </MenuItem>
          <MenuItem key={'helpdesk'} onClick={helpDeskHandler} sx={{ py: 1, px: 2.5 }}>
            <ListItemIcon children={<SupportAgentIcon />} sx={{ fontWeight: 'bold' }} />
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{locale.translate('support.help')}</ListItemText>
          </MenuItem>
        </Box>
      </MenuPopover>
    </>
  );
};
