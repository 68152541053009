import { useEffect, useState } from 'react';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { useTranslation } from 'react-i18next';
import { useSearchPrompt } from 'src/hooks/useSearchBar';
import { Prompts } from 'src/@types/prompt';
import { usePromptCard } from 'src/hooks/usePromptCard';
import { useChatMessageContext } from 'src/contexts/ChatMessageContext';
import { PromptsCategory } from 'src/@types/promptCategory';
import { promptAPI } from 'src/apis/promptsAPI';

export const usePromptPageModel = () => {
  const { t, i18n } = useTranslation();

  const pageTitle = t('prompts.examplePrompt.title');
  const pageDescription = t('prompts.examplePrompt.description');
  const promptUsageDefault = t('prompts.text.usage');

  const [promptList, setPromptList] = useState<Prompts[]>();

  const [timerId, setTimerId] = useState<number>(0);
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [categoryAll, setCategoryAll] = useState<PromptsCategory[]>();

  const { handleInputMessage, handleRegistPromptUsageHistory, handleRegistPromptUsageHistoryAsync } = useChatMessageContext();

  const { palceholder, searchText, handleInputValueChange, categoryLabel, category, handleCategoryValueChange } = useSearchPrompt();
  const {
    showModal,
    title,
    // contents,
    tags,
    prompt,
    sampleResponse,
    promptCategory,
    handleClick,
    closeModal,
    buttonLabel,
    modalTitlePrompt,
    modalTitleSample,
    replace,
    handleChatOpenButtonClick,
  } = usePromptCard({ handleInputMessage, handleRegistPromptUsageHistory, handleRegistPromptUsageHistoryAsync });

  useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  useEffect(() => {
    if (categoryList && categoryList.length === 0) {
      // カテゴリーの取得
      promptAPI
        .getCategory(i18n.language)
        .then((res) => {
          setCategoryAll(res.data);
          setCategoryList(res.data.map((item) => item.title));
        })
        .catch((err) => {
          console.log(err);
        });
    }
    let catId: string | undefined;
    if (categoryAll) {
      const categoryId = categoryAll.find((cat) => cat.title === category); // 入力からcategoryId取得
      catId = categoryId?.tagId.toString();
    }

    if (timerId !== 0) {
      // タイマーが走っていたらキャンセル
      clearTimeout(timerId);
      setTimerId(0);
    }
    // プロンプトの再検索
    const timer = setTimeout(async () => {
      // iOS safari対応 safariでキーボードを閉じた時になぜかsearchTextが空の検索が走った後に通常の検索が走る。そのため、空の検索を走らせないようにするためにsetTimeoutを使う
      await promptAPI
        .getPrompts(i18n.language, searchText, catId)
        .then((res) => {
          const { data } = res;
          setPromptList(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 200 /* ここは何msでもよい */);
    setTimerId(timer as unknown as number);
  }, [searchText, category, i18n.language]);

  return {
    pageTitle,
    pageDescription,
    palceholder,
    searchText,
    handleInputValueChange,
    categoryLabel,
    category,
    handleCategoryValueChange,
    categoryList,
    promptList,
    showModal,
    title,
    tags,
    prompt,
    replace,
    sampleResponse,
    promptCategory,
    handleClick,
    closeModal,
    buttonLabel,
    modalTitlePrompt,
    modalTitleSample,
    handleChatOpenButtonClick,
    promptUsageDefault,
  };
};
