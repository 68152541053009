import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { reducer as loginReducer } from '../slices/loginSlice';
import { reducer as sidebarSliceReducer } from '../slices/sidebarSlice';
import { reducer as docSearchSliceReducer } from '../slices/docSearchSlice';
import { reducer as dataSearchSliceReducer } from '../slices/dataSearchSlice';
import { reducer as chatGptReducer } from '../slices/chatGptSlice';
import { reducer as clipboardReducer } from '../slices/clipboardSlice';
import { reducer as minutesReducer } from '../slices/minutesPageSlice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  sidebarState: sidebarSliceReducer,
  docSearchState: docSearchSliceReducer,
  dataSearchState: dataSearchSliceReducer,
  loginState: loginReducer,
  chatGptState: chatGptReducer,
  clipState: clipboardReducer,
  minutesPageState: minutesReducer,
});

export { rootPersistConfig, rootReducer };
