import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
export type SourceType = { name: string; url: string; content: string; score: string }[] | [];
export interface DocSearchData {
  response: string;
  source: SourceType;
}

export const initialState: DocSearchData = {
  response: '',
  source: [],
};

const docSearchSlice = createSlice({
  name: 'DocSearchSlice',
  initialState,
  reducers: {
    /**
     * ドキュメント検索の結果を追加する
     * @param state state
     * @param actions action
     */
    addResponse(state: DocSearchData, actions): void {
      state.response = state.response + actions.payload;
    },
    /**
     * ドキュメント検索の結果をクリアする
     * @param state state
     */
    clearResponse(state: DocSearchData): void {
      state.response = '';
    },

    /**
     * ドキュメント取得元ソースをクリアする
     * @param state state
     */
    clearSource(state: DocSearchData): void {
      state.source = [];
    },

    /**
     * ドキュメント取得元ソースを登録する
     * @param state state
     * @param actions action
     */
    addSource(state: DocSearchData, actions): void {
      state.source = actions.payload;
    },
  },
});

/**
 * レスポンスをクリアする
 * @return void
 */
export const clearResponse =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(docSearchSlice.actions.clearResponse());
  };

/**
 * レスポンスを追加する
 * @param response 追加するレスポンス
 * @return void
 */
export const addResponse =
  (response: string): AppThunk =>
  (dispatch): void => {
    dispatch(docSearchSlice.actions.addResponse(response));
  };

/**
 * 出典を追加する
 * @param sources 追加するレスポンス
 * @return void
 */
export const addSource =
  (sources: [string]): AppThunk =>
  (dispatch): void => {
    dispatch(docSearchSlice.actions.addSource(sources));
  };

/**
 * 出典をクリアする
 * @return void
 */
export const clearSource =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(docSearchSlice.actions.clearSource());
  };

export default docSearchSlice.reducer;
export const { reducer, actions } = docSearchSlice;
