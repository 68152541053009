import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { useCopy } from 'src/hooks/useCopy';
import { useVision } from 'src/hooks/useVision';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';

export const useVisionTestPageModel = () => {
  // STATE
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [request, setRequest] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  const [files, setFile] = useState<FileList[]>([]);

  // HOOKS
  const copy = useCopy(response);
  const vision = useVision();

  // EVENT HANDLER
  /**
   * ユーザーのメッセージ入力欄のonChangeイベント
   */
  const requestOnChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      setRequest(event.target.value);
    },
    [request],
  );

  /**
   * ファイル追加
   */
  const fileChange = useCallback(async (_: string, value: any) => {
    setFile(value);
  }, []);

  /**
   * メッセージとファイルのデータを送信するボタンクリックのイベント
   */
  const gptVisionTestHandler = useCallback(async () => {
    try {
      setIsLoading(true);
      const responseData = await vision.send(files[0], request);
      setResponse(responseData);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [files, request]);

  // SIDE EFFECT
  useEffect(() => {
    dispatch(setSideBarMenu());
  });

  useEffect(() => {
    if (!request || files.length === 0) setDisabled(true);
    else if (disabled) setDisabled(false);
  }, [request, disabled, files]);
  return {
    // state
    isLoading,
    disabled,
    request,
    response,
    files,
    // hook
    copy,
    vision,
    // handler
    requestOnChange,
    fileChange,
    gptVisionTestHandler,
  };
};
