import { portalAPI } from 'src/apis/portalAPI';
import { OrganizationSegment, OrganizationClassification } from 'src/models/organization';

export const useOrganization = () => {
  /**
   * 組織分類から組織を取得するための関数
   * @param classification 検索条件とする組織分類
   * @returns 組織情報
   */
  const getSegmentsByClassification = async (classification: OrganizationClassification): Promise<OrganizationSegment[]> => {
    const orgDatas = await portalAPI.getPortalDatas({ classification: classification.id });
    return orgDatas.map((item) => {
      return {
        code: item.value,
        displayEng: item.titleEn,
        displayJpn: item.titleJp,
      };
    });
  };

  return {
    getSegmentsByClassification,
  };
};
