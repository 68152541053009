import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';

export interface ClipboardState {
  copiedText: string;
}

export const initialState: ClipboardState = {
  copiedText: '',
};

const slice = createSlice({
  name: 'ClipboardStat',
  initialState,
  reducers: {
    setCopiedText(state: ClipboardState, action: PayloadAction<string>): void {
      state.copiedText = action.payload;
    },
  },
});

/**
 * @param value value
 * @return void
 */
export const setCopiedText =
  (value: string): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.setCopiedText(value));
  };

export default slice;
export const { reducer, actions } = slice;
