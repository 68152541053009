import React from 'react';
import { useTranslation } from 'react-i18next';
import { visionAPI } from 'src/apis/visionAPI';
import { Vision } from 'src/models/ai';

export const useVision = () => {
  const translate = useTranslation();
  const AUTO: Vision = { id: 'auto', displayJpn: 'オート', displayEng: 'Auto' };
  const LOW: Vision = { id: 'low', displayJpn: '低い', displayEng: 'Low' };
  const HIGH: Vision = { id: 'high', displayJpn: '高い', displayEng: 'High' };

  const interpretOptions: Vision[] = [AUTO, LOW, HIGH];

  const [interpret, setInterpret] = React.useState<Vision>(AUTO);

  const onChangedInterpret = React.useCallback((value: Vision | null) => {
    setInterpret(() => value || AUTO);
  }, []);

  const displayInterpret = React.useCallback((value: Vision) => {
    const code = translate.i18n.language;
    return code === 'en' ? value.displayEng : value.displayJpn;
  }, []);

  const send = React.useCallback(
    async (file: FileList, request: string) => {
      return await visionAPI.call({ file: file, request, interpret: interpret.id });
    },
    [interpret],
  );

  return {
    interpretOptions,
    interpret,
    onChangedInterpret,
    displayInterpret,
    send,
  };
};
