import { Container } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { usePromptPageModel } from './usePromptPageModel';
import { PageTitle } from 'src/components/PageTitle';
import { PageDescription } from 'src/components/PageDescription';
import { SearchBarWithCategory } from 'src/components/SearchBarWithCategory';
import { PromptCard } from 'src/components/PromptCard';
import { Grid } from '@mui/material';
import { Prompts } from 'src/@types/prompt';
import { PromptModal } from 'src/components/PromptModal';
export const PromptPage = () => {
  const pm = usePromptPageModel();
  return (
    <Page title="ExamplePrompt">
      <Container maxWidth="md">
        <PageTitle text={pm.pageTitle} />
        <PageDescription text={pm.pageDescription} />
        <SearchBarWithCategory
          categoryValue={pm.category}
          label={pm.categoryLabel}
          searchText={pm.searchText}
          searchOnChange={pm.handleInputValueChange}
          categoryOnChange={pm.handleCategoryValueChange}
          list={pm.categoryList}
          placeholder={pm.palceholder}
        />
        <Grid container spacing={1} justifyContent={'flex-start'}>
          {pm.promptList?.map((item: Prompts, i) => (
            <PromptCard
              key={i}
              onClick={() => pm.handleClick(item)}
              title={item.title}
              // description={item.description}
              usage={pm.promptUsageDefault}
              usedHistory={item.usedHistory}
              tags={item.tags}
            />
          ))}
        </Grid>
        {pm.showModal && (
          <PromptModal
            label={pm.buttonLabel}
            modalTitlePrompt={pm.modalTitlePrompt}
            modalTitleSample={pm.modalTitleSample}
            open={pm.showModal}
            closeModal={pm.closeModal}
            title={pm.title}
            // contents={pm.contents}
            sampleResponse={pm.sampleResponse}
            prompt={pm.prompt}
            replace={pm.replace}
            tags={pm.tags}
            inputMessage={pm.handleChatOpenButtonClick}
            promptId={pm.promptCategory}
          />
        )}
      </Container>
    </Page>
  );
};
