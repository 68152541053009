import { DateTime } from 'luxon';
import type { ValueFormatterParams } from 'ag-grid-community';

/**
 * @param  params value
 * @return   formatに合わせた結果
 */
const dateToStringFormatter = (params: ValueFormatterParams): string => {
  const dateObject: Date = params.value;
  const dateTime: string = DateTime.fromJSDate(dateObject).toFormat('yyyy/MM/dd HH:mm:ss');
  return dateTime;
};

export default dateToStringFormatter;
