import { gptAPI } from 'src/apis/gptAPI';

export const useTextToSpeech = () => {
  const api = gptAPI();

  /**
   * テキストから音声を再生するための関数
   * @param text 再生するテキスト
   * @param options オプション
   * @param options.timeout タイムアウト時間 (defualt: 30000ms)
   * @param options.autoplay 自動再生するかどうか (default: true)
   * @param autoplay 自動再生するかどうか
   * @returns 音声データ
   */
  const tts = async (text: string, options?: { timeout?: number; autoplay?: boolean }): Promise<Blob> => {
    const timeout = options?.timeout ?? 30000;
    const autoplay = options?.autoplay ?? true;

    const wav = await api.tts(text, timeout);
    if (autoplay) {
      const audio = new Audio(URL.createObjectURL(wav));
      audio.play();
    }
    return wav;
  };

  return {
    tts,
  };
};
