import { Auth } from 'aws-amplify';
import { pythonGateway, prefixPythonGateway } from 'src/config';
import { updateProcess } from 'src/hooks/useDocumentMenu';
import { DocumentFile, DocumentFileResponse } from 'src/models/document-file';
import { OrganizationClassification, OrganizationSegment } from 'src/models/organization';
import axios from 'src/utils/axios';

export const documentAPI = {
  /**
   * ドキュメントファイルをアップロードするための関数
   * @param props プロパティオブション
   * - props.files アップロードするドキュメントファイル
   * - props.tag タグ
   * - props.classification カテゴリ
   * - props.headOffice 不明
   * @returns アップロードしたドキュメントファイルのS3キー
   */
  uploadFiles: async (props: { files: File[]; tag: string; classification: string; headOffice: string }): Promise<void> => {
    const formData = new FormData();
    props.files.forEach((item) => formData.append(`files`, item));
    formData.append('tag', props.tag);
    formData.append('classification', props.classification);
    formData.append('headOffice', props.headOffice);
    await axios.post(pythonGateway + prefixPythonGateway + '/store/documents', formData);
  },

  uploadPersonalFiles: async (props: { files: File[] }): Promise<void> => {
    const formData = new FormData();
    props.files.forEach((item) => formData.append(`files`, item));
    await axios.post(pythonGateway + prefixPythonGateway + '/store/personalDocuments', formData);
  },

  uploadPersonalFilesStreaming: async (props: { files: File[] }): Promise<void> => {
    const formData = new FormData();
    props.files.forEach((item) => formData.append(`files`, item));

    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    let reader: ReadableStreamDefaultReader<Uint8Array> | undefined;
    try {
      const response = await fetch(pythonGateway + prefixPythonGateway + '/store/personalDocuments', {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });
      reader = response.body?.getReader();
      if (!reader) throw new Error(response.statusText);
      const decoder = new TextDecoder('utf-8');
      let buffer = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf('\n')) !== -1) {
          const completeChunk = buffer.slice(0, boundaryIndex);
          buffer = buffer.slice(boundaryIndex + 1);

          if (completeChunk.trim()) {
            try {
              const parsed = JSON.parse(completeChunk);
              if (parsed.error) {
                throw new Error(parsed.error);
              }
            } catch (e) {
              console.error(e);
              throw e;
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   * アップロードファイルの中身を文字列化したものを取得するための関数
   * @param props プロパティオブション
   * - props.file ドキュメントの生データ
   * @returns ドキュメントファイルの内容を文字列化したもの
   */
  getContentsString: async (props: { file: File }): Promise<string> => {
    const form = new FormData();
    form.append('file', props.file);
    return await axios.post(pythonGateway + prefixPythonGateway + '/loaders/documents', form).then((res) => res.data);
  },

  /**
   * ドキュメントファイルの一覧を取得するための関数
   * @param props プロパティオブション
   * - props.classification 組織分類
   * - props.segment 組織セグメント
   * - props.includeTag タグを含めるかどうかのフラグ
   * @returns ドキュメントファイルの一覧
   */
  getByOrganization: async (props: {
    classification: OrganizationClassification;
    segment: OrganizationSegment | null;
    includeTag?: boolean;
  }): Promise<DocumentFile[]> => {
    const type = props.classification.id;
    const code = props.segment?.code ?? null;
    const includeTag = Boolean(props.includeTag ?? false);
    const data = await axios
      .get<DocumentFileResponse[]>(pythonGateway + prefixPythonGateway + `/store/documents?type=${type}&code=${code}&include_tag=${includeTag}`)
      .then((res) => res.data);
    return data.map((doc) => ({
      fileName: doc.fileName,
      updatedAt: new Date(doc.updatedAt),
      storage: {
        key: doc.key,
        tags: doc.tag ? [doc.tag] : [],
      },
      size: doc.size,
    }));
  },

  /**
   * ドキュメントファイルの一覧を取得するための関数
   * @param props プロパティオブション
   * - props.includeTag タグを含めるかどうかのフラグ
   * @returns ドキュメントファイルの一覧
   */
  getByUserID: async (): Promise<DocumentFile[]> => {
    const data = await axios.get<DocumentFileResponse[]>(pythonGateway + prefixPythonGateway + `/store/personalDocuments`).then((res) => res.data);
    return data.map((doc) => ({
      fileName: doc.fileName,
      updatedAt: new Date(doc.updatedAt),
      storage: {
        key: doc.key,
        tags: doc.tag ? [doc.tag] : [],
      },
      size: doc.size,
    }));
  },

  /**
   * ドキュメントファイルの署名付きURLを取得するための関数
   * @param props プロパティオブション
   * - props.file ダウンロードするドキュメントファイルのメタデータ
   * @returns DL用の署名付きURL
   */
  getPresignedS3Url: async (props: { file: DocumentFile }): Promise<string> => {
    const { file } = props;
    return await axios
      .get<string>(pythonGateway + prefixPythonGateway + '/store/documents/presigned-url?s3ObjectKey=' + file.storage.key)
      .then((res) => res.data);
  },

  /**
   * ドキュメントファイルの署名付きURLを取得するための関数
   * @param props プロパティオブション
   * - props.file ダウンロードするドキュメントファイルのメタデータ
   * @returns DL用の署名付きURL
   */
  getPersonalPresignedS3Url: async (props: { file: DocumentFile }): Promise<string> => {
    const { file } = props;
    return await axios
      .get<string>(pythonGateway + prefixPythonGateway + '/store/documents/personal-presigned-url?s3ObjectKey=' + file.storage.key)
      .then((res) => res.data);
  },

  /**
   * ドキュメントファイルを削除するための関数
   * @param props プロパティオブション
   * - props.file 削除するドキュメントファイルのメタデータ
   * - props.classification 組織分類
   */
  deleteFile: async (props: { file: DocumentFile; classification: OrganizationClassification }): Promise<void> => {
    const { file, classification } = props;
    const objectKey = file.storage.key;
    await axios.delete(pythonGateway + prefixPythonGateway + `/store/documents?objectKey=${objectKey}&type=${classification.id}`);
  },

  /**
   * ドキュメントファイルを削除するための関数
   * @param props プロパティオブション
   * - props.file 削除するドキュメントファイルのメタデータ
   */
  deletePersonalFile: async (props: { file: DocumentFile }): Promise<void> => {
    const { file } = props;
    const objectKey = file.storage.key;
    await axios.delete(pythonGateway + prefixPythonGateway + `/store/personalDocuments?objectKey=${objectKey}`);
  },

  /**
   * @param onNotify notify
   * @return void
   */
  updateIndex: async (onNotify: (props: { msg?: updateProcess; prs?: number }) => void): Promise<void> => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    let reader: ReadableStreamDefaultReader<Uint8Array> | undefined;
    try {
      const { body, statusText } = await fetch(pythonGateway + prefixPythonGateway + '/sync/s3-to-vectore-store', {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      });
      reader = body?.getReader();
      if (!reader) throw new Error(statusText);
      const decoder = new TextDecoder('utf-8');
      const read = async (): Promise<any> => {
        const { done, value } = await reader!.read();
        if (done) {
          return reader!.releaseLock();
        }
        const _content = decoder.decode(value, { stream: true });
        const parsedContent = _content.split('}{');
        parsedContent.map((content) => {
          if (content[0] !== '{') {
            content = '{' + content;
          }
          if (content[content.length - 1] !== '}') {
            content = content + '}';
          }
          const parsedContent = JSON.parse(content);
          if (parsedContent.error !== undefined) throw new Error(parsedContent.error);
          if (parsedContent.process) onNotify({ msg: parsedContent.process as updateProcess });
          if (parsedContent.task) onNotify({ prs: parsedContent.task as number });
        });
        return read();
      };
      await read();
    } catch (e) {
      throw e;
    }
  },

  getFolderSize: async (): Promise<number> => {
    return await axios.get<number>(pythonGateway + prefixPythonGateway + `/store/folder/size`).then((res) => res.data);
  },
};
