import { Button, Container, InputBase, Paper, Grid } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { useTextToSpeechPage } from './useTextToSpeechPage';
import { PageTitle } from 'src/components/PageTitle';
import { PageDescription } from 'src/components/PageDescription';
import { CustomSelection } from 'src/components/CustomSelection';

export const TextToSpeechPage: React.FC = () => {
  const pm = useTextToSpeechPage();
  return (
    <Page title="Text to speech">
      <Container maxWidth={'md'}>
        <Grid display="flex" justifyContent="space-between">
          <PageTitle text={'Text to speechサンプル'} />
        </Grid>
        <PageDescription
          text={'Text to speechを使用するサンプルページです。 ttsに使う文章を書いて再生ボタンを押してくさださい。'}
          sx={{ marginBottom: 1 }}
        />
        <Paper
          component="form"
          sx={{
            marginBottom: '10px',
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
          }}>
          <InputBase
            id="userMessageInput"
            sx={{
              ml: 1,
              flex: 1,
              minHeight: '38px',
            }}
            value={pm.text.value}
            onChange={(e) => pm.text.setter(e.target.value)}
            placeholder={'ここに質問を入力してください。'}
            multiline
            minRows={5}
            maxRows={5}
          />
        </Paper>
        <Grid container xs={12} mt={2} justifyContent={'space-between'}>
          <Grid item container xs={6} spacing={2}>
            <Grid item xs={4}>
              <CustomSelection
                value={pm.gender.value}
                list={pm.genderList.map((item) => item.message)}
                onChange={pm.gender.setter}
                label={'性別'}
                fullWidth
                size={'small'}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelection
                value={pm.lang.value}
                list={pm.langList.map((item) => item.message)}
                onChange={pm.lang.setter}
                label={'言語'}
                fullWidth
                size={'small'}
              />
            </Grid>
          </Grid>
          <Grid item container xs={4} alignContent={'center'}>
            <Button variant={'contained'} color={'secondary'} size={'small'} fullWidth onClick={pm.azureTTS}>
              Azure Text To Speech（チャット）
            </Button>
          </Grid>
        </Grid>
        <Grid container xs={12} mt={2} justifyContent={'right'}>
          <Grid item container xs={4} alignContent={'center'}>
            <Button variant={'contained'} color={'primary'} size={'small'} fullWidth onClick={pm.onTTS}>
              OpenAI Text To Speech
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
