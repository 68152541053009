import { Box, Button, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { PATH_CHAT } from 'src/routes/paths';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { PromptIcon } from './PromtIcon';
import CancelIcon from '@mui/icons-material/Cancel';
interface PromptDescriptionProps {
  title: string;
  tags: string[];
  inputMessage: () => void;
  closeModal: () => void;
  label: string;
  isChat: boolean;
}

export const PromptDescription = React.memo(({ title, tags, inputMessage, label, closeModal, isChat }: PromptDescriptionProps) => {
  const theme = useTheme();
  const isMoble = useMediaQuery(theme.breakpoints.down('sm'));

  return isMoble ? (
    <Grid container direction={'column'}>
      <Grid pb={2}>
        <Typography component="div">
          <Box sx={{ fontWeight: 'bold' }}>{title}</Box>
        </Typography>
        <Box display={'flex'} marginTop={1}>
          {tags?.map((item: string) => (
            <Box
              style={{
                border: '1px solid #9e9e9e',
                borderRadius: '3px',
              }}
              paddingLeft={1}
              paddingRight={1}
              marginRight={1}
              sx={{
                fontSize: '13px',
              }}>
              {item}
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item container justifyContent={'right'}>
        <Grid item mb={1}>
          {isChat ? (
            <Button
              onClick={() => {
                inputMessage();
                closeModal();
              }}
              size="medium"
              color="info"
              variant="contained">
              <AutoFixHighIcon />
              {label}
            </Button>
          ) : (
            <Button onClick={inputMessage} size="medium" color="info" variant="contained" component={RouterLink} to={PATH_CHAT.main}>
              <AutoFixHighIcon />
              {label}
            </Button>
          )}
        </Grid>
        <Grid item mb={1}>
          <IconButton onClick={closeModal} color="info">
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction={'row'} justifyContent={'space-between'}>
      <Grid item xs={9}>
        <Grid container direction={'row'}>
          <Grid>
            <PromptIcon title={tags[0]} fontSize={50} />
          </Grid>
          <Grid ml={2} pb={2}>
            <Typography component="div">
              <Box sx={{ fontWeight: 'bold' }}>{title}</Box>
            </Typography>
            <Box display={'flex'} marginTop={1}>
              {tags?.map((item: string) => (
                <Box
                  style={{
                    border: '1px solid #9e9e9e',
                    borderRadius: '3px',
                  }}
                  paddingLeft={1}
                  paddingRight={1}
                  marginRight={1}
                  sx={{
                    fontSize: '13px',
                  }}>
                  {item}
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container mt={1} mb={1} xs={3} justifyContent={'space-between'}>
        <Grid>
          {isChat ? (
            <Button
              onClick={() => {
                inputMessage();
                closeModal();
              }}
              size="medium"
              color="info"
              variant="contained">
              <AutoFixHighIcon />
              {label}
            </Button>
          ) : (
            <Button onClick={inputMessage} size="medium" color="info" variant="contained" component={RouterLink} to={PATH_CHAT.main}>
              <AutoFixHighIcon />
              {label}
            </Button>
          )}
        </Grid>
        <Grid item>
          <IconButton onClick={closeModal} color="info">
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
});
