import { Navigate } from 'react-router-dom';

import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { USER_ROLES } from 'src/constants';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';
import { PATH_TERMS } from '../paths';
import { TermsPage } from 'src/pages/terms-page/TermsPage';

export const termsRoute = {
  path: 'terms',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={PATH_TERMS.privacy} replace />,
    },
    {
      path: 'privacy-policy',
      children: [
        {
          path: '',
          element: (
            <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER, USER_ROLES.GPT_USER]}>
              <TermsPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: 'gdpr',
      children: [
        {
          path: '',
          element: (
            <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER, USER_ROLES.GPT_USER]}>
              <TermsPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: 'ccpa',
      children: [
        {
          path: '',
          element: (
            <RoleBasedGuard accessibleRoles={[USER_ROLES.GPT_ADMIN, USER_ROLES.GPT_POWER, USER_ROLES.GPT_USER]}>
              <TermsPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
  ],
};
