import { ReactNode, useEffect, useRef } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  ListItemButton,
  LinkProps,
  ListItemButtonProps,
  Tabs,
  Tab,
  Typography,
  alpha,
  MenuItem,
  MenuProps,
  Menu,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// hooks
import { useOffSetTop } from '../../../hooks/useOffSetTop';
import { useResponsive } from '../../../hooks/useResponsive';
// utils
import { cssStyles } from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import { Iconify } from 'src/components-minimals/Iconify';
import { IconButtonAnimate } from '../../../components-minimals/animate';
import { AccountPopover } from '../AccountPopover';
import { DarkModeSwitch } from '../../DarkModeSwitch';
import { LanguagePopover } from '../LanguagePopover';
import { Chip } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useSettings from 'src/hooks/useSettings';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SpeakerNotesSharpIcon from '@mui/icons-material/SpeakerNotesSharp';
import ScienceSharpIcon from '@mui/icons-material/ScienceSharp';
import { useTranslation } from 'react-i18next';
import {
  PATH_CHAT,
  RELEASE_NOTES_V1_X,
  ROOTS_RELEASE_NOTES,
  PATH_DOC_SEARCH,
  PATH_PROMPT,
  PATH_TERMS,
  PATH_HOME,
  PATH_OTHERS,
  PATH_MINUTES,
} from 'src/routes/paths';
import snackbar from 'src/components-mars/Snackbar';
import { isEnvLocalOrTrial, isNotReleaseFunction } from 'src/utils/envChecker';
import { SupportPopover } from '../SupportPopover';

import HomeIcon from '@mui/icons-material/Home';
import { roleCheck } from 'src/guards/RoleBasedGuard';
import { ARROWED_TO_ACCESS_DOCUMENT_PERSONAL, ARROWED_TO_ACCESS_DOCUMENT_SEARCH, ARROWED_TO_ACCESS_DOCUMENT_UPLOAD } from 'src/constants';
// ----------------------------------------------------------------------
type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};
const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur({ opacity: 0.6, blur: 1 }),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `100%`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));
type IProps = LinkProps & ListItemButtonProps;
interface ListItemStyleProps extends IProps {
  component?: ReactNode;
  to?: string;
  activeRoot?: boolean;
}
export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'activeRoot',
})<ListItemStyleProps>(({ activeRoot, theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  // activeRoot
  ...(activeRoot && {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.main,
  }),
}));
// ----------------------------------------------------------------------
type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
  isHome?: boolean;
};

/**
 * @param  param0 パラメータ
 * @return コンポーネント
 */
export const DashboardHeader = ({ onOpenSidebar, isCollapse = false, verticalLayout = false, isHome = false }: Props) => {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  // デスクトップかどうかを判定
  const isDesktop = useResponsive('up', 'lg');
  const { themeMode } = useSettings();

  // スマホかどうかを判定
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabLet = useMediaQuery(theme.breakpoints.down('md'));

  // // メニューの可視制御用フラグの取得
  const isDisplayDocSearchMenu = roleCheck([
    ...ARROWED_TO_ACCESS_DOCUMENT_PERSONAL,
    ...ARROWED_TO_ACCESS_DOCUMENT_SEARCH,
    ...ARROWED_TO_ACCESS_DOCUMENT_UPLOAD,
  ]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  }));

  const tabStyles = makeStyles({
    // タブのスタイル
    tab: {
      marginRight: '20px !important',
      borderRadius: '1px !important',
      textTransform: 'none',
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
    },
  });
  const classes = tabStyles();

  const menuStyles = makeStyles({
    // アコーディオンのスタイル
    accordion: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '1px !important',
      '&::before': {
        content: 'none',
      },
    },
    typography: {
      textDecoration: 'none',
      color: 'inherit',
      marginRight: '5px',
    },
  });
  const menuClasses = menuStyles({
    typography: {
      width: '100%',
    },
  });

  const urlMap = isNotReleaseFunction()
    ? isDisplayDocSearchMenu
      ? [PATH_HOME.root, PATH_CHAT.root, PATH_DOC_SEARCH.root, PATH_MINUTES.root, PATH_PROMPT.root, ROOTS_RELEASE_NOTES, PATH_OTHERS.root]
      : [PATH_HOME.root, PATH_CHAT.root, PATH_MINUTES.root, PATH_PROMPT.root, ROOTS_RELEASE_NOTES, PATH_OTHERS.root]
    : isDisplayDocSearchMenu
    ? [PATH_HOME.root, PATH_CHAT.root, PATH_DOC_SEARCH.root, PATH_MINUTES.root, PATH_PROMPT.root, ROOTS_RELEASE_NOTES]
    : [PATH_HOME.root, PATH_CHAT.root, PATH_MINUTES.root, PATH_PROMPT.root, ROOTS_RELEASE_NOTES];
  // URLとタブのマッピング

  // URLとタブのマッピングから現在のURLのタブを選択状態にする(useEffectで実行するとタブが移動するのが見えるためuseStateの初期値にセット)
  let firstTab = 0;
  const currentURLpath = location.pathname;
  urlMap.forEach((url, index) => {
    if (currentURLpath.startsWith(url)) {
      firstTab = index;
    } else if (currentURLpath.startsWith(PATH_TERMS.root)) {
      firstTab = urlMap.indexOf(ROOTS_RELEASE_NOTES)!;
    }
  });
  const [tabNub, setTabNum] = React.useState(firstTab);
  const { closeSnackbar } = snackbar();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    closeSnackbar();
    setTabNum(newValue);
  };

  React.useEffect(() => {
    // URLとタブのマッピングから現在のURLのタブを選択状態にする
    const currentURLpath = location.pathname;
    urlMap.forEach((url, index) => {
      if (currentURLpath.startsWith(url)) {
        setTabNum(urlMap.indexOf(url));
      } else if (currentURLpath.startsWith(PATH_TERMS.root)) {
        setTabNum(urlMap.indexOf(ROOTS_RELEASE_NOTES)!);
      }
    });
  });

  const [expanded, setExpanded] = React.useState(false);
  const contentElement = useRef<null | HTMLDivElement>(null);

  // アコーディオンの操作(クリックで開閉)
  // const handleAccordionClick = async (event: any) => {
  //   setExpanded(!expanded);
  //   event.stopPropagation();
  // };

  // アコーディオン以外の操作(クリックしたら閉じる)
  useEffect(() => {
    const handleContentAreaClick = (event: any) => {
      const element = contentElement.current;
      if (!expanded || element?.contains(event.target)) return;
      setExpanded(false);
    };

    window.addEventListener('click', handleContentAreaClick);
    return () => {
      window.removeEventListener('click', handleContentAreaClick);
    };
  }, [expanded, contentElement]);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
        }}>
        <Stack direction="row" alignItems="center">
          {!isDesktop && !isHome && (
            <IconButtonAnimate style={{ marginLeft: -10 }} onClick={onOpenSidebar}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}
          {!isMobile && !isTabLet && (
            <>
              {/* <BurstModeTwoToneIcon sx={{ color: themeMode === 'dark' ? '#fff' : '#000' }} /> */}
              <Typography
                className="marsFont"
                style={{
                  marginLeft: '10px',
                  color: themeMode === 'dark' ? '#fff' : '#000',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginRight: '20px',
                  whiteSpace: 'nowrap',
                }}>
                Toyotsu GChat
              </Typography>
            </>
          )}
          {isDesktop && (
            <Tabs
              sx={isHome ? { marginLeft: '0px' } : { marginLeft: '100px' }}
              value={tabNub}
              onChange={handleChange}
              aria-label="icon position tabs example">
              <Tab className={classes.tab} icon={<HomeIcon />} component={RouterLink} to={PATH_HOME.root} disableRipple={true} />
              <Tab className={classes.tab} label={t('chat.topMenu.title')} component={RouterLink} to={PATH_CHAT.root} disableRipple={true} />
              {isDisplayDocSearchMenu && (
                <Tab
                  className={classes.tab}
                  label={t('document-search.topMenu.title')}
                  component={RouterLink}
                  to={PATH_DOC_SEARCH.root}
                  disableRipple={true}
                />
              )}
              <Tab className={classes.tab} label={t('minutes.topMenu.title')} component={RouterLink} to={PATH_MINUTES.root} disableRipple={true} />
              <Tab className={classes.tab} label={t('prompts.topMenu.title')} component={RouterLink} to={PATH_PROMPT.root} disableRipple={true} />
              <Tab
                className={classes.tab}
                label={t('releaseNotes.topMenu.title')}
                component={RouterLink}
                to={RELEASE_NOTES_V1_X[0].path}
                disableRipple={true}
              />
              {isEnvLocalOrTrial() && ( // ローカルとTrial環境以外は非表示
                <Tab className={classes.tab} label={t('others.topMenu.title')} component={RouterLink} to={PATH_OTHERS.root} disableRipple={true} />
              )}
            </Tabs>
          )}
          {(isMobile || !isDesktop) && (
            <>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                style={{ color: themeMode === 'dark' ? '#fff' : '#000' }} // この行を追加
                disableRipple>
                {t('common.topMenu.title')}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={handleClose} disableRipple>
                  <HomeIcon />
                  <Typography component={RouterLink} to={PATH_HOME.root} className={menuClasses.typography}>
                    <b>{t('home.topMenu.title')}</b>
                    <Typography variant="body2">{t('home.topMenu.description')}</Typography>
                  </Typography>
                </MenuItem>
                {/** FIXME: https://toyotsu-digital123.atlassian.net/jira/software/c/projects/GPT/issues/GPT-349 */}
                <MenuItem onClick={handleClose} disableRipple>
                  <SmartToyIcon />
                  <Typography component={RouterLink} to={PATH_CHAT.root} className={menuClasses.typography}>
                    <b>{t('chat.topMenu.title')}</b>
                    <Typography variant="body2">{t('chat.topMenu.description')}</Typography>
                  </Typography>
                </MenuItem>
                {isDisplayDocSearchMenu && (
                  <MenuItem onClick={handleClose} disableRipple>
                    <DocumentScannerIcon />
                    <Typography component={RouterLink} to={PATH_DOC_SEARCH.root} className={menuClasses.typography}>
                      <b>{t('document-search.topMenu.title')}</b>
                      <Typography variant="body2">{t('document-search.topMenu.description')}</Typography>
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={handleClose} disableRipple>
                  <ScienceSharpIcon />
                  <Typography component={RouterLink} to={PATH_MINUTES.root} className={menuClasses.typography}>
                    <b>{t('minutes.topMenu.title')}</b>
                    <Typography variant="body2">{t('minutes.topMenu.description')}</Typography>
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                  <SpeakerNotesSharpIcon />
                  <Typography component={RouterLink} to={PATH_PROMPT.root} className={menuClasses.typography}>
                    <b>{t('prompts.topMenu.title')}</b>
                    <Typography variant="body2">{t('prompts.topMenu.description')}</Typography>
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                  <NewReleasesIcon />
                  <Typography component={RouterLink} to={RELEASE_NOTES_V1_X[0].path} className={menuClasses.typography}>
                    <b>{t('releaseNotes.topMenu.title')}</b>
                    <Typography variant="body2">{t('releaseNotes.topMenu.description')}</Typography>
                  </Typography>
                </MenuItem>
                {isEnvLocalOrTrial() && ( // ローカルとTrial環境以外は非表示
                  <MenuItem onClick={handleClose} disableRipple>
                    <EqualizerIcon />
                    <Typography component={RouterLink} to={PATH_OTHERS.root} className={menuClasses.typography}>
                      <b>{t('others.topMenu.title')}</b>
                      <Typography variant="body2">{t('others.topMenu.description')}</Typography>
                    </Typography>
                  </MenuItem>
                )}
              </StyledMenu>
            </>
          )}
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {(process.env.REACT_APP_ENV || process.env.NODE_ENV) !== 'production' && (
            <Chip
              sx={{
                marginRight: '10px',
                width: { xs: '4rem', sm: '200px', md: '250px' },
                background: '#aaaaaa',
                fontWeight: 'bold',
                fontSize: { xs: '0.8rem', sm: '1rem', md: '1.1rem' },
                color: '#fff',
              }}
              label={'' + (process.env.REACT_APP_ENV || process.env.NODE_ENV)}
              variant="filled"
            />
          )}
          <SupportPopover />
          <LanguagePopover />
          <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
            <DarkModeSwitch />
          </Box>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
};
