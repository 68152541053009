import { Page } from 'src/components-minimals/Page';
import { Box, Container, Grid, InputBase, Paper } from '@mui/material';
import { useFunctionCallingPage } from './useFunctionCallingPage';
import { PageTitle } from 'src/components/PageTitle';
import { PageDescription } from 'src/components/PageDescription';
import { LoadingButton } from '@mui/lab';
import { p } from 'msw/lib/SetupApi-f4099ef3';

export const FunctionCallingPage = (): React.ReactElement => {
  const pm = useFunctionCallingPage();

  return (
    <Page title="FunctionCalling">
      <Container maxWidth={'md'}>
        <Box display="flex" justifyContent="space-between">
          <PageTitle text={'Function Calling API sample'} />
        </Box>

        <PageDescription text={'GChatのFunctionCalling APIを使うサンプルページです'} />
        <PageDescription text={'通常のチャットと違い、本日の天気と為替情報を返すことが可能です。'} sx={{ mb: 1 }} />

        <Grid container xs={12} direction={'column'} spacing={2}>
          <Grid item>
            <Paper
              component="form"
              sx={{
                marginBottom: '10px',
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                boxShadow: '0px 3px 5px 6px rgba(0, 0, 0, 0.1)',
              }}>
              <InputBase
                id="userMessageInput"
                sx={{
                  ml: 1,
                  flex: 1,
                  minHeight: '38px',
                }}
                defaultValue={pm.prompt}
                onBlur={(e) => pm.onChangedPrompt(e.target.value)}
                placeholder={'ex: 今日のUSDJPYを教えて'}
                multiline
                minRows={5}
                maxRows={5}
              />
            </Paper>
          </Grid>

          <Grid item>
            <LoadingButton
              onClick={pm.onClickedSend}
              type="submit"
              variant="contained"
              fullWidth
              loading={false}
              disabled={false}
              children={'送信'}
            />
          </Grid>

          <Grid item>{pm.result && <Paper sx={{ p: 2 }}>{pm.result}</Paper>}</Grid>
        </Grid>
      </Container>
    </Page>
  );
};
