import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, useMediaQuery } from '@mui/material';
import { LANGS_CODE } from 'src/hooks/useLocales';
import { useTheme } from '@mui/material/styles';

export interface ConfirmDialogProps {
  // FIXME:国コードであって言語コードになっていないため、将来的に修正が必要になるかもしれない
  lang: LANGS_CODE;
  isOpen: boolean;
  children: React.ReactNode;
  onOk: VoidFunction;
  onCanceled: VoidFunction;
  cancelColor?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning';
  cancelVariant?: 'text' | 'outlined' | 'contained';
  okColor?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning';
  okVariant?: 'text' | 'outlined' | 'contained';
}

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { lang, isOpen, children, okColor = 'primary', cancelColor = 'error', cancelVariant = 'outlined', okVariant = 'outlined' } = props;
  const { onOk, onCanceled } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={isOpen} onClose={onCanceled} fullWidth>
      <DialogContent sx={{ minWidth: 240 }}>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container xs={12} justifyContent={'space-around'}>
          <Grid item xs={isMobile ? 5 : 3}>
            <Button
              variant={cancelVariant}
              onClick={onCanceled}
              color={cancelColor}
              sx={{ color: theme.palette.mode === 'light' ? 'black' : '#86929d' }}
              fullWidth>
              {lang === LANGS_CODE.JP ? 'キャンセル' : 'Cancel'}
            </Button>
          </Grid>
          <Grid item xs={isMobile ? 5 : 3}>
            <Button variant={okVariant} onClick={onOk} color={okColor} fullWidth>
              {lang === LANGS_CODE.JP ? 'OK' : 'OK'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
