import Slider from 'react-slick';
// @mui
import { styled } from '@mui/material/styles';
import { Box, IconButton, Theme } from '@mui/material';
//
import Image from './Image';
import CloseIcon from '@mui/icons-material/Close';
import { useCarousel } from 'src/hooks/useCarousel';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '& .slick-slide': {
    float: theme.direction === 'rtl' ? 'right' : 'left',
    '&:focus': { outline: 'none' },
  },
}));

// ----------------------------------------------------------------------

type Props = {
  images: string[];
  closeEvent: (item: string) => void;
  isMobile: boolean;
};
/**
 *
 * @param param0 item
 * @returns carousel component
 */
export const ImagesCarousel = ({ images, closeEvent, isMobile }: Props) => {
  const carouselHook = useCarousel({ isMobile, images: images });

  return (
    <RootStyle>
      <Box
        sx={{
          my: 1,
          mx: 'auto',
          '& .slick-current .isActive': { opacity: 1 },
          ...(images.length === 1 && { maxWidth: carouselHook.thumSize.msg * 1 + 16 }),
          ...(images.length === 2 && { maxWidth: carouselHook.thumSize.msg * 2 + 32 }),
          ...(isMobile
            ? images.length >= 3 && { maxWidth: carouselHook.thumSize.msg * 3 + 48 }
            : images.length === 3 && { maxWidth: carouselHook.thumSize.msg * 3 + 48 }),
          ...(!isMobile && images.length === 4 && { maxWidth: carouselHook.thumSize.msg * 4 + 64 }),
          ...(!isMobile && images.length >= 5 && { maxWidth: carouselHook.thumSize.msg * 6 }),
        }}>
        <Slider {...carouselHook.settings} slidesToShow={carouselHook.sliderToShow.msg} arrows={false}>
          {images.map((img, index) => {
            return (
              <Box key={img} sx={{ px: 0.75, position: 'relative', display: 'inline-block' }}>
                <Image
                  disabledEffect
                  alt="thumb image"
                  src={img}
                  // onClick={() => setCurrentIndex(index)}
                  sx={{
                    width: carouselHook.thumSize.msg,
                    height: carouselHook.thumSize.msg,
                    borderRadius: 1.5,
                    cursor: 'pointer',
                    ...(carouselHook.sliderIndex === index && {
                      border: (theme: Theme) => `solid 3px ${theme.palette.primary.main}`,
                    }),
                  }}
                />
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '4px',
                    right: '12px',
                    width: '20px',
                    height: '20px',
                    p: 0,
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '50%',
                    boxShadow: 1,
                    '&:hover': {
                      backgroundColor: 'grey.300',
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    closeEvent(img);
                  }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            );
          })}
        </Slider>
      </Box>
    </RootStyle>
  );
};
