import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResultType } from 'src/@types/docSearch';
import snackbar from 'src/components-mars/Snackbar';
import { useCopy } from 'src/hooks/useCopy';
import { useDocSearch } from 'src/hooks/useDocSearch';
import { clearResponse, clearSource } from 'src/redux/slices/docSearchSlice';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';

export const useDocSearchPageModel = () => {
  const { t: translate } = useTranslation();
  const { onSnackbarClose } = snackbar();

  const [resultState, setResultState] = useState<ResultType>({ request: { inputText: '' }, response: { result: '' } });
  const dsResponse = useSelector((state: RootState) => state.docSearchState).response;
  const dsSource = useSelector((state: RootState) => state.docSearchState).source;

  const errorMsgHandler = (e: any) => {
    if (e.message.includes('context_length_exceeded') || e.message.includes('ParameterLengthOutOfRange')) {
      onSnackbarClose({
        color: 'error',
        msg: `${translate('document-search.message.error.contextLengthExceeded')}`,
        isPersist: true,
      });
    } else if (!(e instanceof DOMException && e.name === 'AbortError')) {
      onSnackbarClose({
        color: 'error',
        msg: `${translate('common.message.error.sendGPTMessage')} ${e}`,
        isPersist: true,
      });
    }
  };
  const inputBaseOnChange = (e: any) => setResultState({ ...resultState, request: { inputText: e.target.value } });

  const { isOpenCopyTip, copiedMsg, copyBtnLabel, handleCloseTip, handleCopyButtonClick } = useCopy(dsResponse);
  const { isSubmitting, handleSubmitButtonClick, disabled } = useDocSearch({
    text: resultState.request.inputText,
    errorMsgHandler,
  });

  useEffect(() => {
    dispatch(setSideBarMenu());
    dispatch(clearResponse());
    dispatch(clearSource());
  }, []);

  return {
    resultState,
    inputBaseOnChange,
    dsResponse,
    copyBtnLabel,
    isOpenCopyTip,
    handleCloseTip,
    copiedMsg,
    handleCopyButtonClick,
    dsSource,
    isSubmitting,
    handleSubmitButtonClick,
    disabled,
    translate,
  };
};
