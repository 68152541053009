import { Grid, ListItemIcon, MenuItem, Switch, SxProps, Typography } from '@mui/material';
import { IconComponent } from './IconComponents';
import React from 'react';

export interface CustomMenuItemProps {
  onClick: () => void;
  message: string;
  icon?: 'delete' | 'volume' | 'help' | 'open' | 'recycle' | 'voice' | 'gender' | 'speed' | 'lang' | 'web';
  isSwitch?: boolean;
  isChecked?: boolean;
  onChange?: () => void;
  sx?: SxProps;
  showing?: boolean;
  pl?: number;
}

export const CustomMenuItem = React.memo(
  ({ onClick, icon, message, isSwitch, isChecked, onChange, sx, showing = true, pl = 0 }: CustomMenuItemProps) => {
    return showing ? (
      <MenuItem onClick={onClick} disableRipple>
        <Grid pl={pl}>
          <ListItemIcon>
            <IconComponent icon={icon} fontSize="small" />
            <Typography variant="inherit" sx={sx}>
              {message}
            </Typography>
            {isSwitch && (
              <Switch
                checked={isChecked}
                size="small"
                onChange={() => {
                  onChange?.();
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
          </ListItemIcon>
        </Grid>
      </MenuItem>
    ) : (
      <></>
    );
  },
);
