import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useSearchPrompt = () => {
  const { t } = useTranslation();
  const palceholder = t('prompts.examplePrompt.searchBox');
  const categoryLabel = t('prompts.examplePrompt.categorySelect');

  const [category, setCategory] = useState<string>('');
  const [searchText, setSearchText] = useState('');

  const handleInputValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);

  const handleCategoryValueChange = useCallback((_: React.SyntheticEvent<Element, Event>, value: string | null) => {
    setCategory(value ? value : '');
  }, []);

  return {
    palceholder,
    searchText,
    handleInputValueChange,
    categoryLabel,
    category,
    handleCategoryValueChange,
  };
};
