import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';
import { apiGateway, prefixApiGateway } from 'src/config';
import { PromptsCategory } from 'src/@types/promptCategory';
import { Prompts } from 'src/@types/prompt';

type PromptApi = {
  language: string;
  tagId?: number;
  searchText?: string;
  take?: number;
};

export const promptAPI = {
  /**
   * プロンプトの一覧を取得する
   * @param language 言語
   * @param searchWord 検索文字列
   * @param categoryId カテゴリーID
   * @param take 取得数
   * @returns プロンプトの一覧
   */
  getPrompts(language: string, searchWord?: string, categoryId?: string, take?: number): Promise<AxiosResponse<Prompts[]>> {
    const params: PromptApi = {
      language: language,
    };
    if (categoryId && categoryId !== '') params.tagId = parseInt(categoryId, 10);
    if (searchWord !== '') params.searchText = searchWord;
    if (take !== undefined) params.take = take;
    return axios.get(apiGateway + prefixApiGateway + '/prompt', { params: params });
  },

  /**
   * カテゴリーの一覧を取得する
   * @param language 言語
   * @returns カテゴリーの一覧
   */
  getCategory(language: string): Promise<AxiosResponse<PromptsCategory[]>> {
    return axios.get(apiGateway + prefixApiGateway + '/prompt/tags', { params: { language: language } });
  },

  registPromptUsageHistory(selectId: number): Promise<AxiosResponse<number>> {
    const body = {
      promptId: selectId,
    };
    return axios.post(apiGateway + prefixApiGateway + '/prompt/registUsageHistory', body);
  },
};
