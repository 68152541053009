import { Box, InputBase } from '@mui/material';

export interface StyledTextareaProps {
  variant: 'body1' | 'body2';
  value: string;
  placeholder?: string;
  minRows?: number;
  readOnly?: boolean;
  borderless?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const StyledTextarea = (props: StyledTextareaProps) => {
  const { variant, readOnly, borderless } = props;

  // フォントサイズの設定
  let fontSize = 0;
  if (variant === 'body1') fontSize = 16;
  if (variant === 'body2') fontSize = 14;

  return (
    <Box
      sx={{
        pl: 1.5,
        pr: 1.5,
        pt: 1,
        pb: 1,
        borderWidth: !!borderless ? 0 : 1,
        borderColor: readOnly ? 'grey.700' : 'grey.500',
        borderStyle: 'solid',
        borderRadius: 1,
        ':hover': {
          borderColor: readOnly ? 'grey.700' : 'primary.main',
        },
        color: readOnly ? 'grey.500' : 'inherit',
      }}>
      <InputBase
        sx={{
          fontSize: fontSize,
          '.MuiInputBase-inputMultiline': {
            textAlign: 'justify',
            color: 'inherit',
          },
        }}
        fullWidth
        autoComplete={'off'}
        multiline
        readOnly={readOnly}
        {...props}
      />
    </Box>
  );
};
