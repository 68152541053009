import { apiGateway, prefixApiGateway } from 'src/config';
import axios from 'src/utils/axios';

export const transcribeAPI = () => {
  /**
   * 文字起こし
   * @param file 音声ファイル
   * @param service プラットフォームのサービス
   * @param splitLength 音声ファイルの分割時間
   * @param inputLang 入力音声の言語
   * @returns 文字起こしされた文字列
   */
  const transcribe = async (file: File, service: string, splitLength: number, inputLang: string) => {
    // フォームデータを作成
    const form = new FormData();
    form.append('file', file);
    form.append('service', service);
    form.append('splitLength', splitLength.toString());
    form.append('inputLang', inputLang);

    // リクエスト
    const { data } = await axios.post(apiGateway + prefixApiGateway + '/transcribe', form);
    return data;
  };

  return {
    transcribe,
  };
};

/**
 * 文字起こしAPIのエラーコード
 *
 * server/src/transcribe/transcribe.exception.tsと一致させる必要がある
 */
export enum TranscribeApiErrorCode {
  UNDEFINED_ERROR = 110000,
  UNSUPPORTED_TRANSCRIBE_SERVICE,
  APIKEY_NOTFOUND_ERROR,
  CONTENT_TOO_LARGE_ERROR,
  TOO_MANY_REQUEST_ERROR,
  ATTEMPTS_EXCEEDED_ERROR,
  UNSUPPORTED_INPUT_LAUNGUAGE_ERROR,
}

export enum FFmpegExceptionCode {
  EXECUTE_FAILD = '009001',
  INVALID_AUDIO_LENGTH = '009002',
  INVALID_ARGUMENT = '009003',
}
