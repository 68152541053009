import React, { useState } from 'react';
import { Button, Grid, Typography, Dialog, IconButton, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { FileRejection, DropEvent } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { AudioDropZone } from 'src/features/minutes/components/dropzone/AudioDropZone';
import { useSnackbar } from 'src/hooks/useSnackbar';

export interface TranscribingFromFileDialogProps {
  onTranscribe: (file: File) => void;
}

/**
 * ファイルから文字起こしのダイアログ
 * @param props パラメータ
 * @returns レンダリングコンポーネント
 */
export const TranscribingFromFileDialog = (props: TranscribingFromFileDialogProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);

  /**
   * 音声ファイルを受付できた時の処理
   * @param acceptedFiles ドロップされたファイル
   */
  const onDropAccepted = (acceptedFiles: File[]) => {
    setIsOpen(false);
    if (acceptedFiles.length === 1) {
      props.onTranscribe(acceptedFiles[0]);
    }
  };

  /**
   * 音声ファイルを受付けられなかった時の処理
   * @param files ドロップれたファイル
   * @param e ドロップイベントコンテキスト
   */
  const onDropRejected = (files: FileRejection[], e: DropEvent) => {
    let message = '';
    console.log(files);
    if (files.length >= 2) message = t('minutes.minutes.message.multipleFilesCannotBeRegistered');
    if (files.length === 1) message = t('minutes.minutes.message.unsupportedFileFormat');
    snackbar.error(message);
  };

  return (
    <React.Fragment>
      <Button fullWidth variant="contained" children={t('transcribe.content.fileButton')} onClick={() => setIsOpen(true)} />

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Grid container direction={'column'} spacing={1}>
          <Grid item>
            <Box display="flex" justifyContent={'flex-end'}>
              <IconButton onClick={() => setIsOpen(false)} color="info">
                <CancelIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction={'column'} spacing={2} sx={{ pt: 4, pb: 10, pl: 2, pr: 2 }}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {t('transcribe.content.description1')}
                  <br />
                  {t('transcribe.content.description2')}
                </Typography>
              </Grid>

              {/* ファイルドロップエリア */}
              <Grid item>
                <AudioDropZone onAccepted={onDropAccepted} onRejected={onDropRejected} height={80}>
                  <Typography variant="body2">{t('transcribe.content.dropZoneLabel')}</Typography>
                </AudioDropZone>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};
