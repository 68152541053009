import { Button, Checkbox, Container, Grid, Stack, Typography } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { PageTitle } from 'src/components/PageTitle';
import { Loading } from 'src/features/minutes/components/backdrop/Loading';
import { useDocumentCollectionPageModel } from './useDocumentCollectionPageModel';
import { StyledAutocomplite } from 'src/components/StyledAutoComplite';
import { BoxedGrid } from 'src/components-mars/ag-grid/BoxedGrid';
import { ValueGetterParams } from 'ag-grid-community';
import { DocumentFile } from 'src/models/document-file';
import dateToStringFormatter from 'src/components-mars/ag-grid/valueFormatter/dateToStringFormatter';
import { dowbleBtnCellRender } from 'src/components-mars/ag-grid/renderer/BtnCellRenderer';
import { ConfirmDialog } from 'src/components/ConfirmDialog';

export const DocumentCollectionPage: React.FC = () => {
  const vm = useDocumentCollectionPageModel();

  return (
    <Page title={vm.locale.translate('document-collection.text.pageTitle')}>
      {/* Backdrops */}
      <Loading
        isLoading={vm.isLoading || vm.listLoading}
        text={vm.listLoading && !vm.withTags ? vm.locale.translate('document-collection.text.withTags') : ''}
      />

      {/* Dialogs */}
      <ConfirmDialog
        lang={vm.locale.currentLang.code}
        isOpen={vm.deleteConfirmDialog.isOpen}
        onCanceled={vm.deleteConfirmDialog.onCancel}
        onOk={vm.deleteConfirmDialog.onOk}
        cancelColor="inherit"
        cancelVariant="contained"
        okColor="error"
        okVariant="contained">
        <Typography variant={'body1'}>
          {vm.locale.translate('common.dialog.confirm.fileDeleted', { filename: vm.selectedDocumentFile?.fileName })}
        </Typography>
      </ConfirmDialog>

      {/* Content */}
      <Container maxWidth={'md'}>
        <PageTitle text={vm.locale.translate('document-collection.text.pageTitle')} />
        <Stack spacing={1}>
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item md={3}>
              <StyledAutocomplite
                label={vm.locale.translate('common.text.classification')}
                size="small"
                fullWidth
                value={vm.currentOrganizationL1}
                options={vm.organizationsL1}
                display={vm.displayOrganizationL1}
                onChange={vm.onChangedOrganizationL1}
              />
            </Grid>
            <Grid item md={3}>
              <Button
                variant="contained"
                children={vm.locale.translate('document-collection.text.registration')}
                fullWidth
                onClick={vm.onClickedGotoDocumentRegistrationPageButton}
              />
            </Grid>
          </Grid>
          <StyledAutocomplite
            label={vm.currentOrganizationL1 ? vm.displayOrganizationL1(vm.currentOrganizationL1) : ''}
            size="small"
            fullWidth
            disabled={!vm.currentOrganizationL1 || vm.currentOrganizationL1.id === 'all'}
            value={vm.currentOrganizationL2}
            options={vm.organizationsL2}
            display={vm.displayOrganizationL2}
            onChange={vm.onChangedOrganizationL2}
          />
          <Grid container>
            <Stack direction="row" spacing={1} alignItems="center">
              <Checkbox checked={vm.withTags} onClick={vm.checkboxHandler} />
              <Typography variant="caption">{vm.locale.translate('document-collection.text.checkbox')}</Typography>
            </Stack>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <BoxedGrid
                gridTheme={vm.agGridTheme}
                gridHeight={'65vh'}
                gridOptions={{ pagination: true }}
                defaultColDef={{ flex: 2, resizable: true, filter: true, sortable: true, floatingFilter: true }}
                columnDefs={[
                  {
                    headerName: vm.locale.translate('document-collection.ag-grid.column.fileName'),
                    filter: 'agMultiColumnFilter',
                    field: 'fileName',
                  },
                  {
                    headerName: vm.locale.translate('document-collection.ag-grid.column.tag'),
                    filter: 'agMultiColumnFilter',
                    valueGetter: (data: ValueGetterParams<DocumentFile>) => {
                      return vm.displayTagsValue(data.data?.storage.tags);
                    },
                  },
                  {
                    headerName: vm.locale.translate('document-collection.ag-grid.column.updatedAt'),
                    filter: false,
                    field: 'updatedAt',
                    valueFormatter: dateToStringFormatter,
                  },
                  {
                    headerName: '',
                    maxWidth: 100,
                    filter: false,
                    resizable: false,
                    suppressMenu: true,
                    cellRenderer: (arg: { data: DocumentFile }) => {
                      return dowbleBtnCellRender({
                        firstHandler: () => vm.onClickedDownloadButton(arg.data),
                        firstIcon: 'download',
                        secondHandler: () => vm.onClickedDeleteButton(arg.data),
                        secondIcon: 'delete',
                      });
                    },
                  },
                ]}
                rowData={vm.documentFiles}
              />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Page>
  );
};
