import { Container } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { useTermsPageModel } from './useTermsPageModel';
import { PageTitle } from 'src/components/PageTitle';
import { Grid, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';

export const TermsPage = () => {
  const pm = useTermsPageModel();
  const createdAt = pm.createdAt ? format(parseISO(pm.createdAt), 'yyyy/MM/dd') : '';
  return (
    <Page title={pm.locale.t('terms.text.pageTitle')}>
      {/* Content */}
      <Container maxWidth="md">
        <PageTitle text={pm.locale.t(`${pm.path}.text.pageTitle`)} />
        <Typography>{`${pm.locale.t(`${pm.path}.text.createdAt`)} : ${createdAt}`}</Typography>
        <Grid className="bg-gray-200 p-3 text-sm w-full prose prose-sm">
          <Grid dangerouslySetInnerHTML={{ __html: pm.htmlText }} className="w-3/4" />
        </Grid>
      </Container>
    </Page>
  );
};
