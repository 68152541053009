import { createSlice } from '@reduxjs/toolkit';

type SideBarType = 'HOME' | 'CHAT' | 'MENU';

export interface SideBar {
  type: SideBarType;
}

export const initialState: SideBar = {
  type: 'HOME',
};

const sideBarSlice = createSlice({
  name: 'SideBarState',
  initialState,
  reducers: {
    setSideBarDefault(state: SideBar): void {
      state.type = initialState.type;
    },
    setSideBarChat(state: SideBar): void {
      state.type = 'CHAT';
    },
    setSideBarMenu(state: SideBar): void {
      state.type = 'MENU';
    },
  },
});

export const { setSideBarDefault, setSideBarChat, setSideBarMenu } = sideBarSlice.actions;

// /**
//  *
//  * @return void
//  */
// export const setSideBarChat =
//   (): AppThunk =>
//   (dispatch): void => {
//     dispatch(sideBarSlice.actions.setSideBarChat());
//   };

// /**
//  *
//  * @return void
//  */
// export const setSideBarMenu =
//   (): AppThunk =>
//   (dispatch): void => {
//     dispatch(sideBarSlice.actions.setSideBarMenu());
//   };

export default sideBarSlice.reducer;
export const { reducer } = sideBarSlice;
