import { Grid, Typography } from '@mui/material';
import React from 'react';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
interface ChatStatusHeaderProps {
  isWebSearch: boolean;
  model: string;
}

export const ChatStatusHeader = React.memo(({ isWebSearch, model }: ChatStatusHeaderProps) => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="stretch" width={'100%'}>
      <BoltRoundedIcon color={isWebSearch ? 'primary' : 'disabled'} />
      <Typography variant="body1">{isWebSearch ? `Web Search(${model})` : `Default(${model})`}</Typography>
    </Grid>
  );
});
