import { ChatCompletionMessageParam } from 'openai/resources';
import { Ai, AiModel } from './ai';

export type chatEncoding = 'gpt-4';
export interface ChatGpt extends Ai {
  readonly serverSideVersionString: GptModel;
}

export interface OptionChatType extends ChatGpt {
  readonly version: string;
  readonly versionNum: number;
  readonly encoding: chatEncoding;
  readonly token: string;
  readonly maxTokenLength: number;
  readonly label: string;
}

export interface ChatMessage extends ChatGpt {
  datas: ChatCompletionMessageParam[];
}

export enum GptModel {
  GPT_35_TURBO_0125 = 'gpt-35-turbo-0125',
  GPT_4_OMNI_24_08_06 = 'gpt-4o-2024-08-06',
  GPT_4O_MINI_2024_07_18 = 'gpt-4o-mini-2024-07-18',
}

export const chatGptV4oMini: OptionChatType = {
  model: AiModel.CHAT_GPT,
  version: '4o-mini',
  versionNum: 4.1,
  encoding: 'gpt-4',
  token: '128K',
  maxTokenLength: 128000,
  serverSideVersionString: GptModel.GPT_4O_MINI_2024_07_18,
  label: 'GPT-4o mini',
};

export const chatGptV4o: OptionChatType = {
  model: AiModel.CHAT_GPT,
  version: '4',
  versionNum: 4,
  encoding: 'gpt-4',
  token: '128K',
  maxTokenLength: 128000,
  serverSideVersionString: GptModel.GPT_4_OMNI_24_08_06,
  label: 'GPT-4o',
};

export const chatModels = [chatGptV4oMini, chatGptV4o]; // default 0
