import { Button, Input, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';
import { TextField } from '@mui/material';

type InitialValues = {
  files?: any[];
};

/**
 * アップロードされたExcelファイルを保持し、引き渡す部分
 * @param  props props
 * @return return
 */
export const FileInputs = (props: {
  setField: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<InitialValues>>;
  files?: any[];
}) => {
  const { t } = useTranslation();

  // Excelファイルを変更した時
  const changeHandler = (value: any) => {
    const files = (value.target as HTMLInputElement).files;
    if (files !== null) {
      props.setField('files', files.length === 0 ? [] : [files]);
    }
  };

  // ファイルを選択した時
  const clickHanlder = (event: any) => {
    event.target.value = '';
    props.setField('files', []);
  };

  return (
    <label htmlFor="upload-file">
      <Input
        style={{ display: 'none' }}
        type="file"
        id="upload-file"
        inputProps={{ 'data-testid': 'inputFileField' }}
        onChange={changeHandler}
        onClick={clickHanlder}
      />
      <Stack spacing={3} direction="row">
        <Button color="primary" variant="text" component="span">
          {t('common.button.fileInput')}
        </Button>
        <TextField size="small" value={props.files && props.files.length !== 0 ? props.files[0][0].name : ''} disabled style={{ width: '500px' }} />
      </Stack>
    </label>
  );
};
