import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mainChatAPI } from 'src/apis/mainChatAPI';
import {
  ARROWED_TO_ACCESS_DOCUMENT_PERSONAL,
  ARROWED_TO_ACCESS_DOCUMENT_SEARCH,
  ARROWED_TO_ACCESS_DOCUMENT_UPLOAD,
  CHAT_TAKE_COUNT,
} from 'src/constants';
import { useChatMessageContext } from 'src/contexts/ChatMessageContext';
import useSettings from 'src/hooks/useSettings';
import { dispatch } from 'src/redux/store';
import { setSideBarDefault } from 'src/redux/slices/sidebarSlice';
import { PATH_CHAT, PATH_DOC_SEARCH, PATH_PROMPT, RELEASE_NOTES_V1_X, PATH_OTHERS, PATH_MINUTES } from 'src/routes/paths';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useSelection from 'src/hooks/useSelection';
import { isNotReleaseFunction } from 'src/utils/envChecker';
import { roleCheck } from 'src/guards/RoleBasedGuard';

export const useHomePageModel = () => {
  const locale = useTranslation();
  const navigate = useNavigate();
  const { themeMode } = useSettings();
  const theme = useTheme();

  const chatContext = useChatMessageContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDisplayDocSearchMenu = roleCheck([
    ...ARROWED_TO_ACCESS_DOCUMENT_PERSONAL,
    ...ARROWED_TO_ACCESS_DOCUMENT_SEARCH,
    ...ARROWED_TO_ACCESS_DOCUMENT_UPLOAD,
  ]);

  // const [composing, setComposition] = React.useState(false);
  // const startComposition = React.useCallback(() => setComposition(true), []);
  // const endComposition = React.useCallback(() => setComposition(false), []);

  const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    chatContext.handleInputMessage(e.target.value);
  }, []);

  const handleSendMessageButton = () => {
    chatContext.handleHomeToChat(true);
    chatContext.handleSwitchHomeMessage(true);
    navigate(PATH_CHAT.main);
  };

  // const onKeyDownHandler = React.useCallback(
  //   (event: React.KeyboardEvent<HTMLInputElement>) => {
  //     if (event.key === 'Enter' && event.shiftKey) {
  //       // Handle Shift + Enter key press event
  //     } else if (event.key === 'Enter') {
  //       // 何も入力されていない場合、またはメッセージ受信中の場合は送信できない
  //       if (chatContext.inputMessage.length === 0) {
  //         // なにも入力されていない状態でEnterを押した場合は何もしない
  //         event.preventDefault();
  //       } else if (!composing) {
  //         event.preventDefault();
  //         handleSendMessageButton();
  //       }
  //     }
  //   },
  //   [chatContext.inputMessage, composing],
  // );

  const menuData = isNotReleaseFunction()
    ? isDisplayDocSearchMenu
      ? [
          { item: 'chat', path: PATH_CHAT.main },
          { item: 'document', path: PATH_DOC_SEARCH.root },
          { item: 'minutes', path: PATH_MINUTES.transcribing },
          { item: 'prompt', path: PATH_PROMPT.page },
          { item: 'announce', path: RELEASE_NOTES_V1_X[0].path },
          { item: 'others', path: PATH_OTHERS.snowflake },
        ]
      : [
          { item: 'chat', path: PATH_CHAT.main },
          { item: 'minutes', path: PATH_MINUTES.transcribing },
          { item: 'prompt', path: PATH_PROMPT.page },
          { item: 'announce', path: RELEASE_NOTES_V1_X[0].path },
          { item: 'others', path: PATH_OTHERS.snowflake },
        ]
    : isDisplayDocSearchMenu
    ? [
        { item: 'chat', path: PATH_CHAT.main },
        { item: 'document', path: PATH_DOC_SEARCH.root },
        { item: 'minutes', path: PATH_MINUTES.transcribing },
        { item: 'prompt', path: PATH_PROMPT.page },
        { item: 'announce', path: RELEASE_NOTES_V1_X[0].path },
      ]
    : [
        { item: 'chat', path: PATH_CHAT.main },
        { item: 'minutes', path: PATH_MINUTES.transcribing },
        { item: 'prompt', path: PATH_PROMPT.page },
        { item: 'announce', path: RELEASE_NOTES_V1_X[0].path },
      ];

  const selection = useSelection({
    useChatType: chatContext.useChatType,
    handleUseChatType: chatContext.handleUseChatType,
  });

  React.useEffect(() => {
    dispatch(setSideBarDefault());
    chatContext.handleInactivity();
    mainChatAPI
      .getChatLogs(CHAT_TAKE_COUNT, chatContext.skipCount)
      .then((res) => {
        if (res.data.length > 0) {
          setTimeout(() => {
            chatContext.handleChatLog([...chatContext.chatLog, ...res.data]);
            chatContext.handleSkipCount(chatContext.skipCount + CHAT_TAKE_COUNT);
          }, 10);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return {
    isMobile,
    themeMode,
    locale,
    navigate,
    menuData,
    chatContext,
    handleInputChange,
    // onKeyDownHandler,
    // startComposition,
    // endComposition,
    handleSendMessageButton,
    selection,
    chatModel: chatContext.useChatType.label,
  };
};
