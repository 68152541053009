import { experimentalAPI } from 'src/apis/experimentalAPI';

export const useFunctionCalling = () => {
  const api = experimentalAPI;

  const call = async (prompt: string): Promise<string> => {
    return await api.functionCalling({ prompt });
  };

  return {
    call,
  };
};
