import { mainChatAPI } from 'src/apis/mainChatAPI';

export const getTokenLength = async (id: string, callback: (data: any) => void) => {
  if (!id || id === 'new') return;
  await mainChatAPI
    .getChatTokenLength(id)
    .then((res) => {
      callback(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
};
