import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';
import { Droppable } from './Droppable';

export interface FileDropZoneProps {
  onAccepted: (acceptedFiles: File[]) => void;
  onRejected: (fileRejections: FileRejection[], event: DropEvent) => void;
  children: ReactNode;
  height?: number;
}

/**
 * 音声ファイル用のドロップゾーン
 * @param props パラメータ
 * @returns レンダリングコンポーネント
 */
export const AudioDropZone = (props: FileDropZoneProps) => {
  const { onAccepted, onRejected, children, height } = props;
  const accept = { 'audio/*': ['.mp4', '.mpeg', '.m4a', '.mp3'] };
  return (
    <Droppable onDropAccepted={onAccepted} onDropRejected={onRejected} accept={accept}>
      <Box
        sx={{
          height: height ?? 138,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px dashed #666',
          bgcolor: 'rgba(128,128,128,0.1)',
          borderRadius: 1,
          ':hover': {
            borderColor: 'primary.main',
            cursor: 'pointer',
          },
        }}
        children={children}
      />
    </Droppable>
  );
};
