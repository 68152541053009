import { PATH_HOME } from './routes/paths';

// ----------------------------------------------------------------------

export const INITIAL_PATH = PATH_HOME.root;

export const USER_ROLES = {
  GPT_ADMIN: 'toyotsu-gpt-admin',
  GPT_POWER: 'toyotsu-gpt-power-user',
  GPT_USER: 'toyotsu-gpt-user',
  GPT_DOC_USER: 'toyotsu-gpt-doc-user',
  GPT_READ_ADMIN: 'toyotsu-gpt-doc-read-admin',
  GPT_READ_USER: 'toyotsu-gpt-doc-read-user',
  GPT_READ_POWERUSER: 'toyotsu-gpt-doc-read-poweruser',
  GPT_UPLOAD_ADMIN: 'toyotsu-gpt-doc-upload-admin',
  GPT_UPLOAD_USER: 'toyotsu-gpt-doc-upload-user',
  GPT_UPLOAD_POWERUSER: 'toyotsu-gpt-doc-upload-poweruser',
} as const;

export const ARROWED_TO_ACCESS_DOCUMENT_PERSONAL = [USER_ROLES.GPT_DOC_USER];

export const ARROWED_TO_ACCESS_DOCUMENT_SEARCH = [USER_ROLES.GPT_READ_ADMIN, USER_ROLES.GPT_READ_USER, USER_ROLES.GPT_READ_POWERUSER];

export const ARROWED_TO_ACCESS_DOCUMENT_UPLOAD = [USER_ROLES.GPT_UPLOAD_ADMIN, USER_ROLES.GPT_UPLOAD_POWERUSER, USER_ROLES.GPT_UPLOAD_USER];

export const CHAT_TAKE_COUNT = 20;

export const base64ImageStart = 'data:image/'; // base64でイメージを表示するので
export const imageStart = '/chat/image/'; // dbからのイメージリンク
export const imageOriginAddress = '/chat/image/origin/'; // dbからのイメージリンク(原本)
export const markdownImgLinkStart = '![image_url]'; // マークダウンのイメージ表示方法

export const ImageNum = 2;
