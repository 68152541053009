import { gptAPI } from 'src/apis/gptAPI';
import { ChatGpt } from 'src/models/chat-gpt';

/**
 * useChatGPT
 *
 * ChatGPT機能に関する処理をまとめるためのHooks
 *
 * @returns Hooksプロパティ
 */
export function useChatGPT() {
  /**
   * GPTへの質問を行うための関数 (1問1答 ストリーミング用)
   * @param model 使用するGPTのバージョン
   * @param prompt GPTへ送るプロンプト
   * @returns GPTの返答
   */
  const stream = (model: ChatGpt, prompt: string) => {
    return gptAPI().stream(model, prompt);
  };

  return {
    stream,
  };
}
