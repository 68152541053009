import { Alert, Stack } from '@mui/material';
import React from 'react';

interface AlertMessageProps {
  message: string;
  color: string;
}
export const AlertMessage = React.memo(({ message, color }: AlertMessageProps) => {
  return (
    <Stack sx={{ width: '100%' }}>
      <Alert sx={{ color: color, whiteSpace: 'pre-line' }} severity="warning">
        {message}
      </Alert>
    </Stack>
  );
});
