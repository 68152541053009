import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { PATH_DOC_SEARCH, PATH_MINUTES, PATH_OTHERS, PATH_PROMPT, PATH_TERMS } from 'src/routes/paths';
import { RELEASE_NOTES_V1_X } from 'src/routes/paths';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { Filter } from '@mui/icons-material';
import { roleCheck } from 'src/guards/RoleBasedGuard';
import { ARROWED_TO_ACCESS_DOCUMENT_SEARCH, ARROWED_TO_ACCESS_DOCUMENT_PERSONAL, ARROWED_TO_ACCESS_DOCUMENT_UPLOAD } from 'src/constants';
import LockIcon from '@mui/icons-material/Lock';
import EuroIcon from '@mui/icons-material/Euro';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { isEnvLocalOrTrial } from 'src/utils/envChecker';
// -- RELEASE_NOTES ページ
const createItems = (language: string, reloaseNotes: any[]) => {
  const items: { title: string; path: string; icon: JSX.Element }[] = [];
  reloaseNotes.forEach((reloaseNote, index) => {
    items.push({
      title: language === 'ja' ? reloaseNote.info : reloaseNote.infoEn,
      path: reloaseNote.path,
      icon: <FontAwesomeIcon icon={faLink} size="lg" />,
    });
  });
  return items;
};

/**
 * 文書検索のサイドバーを取得する
 * @param t translation
 * @returns サイドバー
 */
const getDocSearchBar = (t: any) => {
  // ドキュメント検索のサイドバー
  const searchItem = {
    title: t('document-search.sidemenu'),
    path: PATH_DOC_SEARCH.search,
    icon: <DocumentScannerOutlinedIcon />,
  };

  const collectionItem = {
    title: t('document-collection.sidemenu'),
    path: PATH_DOC_SEARCH.collection,
    icon: <Filter />,
  };

  // ドキュメント検索のサイドバー
  const personalSearchItem = {
    title: t('document-search.sidemenu'),
    path: PATH_DOC_SEARCH.personalSearch,
    icon: <PlagiarismIcon />,
  };

  const personalCollectionItem = {
    title: t('document-collection.sidemenu'),
    path: PATH_DOC_SEARCH.personalCollection,
    icon: <ReceiptLongIcon />,
  };

  const docSearchItems = [];
  if (roleCheck(ARROWED_TO_ACCESS_DOCUMENT_PERSONAL)) {
    docSearchItems.push({
      subheader: t('doc.sidemenu.personal'),
      items: [personalSearchItem, personalCollectionItem],
    });
  }
  if (isEnvLocalOrTrial()) {
    if (roleCheck(ARROWED_TO_ACCESS_DOCUMENT_SEARCH) || roleCheck(ARROWED_TO_ACCESS_DOCUMENT_UPLOAD)) {
      docSearchItems.push({
        subheader: t('doc.sidemenu.company'),
        items: [],
      });
      // 特定のロールを持つ場合のみドキュメント検索ページを追加
      if (roleCheck(ARROWED_TO_ACCESS_DOCUMENT_SEARCH)) {
        docSearchItems[docSearchItems.length - 1].items.push(searchItem);
      }
      // 特定のロールを持つ場合のみドキュメント登録ページを追加
      if (roleCheck(ARROWED_TO_ACCESS_DOCUMENT_UPLOAD)) {
        docSearchItems[docSearchItems.length - 1].items.push(collectionItem);
      }
    }
  }

  let returnSideBar: any[] = []; // 出力するサイドバー
  if (docSearchItems.length) {
    // 権限がある場合は、ドキュメント検索のサイドバーを追加する
    returnSideBar = [...returnSideBar, ...docSearchItems];
  }

  return returnSideBar;
};

/**
 * ロールによってサイドバーのコンテンツを変更する
 * @return   サイドバーリスト
 */
const sidebarOthersBar = [
  {
    subheader: 'DB検索',
    items: [{ title: 'Snowflake search', path: PATH_OTHERS.snowflake, icon: <FontAwesomeIcon icon={faLink} size="lg" /> }],
  },
  {
    subheader: 'TEST',
    items: [
      {
        title: 'gpt 4 vision',
        path: PATH_OTHERS.vision,
        icon: <FontAwesomeIcon icon={faLink} size="lg" />,
      },
      {
        title: 'Assistants API',
        path: PATH_OTHERS.assistants,
        icon: <FontAwesomeIcon icon={faLink} size="lg" />,
      },
      {
        title: 'ファイル翻訳',
        path: PATH_OTHERS.translate,
        icon: <FontAwesomeIcon icon={faLink} size="lg" />,
      },
      {
        title: 'Text to speech API',
        path: PATH_OTHERS.tts,
        icon: <FontAwesomeIcon icon={faLink} size="lg" />,
      },
      {
        title: 'Function calling API',
        path: PATH_OTHERS.functionCalling,
        icon: <FontAwesomeIcon icon={faLink} size="lg" />,
      },
      {
        title: 'Dall E3 API',
        path: PATH_OTHERS.dallE3,
        icon: <FontAwesomeIcon icon={faLink} size="lg" />,
      },
      {
        title: 'Assistant V2 Beta CI',
        path: PATH_OTHERS.v2BetaCI,
        icon: <FontAwesomeIcon icon={faLink} size="lg" />,
      },
    ],
  },
];

const sidebarConfig = (t: any, language: string) => {
  const { pathname } = useLocation();
  // pathにexample-promptが含まれる場合
  if (pathname.includes('/prompt')) {
    return [
      {
        subheader: '',
        items: [
          {
            title: t('prompts.topMenu.title'),
            path: PATH_PROMPT.page,
            icon: <FontAwesomeIcon icon={faLink} size="lg" />,
          },
        ],
      },
    ];
  }
  // pathにrelease-notesが含まれる場合
  if (pathname.includes('/release-notes') || pathname.includes(PATH_TERMS.root)) {
    return [
      {
        subheader: t('terms.sidemenu.title'),
        items: [
          {
            title: t('privacy-policy.sidemenu.title'),
            path: PATH_TERMS.privacy,
            icon: <AdminPanelSettingsIcon fontSize={'small'} />,
          },
          {
            title: t('gdpr.sidemenu.title'),
            path: PATH_TERMS.gdpr,
            icon: <EuroIcon fontSize={'small'} />,
          },
          {
            title: t('ccpa.sidemenu.title'),
            path: PATH_TERMS.ccpa,
            icon: <LockIcon fontSize={'small'} />,
          },
        ],
      },
      {
        subheader: t('releaseNotes.sidemenu.information'),
        items: createItems(language, RELEASE_NOTES_V1_X),
      },
    ];
  }
  // pathにothersが含まれる場合
  if (pathname.includes('/others')) {
    return sidebarOthersBar;
  }
  // pathにdoc-searchが含まれる場合
  if (pathname.includes('/doc-search')) {
    return getDocSearchBar(t);
  }
  // pathにminutesが含まれる場合
  if (pathname.includes('/minutes')) {
    const bar = [
      {
        subheader: t('minutes.sidemenu.subheader'),
        items: [
          {
            title: t('transcribe.sidemenu.title'),
            path: PATH_MINUTES.transcribing,
            icon: <RecordVoiceOverIcon />,
          },
          {
            title: t('minutes.sidemenu.title'),
            path: PATH_MINUTES.minutes,
            icon: <FormatListBulletedIcon />,
          },
        ],
      },
    ];
    return bar;
  }
  return [];
};

export default sidebarConfig;
