/* eslint-disable @typescript-eslint/naming-convention */
import { DashboardLayout } from '../dashboard';
import AuthGuard from '../../guards/AuthGuard';
import { RootState, useSelector } from '../../redux/store';
import { dispatch } from '../../redux/store';
import { startLoading, stopLoading } from '../../redux/slices/loginSlice';
import { LoadingScreen } from 'src/components-minimals/LoadingScreen';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
/**
 *
 * @return コンポーネント
 */
export default function AuthGuardLayout() {
  const { isLoading } = useSelector((state: RootState) => state.loginState);

  const loginPathNameLocalStrageKey: string = 'callbackLoginPathName';
  if (localStorage.getItem(loginPathNameLocalStrageKey)) {
    dispatch(startLoading());
  }

  const locationUrl = useLocation();

  useEffect(() => {
    const loginPath: string | null = localStorage.getItem(loginPathNameLocalStrageKey);
    if (loginPath && loginPath === location.pathname + location.search) {
      localStorage.removeItem(loginPathNameLocalStrageKey);
      dispatch(stopLoading());
    }
  }, [locationUrl]);

  return <AuthGuard>{!isLoading ? <DashboardLayout /> : <LoadingScreen />}</AuthGuard>;
}
