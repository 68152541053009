import { AuthUser } from 'src/@types/authentication';
import { USER_ROLES } from 'src/constants';
import { OrganizationClassification, OrganizationSegment } from 'src/models/organization';
import { LANGS_CODE, useLocales } from './useLocales';
import { useOrganization } from './useOrganization';

export interface DocumentFileAccessControllerProps {
  user: AuthUser;
  currentClassification?: OrganizationClassification;
  currentSegment?: OrganizationSegment;
}

/**
 * ドキュメントファイルへのアクセス制御(可視制御)を行うためのカスタムフック
 * @param props 設定
 * @returns 公開プロパティ
 */
export const useDocumentFileAccessController = (props: DocumentFileAccessControllerProps) => {
  // Dependency hooks
  const locale = useLocales();
  const organization = useOrganization();

  // Flags
  const isUploadAdmin = props.user?.role.includes(USER_ROLES.GPT_UPLOAD_ADMIN);
  const isUplaodPowerUser = props.user?.role.includes(USER_ROLES.GPT_UPLOAD_POWERUSER);
  const isUplaodUser = props.user?.role.includes(USER_ROLES.GPT_UPLOAD_USER);
  const isS500User = JSON.parse(props.user?.company) === 'S500';

  // Organizational classification objects
  const DEPARTMENT: OrganizationClassification = { id: 'department', displayJpn: '部', displayEng: 'Department' };
  const DIVISION: OrganizationClassification = { id: 'division', displayJpn: '本部', displayEng: 'Division' };
  const COMPANY: OrganizationClassification = { id: 'company', displayJpn: '会社', displayEng: 'Company' };
  const REGION: OrganizationClassification = { id: 'region', displayJpn: '極', displayEng: 'Region' };
  const ALL: OrganizationClassification = { id: 'all', displayJpn: 'TTCグループ全体', displayEng: 'All TTC Groups' };

  /**
   * 組織分類を取得するための関数
   * @returns 組織分類
   */
  const fetchClassifications = () => {
    // AdminとUploadAdminのケース
    // 全ての組織分類を選択可能
    if (isUploadAdmin) {
      return [DEPARTMENT, DIVISION, COMPANY, REGION, ALL];
    }

    // UploadPowerUserのケース
    // 会社、本部、部を選択可能
    if (isUplaodPowerUser || (isS500User && isUplaodUser)) {
      return [DEPARTMENT, DIVISION, COMPANY];
    }

    // UploadUserのケース
    // 会社のみ選択可能
    if (isUplaodUser) {
      return [COMPANY];
    }

    // その他のケース
    return [];
  };

  /**
   * 組織セグメントを取得するための関数
   * @param classification 組織分類
   * @returns 組織セグメント
   */
  const fetchSegmentsByClassification = async (classification: OrganizationClassification) => {
    const segments = await organization.getSegmentsByClassification(classification);
    segments.sort((a, b) => (a.code < b.code ? -1 : 1));
    return segments;
  };

  /**
   * 組織L1の文字列構成を行うための関数
   * @param classification 組織分類
   * @returns 組織分類の表示文字列
   */
  const displayOrganizationL1 = (classification: OrganizationClassification) => {
    const code = locale?.currentLang.code;
    return code === LANGS_CODE.JP ? classification.displayJpn : classification.displayEng;
  };

  /**
   * 組織L2の文字列構成を行うための関数
   * @param segment 組織セグメント
   * @returns 組織セグメントの表示文字列
   */
  const displayOrganizationL2 = (segment: OrganizationSegment) => {
    const code = locale?.currentLang.code;
    // prettier-ignore
    return code === LANGS_CODE.JP 
      ? `(${segment.code}) ${segment.displayJpn}`
      : `(${segment.code}) ${segment.displayEng}`;
  };

  return {
    fetchClassifications,
    fetchSegmentsByClassification,
    displayOrganizationL1,
    displayOrganizationL2,
  };
};
