import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomMenuItemProps } from 'src/components/CustomMenuItem';
import { apiGateway, prefixApiGateway } from 'src/config';
import { Country, Gender, Speed, defaultSpeed, voice } from 'src/models/voice';
const localKey = {
  onoff: 'tts-onoff',
  gender: 'tts-gender',
  speed: 'tts-speed',
  lang: 'tts-language',
};
export const useTTSSettings = () => {
  const { t } = useTranslation();

  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  const [ttsOnOff, setTTSOnOff] = useState(() => {
    const storedItem = localStorage.getItem(localKey.onoff) as string;
    return storedItem === 'true';
  });

  const [gender, setGender] = useState<Gender>(() => {
    const storedItem = localStorage.getItem(localKey.gender) as Gender;
    return storedItem ? storedItem : 'male';
  });
  const [genderSetting, setGenderSetting] = useState(false);

  const [speed, setSpeed] = useState<Speed>(() => {
    const storedItem = localStorage.getItem(localKey.speed) as Speed;
    return storedItem ? storedItem : defaultSpeed;
  });
  const [speedSetting, setSpeedSetting] = useState(false);

  const [lang, setLang] = useState<Country>(() => {
    const storedItem = localStorage.getItem(localKey.lang) as Country;
    return storedItem ? storedItem : Country.JA;
  });
  const [langSetting, setLangSetting] = useState(false);

  const handleTTSOnOff = useCallback(() => {
    setTTSOnOff(!ttsOnOff);
  }, [ttsOnOff]);

  const hadleGenderSettingOpen = useCallback(() => {
    setGenderSetting(!genderSetting);
  }, [genderSetting]);

  const handleGender = useCallback(
    (gender: Gender) => {
      setGender(gender);
      hadleGenderSettingOpen();
    },
    [gender, genderSetting, hadleGenderSettingOpen],
  );

  const hadleSpeedSettingOpen = useCallback(() => {
    setSpeedSetting(!speedSetting);
  }, [speedSetting]);

  const handleSpeed = useCallback(
    (speed: Speed) => {
      setSpeed(speed);
      hadleSpeedSettingOpen();
    },
    [speed, speedSetting, hadleSpeedSettingOpen],
  );

  const hadleLangSettingOpen = useCallback(() => {
    setLangSetting(!langSetting);
  }, [langSetting]);

  const handleLang = useCallback(
    (lang: Country) => {
      setLang(lang);
      hadleLangSettingOpen();
    },
    [lang, langSetting, hadleLangSettingOpen],
  );

  const ttsGender: CustomMenuItemProps[] = [
    { message: t('chat.tts.gender.male'), onClick: () => handleGender('male'), showing: ttsOnOff && genderSetting, pl: 4 },
    { message: t('chat.tts.gender.female'), onClick: () => handleGender('female'), showing: ttsOnOff && genderSetting, pl: 4 },
  ];
  const ttsSpeed: CustomMenuItemProps[] = [
    { message: 'x1.0', onClick: () => handleSpeed(defaultSpeed), showing: ttsOnOff && speedSetting, pl: 4 },
    // { message: "x2.0", onClick: () => handleSpeed(speed2), showing: ttsOnOff && speedSetting, pl: 4 },
    // { message: "x3.0", onClick: () => handleSpeed(speed3), showing: ttsOnOff && speedSetting, pl: 4 },
  ];
  const ttsLang: CustomMenuItemProps[] = [
    { message: t(`chat.tts.language.${Country.JA}`), onClick: () => handleLang(Country.JA), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.EN}`), onClick: () => handleLang(Country.EN), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.FR}`), onClick: () => handleLang(Country.FR), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.ES}`), onClick: () => handleLang(Country.ES), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.AR}`), onClick: () => handleLang(Country.AR), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.ZH}`), onClick: () => handleLang(Country.ZH), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.HI}`), onClick: () => handleLang(Country.HI), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.ID}`), onClick: () => handleLang(Country.ID), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.PT}`), onClick: () => handleLang(Country.PT), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.DE}`), onClick: () => handleLang(Country.DE), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.IT}`), onClick: () => handleLang(Country.IT), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.TH}`), onClick: () => handleLang(Country.TH), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.KO}`), onClick: () => handleLang(Country.KO), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.RU}`), onClick: () => handleLang(Country.RU), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.MS}`), onClick: () => handleLang(Country.MS), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.SW}`), onClick: () => handleLang(Country.SW), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.CS}`), onClick: () => handleLang(Country.CS), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.PL}`), onClick: () => handleLang(Country.PL), showing: ttsOnOff && langSetting, pl: 4 },
    { message: t(`chat.tts.language.${Country.TR}`), onClick: () => handleLang(Country.TR), showing: ttsOnOff && langSetting, pl: 4 },
  ];

  const ttsSettings: CustomMenuItemProps[] = [
    {
      message: t('chat.tts.title'),
      onClick: handleTTSOnOff,
      icon: 'voice',
      isSwitch: true,
      isChecked: ttsOnOff,
      onChange: handleTTSOnOff,
    },
    { message: t(`chat.tts.gender.${gender}`), onClick: hadleGenderSettingOpen, icon: 'gender', showing: ttsOnOff, pl: 2 },
    ...ttsGender,
    { message: speed, onClick: hadleSpeedSettingOpen, icon: 'speed', showing: ttsOnOff, pl: 2 },
    ...ttsSpeed,
    { message: t(`chat.tts.language.${lang}`), onClick: hadleLangSettingOpen, icon: 'lang', showing: ttsOnOff, pl: 2 },
    ...ttsLang,
  ];
  const usingTTS = useCallback(
    async (message: string) => {
      if (ttsOnOff && message) {
        const body = {
          text: message,
          language: lang,
          voice: voice[gender][lang],
          rate: Number(speed),
        };
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();

        fetch(apiGateway + prefixApiGateway + '/text-to-speech', {
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Blobから音声データを作成
            if (audio) audio.pause();
            const audioUrl = URL.createObjectURL(blob);
            const item = new Audio(audioUrl);
            setAudio(item);
          })
          .catch((error) => console.error('Error fetching audio:', error));
      }
    },
    [ttsOnOff, lang, gender, speed, audio],
  );

  useEffect(() => {
    if (audio !== null) audio.play().catch((error) => console.error('Error playing audio:', error));
    return () => {
      if (audio) audio.pause();
    };
  }, [audio]);

  useEffect(() => {
    localStorage.setItem(localKey.onoff, `${ttsOnOff}`);
  }, [ttsOnOff]);

  useEffect(() => {
    localStorage.setItem(localKey.gender, gender);
  }, [gender]);

  useEffect(() => {
    localStorage.setItem(localKey.speed, speed);
  }, [speed]);

  useEffect(() => {
    localStorage.setItem(localKey.lang, lang);
  }, [lang]);

  return {
    ttsSettings,
    ttsOnOff,
    gender,
    speed,
    lang,
    usingTTS,
    //
    audio,
  };
};
