import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Page } from 'src/components-minimals/Page';
import { useHomePageModel } from './useHomePageModel';
import { ChatLogo } from 'src/components/ChatIcon';
import { MenuCard } from 'src/components/MenuCard';
import { InputMessage } from 'src/components/InputMessageHome';
import { LayoutToggleButtons } from 'src/components/LayoutToggleButtons';
const HomePage = (): React.ReactElement => {
  const pm = useHomePageModel();

  return (
    <Page title="Toyotsu GChat">
      <Container maxWidth={false} sx={{ padding: '0px' }}>
        <>
          <Grid item xs={12} sm={12} md={12}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack direction={'row'} spacing={2}>
                <Grid pt={pm.isMobile ? 0 : 1}>
                  <ChatLogo size={45} themeMode={pm.themeMode} />
                </Grid>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography
                    className="marsFont"
                    sx={{
                      paddingBottom: '0px',
                      fontSize: { xs: '30px', sm: '35px' },
                      fontWeight: '300',
                    }}>
                    Toyotsu GChat
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid container xs={12} direction="row" justifyContent={'center'} sx={{ paddingTop: { xs: '10px', sm: '10px', md: '10px', lg: '20px' } }}>
            <LayoutToggleButtons options={pm.selection.options} onChange={pm.selection.onChangeOption} value={pm.selection.value} />
          </Grid>
          <Grid id="asdasd" container mt={'calc(27vh)'} sx={{ position: 'relative' }} mb={1}>
            <InputMessage
              placeholder={pm.locale.t('home.message.placeholder')}
              messageInputBoxValue={pm.chatContext.inputMessage}
              onChange={pm.handleInputChange}
              // onKeyDown={pm.onKeyDownHandler}
              // onCompositionStart={pm.startComposition}
              // onCompositionEnd={pm.endComposition}
              onSubmit={pm.handleSendMessageButton}
              isWebSearch={pm.chatContext.isWebSearch}
              chatModel={pm.chatModel}
            />
          </Grid>
          <Container maxWidth="md">
            <Grid container spacing={1} justifyContent={'flex-start'}>
              {pm.menuData.map((item) => (
                <MenuCard
                  title={pm.locale.t(`home.title.${item.item}`)}
                  description={pm.locale.t(`home.description.${item.item}`)}
                  img={item.item}
                  onClick={() => {
                    if (item.item === 'chat') pm.chatContext.handleHomeToChat(true);
                    pm.navigate(item.path);
                  }}
                  guideline={pm.locale.t(`home.tooltip.${item.item}`)}
                  isMobile={pm.isMobile}
                />
              ))}
            </Grid>
          </Container>
        </>
      </Container>
    </Page>
  );
};
export default HomePage;
