import { Auth } from 'aws-amplify';
import { apiGateway, prefixApiGateway } from 'src/config';

export const minutesAPI = () => {
  /**
   * 議事録の文字起こし用API
   * @param file 文字起こしに使う音声ファイル
   * @param service 文字起こしするためのサービス名
   * @param splitLength 音声ファイルの分割時間
   * @param inputLang 入力音声の言語
   * @param onNotify 通知用コールバック関数
   */
  async function transcribe(file: File, service: string, splitLength: number, inputLang: string, onNotify: (msg: string) => void) {
    // フォームデータの作成
    const form = new FormData();
    form.append('file', file);
    form.append('service', service);
    form.append('splitLength', splitLength.toString());
    form.append('inputLang', inputLang);

    // リクエスト
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const { body } = await fetch(apiGateway + prefixApiGateway + '/minutes/transcribe', {
      method: 'POST',
      body: form,
      headers: { Authorization: `Bearer ${token}` },
    });

    // ストリームリーダーの取得
    const reader = body?.getReader();
    if (!reader) {
      throw Error('Failed to get reader.');
    }

    // 読み込み
    let done = false;
    let transcribedText = '';
    let buffer = '';
    while (!done) {
      const res = await reader.read();
      done = res.done;

      // バイナリデータをJSONに変換
      if (res.value) {
        // ストリームから取得したバイナリをテキスト化する
        // １つ以上のオブジェクトを受信している可能性がある
        // また、２つ目以降のオブジェクトは中途半端に受信している能性がある
        // そのため、一度すべての文字列をバッファーに格納した後に、バッファ全体に対して処理をかける
        //
        // 本来は処理が終了した箇所からバッファを捨てていく方が良いが、そこまでデータが多くないため一括で処理を行う
        //
        const text = new TextDecoder().decode(res.value);
        buffer += text;
        console.log(buffer);

        // ヌルコードでオブジェクトを区切っているため、ヌルコードを基準に文字列を分割する
        // この時点で、戻り値はJSON文字列の配列になっている
        const bodies = buffer.split('\n');

        // バリデーションエラー時は配列要素が1つしかないケースがある
        if (bodies.length === 1) {
          const json = JSON.parse(bodies[0]);
          throw json;
        }

        // 最後に受信したJSON文字列をパース
        // ヌルコードでsplitしているため、最終要素には空文字か受信途中のオブジェクトの文字列が入っている
        // そのため、配列長-2が正常に受信が完了してるJSONオブジェクトとなる
        const { result, message, error } = JSON.parse(bodies[bodies.length - 2]);

        // resultに値が入っている時は、文字起こしが終了したことを意味する
        // 次のループ時にdoneがtrueとなり、ループから抜けるため、文字起こしした結果をループ外の変数に保持する
        console.log(result);
        if (result) {
          console.log('result in');
          transcribedText = result;
        }

        // メッセージがある場合は、進捗通知に関する文字列が入っているため、通知用のコールバックを呼ぶ
        if (message) {
          onNotify?.(message);
        }

        // エラーを受信した場合は処理を中断する
        if (error) {
          console.log('ERROR!!!', error);
          throw error;
        }
      }
    }
    console.log('return : 😈', transcribedText);
    return transcribedText;
  }

  return {
    transcribe,
  };
};
