import { useEffect, useState } from 'react';
import { useLocales } from 'src/hooks/useLocales';
import { useNavigate } from 'react-router-dom';
import { PATH_DOC_SEARCH } from 'src/routes/paths';
import { dispatch } from 'src/redux/store';
import { setSideBarMenu } from 'src/redux/slices/sidebarSlice';
import { DocumentFile } from 'src/models/document-file';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { useDocumentFile } from 'src/hooks/useDocumentFile';
import useAgGridTheme from 'src/hooks/useAgGridTheme';
import { useDialog } from 'src/hooks/useDialog';
import useSettings from 'src/hooks/useSettings';

export const usePersonalDocumentCollectionPageModel = () => {
  // Dependency Hooks
  const locale = useLocales();
  const snackbar = useSnackbar();
  const documentFile = useDocumentFile();
  const navigate = useNavigate();
  const agGridTheme = useAgGridTheme();
  const deleteConfirmDialog = useDialog();
  const { themeMode } = useSettings();

  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentFiles, setDocumentFiles] = useState<DocumentFile[]>([]);
  const [selectedDocumentFile, setSelectedDocumentFile] = useState<DocumentFile | null>(null);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [usageStorage, setUsageStorage] = useState<number>(0);

  const bytesToMegaBytes = 1 / (1024 * 1024);
  const FOLDER_LIMIT = 1024 * 1024 * 1024;

  /**
   * ドキュメントファイルを削除するためのイベント
   * @param file 削除するドキュメントファイル
   */
  const onClickedDeleteButton = async (file: DocumentFile) => {
    // ダイアログのボタンが押されると処理が返ってくる作りになっている
    // okボタンが押された場合は、対象のファイルを削除する
    // 削除に成功した場合はリストの更新を行う
    setSelectedDocumentFile(file);
    const result = await deleteConfirmDialog.openDialog();
    if (result === 'ok') {
      await deleteDocumentFile(file)
        .then(async () => {
          const files = await fetchDocumentFiles();
          setDocumentFiles(() => files);
        })
        .finally(() => {
          setSelectedDocumentFile(null);
        });
    }
  };

  /**
   * ドキュメントファイルをダウンロードするためのイベント
   * @param file ダウンロードするドキュメントファイル
   */
  const onClickedDownloadButton = async (file: DocumentFile) => {
    await downloadDocumentFile(file);
  };

  /**
   * 登録画面へ遷移するためのボタンが押下された時のイベント
   */
  const onClickedGotoDocumentRegistrationPageButton = () => {
    navigate(PATH_DOC_SEARCH.personalUpload, { replace: false });
  };

  /**
   * ドキュメントファイルを取得するための関数
   */
  const fetchDocumentFiles = async () => {
    try {
      setListLoading(() => true);
      return await documentFile.getByUserID();
    } catch (err: any) {
      let message = '';
      if (err instanceof Error) message += err.message;
      snackbar.error(locale.translate('document-personal-collection.message.error.get') + message);
      return [];
    } finally {
      setListLoading(() => false);
    }
  };

  /**
   * ドキュメントファイルを削除するための関数
   * @param file 削除するドキュメントファイル
   * @param classification 組織分類
   */
  const deleteDocumentFile = async (file: DocumentFile) => {
    try {
      setIsLoading(() => true);
      await documentFile.removePersonal(file);
      snackbar.success(locale.translate('document-personal-collection.message.success.delete'));
    } catch (err: any) {
      let message = '';
      if (err instanceof Error) message += err.message;
      snackbar.error(locale.translate('document-personal-collection.message.error.delete') + message);
    } finally {
      setIsLoading(() => false);
    }
  };

  // /**
  //  * ドキュメントファイルをダウンロードするための関数
  //  * @param file ダウンロードするドキュメントファイル
  //  */
  const downloadDocumentFile = async (file: DocumentFile) => {
    try {
      setIsLoading(() => true);
      await documentFile.downloadPersonal(file);
    } catch (err: any) {
      let message = '';
      if (err instanceof Error) message += err.message;
      snackbar.error(locale.translate('document-personal-collection.message.error.download') + message);
    } finally {
      setIsLoading(() => false);
    }
  };

  // SIDE EFFECT
  // ================================================================================
  // サイドバーの表示
  useEffect(() => {
    dispatch(setSideBarMenu());
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
      const files = await fetchDocumentFiles();
      setDocumentFiles(() => files);
    };
    fetchFiles();
  }, []);

  useEffect(() => {
    if (documentFiles.length > 0) {
      const usedStorage = documentFiles.reduce((acc, doc) => acc + doc.size, 0);
      setUsageStorage(() => usedStorage);
      // setUsageStorage(() => 50 * 1024 * 1024 * 1024); // for test
    }
  }, [documentFiles]);

  return {
    // hooks
    locale,
    agGridTheme,
    deleteConfirmDialog,
    // states
    isLoading,
    documentFiles,
    selectedDocumentFile,
    listLoading,
    usageStorage,
    bytesToMegaBytes,
    FOLDER_LIMIT,
    themeMode,
    // event handlers
    onClickedDeleteButton,
    onClickedDownloadButton,
    onClickedGotoDocumentRegistrationPageButton,
  };
};
