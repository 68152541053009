import { useEffect, useRef } from 'react';

interface TypewriterProps {
  speed?: number;
}

export interface Typewriter {
  typing: (text: string, onTyping: (char: string) => void) => void;
  waitForTyping: () => Promise<void>;
  abort: () => void;
  clear: () => void;
}

export const useTypewriter = (props: TypewriterProps): Typewriter => {
  const buffer = useRef('');
  const typingTimer = useRef<NodeJS.Timeout | null>(null);
  const waitTimer = useRef<NodeJS.Timeout | null>(null);
  const speed = useRef(props.speed ?? 20);

  useEffect(() => abort, []);

  const typing = (text: string, onTyping: (char: string) => void) => {
    buffer.current += text;
    typingTimer.current && clearInterval(typingTimer.current);
    typingTimer.current = setInterval(() => {
      if (buffer.current.length) {
        onTyping(buffer.current[0]);
        buffer.current = buffer.current.slice(1);
      }
    }, speed.current);
  };

  const waitForTyping = async () => {
    return new Promise<void>((resolve) => {
      waitTimer.current && clearInterval(waitTimer.current);
      waitTimer.current = setInterval(() => {
        if (buffer.current.length === 0) {
          waitTimer.current && clearInterval(waitTimer.current);
          resolve();
        }
      }, speed.current);
    });
  };

  const abort = () => {
    typingTimer.current && clearInterval(typingTimer.current);
    waitTimer.current && clearInterval(waitTimer.current);
  };

  const clear = () => {
    buffer.current = '';
  };

  return {
    typing,
    waitForTyping,
    abort,
    clear,
  };
};
