import React, { useContext, createContext, useEffect, ReactNode, useState } from 'react';
import useChatMessageReducer, { defaultChatReducer } from './ChatMessageReducer';
import { mainChatAPI } from 'src/apis/mainChatAPI';
import { ChatLog } from 'src/@types/chat';
import { ChatMeesageContextType, RecordingStatus } from './type/ChatMessageContextType';

import { OptionChatType, chatModels } from 'src/models/chat-gpt';
import { promptAPI } from 'src/apis/promptsAPI';
import snackbar from 'src/components-mars/Snackbar';
import { PATH_CHAT } from 'src/routes/paths';
// createContextのdefaultValue
const { chatMessageDispatch: dispatch } = defaultChatReducer;

const initialState: ChatMeesageContextType = {
  chatMessages: [],
  selectId: '',
  handleSelectId: () => {},
  chatEnd: true,
  chatLog: [],
  handleChatLog: () => {},
  handleIsNewChat: () => {},
  abortCntl: undefined,
  handleAbortCntl: () => {},
  useChatType: chatModels[0],
  handleUseChatType: () => {},
  inputMessage: '',
  handleInputMessage: () => {},
  handleRegistPromptUsageHistory: () => {},
  handleRegistPromptUsageHistoryAsync: () => {},
  handleChatEnd: () => {},
  skipCount: 0,
  handleSkipCount: () => {},
  handleInactivity: () => {},
  inactivityTime: 1000 * 60 * 30,
  handleRecordingStatus: () => {},
  recordingStatus: 'STOPPING',
  isWebSearch: false,
  isTopWebSearch: false,
  handleSwitchWebSearch: () => {},
  handleChatHistoryWebSearch: () => {},
  handlingErrorGetPreMessages: () => {},
  isDelete: false,
  handleIsDelete: () => {},
  homeMessage: false,
  handleSwitchHomeMessage: () => {},
  homeToChat: false,
  handleHomeToChat: () => {},
  topicFlag: false,
  handleTopicFlag: (flag: boolean) => {},
};

const ChatMessageContext = createContext(initialState);

const ChatMessageDispatchContext = createContext(dispatch);

type Props = {
  children: ReactNode;
};

/**
 * チャットメッセージ用Provider
 * @param children コンポーネント
 * @returns Provider
 */
const ChatMessageProvider = ({ children }: Props) => {
  // カスタムフックの返り値
  const { chatMessages: chatMessages, chatMessageDispatch: chatMessageDispatch } = useChatMessageReducer();
  const [chatEnd, setChatEnd] = useState<boolean>(true);
  const [selectId, setSelectId] = useState<string>('');
  const [chatLog, setChatLog] = useState<ChatLog[]>([]);
  const [isNewChat, setIsNewChat] = useState<boolean>(true);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [useChatType, setUseChatType] = useState<OptionChatType>(chatModels[0]);
  const [abortCntl, setAbortCntl] = useState<AbortController | undefined>(undefined);
  const [skipCount, setSkipCount] = useState<number>(0);
  const { closeSnackbar } = snackbar();
  // TextFieldに入力したメッセージ
  const [inputMessage, setInputMessage] = useState('');
  // 録音ステータス
  const [recordingStatus, setRecordingStatus] = useState<RecordingStatus>('STOPPING');
  // serverへ送信するWEB検索ステータス(true/false)
  const [isWebSearch, setIsWebSearch] = useState<boolean>(false);
  // トップ画面のWEB検索ステータス(true/false)
  const [isTopWebSearch] = useState<boolean>(false);
  // ホームからメッセージを打ったら
  const [homeMessage, setHomeMessage] = useState<boolean>(false);
  const [homeToChat, setHomeToChat] = useState<boolean>(false);
  // new chat room
  const [topicFlag, setTopicFlag] = useState<boolean>(false);

  const initializeMessage = () => {
    if (selectId && selectId !== 'new' && !isNewChat) {
      mainChatAPI.getChatMessages(selectId).then((res) => {
        if (res.data && res.data.serverSideVersionString) {
          // 利用するAPIの種類とAIのバージョンを変更
          setUseChatType(chatModels.find((model) => model.serverSideVersionString === res.data.serverSideVersionString) || chatModels[0]);
        }
        chatMessageDispatch({
          type: 'message/init',
          payload: res.data.datas,
        });
      });
    }
  };

  useEffect(() => {
    if (selectId !== '' && selectId !== 'new') {
      // チャットIDに変更が生じたときにリストを再取得をするがNewChatの場合は取得処理を行わない
      mainChatAPI
        .getChatLogs(skipCount, 0)
        .then((res) => {
          setChatLog(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    initializeMessage();
    if (!selectId) {
      setIsWebSearch(false);
    }
  }, [selectId]);

  useEffect(() => {
    const currentURLpath = location.pathname;
    if (isWebSearch && !currentURLpath.startsWith(PATH_CHAT.root)) {
      setIsWebSearch(false);
    }
  }, [location.pathname]);

  //
  const handleRecordingStatus = (recordingStatus: RecordingStatus) => {
    setRecordingStatus(recordingStatus);
  };

  // 選択しているチャット履歴の操作（チャット履歴選択時にスナックバーを消す）
  const handleSelectId = (data: string) => {
    closeSnackbar();
    setSelectId(data);
  };

  const handleChatEnd = (data: boolean) => {
    setChatEnd(data);
  };

  // ダッシュボードに表示するチャット履歴一覧の操作
  const handleChatLog = (datas: ChatLog[]) => {
    setChatLog(datas);
  };

  // 選択している項目がNewChat状態の操作
  const handleIsNewChat = (data: boolean) => {
    setIsNewChat(data);
  };

  // APIをキャンセルするための状態の保存をする操作
  const handleAbortCntl = (data?: AbortController) => {
    setAbortCntl(data);
  };

  // 選択しているチャットタイプの操作
  const handleUseChatType = (data: OptionChatType) => {
    setUseChatType(data);
  };

  // 取得をスキップするチャットの数
  const handleSkipCount = (data: number) => {
    setSkipCount(data);
  };

  // 入力されたメッセージの操作
  const handleInputMessage = (data: string) => {
    setInputMessage(data);
  };

  // 利用されたプロンプト情報を、利用履歴に登録
  const handleRegistPromptUsageHistory = (data: number) => {
    // registPromptUsageHistory(data);
    promptAPI.registPromptUsageHistory(data);
  };

  const handleRegistPromptUsageHistoryAsync = async (data: number) => {
    // registPromptUsageHistory(data);
    await promptAPI.registPromptUsageHistory(data);
  };

  // プラグインのWEB検索の操作
  const handleSwitchWebSearch = (value: boolean) => {
    setIsWebSearch(value);
  };

  const handleSwitchHomeMessage = (data: boolean) => {
    setHomeMessage(data);
  };

  // チャット履歴のweb検索ステータスをセット
  const handleChatHistoryWebSearch = (data: boolean) => {
    setIsWebSearch(data);
  };

  const handleInactivity = () => {
    handleUseChatType(initialState.useChatType);
    handleSelectId(initialState.selectId);
  };

  const handleHomeToChat = (value: boolean) => {
    setHomeToChat(value);
  };

  const handlingErrorGetPreMessages = () => {
    initializeMessage();
  };

  const handleIsDelete = (data: boolean) => {
    setIsDelete(data);
  };

  const handleTopicFlag = (flag: boolean) => {
    setTopicFlag(flag);
  };

  return (
    <ChatMessageContext.Provider
      value={{
        chatMessages: chatMessages,
        selectId,
        chatEnd,
        handleSelectId,
        handleChatEnd,
        chatLog,
        handleChatLog,
        handleIsNewChat,
        abortCntl,
        handleAbortCntl,
        useChatType,
        handleUseChatType,
        skipCount,
        handleSkipCount,
        inputMessage,
        handleInputMessage,
        isWebSearch,
        isTopWebSearch,
        handleSwitchWebSearch,
        handleChatHistoryWebSearch,
        handleInactivity,
        inactivityTime: initialState.inactivityTime,
        recordingStatus,
        handleRecordingStatus,
        handlingErrorGetPreMessages,
        handleRegistPromptUsageHistory,
        handleRegistPromptUsageHistoryAsync,
        isDelete,
        handleIsDelete,
        homeMessage,
        handleSwitchHomeMessage,
        homeToChat,
        handleHomeToChat,
        topicFlag,
        handleTopicFlag,
      }}>
      <ChatMessageDispatchContext.Provider value={chatMessageDispatch}>{children}</ChatMessageDispatchContext.Provider>
    </ChatMessageContext.Provider>
  );
};

const useChatMessageContext = () => useContext(ChatMessageContext);

/**
 * 更新関数を登録しているコンテキストを返す関数
 */

const useDispatchChatMessageContext = () => useContext(ChatMessageDispatchContext);

export { useChatMessageContext, useDispatchChatMessageContext };
export default ChatMessageProvider;
