import { Card, CardContent, CardHeader, Container, Grid, Stack, Typography } from '@mui/material';
import { Loading } from '../../features/minutes/components/backdrop/Loading';
import { useMinutesTranscribingPageModel } from './useMinutesTranscribingPageModel';
import { RecordingDialog } from 'src/features/minutes/components/dialog/RecordingDialog';
import { TranscribingFromFileDialog } from 'src/features/minutes/components/dialog/TranscribingFromFileDialog';
import { TranscribingFromMicDialog } from 'src/features/minutes/components/dialog/TranscribingFromMicDialog';
import { StyledSelectList } from 'src/features/minutes/components/select/StyledSelectList';
import { Page } from 'src/components-minimals/Page';
import { PageTitle } from 'src/components/PageTitle';

export const MinutesTranscribingPage = () => {
  const vm = useMinutesTranscribingPageModel();

  return (
    <Page title="Minutes Transcribe">
      {/* prettier-ignore */}
      <Loading 
        isLoading={vm.isLoading} 
        text={vm.notify} 
        subText={vm.notifySubText} 
      />

      <RecordingDialog
        isOpen={vm.isOpenRecordingDialog}
        isRecording={vm.isRecording}
        onClick={vm.isRecording ? vm.onRecordingStop : vm.onRecordingStart}
        onClosed={vm.onRecordingDialogClose}
        text={vm.isRecording ? vm.t('transcribe.content.recording') : vm.t('transcribe.content.stopRecording')}
      />

      <Container maxWidth={'md'}>
        <Stack spacing={3}>
          {/* Page title */}

          <PageTitle text={vm.t('transcribe.text.title')} />

          {/* Page description */}
          <Typography component={'p'}>{vm.t('transcribe.text.description')}</Typography>

          <Stack spacing={2}>
            <Card>
              {/* オプションの設定用フォーム */}
              <CardHeader title={vm.t('transcribe.options.title')} />
              <CardContent>
                <Stack direction={'column'} spacing={2}>
                  <StyledSelectList
                    size="small"
                    label={vm.t('transcribe.options.inputLangSelectorLabel')}
                    value={vm.currentTranscribeInputLang.fullname}
                    options={vm.currentTranscribeService.service.langs.map((lang) => lang.fullname)}
                    onChange={vm.onChangeCurrentTranscribeInputLang}
                  />
                </Stack>
              </CardContent>

              {/* 文字起こし対象の設定用フォーム */}
              <CardHeader title={vm.t('transcribe.content.title')} />
              <CardContent>
                <Grid container direction={'row'} spacing={2}>
                  {/* Description */}
                  <Grid item xs={6}>
                    <TranscribingFromFileDialog onTranscribe={vm.onTranscribe} />
                  </Grid>
                  <Grid item xs={6}>
                    <TranscribingFromMicDialog onClick={vm.onRecordingDialogOpen} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
};
