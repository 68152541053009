import React from 'react';
// component
import { Box } from '@mui/material';
// grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import type { ColDef, GridOptions } from 'ag-grid-enterprise';

interface BoxedGridProps<T = any> {
  id?: string;
  classes?: string;
  gridTheme: string;
  gridHeight?: string;
  gridRef?: React.RefObject<AgGridReact<T>>;
  gridOptions: GridOptions;
  defaultColDef: ColDef;
  columnDefs: ColDef[];
  rowData: T[] | undefined;
  width?: number | string;
}

/**
 *
 * @return コンポーネント
 */
export const BoxedGrid = React.memo(
  ({
    id = 'boxed-grid',
    classes = '',
    gridTheme = 'ag-theme-alpine',
    gridHeight = undefined,
    gridOptions,
    defaultColDef,
    columnDefs,
    rowData,
    width = '100%',
  }: BoxedGridProps): React.ReactElement => {
    return (
      <Box
        id={id}
        className={[classes, gridTheme].join(' ')}
        sx={{
          height: gridHeight ?? '50vh',
          width: width,
          transition: 'height 0.4s',
        }}>
        <AgGridReact gridOptions={gridOptions} defaultColDef={defaultColDef} columnDefs={columnDefs} rowData={rowData} />
      </Box>
    );
  },
);
