// ... menu : meatballs menu
import { Box, Divider, IconButton, PopoverOrigin, SxProps } from '@mui/material';
import React from 'react';
import { ThemeMode } from 'src/@types/settings';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { StyledMenu } from 'src/components/StyledMenu';
import { CustomMenuItem, CustomMenuItemProps } from './CustomMenuItem';

interface MeatballsMenuProps {
  sx?: SxProps;
  themeMode: ThemeMode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorElement: null | HTMLElement;
  onClose: () => void;
  itemList: CustomMenuItemProps[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  chatMain?: boolean;
  plugin?: CustomMenuItemProps;
}

export const MeatballsMenu = React.memo(
  ({
    sx,
    themeMode,
    onClick,
    anchorElement,
    onClose,
    itemList,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'right',
    },
    chatMain,
    plugin,
  }: MeatballsMenuProps): React.ReactElement => {
    return (
      <>
        <Box sx={sx ? sx : { textAlign: 'end', width: '100%', paddingBottom: '2px' }}>
          <IconButton
            accessKey=""
            size="small"
            color="primary"
            sx={{ borderRadius: 0.8 }}
            id="basic-button"
            aria-controls={Boolean(anchorElement) ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorElement) ? 'true' : undefined}
            onClick={onClick}>
            <MoreHorizRoundedIcon />
          </IconButton>
        </Box>
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorElement}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          open={Boolean(anchorElement)}
          onClose={onClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            dense: true,
          }}>
          {itemList.map((item, i) => (
            <CustomMenuItem
              key={i}
              message={item.message}
              onClick={item.onClick}
              icon={item.icon}
              onChange={item.onChange}
              isSwitch={item.isSwitch}
              isChecked={item.isChecked}
              showing={item.showing}
              pl={item.pl}
            />
          ))}
        </StyledMenu>
      </>
    );
  },
);
