import { Grid, SxProps, Typography } from '@mui/material';
import React from 'react';

interface PageDescriptionProps {
  text?: string;
  sx?: SxProps;
}

export const PageDescription = React.memo(({ text, sx }: PageDescriptionProps) => {
  return (
    <Grid item sx={sx}>
      <Typography component={'p'} fontSize={'14px'} maxWidth={'800px'}>
        {text}
      </Typography>
    </Grid>
  );
});
