import { Box, Divider, InputBase, Tab, Theme, ThemeOptions, makeStyles } from '@mui/material';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ChangeEvent, useState } from 'react';

interface MarkdownViewProps {
  variant: 'body1' | 'body2';
  text: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => Promise<void>;
  onBlur: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => Promise<void>;
}

export const MarkdownView = (props: MarkdownViewProps) => {
  const { variant, text } = props;
  const { onChange, onBlur } = props;
  const editable = onChange !== undefined;
  const rawMarkdown = marked(text);
  const sanitized = DOMPurify.sanitize(rawMarkdown);
  const [index, setIndex] = useState<string>('preview');

  // フォントサイズの設定
  let fontSize = 0;
  if (variant === 'body1') fontSize = 16;
  if (variant === 'body2') fontSize = 14;

  return (
    <Box
      sx={{
        pl: 1.5,
        pr: 1.5,
        pt: 1,
        pb: 1,
        borderWidth: 1,
        borderColor: !editable ? 'grey.700' : 'grey.500',
        borderStyle: 'solid',
        borderRadius: 1,
        ':hover': {
          borderColor: !editable ? 'grey.700' : 'primary.main',
        },
        minHeight: 180,
        '.MuiTabs-root': { minHeight: 24, maxHeight: 24, mb: 1 },
      }}>
      <TabContext value={index}>
        <TabList
          variant="scrollable"
          onChange={(_, v) => setIndex(v)}
          sx={{
            '.MuiTabs-scroller': { maxHeight: 24 },
            '.MuiButtonBase-root': {
              fontSize: 12,
              minHeight: 24,
            },
          }}>
          <Tab label={'Preview'} value={'preview'} />
          <Tab label={'Editor'} value={'editor'} />
        </TabList>
        <Divider />
        <TabPanel value={'preview'}>
          <Box sx={{ color: 'inherit' }} dangerouslySetInnerHTML={{ __html: sanitized }} />
        </TabPanel>
        <TabPanel value={'editor'}>
          <InputBase
            sx={{
              color: 'inherit',
              fontSize: fontSize,
              '.MuiInputBase-inputMultiline': {
                textAlign: 'justify',
              },
            }}
            fullWidth
            autoComplete={'off'}
            multiline
            readOnly={!editable}
            {...props}
            minRows={16}
            value={text}
            onChange={onChange}
            onBlur={onBlur}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
