// material
// import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
// import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
// アプリロゴ
/**
 *
 * @param  param0 パラメータ
 * @return コンポーネント
 */
export const Logo = ({ sx }: BoxProps) => {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <FontAwesomeIcon icon={faStar} size="lg" />
    </Box>
  );
};
