import { Card, CardActionArea, CardContent, CardMedia, Grid, Paper, SxProps, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

type Props = {
  title: string;
  description: string;
  img: string;
  onClick: () => void;
  sx?: SxProps;
  guideline: string;
  isMobile: boolean;
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export const MenuCard = React.memo(
  ({
    title,
    description,
    img,
    onClick,
    sx = { display: 'flex', maxWidth: '927px', height: '72px' },
    guideline,
    isMobile,
  }: Props): React.ReactElement => {
    return (
      <Grid item md={6} xs={12}>
        <Paper elevation={0}>
          <CustomWidthTooltip disableTouchListener arrow placement="bottom" title={<TooltipContent content={guideline} />}>
            <Grid>
              <CustomCard
                sx={sx}
                onClick={onClick}
                title={title}
                description={description}
                img={img}
                height={isMobile ? 70 : 75}
                width={isMobile ? 80 : 115}
              />
            </Grid>
          </CustomWidthTooltip>
        </Paper>
      </Grid>
    );
  },
);

const TooltipContent = ({ content }: { content: string }): React.ReactElement => {
  return (
    <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap' }} width={'500px'}>
      {content}
    </Typography>
  );
};

const CustomCard = ({
  sx,
  onClick,
  title,
  description,
  img,
  width,
  height,
}: {
  sx: SxProps;
  onClick: () => void;
  title: string;
  description: string;
  img: string;
  width: number;
  height: number;
}): React.ReactElement => {
  return (
    <Card sx={sx}>
      <CardActionArea sx={{ display: 'flex' }} onClick={onClick}>
        <Grid item xs={10} container direction={'column'}>
          <CardContent>
            <Typography component="div" variant={'subtitle2'}>
              {title}
            </Typography>
            <Typography variant={'caption'} color={'text.secondary'} component="div">
              {description}
            </Typography>
          </CardContent>
        </Grid>
        <Grid xs={3}>
          <CardMedia component="img" sx={{ width: width, height: height }} image={`/static/images/menu/${img}.png`} />
        </Grid>
      </CardActionArea>
    </Card>
  );
};
