import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { HostClientType, app, authentication } from '@microsoft/teams-js';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Link, Stack, Alert, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { experimentalStyled as styled } from '@mui/material/styles';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import { INITIAL_PATH } from '../../../constants';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';
import { dispatch } from '../../../redux/store';
import { stopLoading } from '../../../redux/slices/loginSlice';
import { currentEnv } from 'src/config';
import { teamsAppConfig, amplifyConfig } from 'src/config';
import axios, { AxiosRequestConfig } from 'axios';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';

// for icon informations, please see the url below
// https://material-ui.com/components/material-icons/

type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

type LoginFormProps = {
  onGetUser: (user: any) => void;
};

const StyledButton = styled(Button)`
  opacity: 0;
  width: 1px !important;
  margin-right: 600 !important;
`;

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 8,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});
/**
 *
 * @param  param0 ログインproperties
 * @return   ログイン画面
 */
export const LoginForm = ({ onGetUser }: LoginFormProps) => {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loginPathNameLocalStrageKey: string = 'callbackLoginPathName';

  const [teamsMessage, setTeamsMessage] = useState('');
  const [teamsLoginDisabled] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('login.message.emailTypeValid')).required(t('login.message.emailIsNotEmpty')),
    password: Yup.string().required(t('login.message.pwIsNotEmpty')),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        // FIXME: 関心の分離の観点から、local-storage用のhooksか、login処理用のhooksを作って、そこでremoveする方が良さそう
        localStorage.removeItem(loginPathNameLocalStrageKey);
        dispatch(stopLoading());
        const result = await login(values.email, values.password);

        if (result) {
          enqueueSnackbar('Login success', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
          if (isMountedRef.current) {
            setSubmitting(false);

            window.location.pathname !== '/'
              ? navigate(window.location.pathname + window.location.search, { replace: true })
              : navigate(INITIAL_PATH, { replace: true });
          }
        }
      } catch (err: any) {
        if (err.isNewPasswordRequired) {
          onGetUser(err.data);
        } else {
          console.error(err);
          resetForm();
          if (isMountedRef.current) {
            setSubmitting(false);
            setErrors({ afterSubmit: err.data.message });
          }
        }
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const classes = useStyles();

  const [display, setDisplay] = useState('none');

  const handleOpen = () => {
    setDisplay('block');
  };

  const externalAuth = () => {
    const url = window.location.origin + '/auth/close-popup';
    return new Promise((resolve, reject) => {
      app.initialize().then(() => {
        app.getContext().then((context) => {
          const clientType = context.app.host.clientType;
          const oauthRedirectMethod = clientType === HostClientType.web ? 'web' : 'noweb';
          const authId = teamsAppConfig.id;
          // setTeamsLoginDisabled(true);
          setTeamsMessage('');
          authentication
            .authenticate({
              url: `${window.location.origin}/auth/start-popup?oauthRedirectMethod=${oauthRedirectMethod}&authId=${authId}&hostRedirectUrl=${url}`,
              isExternal: true,
            })
            .then((code) => {
              loginFromTeams(code);
              resolve(code);
            })
            .catch((error) => {
              // setTeamsLoginDisabled(false);
              setTeamsMessage('Your login process has been timeout, please login again.');
              // reject(error);
            });
        });
      });
    });
  };

  const loginFromTeams = async (code: string) => {
    const clientId = amplifyConfig.aws_user_pools_web_client_id;
    const redirectUrl = encodeURI(amplifyConfig.redirect_sign_in_teams as string);
    const domainUrl = amplifyConfig.cognitoDomain;
    const grantType = 'authorization_code';

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const url = `https://${domainUrl}/oauth2/token?client_id=${clientId}&code=${code}&redirect_uri=${redirectUrl}&grant_type=${grantType}`;

    axios
      .post(url, {}, config)
      .then((response: any) => {
        const result = response.data;
        // create a CognitoAccessToken using the response accessToken
        const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
          AccessToken: result.access_token,
        });
        // create a CognitoIdToken using the response idToken
        const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
          IdToken: result.id_token,
        });
        // create a RefreshToken using the response refreshToken
        const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
          RefreshToken: result.refresh_token,
        });
        // create a session object with all the tokens
        const sessionData = {
          IdToken: IdToken,
          AccessToken: AccessToken,
          RefreshToken: RefreshToken,
        };
        // create the CognitoUserSession using the sessionData
        const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);
        // create an object with the UserPoolId and ClientId
        const poolData = {
          UserPoolId: amplifyConfig.aws_user_pools_id,
          ClientId: amplifyConfig.aws_user_pools_web_client_id,
        };
        // pass the poolData object to CognitoUserPool
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        // create an object containing the username and user pool.
        // You can get the username from CognitoAccessToken object
        // we created above.
        const userData = {
          Username: AccessToken.payload.username,
          Pool: userPool,
        };
        // create a cognito user using the userData object
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        // set the cognito user session w/ the CognitoUserSession
        cognitoUser.setSignInUserSession(session);
        // 画面のリフレシュ
        window.location.href = amplifyConfig.redirect_sign_in;
      })
      .catch((error) => {
        throw error;
      });
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {window !== window.parent ? (
          <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
            <Button fullWidth color="secondary" size="large" variant="contained" onClick={externalAuth} disabled={teamsLoginDisabled}>
              {`${t('login.text.toyotsuLogin')}`}
            </Button>
            <br />
            <div>{teamsMessage}</div>
          </Stack>
        ) : (
          <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={2} sx={{ my: 1 }}>
            <Button
              fullWidth
              color="secondary"
              size="large"
              variant="contained"
              // startIcon={<FontAwesomeIcon icon={faWindows} size="xs" />}
              // loading={isSubmitting}
              onClick={() => {
                // FIXME: 関心の分離の観点から、local-storage用のhooksか、login処理用のhooksを作って、そこでremoveする方が良さそう
                localStorage.setItem(loginPathNameLocalStrageKey, location.pathname + location.search);
                // @ts-ignore
                Auth.federatedSignIn({ provider: 'mickey' });
              }}>
              {t('login.text.toyotsuLogin')}
            </Button>
            {(currentEnv === 'local' || currentEnv === 'trial') && (
              <Button
                fullWidth
                color="secondary"
                size="large"
                variant="contained"
                onClick={() => {
                  localStorage.setItem(loginPathNameLocalStrageKey, location.pathname + location.search);
                  // @ts-ignore
                  Auth.federatedSignIn({ provider: 'EntraId' });
                }}>
                {t('login.text.entraIdLogin')}
              </Button>
            )}
          </Stack>
        )}
        <Stack sx={currentEnv !== 'local' || window !== window.parent ? { display: display } : {}}>
          <Stack sx={{ height: '80px', alignSelf: 'center' }}>
            <Box
              sx={{
                marginTop: '20px',
                alignSelf: 'center',
                fontSize: '20px',
                fontWeight: '10',
              }}>
              or
            </Box>
          </Stack>
          <Stack spacing={2}>
            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
            <TextField
              fullWidth
              size="medium"
              autoComplete="username"
              type="email"
              label={t('login.text.email')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              size="medium"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label={t('login.text.pw')}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 0 }}>
            <FormControlLabel control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />} label={t('login.text.saveInfo')} />

            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
              {t('login.text.forgot')}
            </Link>
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} className={classes.root}>
            {t('login.text.mars')}
          </LoadingButton>
        </Stack>
        <StyledButton onClick={handleOpen} id="button">
          _
        </StyledButton>
      </Form>
    </FormikProvider>
  );
};
