// material
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Props = {
  size: string | number;
  themeMode?: string;
};

export const ChatLogo = ({ size, themeMode }: Props) => {
  const theme = useTheme();
  const sizeStr = typeof size === 'number' ? `${size}px` : size;

  const LINE = themeMode === 'light' ? theme.palette.common.black : theme.palette.common.white;
  const BACKGROUND = theme.palette.background.default;

  return (
    <Box sx={{ width: sizeStr, height: sizeStr }}>
      <svg height="100%" version="1.1" viewBox="0 0 123.615 120.367" width="100%" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill={LINE} d="M9.6296 9.72292L115.199 9.72292L115.199 113.574L9.6296 113.574L9.6296 9.72292Z" opacity="1" />
        </g>
        <g>
          <path
            fill={BACKGROUND}
            d="M109.621 3L14.6217 3C9.39766 3 5.12124 7.27642 5.12124 12.5005L5.12124 107.499C5.12124 112.723 9.39766 117 14.6217 117L109.621 117C114.845 117 119.121 112.723 119.121 107.499L119.121 12.5005C119.121 7.27642 114.845 3 109.621 3ZM104.871 83.7504L62.1212 107.499L38.3707 94.3068L38.3707 47.43L62.1212 34.2374L85.8716 47.43L85.8716 72.5713L62.1212 85.7625L57.3717 83.1234L57.3717 58.6077L62.1212 55.9743L66.8707 58.6092L66.8707 72.2564L76.3712 66.981L76.3712 53.0175L62.1212 45.0987L47.8712 53.0175L47.8712 88.7122L62.1212 96.631L95.3707 78.1601L95.3707 41.8398L62.1212 23.3689L28.8717 41.8398L28.8717 89.0286L19.3712 83.7504L19.3712 36.2495L62.1212 12.5005L104.871 36.2495L104.871 83.7504Z"
            opacity="1"
          />
        </g>
      </svg>
    </Box>
  );
};
