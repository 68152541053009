import { useEffect, useState } from 'react';
import { TermsOfService, TermsType } from 'src/models/term';
import { useDialog } from './useDialog';
import useAuth from './useAuth';
import { termsofserviceAPI } from 'src/apis/termsofserviceAPI';
import { useTranslation } from 'react-i18next';
import { useDateFormatter } from './useDateFormatter';
import { useLocales } from './useLocales';
import { logAPI } from 'src/apis/logAPI';

export const useTermsOfService = () => {
  // Dependency hooks
  const dialog = useDialog();
  const auth = useAuth();
  const { t } = useTranslation();
  const locales = useLocales();
  const dateFormatter = useDateFormatter();

  // States
  const [terms, setTerms] = useState<TermsOfService[]>([]);

  /**
   * ログインが完了した時の処理
   * このHooksが利用される際にログインしている保証がないため、auth.isAuthenticatedが変更された際に利用規約の更新を確認する
   */
  useEffect(() => {
    if (auth.isAuthenticated) {
      checkUpdatedTerms();
    }
  }, [auth.isAuthenticated]);

  /**
   * 利用規約の更新を確認する
   */
  const checkUpdatedTerms = async () => {
    // 更新された利用規約がある場合のみAPIから規約情報が取得される
    // そのため、更新された利用規約がない場合は、同意の確認を行わない
    const terms = await fetchTerms();
    if (terms.length !== 0) {
      setTerms(terms);
      return await confirmTarms();
    } else {
      // contentがなければログイン履歴登録
      logAPI.registLogin().catch((err) => {
        console.error(err);
      });
    }
  };

  /**
   * 利用規約を取得する
   */
  const fetchTerms = async () => {
    return await termsofserviceAPI.checkAgree();
  };

  /**
   * 利用規約の同意を確認する
   */
  const confirmTarms = async () => {
    const twins = 'https://ttc-twins.toyotsu.co.jp';
    const gtwins = 'https://ttc-g-twins.toyotsu.co.jp/index.html';
    const isToyotsu = auth.user?.company === '"S500"';
    const result = await dialog.openDialog();
    if (result === 'positive') {
      // 同意された時の処理
      // 同意日時を登録する
      await termsofserviceAPI.registAgree().then((_) => {
        // 利用者登録成功したらログイン履歴登録
        logAPI.registLogin().catch((err) => {
          console.error(err);
        });
      });
    } else if (result === 'negative') {
      // 同意されなかった時の処理
      // 同意されなかった時は、豊通単体の人はTwinsに遷移する\
      // それ以外はGTwinsに遷移する
      // window.location.href = isToyotsu ? twins : gtwins;
      window.open(isToyotsu ? twins : gtwins, '_blank');
      await confirmTarms();
    } else {
      throw new Error('Invalid result');
    }
  };

  /**
   * 利用規約のタイトルを表示する
   * @param tos 利用規約
   * @returns 利用規約のタイトル
   */
  const displayTermsTitle = (tos: TermsOfService) => {
    switch (tos.type) {
      case TermsType.PrivacyPolicy:
        return t('privacy-policy.text.pageTitle');
      case TermsType.GDPR:
        return t('gdpr.text.pageTitle');
      case TermsType.CCPA:
        return t('ccpa.text.pageTitle');
      default:
        throw Error('Invalid term type');
    }
  };

  /**
   * 利用規約の情報を表示する
   * @param tos 利用規約
   * @returns 利用規約の情報の文字列
   */
  const displayTermsInfo = (tos: TermsOfService) => {
    const label = t('common.text.update').toUpperCase();
    const date = dateFormatter.toLocalesDateString(locales.currentLang.code, tos.createdAt);
    return `${label}: ${date}`;
  };

  return {
    t,
    terms,
    open: dialog.isOpen,
    onAgree: dialog.onPositive,
    onDisagree: dialog.onNegative,
    displayTermsTitle,
    displayTermsInfo,
  };
};
