import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useCopy = (dsResponse: string) => {
  const { t } = useTranslation();
  const copiedMsg = t('common.text.Copied');
  const copyBtnLabel = t('common.button.copy');
  const [isOpenCopyTip, setIsOpenCopyTip] = useState<boolean>(false);
  const handleCloseTip = (): void => {
    setIsOpenCopyTip(false);
  };
  const copyToClipboard = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.log(error || t('common.message.error.copyFailurelog'));
    }
  }, []);
  const handleCopyButtonClick = useCallback((): void => {
    setIsOpenCopyTip(true);
    copyToClipboard(dsResponse);
    // 3秒後に閉じる
    setTimeout(() => {
      setIsOpenCopyTip(false);
    }, 3000);
  }, [dsResponse]);
  return { copiedMsg, copyBtnLabel, isOpenCopyTip, handleCloseTip, handleCopyButtonClick };
};
