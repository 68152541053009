import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  FormControl,
  FormControlPropsSizeOverrides,
  TextField,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

export type StyledAutoCompliteOnChangeCallback = (
  event: React.SyntheticEvent<Element, Event>,
  value: string | null,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<string> | undefined,
) => void;

interface StyledAutoCompliteProps<T> {
  label: string;
  value: T | null;
  options: T[];
  display: (value: T) => string;
  size?: OverridableStringUnion<'small' | 'medium', FormControlPropsSizeOverrides>;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange?: (value: T | null) => void;
}

export const StyledAutocomplite = <T extends any>(props: StyledAutoCompliteProps<T>) => {
  const { label, value, options, display, size, disabled, fullWidth } = props;
  const { onChange } = props;

  const onChangeHandler = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    const results = options.filter((option) => display(option) === value);
    if (results.length === 1) {
      onChange?.(results[0]);
    } else {
      onChange?.(null);
    }
  };

  return (
    <FormControl fullWidth={fullWidth} size={size} disabled={disabled}>
      <Autocomplete
        value={value ? display(value) : null}
        size={size}
        options={options.map(display)}
        renderInput={(params) => <TextField {...params} label={label} disabled={disabled} />}
        onChange={onChangeHandler}
        disabled={disabled}
      />
    </FormControl>
  );
};
