/* eslint-disable new-cap */
import { useEffect } from 'react';
import Lightbox, { ILightBoxProps } from 'react-image-lightbox';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import { Typography, GlobalStyles, IconButton } from '@mui/material';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ClearIcon from '@mui/icons-material/Clear';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { SvgIconComponent } from '@mui/icons-material';
import { OriginImageList } from 'src/contexts/ChatMessageReducer';
// ----------------------------------------------------------------------

const ModalStyles = () => {
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  const ICON_SIZE = 32;
  const ICON_COLOR = theme.palette.grey[600];

  const IconComponent = (Icon: SvgIconComponent) => ({
    opacity: 1,
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    transition: theme.transitions.create('opacity'),
    '&:hover': {
      opacity: 0.72,
    },
    svg: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      color: ICON_COLOR,
    },
  });

  return (
    <GlobalStyles
      styles={{
        '& .ReactModalPortal': {
          '& .ril__outer': {
            backgroundColor: alpha(theme.palette.grey[900], 0.96),
          },

          // Toolbar
          '& .ril__toolbar': {
            height: 'auto !important',
            padding: theme.spacing(2, 3),
            backgroundColor: 'transparent',
          },
          '& .ril__toolbarLeftSide': { display: 'none' },
          '& .ril__toolbarRightSide': {
            height: 'auto !important',
            padding: 0,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            '& li': {
              display: 'flex',
              alignItems: 'center',
            },
            '& li:first-of-type': {
              flexGrow: 1,
            },
            '& li:not(:first-of-type)': {
              width: 40,
              height: 40,
              justifyContent: 'center',
              marginLeft: theme.spacing(2),
            },
          },

          // Button
          '& button:focus': { outline: 'none' },
          '& .ril__toolbarRightSide button': {
            width: '100%',
            height: '100%',
            '&.ril__zoomInButton': IconComponent(ZoomInIcon),
            '&.ril__zoomOutButton': IconComponent(ZoomOutIcon),
            '&.ril__closeButton': IconComponent(ClearIcon),
          },
          '& .ril__navButtons': {
            padding: theme.spacing(3),
            '&.ril__navButtonPrev': {
              right: 'auto',
              left: theme.spacing(2),
              ...IconComponent(isRTL ? KeyboardArrowRightIcon : KeyboardArrowLeftIcon),
            },
            '&.ril__navButtonNext': {
              left: 'auto',
              right: theme.spacing(2),
              ...IconComponent(isRTL ? KeyboardArrowLeftIcon : KeyboardArrowRightIcon),
            },
          },
        },
      }}
    />
  );
};

// ----------------------------------------------------------------------

interface Props extends ILightBoxProps {
  images: string[];
  photoIndex: number;
  setPhotoIndex: (index: number) => void;
  isOpen: boolean;
  download: () => void;
  originImg?: OriginImageList[];
}

const ChatImageModal = ({ images, photoIndex, setPhotoIndex, isOpen, download, originImg, mainSrc, ...other }: Props) => {
  const theme = useTheme();
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  const showIndex = <Typography variant="subtitle2">{`${photoIndex + 1} / ${images.length}`}</Typography>;

  const toolbarButtons = [
    showIndex,
    <IconButton onClick={download}>
      <SimCardDownloadIcon sx={{ color: theme.palette.common.white }} />
    </IconButton>,
  ];

  const customStyles = {
    overlay: {
      zIndex: 9999,
    },
  };

  return (
    <>
      <ModalStyles />

      {isOpen && (
        <Lightbox
          // discourageDownloads={true}
          mainSrc={mainSrc}
          animationDuration={160}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          toolbarButtons={toolbarButtons}
          reactModalStyle={customStyles}
          {...other}
        />
      )}
    </>
  );
};

export default ChatImageModal;
