import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompts } from 'src/@types/prompt';

interface UsePromptCardProps {
  handleInputMessage: (data: string) => void;
  handleRegistPromptUsageHistory: (data: number) => void;
  handleRegistPromptUsageHistoryAsync: (data: number) => void;
}
export const usePromptCard = ({ handleInputMessage, handleRegistPromptUsageHistory, handleRegistPromptUsageHistoryAsync }: UsePromptCardProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [prompt, setPrompt] = useState('');
  const [replace, setReplace] = useState<string[]>([]);
  const [sampleResponse, setSampleResponse] = useState('');
  const [promptCategory, setPromptCategory] = useState(0);

  const buttonLabel = t('prompts.examplePrompt.chatOpenButton');
  const modalTitlePrompt = t('prompts.examplePrompt.modalTitlePrompt');
  const modalTitleSample = t('prompts.examplePrompt.modalTitleSample');

  /**
   * プロンプトのカードを選択した時の処理
   * 詳細モーダルに渡すパラメータをセットし、モーダルを表示する
   */
  const handleClick = useCallback((item: Prompts) => {
    setShowModal(true);
    setPromptCategory(Number(item.promptId));
    setTitle(item.title);
    setTags(item.tags);
    setPrompt(item.prompt);
    setReplace(item.replace);
    setSampleResponse(item.sampleResponse);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setPromptCategory(0);
    setTitle('');
    setTags([]);
    setPrompt('');
    setReplace([]);
    setSampleResponse('');
  }, []);

  const handleChatOpenButtonClick = useCallback(
    (promptId: number, updated: string[]) => {
      handleRegistPromptUsageHistory(promptId);
      let value2 = prompt;
      updated.forEach((str, index) => (value2 = value2.replace(replace[index], str)));
      handleInputMessage(value2);
    },
    [prompt, replace],
  );

  const handleChatOpenButtonClickAsync = useCallback(
    async (promptId: number, updated: string[]) => {
      await handleRegistPromptUsageHistoryAsync(promptId);
      let value2 = prompt;
      updated.forEach((str, index) => (value2 = value2.replace(replace[index], str)));
      handleInputMessage(value2);
    },
    [prompt],
  );

  return {
    showModal,
    title,
    tags,
    prompt,
    sampleResponse,
    promptCategory,
    replace,
    handleClick,
    closeModal,
    buttonLabel,
    modalTitlePrompt,
    modalTitleSample,
    handleChatOpenButtonClick,
    handleChatOpenButtonClickAsync,
  };
};
