/* eslint-disable prefer-promise-reject-errors */
import { createContext, ReactNode, useCallback, useEffect, useReducer } from 'react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserSession, CognitoUserAttribute } from 'amazon-cognito-identity-js';
// utils
import axios from '../utils/axios';
// routes
import { PATH_AUTH } from '../routes/paths';
// @types
import { ActionMap, AuthState, AuthUser, AWSCognitoContextType } from '../@types/authentication';
//
import { amplifyConfig } from '../config';
import * as config from '../config';
import Amplify, { Auth, Hub } from 'aws-amplify';

export const UserPool = new CognitoUserPool({
  UserPoolId: amplifyConfig.aws_user_pools_id || '',
  ClientId: amplifyConfig.aws_user_pools_web_client_id || '',
});

let fromTeams = false;
if (window.location.search) {
  const params = new URLSearchParams(window.location.search);
  if (!!params.get('authId')) {
    fromTeams = true;
  } else {
    if (params.get('state')?.includes('{') && params.get('state')?.includes('}')) {
      const { authId, method } = JSON.parse(params.get('state') as string);
      fromTeams = !!authId && !!method;
    }
  }
}

if ((window.opener !== null && window.opener !== window) || fromTeams) {
  // Teamから開かれるため、Amplifyを設定しない
} else {
  Amplify.configure({
    Auth: {
      region: 'ap-northeast-1',
      userPoolId: amplifyConfig.aws_user_pools_id,
      userPoolWebClientId: amplifyConfig.aws_user_pools_web_client_id,
      oauth: {
        domain: amplifyConfig.cognitoDomain,
        scope: ['openid'],
        // TODO:ここを環境変数等で指定できるようにする必要あり
        redirectSignIn: amplifyConfig.redirect_sign_in,
        redirectSignOut: amplifyConfig.redirect_sign_out,
        responseType: 'code',
        // options: {},
        // urlOpener: (url: string, redirectUrl: string) => Promise < any >,
      },
    },
  });
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  auth = 'AUTHENTICATE',
  logout = 'LOGOUT',
}

type AwsAuthPayload = {
  [Types.auth]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.logout]: undefined;
};

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>];

const reducer = (state: AuthState, action: AwsActions) => {
  if (action.type === 'AUTHENTICATE') {
    const { isAuthenticated, user } = action.payload;
    // console.log('🐮', action);
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext<AWSCognitoContextType | null>(null);
/**
 *
 * @param  param0 パラメータ
 * @return コンポーネント
 */
const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const loginPathNameLocalStrageKey: string = 'callbackLoginPathName';

  const getUserAttributes = useCallback(
    (currentUser: CognitoUser): Record<string, any> =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          // console.log('🐤', attributes);
          if (err) {
            reject(err);
          } else {
            const results: Record<string, any> = {};

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    [],
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();

        if (user) {
          user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              reject(err);
            } else {
              const attributes = await getUserAttributes(user);
              const token = session?.getIdToken().getJwtToken();

              const payload = session?.getIdToken().payload || {};
              Object.keys(payload).forEach((key) => {
                // console.log('🍌', key, ':', payload![key]);
                attributes[key] = payload[key];
              });

              // use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
              // axios.defaults.headers.common.Authorization = 'Bearer ' + token;
              axios.interceptors.request.use(async (request) => {
                const token = await new Promise((resolve2) => {
                  user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
                    if (err) {
                      // console.log(err);
                      alert('セッションの有効時間が切れましたので再ログインしてください。(The session has expired, so please log in again.)'); // 遭遇頻度が低くsnackbarが使用できないためalertにて表示
                    } else {
                      const token = session?.getIdToken().getJwtToken();
                      resolve2(token);
                    }
                  });
                });
                request.headers.Authorization = 'Bearer ' + token;
                return request;
              });
              dispatch({
                type: Types.auth,
                payload: { isAuthenticated: true, user: attributes },
              });
              resolve({
                user,
                session,
                headers: { Authorization: token },
              });
            }
          });
        } else {
          dispatch({
            type: Types.auth,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes],
  );

  // const locationUrl = useLocation();

  // useEffect(() => {
  //   const loginPath: string | null = localStorage.getItem(loginPathNameLocalStrageKey);
  //   if (loginPath && loginPath === location.pathname + location.search) {
  //     localStorage.removeItem(loginPathNameLocalStrageKey);
  //     appDispatch(stopLoading());
  //   }
  // }, [locationUrl]);

  const initial = useCallback(async () => {
    try {
      // console.log('initial!!!');

      await getSession();
    } catch {
      // console.log('🐴 dispachします。');
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  const initialForAd = () => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          // console.log('🐟 sign in');
          break;
        case 'cognitoHostedUI':
          (async () => {
            await Auth.currentSession();
            // const userData = await Auth.currentSession();
            // console.log('🐔', userData);
            // await loginFromAzure(userData);
          })();
          break;
        case 'signOut':
          // console.log('サインアウトされました。');
          // setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          // console.log('Sign in failure', data);
          break;
        case 'customOAuthState':
          // console.log('🐟 customOAuthState');
          break;
      }
    });

    // 毎回実行される
    getUser().then(async (userData) => {
      await loginFromAzure(userData!);
    });

    return () => {
      Hub.remove('auth', () => Auth.signOut().catch((err) => console.log('error signing out: ', err)));
    };
  };

  useEffect(() => {
    if ((window.opener !== null && window.opener !== window) || fromTeams) {
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    } else {
      initialForAd();
      initial();
    }
  }, [initial]);

  const getUser = () => {
    return Auth.currentSession()
      .then((userData) => userData)
      .catch(() => console.log('Not signed in'));
  };

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = useCallback(
    async (email, password) => {
      // TODO: いる？いらない？
      // const result = await Auth.signIn(email, password);
      // console.log('🐠');

      return await new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        // 環境変数がdevelopmentかローカルの場合
        if (config.currentEnv === 'development' || config.currentEnv === 'local') {
          user.setAuthenticationFlowType('USER_PASSWORD_AUTH');
        }

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            // console.log('🈲', data.getIdToken().payload);
            getSession();
            resolve(data);
          },
          onFailure: (err) => {
            reject({
              isNewPasswordRequired: false,
              data: err,
            });
          },
          newPasswordRequired: () => {
            reject({
              isNewPasswordRequired: true,
              data: user,
            });
          },
        });
      });
    },
    [getSession],
  );

  const logout = async () => {
    const user = UserPool.getCurrentUser();

    // console.log('🚪 ログアウトが呼ばれました。', user);
    if (user) {
      // TODO: これだと失敗する.
      // await Auth.signOut({ global: true });
      // await Auth.signOut({ global: false });

      // TODO: これでもAzureのサインアウトはできない。
      // user.globalSignOut({
      //   onSuccess: (msg: string) => {},
      //   onFailure: (err: Error) => {},
      // });
      user.signOut();
      removeLocalStorage();
      location.reload(); // これがないと変にセッションがのこる？古い情報が残るケースがありそう
      dispatch({ type: Types.logout });
    } else {
      // console.log('ユーザの情報がないので何もしない');
    }

    // ここでログアウト処理
  };

  /**
   * ローカルストレージの不要データを念のため削除
   */
  const removeLocalStorage = () => {
    const keys = Object.keys(localStorage);
    const cognitoClientId = config.cognitoConfig.clientId;
    // Access Tokenの取得
    let i = keys.length;
    while (i--) {
      if (keys[i].includes(`${cognitoClientId}`) && localStorage.getItem(keys[i]) !== null) {
        localStorage.removeItem(keys[i]);
      }
    }
  };

  /**
   *
   * @param  user ユーザ
   * @param  password パスワード
   * @return 結果
   */
  const updatePassword = async (user: CognitoUser, password: string) => {
    return await new Promise((resolve, reject) => {
      user.completeNewPasswordChallenge(
        password,
        {},
        {
          onSuccess: () => {
            getSession();
            resolve(true);
          },
          onFailure: () => {
            reject(false);
          },
        },
      );
    });
  };

  const register = (email: string, password: string, firstName: string, lastName: string) =>
    new Promise((resolve, reject) => {
      UserPool.signUp(
        email,
        password,
        [new CognitoUserAttribute({ Name: 'email', Value: email }), new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })],
        [],
        async (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(undefined);
          window.location.href = PATH_AUTH.login;
        },
      );
    });

  const loginFromAzure = (session: CognitoUserSession) =>
    new Promise((resolve, reject) => {
      // console.log('aaaa', '😺', JSON.stringify(session));
      // getSession()

      if (!session) {
        // console.log('ユーザデータが無いのでサインアウトします。');
        dispatch({ type: Types.logout });
      } else {
        // const token = session?.getIdToken().getJwtToken();
        // use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
        // axios.defaults.headers.common.Authorization = token;
        // axios.defaults.headers.common.Authorization = 'Bearer ' + token;
        const user = UserPool.getCurrentUser()!;
        axios.interceptors.request.use(async (request) => {
          const token = await new Promise((resolve2) => {
            user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
              if (err) {
                // console.log(err);
                alert('セッションの有効時間が切れましたので再ログインしてください。(The session has expired, so please log in again.)'); // 遭遇頻度が低くsnackbarが使用できないためalertにて表示
              } else {
                const token = session?.getIdToken().getJwtToken();
                resolve2(token);
              }
            });
          });
          request.headers.Authorization = 'Bearer ' + token;
          return request;
        });

        // ここでPayloadの値を設定!!!!
        const payload = session?.getIdToken().payload || {};
        // console.log('🗻', session);
        const attributes: any = {};

        Object.keys(payload).forEach((key) => {
          // console.log('🍌', key, ':', payload![key]);
          attributes[key] = payload[key];
        });

        dispatch({
          type: Types.auth,
          payload: {
            isAuthenticated: true,
            user: attributes,
            // user: {displayName:"xxxx",email:"aaaaaa"}
          },
        });
        const loginPath: string | null = localStorage.getItem(loginPathNameLocalStrageKey);
        if (loginPath) {
          localStorage.removeItem(loginPathNameLocalStrageKey);
          location.href = loginPath!;
        }
      }
    });

  const resetPassword = async (email: string) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    await new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: (data) => {
          // ここには入らない想定
          // console.log(data);
          resolve(data);
        },
        onFailure: (err) => {
          // console.log(err);
          reject(err);
        },
        inputVerificationCode: (data) => {
          // console.log(data);
          resolve(data);
        },
      });
    });
  };

  const newPassword = async (email: string, code: string, password: string) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    await new Promise((resolve, reject) => {
      user.confirmPassword(code, password, {
        onSuccess() {
          resolve(() => console.log('Password confirmed!'));
        },
        onFailure(err) {
          console.log('Password not confirmed!');
          reject(err);
        },
      });
    });
  };

  const updateProfile = () => {};

  // await getSession()
  // console.log('🐣:state', state);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          displayName: state?.user?.['cognito:username'] || 'NO NAME',
          // TODO: rolesにしたい・・・
          role: JSON.parse(state?.user?.['roles'] || '[]'),
          subCompaniesArray: JSON.parse(state?.user?.['subCompanies'] || '[]'),
          subDepartmentsArray: JSON.parse(state?.user?.['subDepartments'] || '[]'),
          subDivisionsArray: JSON.parse(state?.user?.['subDivisions'] || '[]'),
          avatarName: state?.user?.['email'] || '[]',
          ...state.user,
        },
        login,
        register,
        logout,
        updateProfile,
        resetPassword,
        loginFromAzure,
        newPassword,
        updatePassword,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
