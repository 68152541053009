import { Grid } from '@mui/material';
import { StyledMarkdownWrapper } from 'src/shared/components/MarkdownRenderer';
import React from 'react';
import { ChatCompletionContentPartImage } from 'openai/resources';
import { OriginImageList } from 'src/contexts/ChatMessageReducer';
import { SpeakerIcon } from './SpeakerIcon';
import { apiGateway, prefixApiGateway } from 'src/config';
import { imageStart } from 'src/constants';
import ChatImagesCarousel from './ChatImagesCarousel';
interface ChatContentsProps {
  images: ChatCompletionContentPartImage[];
  downloadImg: (value?: string) => void;
  originImg?: OriginImageList[];
  isMobile: boolean;
}

export const ChatContentsImage = React.memo(({ images, downloadImg, originImg, isMobile }: ChatContentsProps): React.ReactElement => {
  const item = images.map((item) =>
    item.image_url.url.startsWith(imageStart) ? apiGateway + prefixApiGateway + item.image_url.url : item.image_url.url,
  );
  return (
    <Grid container direction="column" justifyContent="center" alignItems="stretch" sx={{ paddingX: '20px', width: '100%', alignItems: 'center' }}>
      <Grid
        container
        sx={{ paddingTop: '20px', paddingBottom: '20px', maxWidth: '800px' }}
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={0}>
        <Grid item xs={2} sm={2}>
          <SpeakerIcon flag={true} />
        </Grid>
        <Grid item xs={9} sm={9}>
          <StyledMarkdownWrapper>
            <ChatImagesCarousel images={item} isMobile={isMobile} originImg={originImg} downloadImg={downloadImg} />
          </StyledMarkdownWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
});
