import { Outlet } from 'react-router-dom';
import { TermsOfServiceDialog } from 'src/components/TermsOfServiceDialog';
import { useTermsOfService } from 'src/hooks/useTermsOfService';

export const TermsOfServiceGuard = () => {
  const tos = useTermsOfService();
  return (
    <>
      <TermsOfServiceDialog
        open={tos.open}
        terms={tos.terms}
        dialogTitle={tos.t('terms.dialog.title')}
        termsTitle={tos.displayTermsTitle}
        info={tos.displayTermsInfo}
        onAgree={tos.onAgree}
        onDisagree={tos.onDisagree}
        agreeButtonLabel={tos.t('TermsOfService.agree')}
        disagreeButtonLabel={tos.t('TermsOfService.disagree')}
      />
      <Outlet />
    </>
  );
};
